/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/api/compute/Taxon
 * @summary Requests and manipulation methods for taxonomies
 *
 */

/**
 * Format the taxon prefixing the parent if taxon.type === taxonType
 *
 * @param {object} taxon - the taxon to be formatted
 * @param {array} taxonomies - taxonomies on which retrieve parent
 * @param {string} taxonType - the type of the taxon (parent)
 */
export const formatTaxonPrefixingParent = (
    taxon: any,
    taxonomies: any,
    taxonType: string
) => {
    var formatted = taxon["name"];
    var parent = null;

    if (taxon["type"] === taxonType) {
        parent = getTaxonById(taxon["parent"], taxonomies);

        formatted = parent["name"] + " - " + formatted;
    }

    return formatted;
};

/**
 * Format the taxon prefixing ancestor
 *
 * @param {object} taxon - the taxon to be formatted
 * @param {array} taxonomies - taxonomies on which retrieve parent
 * @param @param {string} ancestorType - the type of the ancestor
 */
export const formatTaxonPrefixingAncestor: any = (
    taxon: any,
    taxonomies: any,
    ancestorType: string
) => {
    var formatted = taxon["name"];

    if (ancestorType != null) {
        var i = 0;
        var parent = null;

        while (parent == null && i < taxonomies.length) {
            parent = getAncestorByType(
                taxon["id"],
                taxonomies[i],
                ancestorType
            );
            i++;
        }

        if (parent != null && parent["id"] !== taxon["id"]) {
            formatted = parent["name"] + " - " + formatted;
        }
    }

    return formatted;
};

/**
 * Retrives the first matching taxon among taxonomies (order matters)
 *
 * @param {string} taxonId - the taxonony to which the taxon belongs. Default null
 * @param {array} taxonomies - taxonomies on which perform search
 * @param {number} taxonomyIndex - the index of taxonomy (in taxonomies)
 *   to be used
 */
export const getTaxonById: any = (
    taxonId: string,
    taxonomies: any,
    taxonomyIndex: number
) => {
    taxonomyIndex =
        taxonomyIndex === undefined || taxonomyIndex == null
            ? 0
            : taxonomyIndex;

    var taxon = taxonomies[taxonomyIndex][taxonId];

    if (taxon !== undefined) {
        return taxon;
    } else if (taxonomyIndex === taxonomies.length - 1) {
        return taxonId;
    } else {
        return getTaxonById(taxonId, taxonomies, taxonomyIndex + 1);
    }
};

/**
 * Retrives a taxon ancestor with the specified type (level)
 *
 * @param {string} taxonId - the ID of the taxon
 * @param {object} taxonomy - the taxonomy to which the taxon belongs.
 *      Default null
 * @param {string} ancestorType - the type of the ancestor
 */
export const getAncestorByType: any = (
    taxonId: string,
    taxonomy: any,
    ancestorType: string
) => {
    if (taxonomy[taxonId] === undefined) {
        return null;
    }

    if (
        taxonomy[taxonId].parent == null ||
        taxonomy[taxonId].type === ancestorType
    ) {
        return taxonomy[taxonId];
    }

    return getAncestorByType(taxonomy[taxonId].parent, taxonomy, ancestorType);
};
