import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";

type ProductListFilterProps = {
  value: any;
  filter: any;
  setFilter: Function;
  type: string;
};

export const ProductListFilter = ({
  value,
  filter,
  setFilter,
  type,
}: ProductListFilterProps) => {
  const filterByValue = filter[type];
  const { t } = useTranslation();

  const handleInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    // const target = event.currentTarget;
    setFilter((prevFilter: any) => {
      let updatedFilter = prevFilter;
      //   if (target.checked && !prevFilter[type].includes(value)) {
      //     updatedFilter = {
      //       ...prevFilter,
      //       [type]: prevFilter[type].concat(value),
      //     };
      //   } else if (!target.checked && prevFilter[type].includes(value)) {
      //     updatedFilter = {
      //       ...prevFilter,
      //       [type]: prevFilter[type].filter((item: string) => item !== value),
      //     };
      //   }

      if (prevFilter[type].includes(value)) {
        return prevFilter;
      }

      updatedFilter = {
        ...prevFilter,
        [type]: value !== "any" ? [value] : [],
      };

      return updatedFilter;
    });
  };

  return (
    <span className="me-0 me-sm-2 d-block d-sm-inline-block">
      <input
        checked={filterByValue?.includes(value)}
        type="checkbox"
        className="btn-check"
        id={`${type}-${value}-input`}
        onChange={handleInput}
        autoComplete="off"
        value={value}
      />
      <label
        className="btn btn-outline-light btn-filter d-block d-sm-inline-block mb-2"
        htmlFor={`${type}-${value}-input`}
      >
        {t(`${type}-${value}`, value)}
      </label>
    </span>
  );
};
