import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { paths } from "../types/Defaults";
import { PageWrap } from "./PageWrap";

export const TermsOfServiceText = () => {
    return (
        <div>
            <p>
                These terms and conditions govern your use of this Web
                Application/Website. By accessing this Web Application/Website,
                you agree that you have read and accepted these terms and
                conditions. If you do not wish to be bound by these terms and
                conditions, please do not continue to use this Web
                Application/Website.
            </p>

            <h4>Regulatory Status</h4>

            <p>This Web Application/Website is owned by Trendrating SA</p>

            <h4>Content and Use of this Web Application/Website</h4>

            <p>
                As a user, you may not sell, copy, publish, distribute,
                transfer, modify, display, reproduce, and/or create any
                derivative works from the information or software on this Web
                Application/Website. You may not redeliver any of the pages,
                text, images, or content of this Web Application/Website using
                “framing” or similar technology. You acknowledge that you have
                no right to use the content of this Web Application/Website in
                any other manner.
            </p>

            <p>
                The contents of this Web Application/Website, including text,
                graphics, links and/or other items, have been prepared based
                upon sources, materials, and systems believed to be reliable and
                accurate, and are provided to you on an “as is” and “as
                available” basis. Trendrating SA makes no representations, and
                disclaims all, express, implied and statutory warranties of any
                kind to you or any third party, including, but not limited to,
                representations, and warranties regarding accuracy, timeliness,
                completeness, merchantability, fitness for any particular
                purpose, non-infringement of third-party rights, and/or freedom
                from computer viruses.
            </p>

            <p>
                Links to other Web Applications/Web Application/Websites are
                provided for your convenience, and are not to be construed as an
                endorsement by Trendrating SA of such Web Applications/Web
                Application/Websites, their content, products and/or services,
                or vice versa. Trendrating SA accepts no responsibility for any
                of the content (regardless of what form this content is in) and
                the use of such third party Web Applications/Web
                Application/Websites is at your own risk.
            </p>

            <p>
                The content may not be copied or distributed to any other person
                without the written consent of Trendrating SA.
            </p>

            <p>
                Although Trendrating SA endeavors to update and ensure the
                accuracy of the content placed on this Web Application/Website,
                Trendrating SA does not warrant or guarantee the accuracy or
                correctness of it or of any other content, materials and/or
                offers placed on, featured or referred to in or made available
                by means of this Web Application/Website. Despite the exercise
                of all due care, some information on this Web
                Application/Website may have changed since the last update.
                Trendrating SA also does not warrant or guarantee that this Web
                Application/Website, its operation of this Web
                Application/Website, the content of this Web Application/Website
                or the server that makes the Web Application/Website available
                are error or virus free or free of other harmful components or
                that your use of this Web Application/Website and its content
                will be uninterrupted.
            </p>

            <h4>Restrictions on Use</h4>

            <p>
                Access to information displayed on this Web Application/Website
                may be restricted to certain persons in certain countries. The
                relevant prospectus contains information as to the selling
                restrictions applicable to the applicable funds or securities
                and the user should read them carefully.
            </p>

            <p>
                The securities referred to in this Web Application/Website may
                not be offered or sold directly or indirectly in the United
                States or for the account or benefit of any US Person or US
                resident.
            </p>

            <h4>Risk Warnings</h4>

            <p>
                The value of an investment in any securities listed on the Web
                Application/Website may go down as well as up and past
                performance is not a reliable indicator of future results.
                Investment in securities involves risks, for a detailed
                explanation of the risks involved please consult the applicable
                prospectus.
            </p>

            <p>
                Exchange rate movements can affect the value of investments
                involving exposure to foreign currencies.
                <br />
                Any projections or examples (including calculations used
                therein) set out in this Web Application/Website are for
                illustrative purposes only and are not guaranteed to be
                accurate, complete or up to date.
            </p>

            <h4>Investment Research</h4>

            <p>
                Any research published on the site has not been prepared in
                accordance with legal requirements designed to promote the
                independence of research and Trendrating SA is not subject to
                any prohibition on dealing ahead of the dissemination of
                investment research.
            </p>

            <p>
                The research provided by Trendrating SA is based on a
                methodology of ‘Rating’ and ‘Scoring’, financial instruments and
                portfolios produced by the company proprietary multi-factor
                algorithm.
            </p>

            <p>
                Trendrating research does not require any opinion or
                intervention from analysts or other experts as it is fully
                model-driven.
            </p>

            <h4>Investment Advice</h4>

            <p>
                This Web Application/Website is not intended to provide specific
                investment advice including, without limitation, investment,
                financial, legal, accounting or tax advice, or to make any
                recommendations about the suitability of products for the
                circumstances of any particular investor. If you do require
                investment advice, please contact an independent broker or
                financial advisor.
            </p>

            <h4>No Offer</h4>

            <p>
                Nothing contained on this Web Application/Website should be
                considered an offer to purchase any of the securities as
                described on the Web Application/Website. Web
                Application/Websites are indicative and the information
                contained herein is for information purposes only.
            </p>

            <h4>Exclusion of Liability</h4>

            <p>
                In no event shall Trendrating SA be liable for any damages,
                including without limitation direct or indirect, special,
                incidental, or consequential damages, losses or expenses arising
                in connection with this Web Application/Website or the use
                thereof or inability to use by any party, or in connection with
                any failure of performance, error, omission, interruption,
                defect, delay in operation or transmission, computer virus or
                line or system failure, even if Trendrating SA , or
                representatives thereof, are advised of the possibility of such
                damages, losses or expenses. Trendrating SA do not limit or
                exclude liability for death or personal injury resulting from
                its negligence or where this would be prohibited by law
                (including the
            </p>

            <p>
                Rules of the UK Financial Conduct Authority). Use of hyperlinks
                to other internet resources is at your own risk.
            </p>

            <h4>Index Disclaimers</h4>

            <p>
                Any third party licensor trademarks referenced herein are
                trademarks of such third party licensor and have been licensed
                for use by Trendrating SA . The securities incorporating third
                party indexes are not created, sponsored, endorsed, recommended
                or promoted by such third party licensors and the licensors
                shall have any no liability in connection with the securities.
            </p>

            <h4>Privacy and Cookie Policy</h4>

            <p>
                If you provide Trendrating SA with personal information through
                this Web Application/Website, Trendrating SA will treat it in
                accordance with its privacy policy which can be found at privacy
                policy.
            </p>

            <p>
                Trendrating SA may collect data about your computer, including,
                where available, your IP address, operating system and browser
                type, for system administration and other similar purposes. Such
                data will be used in accordance with its cookie policy which can
                be found within the privacy policy.
            </p>

            <p>
                The internet is not a fully secure medium, and whilst
                Trendrating SA will endeavor to secure any private and
                confidential information sent to Trendrating SA, the
                confidentiality and privacy of such information sent through the
                internet cannot be guaranteed.
            </p>

            <h4>Copyright</h4>

            <p>
                The content and design of the Web Application/Website are
                protected by copyright and any copying of the Web
                Application/Website or its content requires the prior written
                consent of Trendrating SA.
                <br /> Variation
            </p>

            <p>
                Trendrating SA reserves the right to vary these terms and
                conditions from time to time. You can access the latest version
                of the terms and conditions on this Web Application/Website.
            </p>

            <h4>Governing Law and Jurisdiction</h4>

            <p>
                These terms and conditions and your access to and use of this
                Web Application/Website and the content are subject to Swiss law
                and the non-exclusive jurisdiction of the Swiss courts.
            </p>
        </div>
    );
};

export const TermsOfService = () => {
    return (
        <PageWrap>
            <div className="head-content mb-5 pb-3">
                <Container>
                    <h1>Terms of Service</h1>
                </Container>
            </div>
            <div className="mt-5 pb-3">
                <Container>
                    <TermsOfServiceText />
                    <div className="mt-5 pb-3">
                        <Link className="text-decoration-none" to={paths.home}>
                            Back to home
                        </Link>
                    </div>
                </Container>
            </div>
        </PageWrap>
    );
};
