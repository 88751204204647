import { ReactNode, useEffect, useState } from "react";
import { KnownHostnames } from "../types/Defaults";
import { Footer } from "./Footer";
import { Header } from "./Header";

type PageWrapMode = "fixed" | "default";

type PageWrapProps = {
  children: ReactNode;
  footerChildren?: ReactNode;
  headerChildren?: ReactNode;
  mode?: PageWrapMode;
};

export const PageWrap = ({
  children,
  footerChildren,
  headerChildren,
  mode = "default",
}: PageWrapProps) => {
  const hostname: KnownHostnames = window.location.hostname as KnownHostnames;
  const [viewType, setViewType] = useState<
    "longOnly" | "longShort" | "portfolios"
  >("longOnly");

  const developementViewTypeForcing: "longOnly" | "longShort" | "portfolios" =
    "portfolios";

  useEffect(() => {
    switch (hostname) {
      // case "longonly.trendrating.com":
      //   setViewType("longOnly");

      //   break;
      case "longshort.trendrating.com":
      case "longshortd.trendrating.com":
        setViewType("longShort");

        break;

      case "localhost":
        setViewType(developementViewTypeForcing);

        break;

      case "longonly.trendrating.com":
      case "develop.portfolios.trendrating.com":
      case "portfolios.trendrating.com":
      default:
        setViewType("portfolios");
    }
  }, [hostname]);

  return (
    <div
      className={`app-wrap ${
        mode === "default" ? "min-full-height" : "full-height"
      } d-flex flex-column`}
    >
      <Header viewType={viewType} className="flex-shrink-0">
        {headerChildren}
      </Header>
      <main className="fillHeight flex-grow-1 flex-shrink-1">{children}</main>
      <Footer className="flex-shrink-0">{footerChildren}</Footer>
    </div>
  );
};
