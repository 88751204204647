/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/api/account/endpoints
 * @summary Endpoints of Trendrating Account API
 *
 */

export const endpoints = {
    sessions: {
        get: "/trendrating-rest/api/useronline/sessionInfo",
        remove: "/trendrating-rest/api/useronline/resetCookies",
    },
    users: {
        register: "/trendrating-rest/api/pendingRegistration/register",
        login: "/trendrating-rest/api/useronline/login",
        logout: "/trendrating-rest/api/useronline/logout",
        updateFields: "/trendrating-rest/api/useronline/updateFields",
    },
};
