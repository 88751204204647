import { Col, Container, Row } from "react-bootstrap";
import {
  ColumnDefinition,
  ColumnDefinitionAlign,
  Formatter,
} from "tabulator-tables";
import { DownloadCsv } from "../../components/DownloadCsv";
import { Grid } from "../../components/Grid";
import { Lock } from "../../components/Lock";
import { LockTable } from "../../components/LockTable";
import { PeerWeight } from "../../components/PeerWeight";
import { useProperties } from "../../hooks/useProperties";

type ProductDetailHoldingsProps = {
  alignChartOnTop?: boolean;
  callback?: Function;
  isPieClickable?: boolean;
  product: any;
};

export const ProductDetailHoldings = ({
  product,
  callback,
  alignChartOnTop = false,
  isPieClickable = false,
}: ProductDetailHoldingsProps) => {
  const properties = useProperties();
  //   const { environment } = useEnvironment();

  //   const chartFormatter: Formatter = (cell, formatterParams, onRendered) => {
  //     onRendered(() => {
  //       const data: any = cell.getData();
  //       if (data.symbol == null) {
  //         return '<div className="table-chart-wrap"></div>';
  //       }

  //       const svgPath = `${environment.api.uri}/${product.type}/charts/${data.symbol}.svg`;
  //       ReactDOM.render(
  //         <GridChart cell={cell} data={data} imageUrl={svgPath} />,
  //         cell.getElement()
  //       );
  //     });
  //     return '<div className="table-chart-wrap"></div>';
  //   };

  // cell does only contain getElement or getValue
  // Using the title field as property field
  const titleFormatter: Formatter = (cell, formatterParams, onRendered) => {
    const label = properties.get(cell.getValue());
    return `<span title="${label}">${label}</span>`;
  };

  const columns: ColumnDefinition[] = [
    {
      title: "ticker",
      titleFormatter: titleFormatter,
      field: "ticker",
      widthGrow: 1,
    },
    {
      title: "weight",
      titleFormatter: titleFormatter,
      field: "weight",
    },
    {
      title: "name",
      titleFormatter: titleFormatter,
      field: "name",
      widthGrow: 3,
    },
    {
      title: "country",
      titleFormatter: titleFormatter,
      field: "country",
    },
    {
      hozAlign: "center" as ColumnDefinitionAlign,
      title: "currency",
      titleFormatter: titleFormatter,
      field: "currency",
    },
    // {
    //     hozAlign: "center" as ColumnDefinitionAlign,
    //     title: "rc",
    //     titleFormatter: titleFormatter,
    //     field: "rc",
    // },
    // {
    //     hozAlign: "right" as ColumnDefinitionAlign,
    //     title: "pr",
    //     titleFormatter: titleFormatter,
    //     field: "pr",
    // },
    // {
    //     headerSort: false,
    //     title: "",
    //     field: "chart",
    //     formatter: chartFormatter,
    //     width: 70,
    //     widthGrow: 0,
    //     widthShrink: 0,
    // },
  ];

  if (!product) {
    return <>"Loading..."</>;
  }

  // Prepare data
  const { holdings, info, isTcrMode, productId, peerWeights } = product;

  const csvHeaders = ["ticker", "weight", "name", "country", "currency"];

  const isLongShort = info.method === "longShort";

  return (
    <Container fluid className="g-3 pb-4">
      <Row className="mt-4">
        {isLongShort ? (
          <>
            <Col md={6}>
              <PeerWeight
                alignChartOnTop={alignChartOnTop}
                peerWeights={peerWeights}
                isPieClickable={isPieClickable}
                isTcrMode={isTcrMode}
                mode="market"
                chartType="histogram"
              />
            </Col>
            <Col md={6}>
              <PeerWeight
                alignChartOnTop={alignChartOnTop}
                peerWeights={peerWeights}
                isPieClickable={isPieClickable}
                isTcrMode={isTcrMode}
                mode="sector"
                chartType="histogram"
              />
            </Col>
          </>
        ) : (
          <>
            <Col md={6}>
              <PeerWeight
                alignChartOnTop={alignChartOnTop}
                peerWeights={peerWeights}
                isPieClickable={isPieClickable}
                isTcrMode={isTcrMode}
                mode="market"
                chartType="pie"
              />
            </Col>
            <Col md={6}>
              <PeerWeight
                alignChartOnTop={alignChartOnTop}
                peerWeights={peerWeights}
                isPieClickable={isPieClickable}
                isTcrMode={isTcrMode}
                mode="sector"
                chartType="pie"
              />
            </Col>
          </>
        )}
        <Col md={12}>
          {holdings != null ? (
            <>
              <div className="product-analytics-actions pt-2 pb-2 d-flex justify-content-end">
                <Lock>
                  <DownloadCsv
                    productId={productId}
                    data={holdings}
                    headers={csvHeaders}
                    download={`${info.name}.csv`}
                    title="Full Holdings .CSV"
                  ></DownloadCsv>
                </Lock>
              </div>
              <LockTable>
                <Grid
                  className="product-analytics-holdings"
                  columns={columns}
                  options={{ initialSort: [{ column: "weight", dir: "desc" }] }}
                  data={holdings}
                  callback={callback}
                />
              </LockTable>
            </>
          ) : (
            "No data"
          )}
        </Col>
      </Row>
    </Container>
  );
};
