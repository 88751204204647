import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { decodeUser } from "../trendrating/api/account/Decoder";
import { endpoints } from "../trendrating/api/account/endpoints";
import { User } from "../types/Defaults";
import { useKeen } from "./useKeen";

type Auth = {
    isReady: boolean;
    isLogged: boolean;
    register: any;
    signin: any;
    signout: any;
    user: any;
    editProfile: any;
};

type RegisterProps = {
    email: string;
    firstName: string;
    lastName: string;
    profile: string;
    userName: string;
    country: string;
    firm: string;
};

const AuthContext = createContext<Auth>({
    isReady: false,
    isLogged: false,
    register: null,
    signin: null,
    signout: null,
    user: null,
    editProfile: null,
});
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(AuthContext);
};
// Provider hook that creates auth object and handles state
const useProvideAuth = (): Auth => {
    const { recordEvent } = useKeen();
    const [isReady, setReady] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>();

    useEffect(() => {
        // Exclude login check in special page
        if (document.location.pathname === "/capture") {
            setUser(null);
            setReady(true);
            return;
        }
        axios.get(endpoints.sessions.get, { withCredentials: true }).then(
            (response) => {
                if (response.data.status === "KO") {
                    if (response.data.error === "USER_NOT_FOUND") {
                        return Promise.reject({
                            type: "USER_NOT_FOUND",
                            message: null,
                        });
                    }
                    return Promise.reject({
                        type: "UNKNOWN_ERROR",
                        message: null,
                    });
                }

                const decodedUser = decodeUser(response.data.data);
                setUser(decodedUser);
                setReady(true);
                return decodedUser;
            },
            (error) => {
                setUser(null);
                setReady(true);
                // No need to return anything
            }
        );
    }, []);

    const register = ({
        email,
        firstName,
        lastName,
        profile,
        userName,
        country,
        firm,
    }: RegisterProps) =>
        axios
            .post(
                endpoints.users.register,
                {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    profile: profile,
                    userName: userName,
                    country: country,
                    firm: firm,
                },
                {
                    headers: {
                        // "Authorization":
                        //     "Basic " + btoa(email + ":" + password),
                        "Content-Type": "application/json",
                    },
                    params: {
                        ts: new Date().getTime(),
                    },
                }
            )
            .then(
                (response) => {
                    console.log(response);
                },
                (error) => {
                    return Promise.reject({
                        type: error,
                        message: error,
                    });
                }
            );

    const signin = (email: string, password: string) =>
        // axios.get(endpoints.sessions.remove).then(() =>
        axios
            .post(
                endpoints.users.login,
                {
                    password: password,
                    userName: email,
                },
                {
                    headers: {
                        // "Authorization":
                        //     "Basic " + btoa(email + ":" + password),
                        "Content-Type": "application/json",
                    },
                    params: {
                        ts: new Date().getTime(),
                    },
                }
            )
            .then(
                (response) => {
                    // Manage error
                    if (response.data.status === "KO") {
                        if (response.data.error === "USER_NOT_FOUND") {
                            return Promise.reject({
                                type: "USER_NOT_FOUND",
                                message: null,
                            });
                        }
                        return Promise.reject({
                            type: "UNKNOWN_ERROR",
                            message: null,
                        });
                    }

                    const decodedUser = decodeUser(response.data.data.user);

                    setUser(decodedUser);

                    recordEvent("login", null, decodedUser);

                    return decodedUser;
                },
                (error) => {
                    setUser(null);
                    return Promise.reject({
                        type: "USER_NOT_FOUND",
                        message: error,
                    });
                }
            );
    // );

    const signout = () =>
        axios
            .get(endpoints.users.logout, {
                params: {
                    ts: new Date().getTime(),
                },
            })
            .then(() => {
                setUser(null);
            });

    const editProfile = async <T extends { id: number }>(fieldsToUpdate: T) => {
        const res = await axios.post(
            endpoints.users.updateFields,
            fieldsToUpdate
        );
        setUser(res?.data?.data?.user ?? user);
        return res;
    };

    const isLogged = user != null;

    // Return the user object and auth methods
    return {
        isReady,
        isLogged,
        register,
        signin,
        signout,
        editProfile,
        user,
    };
};
