/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/formatter/RankingSelection
 * @summary Format ranking and selection rules
 *
 */

import { Properties } from "../../api/Properties";
import { FormOptions } from "./FormOptions";

export class RankingSelection /* extends "trendrating-widgets/FormatRankingSelection" */ {
    formatType: any = null;
    label: any = null;

    options: any = null;
    // must be unified and have the same structure of selection
    optionsRanking: any = null;
    properties: any = null;

    constructor({ environment, formatType }: any) {
        this.options = new FormOptions(environment);

        var configurationBuilder = environment["configuration"].get(
            "strategyBuilder"
        );
        this.optionsRanking = this._prepareOptionsRanking(
            configurationBuilder["ranking"]["edit"]["options"]
        );

        if (formatType != null) {
            this.formatType = formatType;
        } else {
            this.formatType = "HTML";
        }

        this.properties = environment.properties;
        this.label = new Properties({
            properties: this.properties,
        });
    }

    function(property: string, functionName: string, functionParams: any) {
        var formatted = "";

        switch (functionName) {
            case "outlier": {
                formatted = "Outlier";

                break;
            }
            case "quantile": {
                switch (functionParams["value"]) {
                    case 4: {
                        formatted = "Quartile";
                        break;
                    }
                    case 5: {
                        formatted = "Quintile";
                        break;
                    }
                    case 10: {
                        formatted = "Decile";
                        break;
                    }
                    default: {
                        formatted = `Quantile (${functionParams["value"]})`;
                    }
                }

                break;
            }
            case "threshold": {
                formatted = "Threshold";

                break;
            }
            case "value": {
                //formatted = "Value";
                formatted = "";

                break;
            }
            default: {
                formatted = "#UNKNOWN";
            }
        }

        return formatted;
    }

    operator(
        property: string,
        functionName: string,
        operatorName: string,
        operatorParams: any
    ) {
        var formatted = "";
        var _formatted = null; // for intermediate manipulation
        var options = this.options;

        switch (operatorName) {
            case "bottom": /* case "bottomPercentage": */ {
                // _formatted = operatorParams["value"];
                // formatted =
                //     _formatted > 0
                //         ? [
                //               "Bottom",
                //               _formatted
                //           ].join(" ")
                //         : "Bottom";

                formatted = this._formatBottomTop(
                    operatorParams["value"],
                    "Bottom"
                );

                break;
            }
            // case "bottomPercentage": {
            //     _formatted = operatorParams["value"];
            //     formatted =
            //         _formatted > 0
            //             ? [
            //                   "Bottom",
            //                   _formatted + "%"
            //               ].join(" ")
            //             : "Bottom";

            //     break;
            // }
            case "equalTo": {
                _formatted = operatorParams["value"];
                formatted = ["Is equal to", _formatted].join(" ");

                break;
            }
            case "equalToPercentage": {
                _formatted = operatorParams["value"];
                formatted = ["Is equal to", _formatted + "%"].join(" ");

                break;
            }
            case "equalToRate": {
                _formatted = [];

                if (operatorParams["value"]["A"] === true) {
                    switch (this.formatType) {
                        case "HTML": {
                            _formatted.push(
                                '<strong class="rate rate--A">A</strong>'
                            );
                            break;
                        }
                        default: {
                            _formatted.push("A");
                            break;
                        }
                    }
                }

                if (operatorParams["value"]["B"] === true) {
                    switch (this.formatType) {
                        case "HTML": {
                            _formatted.push(
                                '<strong class="rate rate--B">B</strong>'
                            );
                            break;
                        }
                        default: {
                            _formatted.push("B");
                            break;
                        }
                    }
                }

                if (operatorParams["value"]["C"] === true) {
                    switch (this.formatType) {
                        case "HTML": {
                            _formatted.push(
                                '<strong class="rate rate--C">C</strong>'
                            );
                            break;
                        }
                        default: {
                            _formatted.push("C");
                            break;
                        }
                    }
                }

                if (operatorParams["value"]["D"] === true) {
                    switch (this.formatType) {
                        case "HTML": {
                            _formatted.push(
                                '<strong class="rate rate--D">D</strong>'
                            );
                            break;
                        }
                        default: {
                            _formatted.push("D");
                            break;
                        }
                    }
                }

                if (_formatted.length === 0) {
                    _formatted.push("Any");
                }

                formatted = _formatted.join(", ");

                break;
            }
            case "greaterThan":
            case "greaterThanOrEqualTo":
            case "lessThan":
            case "lessThanOrEqualTo":
            case "range": {
                formatted = this._formatRange(operatorParams["value"]);

                break;
            }
            case "greaterThanPercentage":
            case "greaterThanOrEqualToPercentage":
            case "lessThanPercentage":
            case "lessThanOrEqualToPercentage":
            case "rangePercentage": {
                formatted = this._formatRangePercentage(
                    operatorParams["value"]
                );

                break;
            }
            case "rangeMarketCap": {
                _formatted = options.getOption(
                    "MARKET_CAP",
                    operatorParams["value"][0]
                );

                if (
                    _formatted["raw_value"]["ge"] == null &&
                    _formatted["raw_value"]["le"] == null
                ) {
                    formatted =
                        _formatted["ge"] === _formatted["le"]
                            ? _formatted["ge"]
                            : _formatted["le"] == null
                            ? _formatted["ge"]
                            : [
                                  "Between",
                                  _formatted["ge"],
                                  "and",
                                  _formatted["le"],
                              ].join(" ");
                } else {
                    formatted =
                        _formatted["ge"] === _formatted["le"]
                            ? _formatted["ge"]
                            : _formatted["le"] == null
                            ? _formatted["ge"]
                            : [_formatted["ge"], "-", _formatted["le"]].join(
                                  " "
                              );
                }

                break;
            }
            case "rangeVolatility": {
                _formatted = options.getOption(
                    "VOLATILITY",
                    operatorParams["value"][0]
                );

                formatted =
                    _formatted["ge"] === _formatted["le"]
                        ? _formatted["ge"]
                        : _formatted["le"] == null
                        ? _formatted["ge"]
                        : [_formatted["ge"], "-", _formatted["le"]].join(" ");

                break;
            }
            case "sortByExists":
            case "sortByNumeric":
            case "sortByOutlier":
            case "sortByQuantile":
            case "sortByPerformance":
            case "sortByRating":
            case "sortByRetracement":
            case "sortByString":
            case "sortByThreshold": {
                _formatted =
                    this.optionsRanking[property]["functions"][functionName];
                _formatted = _formatted["operators"][operatorName];
                formatted = _formatted[operatorParams["value"]]["label"];

                break;
            }
            case "top": /* case "topPercentage": */ {
                // _formatted = operatorParams["value"];
                // formatted =
                //     _formatted > 0
                //         ? [
                //               "Top",
                //               _formatted
                //           ].join(" ")
                //         : "Top";

                formatted = this._formatBottomTop(
                    operatorParams["value"],
                    "Top"
                );

                break;
            }
            // case "topPercentage": {
            //     _formatted = operatorParams["value"];
            //     formatted =
            //         _formatted > 0
            //             ? [
            //                   "Top",
            //                   _formatted + "%"
            //               ].join(" ")
            //             : "Top";

            //     break;
            // }
            default: {
                formatted = `#UNKNOWN - ${operatorName}`;
            }
        }

        return formatted;
    }

    property(property: string, propertyLabel: any, labelIndex: number) {
        labelIndex =
            labelIndex === undefined || labelIndex == null ? 0 : labelIndex;

        if (propertyLabel === undefined || propertyLabel == null) {
            var label = this.label;
            return label.get(property, labelIndex);
        }

        return propertyLabel[property];
    }
    // ----------------------------------------------------- private methods
    //
    _formatBottomTop(value: string, label: string) {
        return `${label} ${value}`;
    }

    _formatRange(value: any[]) {
        var _formatted = value[0];
        var formatted = [];
        if (value.length > 1) {
            formatted.push("Between:");
        }
        var keyCounter = 0;
        var start = null;
        var end = null;

        for (var key in _formatted) {
            switch (key) {
                case "ge":
                case "gt": {
                    start = _formatted[key];

                    break;
                }
                case "le":
                case "lt": {
                    end = _formatted[key];

                    break;
                }
            }
            keyCounter++;
        }

        if (keyCounter === 1) {
            if ("ge" in _formatted) {
                // is greater than or equal to
                formatted.push("Is greater than or equal to", start);
            } else if ("gt" in _formatted) {
                // is greater than
                formatted.push("Is greater than", start);
            } else if ("le" in _formatted) {
                // is less than or equal to
                formatted.push("Is less than or equal to", end);
            } else if ("lt" in _formatted) {
                // is less than
                formatted.push("Is less than", end);
            }
        } else {
            // range
            formatted = [
                "Between",
                start,
                "and",
                end,
            ];
        }

        if (value.length > 1) {
            formatted.push("...");
        }

        return formatted.join(" ");
    }

    _formatRangePercentage(value: any[]) {
        var _formatted = value[0];
        var formatted: any[] = [];
        var keyCounter = 0;
        var start = null;
        var end = null;

        for (var key in _formatted) {
            switch (key) {
                case "ge":
                case "gt": {
                    start = _formatted[key] * 100;

                    break;
                }
                case "le":
                case "lt": {
                    end = _formatted[key] * 100;

                    break;
                }
            }
            keyCounter++;
        }

        if (keyCounter === 1) {
            if ("ge" in _formatted) {
                // is greater than or equal to
                formatted = ["Is greater than or equal to", start + "%"];
            } else if ("gt" in _formatted) {
                // is greater than
                formatted = ["Is greater than", start + "%"];
            } else if ("le" in _formatted) {
                // is less than or equal to
                formatted = ["Is less than or equal to", end + "%"];
            } else if ("lt" in _formatted) {
                // is less than
                formatted = ["Is less than", end + "%"];
            }
        } else {
            // range
            formatted = [
                "Between",
                start + "%",
                "and",
                end + "%",
                value.length > 1 ? "..." : "",
            ];
        }

        return formatted.join(" ");
    }

    // create an inverted map that is accessible using
    // raw values (e.g. rc, quantile, desc)
    _prepareOptionsRanking(options: any) {
        var itemFunction = null;
        var itemOperator = null;
        var keyFunction = null;
        var option = null;
        var optionsMap: any = {};
        var property = null;

        for (var i = 0, lengthI = options.length; i < lengthI; i++) {
            option = options[i];

            property = option["property"]["value"];

            optionsMap[property] = {
                functions: {},
            };

            // functions and operators (sortBy) have the same length
            // and there is a one-to-one relation among items
            for (
                var j = 0, lengthJ = option["functions"].length;
                j < lengthJ;
                j++
            ) {
                itemFunction = option["functions"][j];
                // [0] because every function has only 1 operator
                itemOperator = option["operators"][j][0];

                keyFunction = itemFunction["value"];
                optionsMap[property]["functions"][keyFunction] = {
                    label: itemFunction["label"],
                    operators: {},
                };

                optionsMap[property]["functions"][keyFunction]["operators"][
                    itemOperator["value"]
                ] = {};

                const key =
                    optionsMap[property]["functions"][keyFunction]["operators"][
                        itemOperator["value"]
                    ];
                for (
                    var k = 0,
                        lengthK = itemOperator["widget"]["options"].length;
                    k < lengthK;
                    k++
                ) {
                    key[itemOperator["widget"]["options"][k]["value"]] = {
                        label: itemOperator["widget"]["options"][k]["label"],
                    };
                }
            }
        }

        return optionsMap;
    }
}
