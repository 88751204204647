import { useCallback, useMemo } from "react";
import { useFormatter } from "../../hooks/useFormatter";
import { DispersionDetail } from "../../pages/Capture";

type CaptureProps = {
    dispersion?: DispersionDetail;
    type: "winners" | "losers";
};

export function CaptureStatistic({ dispersion, type }: CaptureProps) {
    const formatter = useFormatter();
    const formatPercentage = useCallback(
        (value) =>
            formatter != null &&
            formatter.custom("number", {
                options: {
                    hasPositiveSign: false,
                    isPercentage: true,
                    notAvailable: {
                        input: null,
                        output: "",
                    },
                },
                output: "TEXT",
                value: value,
                valueHelper: null,
            }),
        [formatter]
    );

    const dField = useMemo(
        () => dispersion?.info.byAgeField,
        [dispersion?.info.byAgeField]
    );
    // const maxThreshold = dispersion.info.top;
    //     const minThreshold = dispersion.info.bottom;
    //     const cluster = dispersion.aggregated.top;

    return (
        <>
            <table className="table tableABvsCDPublic">
                <thead>
                    <tr>
                        <th>
                            {type === "winners" ? (
                                <>
                                    <span className="rate_A">A</span>
                                    <span className="rate_B">B</span>
                                </>
                            ) : (
                                <>
                                    <span className="rate_C">C</span>
                                    <span className="rate_D">D</span>
                                </>
                            )}{" "}
                            rated stocks
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Number of Stocks</td>
                        <td className="text-end">
                            {dispersion && type === "winners"
                                ? dispersion?.byAge?.rc?.clusterStats?.["0"]
                                      .cardinality
                                : dispersion?.byAge?.rc?.clusterStats?.["1"]
                                      .cardinality}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Number of
                            {type === "winners" ? " positive " : " negative "}
                            trends
                        </td>
                        <td className="text-end">
                            {dispersion ? (
                                type === "winners" ? (
                                    dispersion.byAge?.[`rc|${dField}`]
                                        ?.clusterStats?.["0|0"]?.cardinality
                                ) : (
                                    dispersion.byAge?.[`rc|${dField}`]
                                        ?.clusterStats?.["1|1"]?.cardinality
                                )
                            ) : (
                                <></>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            % of
                            {type === "winners"
                                ? " positive "
                                : " negative "}{" "}
                            trends
                        </td>
                        <td className="text-end">
                            {dispersion &&
                                formatPercentage(
                                    type === "winners"
                                        ? dispersion.byAge?.[`rc|${dField}`]
                                              ?.clusterStats?.["0|0"]
                                              ?.cardinality /
                                              dispersion?.byAge?.rc
                                                  ?.clusterStats?.["0"]
                                                  .cardinality
                                        : dispersion.byAge?.[`rc|${dField}`]
                                              ?.clusterStats?.["1|1"]
                                              ?.cardinality /
                                              dispersion?.byAge?.rc
                                                  ?.clusterStats?.["1"]
                                                  .cardinality
                                )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {type === "winners" ? "Positive " : "Negative "}{" "}
                            trends average performance
                        </td>
                        <td className="text-end">
                            {dispersion &&
                                formatPercentage(
                                    type === "winners"
                                        ? dispersion?.byAge?.[`rc|${dField}`]
                                              ?.clusterStats?.["0|0"]?.[
                                              `${dField}#avg#false`
                                          ]
                                        : dispersion?.byAge?.[`rc|${dField}`]
                                              ?.clusterStats?.["1|1"]?.[
                                              `${dField}#avg#false`
                                          ]
                                )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Number of{" "}
                            {type === "winners" ? "negative " : "positive "}{" "}
                            trends
                        </td>
                        <td className="text-end">
                            {dispersion != null && type === "winners"
                                ? dispersion?.byAge?.[`rc|${dField}`]
                                      ?.clusterStats?.["0|1"]?.cardinality
                                : dispersion?.byAge?.[`rc|${dField}`]
                                      ?.clusterStats?.["1|0"]?.cardinality}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            % of{" "}
                            {type === "winners" ? "negative " : "positive "}{" "}
                            trends
                        </td>
                        <td className="text-end">
                            {dispersion &&
                                formatPercentage(
                                    type === "winners"
                                        ? dispersion?.byAge?.[`rc|${dField}`]
                                              ?.clusterStats?.["0|1"]
                                              ?.cardinality /
                                              dispersion?.byAge?.rc
                                                  ?.clusterStats?.["0"]
                                                  .cardinality
                                        : dispersion?.byAge?.[`rc|${dField}`]
                                              ?.clusterStats?.["1|0"]
                                              ?.cardinality /
                                              dispersion?.byAge?.rc
                                                  ?.clusterStats?.["1"]
                                                  .cardinality
                                )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {type === "winners" ? "Negative " : "Positive "}{" "}
                            trends average performance
                        </td>
                        <td className="text-end">
                            {dispersion &&
                                formatPercentage(
                                    type === "winners"
                                        ? dispersion?.byAge?.[`rc|${dField}`]
                                              ?.clusterStats?.["0|1"]?.[
                                              `${dField}#avg#false`
                                          ]
                                        : dispersion?.byAge?.[`rc|${dField}`]
                                              ?.clusterStats?.["1|0"]?.[
                                              `${dField}#avg#false`
                                          ]
                                )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );

    // ______________________________________________________________________________________________

    // const formatter = useFormatter();
    // const formatPercentage = useCallback(
    //     (value) =>
    //         formatter != null &&
    //         formatter.custom("number", {
    //             options: {
    //                 hasPositiveSign: false,
    //                 isPercentage: true,
    //                 decimals: 0,
    //                 notAvailable: {
    //                     input: null,
    //                     output: "",
    //                 },
    //             },
    //             output: "TEXT",
    //             value: value,
    //             valueHelper: null,
    //         }),
    //     [formatter]
    // );

    // const formatPercentagePlusSign = useCallback(
    //     (value) =>
    //         formatter != null &&
    //         formatter.custom("number", {
    //             options: {
    //                 hasPositiveSign: true,
    //                 isPercentage: true,
    //                 decimals: 0,
    //                 notAvailable: {
    //                     input: null,
    //                     output: "",
    //                 },
    //             },
    //             output: "TEXT",
    //             value: value,
    //             valueHelper: null,
    //         }),
    //     [formatter]
    // );

    // // const maxThreshold = dispersion.info.top;
    // //     const minThreshold = dispersion.info.bottom;
    // //     const cluster = dispersion.aggregated.top;

    // console.log(dispersion?.byAge);

    // if (dispersion == null) {
    //     return <></>;
    // }

    // // there is AB+, AB-, CD+, CD-, also AB = AB+ + AB- and CD = CD+ + CD-
    // const clusterStatsAccessor = `rc|${dispersion["info"]["field"]}`;

    // const abCardinality =
    //     dispersion.byAge?.["rc"]?.clusterStats?.["0"]?.cardinality ?? 0;
    // const abCardinalityPlus =
    //     dispersion.byAge?.[clusterStatsAccessor]?.clusterStats?.["0|0"]
    //         ?.cardinality ?? 0;
    // const abCardinalityMinus =
    //     dispersion.byAge?.[clusterStatsAccessor]?.clusterStats?.["0|1"]
    //         ?.cardinality ?? 0;
    // const abAveragePlus =
    //     dispersion.byAge?.[clusterStatsAccessor]?.clusterStats?.["0|0"]?.[
    //         `${dispersion["info"]["field"]}#avg#false`
    //     ] ?? 0;
    // const abAverageMinus =
    //     dispersion.byAge?.[clusterStatsAccessor]?.clusterStats?.["0|1"]?.[
    //         `${dispersion["info"]["field"]}#avg#false`
    //     ] ?? 0;

    // const cdCardinality =
    //     dispersion.byAge?.["rc"]?.clusterStats?.["1"]?.cardinality ?? 0;
    // const cdCardinalityPlus =
    //     dispersion.byAge?.[clusterStatsAccessor]?.clusterStats?.["1|0"]
    //         ?.cardinality ?? 0;
    // const cdCardinalityMinus =
    //     dispersion.byAge?.[clusterStatsAccessor]?.clusterStats?.["1|1"]
    //         ?.cardinality ?? 0;
    // const cdAveragePlus =
    //     dispersion.byAge?.[clusterStatsAccessor]?.clusterStats?.["1|0"]?.[
    //         `${dispersion["info"]["field"]}#avg#false`
    //     ] ?? 0;
    // const cdAverageMinus =
    //     dispersion.byAge?.[clusterStatsAccessor]?.clusterStats?.["1|1"]?.[
    //         `${dispersion["info"]["field"]}#avg#false`
    //     ] ?? 0;

    // let abPercentagePlus = 0;
    // let abPercentageMinus = 0;
    // if (abCardinality > 0) {
    //     abPercentagePlus = abCardinalityPlus / abCardinality;
    //     abPercentageMinus = abCardinalityMinus / abCardinality;
    // }

    // let cdPercentagePlus = 0;
    // let cdPercentageMinus = 0;
    // if (cdCardinality > 0) {
    //     cdPercentagePlus = cdCardinalityPlus / cdCardinality;
    //     cdPercentageMinus = cdCardinalityMinus / cdCardinality;
    // }

    // // const max = Math.max(abAveragePlus, cdAveragePlus);
    // // const min = Math.min(abAverageMinus, cdAverageMinus);

    // // const max = Math.max(
    // //     maxPlus > 0 ? maxPlus : -maxPlus,
    // //     minMinus > 0 ? minMinus : -minMinus
    // // );
    // // const min = Math.min(
    // //     maxPlus > 0 ? -maxPlus : maxPlus,
    // //     minMinus > 0 ? -minMinus : minMinus
    // // );

    // const baseOptions: Highcharts.Options = {
    //     chart: {
    //         marginBottom: 0,
    //         marginLeft: 0,
    //         marginRight: 0,
    //         marginTop: 0,
    //         width: 100,
    //         height: 100,
    //     },
    //     credits: { enabled: false },
    //     exporting: { enabled: false },
    //     legend: { enabled: false, reversed: true },
    //     scrollbar: { enabled: false },
    //     navigator: { enabled: false },
    //     rangeSelector: {
    //         enabled: false,
    //     },
    //     title: {
    //         text: undefined,
    //     },
    //     xAxis: {
    //         categories: ["Up", "Down"],
    //         labels: {
    //             enabled: false,
    //         },
    //         visible: false,
    //     },
    //     yAxis: {
    //         startOnTick: false,
    //         endOnTick: false,
    //         title: {
    //             text: null,
    //         },
    //         labels: {
    //             enabled: false,
    //         },
    //         gridLineWidth: 0,
    //         plotLines: [
    //             {
    //                 color: "#cccccc",
    //                 width: 1,
    //                 value: 0,
    //             },
    //         ],
    //     },
    //     plotOptions: {
    //         bar: {
    //             softThreshold: true,
    //         },
    //     },
    //     tooltip: {
    //         useHTML: true,
    //         enabled: true,
    //         outside: true,
    //         formatter: function () {
    //             return `${this.x}:&nbsp;<b>${formatPercentagePlusSign(
    //                 this.y
    //             )}</b>`;
    //         },
    //     },
    // };

    // const abChartOptions = {
    //     ...baseOptions,
    //     series: [
    //         {
    //             color: "green",
    //             data: [
    //                 { x: 0, y: abAveragePlus },
    //                 { x: 1, y: abAverageMinus },
    //             ],
    //             negativeColor: "red",
    //             pointWidth: 30,
    //             type: "column",
    //         },
    //     ],
    // };

    // const cdChartOptions = {
    //     ...baseOptions,
    //     series: [
    //         {
    //             color: "green",
    //             data: [
    //                 { x: 0, y: cdAveragePlus },
    //                 { x: 1, y: cdAverageMinus },
    //             ],
    //             negativeColor: "red",
    //             pointWidth: 30,
    //             type: "column",
    //         },
    //     ],
    // };

    // //https://api.highcharts.com/highcharts/plotOptions.series.negativeColor
    // return (
    //     <table className="table table-small-width mt-4">
    //         <tbody>
    //             <tr>
    //                 <td colSpan={4}></td>
    //                 <th colSpan={3} className="text-center">
    //                     {`${dispersion.info.byAgefMnemonic} average performance`}
    //                 </th>
    //             </tr>
    //             <tr>
    //                 <th className="text-center fs-5" rowSpan={2}>
    //                     <span className="rate_A">A</span>
    //                     <span className="rate_B">B</span>
    //                 </th>
    //                 <td rowSpan={2} className="text-center">
    //                     {abCardinality} stocks
    //                 </td>
    //                 <td className="text-end">{abCardinalityPlus} up</td>
    //                 <td className="text-end">
    //                     {formatPercentage(abPercentagePlus)}
    //                 </td>
    //                 <td className="text-end">
    //                     {formatPercentagePlusSign(abAveragePlus)}
    //                 </td>
    //                 <td rowSpan={2} className="text-center">
    //                     <Chart
    //                         className="d-inline-block"
    //                         constructorType="chart"
    //                         options={abChartOptions}
    //                         useAutomaticRatio={false}
    //                     />
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <td className="text-end">{abCardinalityMinus} down</td>
    //                 <td className="text-end">
    //                     {formatPercentage(abPercentageMinus)}
    //                 </td>
    //                 <td className="text-end">
    //                     {formatPercentagePlusSign(abAverageMinus)}
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <th className="text-center fs-5" rowSpan={2}>
    //                     <span className="rate_C">C</span>
    //                     <span className="rate_D">D</span>
    //                 </th>
    //                 <td rowSpan={2} className="text-center">
    //                     {cdCardinality} stocks
    //                 </td>
    //                 <td className="text-end">{cdCardinalityPlus} up</td>
    //                 <td className="text-end">
    //                     {formatPercentage(cdPercentagePlus)}
    //                 </td>
    //                 <td className="text-end">
    //                     {formatPercentagePlusSign(cdAveragePlus)}
    //                 </td>
    //                 <td rowSpan={2} className="text-center">
    //                     <Chart
    //                         className="d-inline-block"
    //                         constructorType="chart"
    //                         options={cdChartOptions}
    //                         useAutomaticRatio={false}
    //                     />
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <td className="text-end">{cdCardinalityMinus} down</td>
    //                 <td className="text-end">
    //                     {formatPercentage(cdPercentageMinus)}
    //                 </td>
    //                 <td className="text-end">
    //                     {formatPercentagePlusSign(cdAverageMinus)}
    //                 </td>
    //             </tr>
    //         </tbody>
    //     </table>
    // );
}
