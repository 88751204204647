/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/date/TDate
 * @summary Utils to manipulate Trendrating dates based on days
 *
 */
export class TDate {
    /**
     * Converts a native Date object to days (UTC) managing timezone
     *
     * @param {Date} dateObject - a native Date instance
     *
     * @returns {number} days
     */
    dateToDays(dateObject) {
        var milliseconds = dateObject.getTime();
        var timezoneOffset = dateObject.getTimezoneOffset(); // minutes

        var value = milliseconds - timezoneOffset * 60 * 1000;

        return this.millisecondsToDays(value);
    }

    /**
     * Convert a date object in an ISO 8601 string
     *
     * @param {Date} date
     *
     * @return {string} - an ISO 8601 string
     */
    dateToIso8601(date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var formatted = [
            year,
            this._prefixZero(month),
            this._prefixZero(day),
        ].join("-");

        return formatted;
    }

    /**
     * Converts days into a native Date object
     *
     * @param {number} days - number of days
     *
     * @returns {Date} a native Date object
     */
    daysToDate(days) {
        let date = new Date(this.daysToMilliseconds(days));
        date.setHours(0, 0, 0, 0); // Reset hour, min, seconds, ms
        return date;
    }

    /**
     * Convert days in an ISO 8601 string
     *
     * @param {number} days
     *
     * @return {string} - an ISO 8601 string
     */
    daysToIso8601(days) {
        var milliseconds = this.daysToMilliseconds(days);
        var date = new Date(milliseconds);

        var day = date.getUTCDate();
        var month = date.getUTCMonth() + 1;
        var year = date.getUTCFullYear();

        var formatted = [
            year,
            this._prefixZero(month),
            this._prefixZero(day),
        ].join("-");

        return formatted;
    }

    /**
     * Converts days in milliseconds
     *
     * @param {number} days - number of days
     *
     * @returns {number} milliseconds
     */
    daysToMilliseconds(days) {
        days = parseInt(days);
        var quotient = Math.floor(days / 5);
        var remainder = Math.floor(days % 5);
        var milliseconds = Math.floor(
            (quotient * 7 + remainder + 4) * 86400 * 1000
        );
        return milliseconds;
    }

    /**
     * Converts milliseconds in days excluding weekends
     *
     * @param {number} milliseconds - milliseconds
     *
     * @returns {number} days
     */
    millisecondsToDays(milliseconds) {
        milliseconds = parseInt(milliseconds);
        // http://stackoverflow.com/questions/4055633/what-does-double-tilde-do-in-javascript
        // ~ not: bitwise operator
        // ~~ -> (int)
        var seconds = milliseconds / 1000;
        var t = ~~(seconds / 86400) - 4;
        var quotient = ~~(t / 7);
        var remainder = ~~(t % 7);
        if (remainder === 6) {
            remainder = 5; // sunday as saturday
        }
        var days = quotient * 5 + remainder;
        return days;
    }

    /**
     * Return today as Trendrating days
     *
     * @returns {number} days
     */
    today() {
        return this.dateToDays(new Date());
    }

    /**
     * Return yesterday as Trendrating days
     *
     * @returns {number} days
     */
    yesterday() {
        var today = this.today();

        return today - 1;
    }
    // ----------------------------------------------------- private methods
    _prefixZero(value) {
        if (value < 10) {
            return "0" + value;
        }

        return value;
    }
}
