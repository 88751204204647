import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useImmerReducer } from "use-immer";

type signUpformFields = {
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    confirmPassword?: string;
    firm?: string;
    country?: string;
    role?: string;
};

type SignInFields = {
    email: string;
    password: string;
};

type InitialValidationStateSignUp = {
    email: { isValid: boolean; message: string };
    firstName: { isValid: boolean; message: string };
    lastName: { isValid: boolean; message: string };
    // password: { isValid: boolean; message: string };
    firm: { isValid: boolean; message: string };
    country: { isValid: boolean; message: string };
    role: { isValid: boolean; message: string };
};

type InitialValidationStateSignIn = {
    email: { isValid: boolean; message: string };
    password: { isValid: boolean; message: string };
};

type Action = {
    type: string;
};

export const useSignUpValidator = (signUpInputValues: signUpformFields) => {
    const initialValidationState = {
        email: { isValid: false, message: "" },
        firstName: { isValid: false, message: "" },
        lastName: { isValid: false, message: "" },
        // password: { isValid: false, message: "" },
        firm: { isValid: false, message: "" },
        country: { isValid: false, message: "" },
        role: { isValid: false, message: "" },
    };

    const VALIDATE_EMAIL = "VALIDATE_EMAIL";
    const VALIDATE_FIRSTNAME = "VALIDATE_FIRSTNAME";
    const VALIDATE_LASTNAME = "VALIDATE_LASTNAME";
    // const VALIDATE_PASSWORD = "VALIDATE_PASSWORD";
    const VALIDATE_FIRM = "VALIDATE_FIRM";
    const VALIDATE_COUNTRY = "VALIDATE_COUNTRY";
    const VALIDATE_ROLE = "VALIDATE_ROLE";

    const { t } = useTranslation();

    const reducer = (draft: InitialValidationStateSignUp, action: Action) => {
        switch (action.type) {
            case VALIDATE_EMAIL:
                const emailPattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (emailPattern.test(signUpInputValues.email ?? "")) {
                    draft.email = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.email = {
                        isValid: false,
                        message: t("email.required"),
                    };
                }
                break;

            case VALIDATE_FIRSTNAME:
                const firstNamePattern = /[A-Za-z]{1,32}/;
                if (firstNamePattern.test(signUpInputValues.firstName ?? "")) {
                    draft.firstName = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.firstName = {
                        isValid: false,
                        message: t("firstName.error"),
                    };
                }
                break;

            case VALIDATE_LASTNAME:
                const lastNamePattern = /[A-Za-z]{1,32}/;
                if (lastNamePattern.test(signUpInputValues.lastName ?? "")) {
                    draft.lastName = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.lastName = {
                        isValid: false,
                        message: t("lastName.error"),
                    };
                }
                break;

            // case VALIDATE_PASSWORD:
            //     const password = signUpInputValues.password;
            //     const confirmPassword = signUpInputValues.confirmPassword;
            //     const isPasswordConfirmed =
            //         password === confirmPassword ? true : false;
            //     const isPasswordValid = password!.length >= 8 ? true : false;
            //     if (isPasswordValid && isPasswordConfirmed) {
            //         draft.password = {
            //             isValid: true,
            //             message: "",
            //         };
            //     } else if (isPasswordValid && !isPasswordConfirmed) {
            //         draft.password = {
            //             isValid: false,
            //             message: t("password.mismatch"),
            //         };
            //     } else if (!isPasswordValid && isPasswordConfirmed) {
            //         draft.password = {
            //             isValid: false,
            //             message: t("password.invalid"),
            //         };
            //     } else {
            //         draft.password = {
            //             isValid: false,
            //             message: t("password.error"),
            //         };
            //     }
            //     break;

            case VALIDATE_FIRM:
                if (signUpInputValues.firm!.length) {
                    draft.firm = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.firm = {
                        isValid: false,
                        message: t("firm.invalid"),
                    };
                }
                break;

            case VALIDATE_COUNTRY:
                const countryRuleValidation =
                    signUpInputValues.country!.length &&
                    signUpInputValues.country !== "Choose a country"
                        ? true
                        : false;
                if (countryRuleValidation) {
                    draft.country = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.country = {
                        isValid: false,
                        message: t("country.invalid"),
                    };
                }
                break;

            case VALIDATE_ROLE:
                const roleRuleValidation =
                    signUpInputValues.role!.length &&
                    signUpInputValues.role !== "Choose a role"
                        ? true
                        : false;
                if (roleRuleValidation) {
                    draft.role = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.role = {
                        isValid: false,
                        message: t("role.invalid"),
                    };
                }
                break;

            default:
                return draft;
        }
    };

    const [state, dispatch] = useImmerReducer(reducer, initialValidationState);

    useEffect(() => {
        dispatch({ type: VALIDATE_EMAIL });
    }, [signUpInputValues.email, dispatch]);

    useEffect(() => {
        dispatch({ type: VALIDATE_FIRSTNAME });
    }, [signUpInputValues.firstName, dispatch]);

    useEffect(() => {
        dispatch({ type: VALIDATE_LASTNAME });
    }, [signUpInputValues.lastName, dispatch]);

    // useEffect(() => {
    //     dispatch({ type: VALIDATE_PASSWORD });
    // }, [
    //     signUpInputValues.password,
    //     signUpInputValues.confirmPassword,
    //     dispatch,
    // ]);

    useEffect(() => {
        dispatch({ type: VALIDATE_FIRM });
    }, [signUpInputValues.firm, dispatch]);

    useEffect(() => {
        dispatch({ type: VALIDATE_ROLE });
    }, [signUpInputValues.role, dispatch]);

    useEffect(() => {
        dispatch({ type: VALIDATE_COUNTRY });
    }, [signUpInputValues.country, dispatch]);

    const checkFormfields = Object.values(state).filter(
        (value) => value.isValid === false
    );

    const isFormValid = !checkFormfields.length ? true : false;

    return { state, isFormValid };
};

export const useSignInValidator = (signInInputValues: SignInFields) => {
    const initialValidationState = {
        email: { isValid: false, message: "" },
        password: { isValid: false, message: "" },
    };

    const VALIDATE_EMAIL = "VALIDATE_EMAIL";
    const VALIDATE_PASSWORD = "VALIDATE_PASSWORD";

    const { t } = useTranslation();

    const reducer = (draft: InitialValidationStateSignIn, action: Action) => {
        switch (action.type) {
            case VALIDATE_EMAIL:
                const emailPattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (emailPattern.test(signInInputValues.email ?? "")) {
                    draft.email = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.email = {
                        isValid: false,
                        message: t("email.required"),
                    };
                }
                break;

            case VALIDATE_PASSWORD:
                if (signInInputValues.password.length) {
                    draft.password = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.password = {
                        isValid: false,
                        message: t("password.required"),
                    };
                }
        }
    };

    const [state, dispatch] = useImmerReducer(reducer, initialValidationState);

    useEffect(() => {
        dispatch({ type: VALIDATE_EMAIL });
    }, [signInInputValues.email, dispatch]);

    useEffect(() => {
        dispatch({ type: VALIDATE_PASSWORD });
    }, [signInInputValues.password, dispatch]);

    const checkFormfields = Object.values(state).filter(
        (value) => value.isValid === false
    );

    const isFormValid = !checkFormfields.length ? true : false;

    return { state, isFormValid };
};
