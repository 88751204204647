import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { paths } from "../types/Defaults";

export function PortfolioHomeLongOnly() {
  return (
    <>
      <div className="home-header-content--part2b">
        <Container className="pt-4 pb-4">
          <Row className="justify-content-center">
            <Col md={3} className="home-header-content-cell pt-5 mb-5">
              <span className="img-home flex-shrink-0">
                <img
                  className="img-fluid"
                  src="/images/home.svg"
                  alt="Trendrating"
                />
              </span>
            </Col>

            <Col md={8} className="home-header-content-cell pt-5 ps-5">
              <div className="">
                <p className="fs-2 pb-2">
                  Looking for a way to boost your returns?
                </p>
                <p className="">
                  <strong>
                    <Link
                      to={paths.product}
                      className="btn btn-lg btn-primary fs-2 button-zoom btn-rounded"
                    >
                      Discover our smart model portfolios
                    </Link>
                  </strong>
                </p>
              </div>
              <div className="pt-2">
                <p className="fs-3 mt-2">
                  Our sophisticated technology and advanced analytics select
                  stocks that combine solid fundamentals with strong price
                  trends designed to outperform popular benchmarks.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="home-header-content--part2b">
        <Container className="pt-4 pb-5">
          <>
            <Row className="align-items-md-stretch justify-content-center pb-2 ">
              <Col md={10} className="home-header-content-cell fs-2 topic">
                <p>The challenge</p>
              </Col>
              <Col md={10} className="fs-4">
                The new stock market cycle is raising the bar to deliver
                performance.
                <br /> The year 2022 exposed the shortfalls of conventional
                model portfolios.
                <br /> The new market regime of lower returns and higher risks
                is here to stay for a few years.
                <br /> It is time to upgrade the investment framework shifting
                to advanced solutions.
                <br /> The performance dispersion across stocks provides the
                opportunity to outperform.
                <br />
                <strong>
                  But profiting from the broad dispersion requires smarter
                  strategies, based on sound, logical, well-proven rules,
                  executed with rigorous coherence, and unaffected by the
                  pitfalls of subjective opinions and emotions.
                </strong>
              </Col>
            </Row>

            <Row className="align-items-md-stretch justify-content-center pb-2 pt-5">
              <Col md={10} className="home-header-content-cell fs-2 topic">
                <p>The opportunity</p>
              </Col>
              <Col md={10} className="fs-4">
                At Trendrating we like to challenge the status quo and bring a
                fresh view by offering sophisticated, innovative solutions to
                help our clients to maximize investment returns.
                <br /> How? The opportunity is called performance dispersion. It
                is a phenomenon working in any investment universe and it is
                there to be exploited by smart investors.
                <br />
                <br />
              </Col>
              <Col md={10}>
                <table className=" heroTable">
                  <tr className=" heroTable__headers">
                    <th>&nbsp;</th>
                    <th>US</th>
                    <th>Developed Europe</th>
                    <th>Asia Pacific</th>
                  </tr>
                  <tr>
                    <td>Benchmark</td>
                    <td>
                      SPX <span className="negativeValue">-19%</span>
                    </td>
                    <td>
                      SXXP <span className="negativeValue">-13%</span>
                    </td>
                    <td>
                      MSCI Pacific AC <span className="negativeValue">-4%</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Top 25% Performers</td>
                    <td>
                      <span className="positiveValue">+22%</span>
                    </td>
                    <td>
                      <span className="positiveValue">+26%</span>
                    </td>
                    <td>
                      <span className="positiveValue">+31%</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Bottom 25% Performers</td>
                    <td>
                      <span className="negativeValue">-44%</span>
                    </td>
                    <td>
                      <span className="negativeValue">-34%</span>
                    </td>
                    <td>
                      <span className="negativeValue">-20%</span>
                    </td>
                  </tr>
                </table>
                <p className="pt-3">2022 Performance Dispersion Facts.</p>
              </Col>
              <Col className="fs-4" md={10}>
                Picking the outperformers while avoiding the underperformers is
                possible only with advanced methodologies, that captures
                critical market insights and check more quality control boxes.
              </Col>
            </Row>

            <Row className="align-items-md-stretch justify-content-center pb-2 pt-5">
              <Col md={10} className="home-header-content-cell fs-2 topic">
                <p>The solution</p>
              </Col>
              <Col md={10} className="fs-4">
                The market is very selective and the outperforming stocks
                fulfill two key conditions:
                <br />
                <strong>
                  High quality fundamentals + validated positive price trend.
                </strong>
                <br />
                Combining sound principles and pragmatic evidence.
                <br />
                Good fundamentals offer the foundation for the selection - first
                safety rule.
                <br />
                A confirmed positive trend is the confirmation that the market
                is rewarding the fundamentals - second safety rule.
                <br />
                If solid fundamentals are not validated by a positive price
                action then the disconnection highlights a risk, as the selling
                pressure outweighs the buyers flow.
              </Col>
            </Row>

            <Row className="align-items-md-stretch justify-content-center pb-2 pt-5">
              <Col md={10} className="home-header-content-cell fs-2 topic">
                <p>The advantage</p>
              </Col>
              <Col md={10} className="fs-4">
                Time tested models, using a unique combination of sound rules,
                combining company's quality parameters and actual price action.
                <br />
                <br />
                The benefits are mission critical:
              </Col>
            </Row>
            <Row className="align-items-md-center justify-content-center  pb-2">
              <Col md={5} className={"fs-4"}>
                <ul
                  className="unorderedList"
                  style={{
                    listStyle: "none",
                    padding: "0",
                  }}
                >
                  <li className="fs-4">
                    <i
                      style={{
                        color: "green",
                      }}
                      className="bi bi-check "
                    ></i>
                    &nbsp;Transparency and discipline.
                  </li>
                  <li className="fs-4">
                    <i
                      style={{
                        color: "green",
                      }}
                      className="bi bi-check "
                    ></i>
                    &nbsp;Distinctive investment plan.
                  </li>
                  <li className="fs-4">
                    <i
                      style={{
                        color: "green",
                      }}
                      className="bi bi-check "
                    ></i>
                    &nbsp;Improved performance.
                  </li>
                  <li className="fs-4">
                    <i
                      style={{
                        color: "green",
                      }}
                      className="bi bi-check "
                    ></i>
                    &nbsp;Stronger risk control.
                  </li>
                  <li className="fs-4">
                    <i
                      style={{
                        color: "green",
                      }}
                      className="bi bi-check "
                    ></i>
                    &nbsp;Full scalability.
                  </li>
                  <li className="fs-4">
                    <i
                      style={{
                        color: "green",
                      }}
                      className="bi bi-check "
                    ></i>
                    &nbsp;Time saving.
                  </li>
                  <li className="fs-4">
                    <i
                      style={{
                        color: "green",
                      }}
                      className="bi bi-check "
                    ></i>
                    &nbsp;Cost cutting.
                  </li>
                </ul>
              </Col>
              <Col md={5}>
                <img
                  src="/images/funnel_new.png"
                  alt="funnel"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </>
        </Container>
      </div>

      <div className="home-header-content home-header-content--part3">
        <div className="home-header-content-mask"></div>
        <Container className="pb-2 pt-2">
          <Row className="align-items-md-stretch justify-content-center pb-2 pt-2">
            <Col
              md={10}
              className="home-header-content-cell text-center mb-5 mt-5"
            >
              <div className="fs-3 mb-5">
                <h5 className="fs-3">
                  Profit from the performance dispersion with a new generation
                  of active model portfolios.
                </h5>
                <h5 className="fs-3">
                  <strong>
                    See our catalogue, track the performance, compare and
                    decide.
                  </strong>
                </h5>
                <h5 className="fs-3">
                  <strong>Sign up and access for free for 30 days.</strong>
                </h5>
              </div>
              <Link
                to={paths.product}
                className="home-button-action button-zoom btn btn-lg mt-3 me-1 btn-primary px-5 fs-3 btn-rounded"
              >
                Discover our Smart Model Portfolios
              </Link>

              <a
                href="https://www.trendrating.com/contact-us/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="home-button-action button-zoom btn btn-lg mt-5 me-1 btn-primary px-5 fs-3 btn-rounded">
                  Learn how you can use our technology to build your own model
                  portfolios.
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
