/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/formatter/Strategy
 * @summary Format strategy parameters
 *
 */

import { AppEnvironment } from "../../../types/Defaults";
import { RankingSelection } from "./RankingSelection";

export class Strategy {
    environment: AppEnvironment;
    formatRankingSelection: any = null;
    formatType = "HTML";
    optionsRanking: any = null;
    optionsSelection: any = null;
    optionsSmartBeta: any = null;
    properties = null;
    taxonomyStock: Map<string, object>;
    // taxonomyWhatEtf = null;
    // taxonomyWhatStock = null;
    // taxonomyWhere = null;
    // _options = null;
    // /**
    //  * @param {object} params - parameters
    //  */
    constructor(environment: AppEnvironment) {
        this.environment = environment;
        // if (params === undefined || params == null) {
        //     throw new Error("Missing parameters");
        // }
        // // formatType
        // if ("formatType" in params && params["formatType"] != null) {
        //     this.formatType = params["formatType"];
        // }
        // // ranking
        var configurationBuilder =
            environment.configuration.get("strategyBuilder");
        this.optionsRanking = configurationBuilder.ranking.edit.options;

        this.optionsSelection = configurationBuilder.selection.edit.options;

        // smart beta
        const optionsSmartBeta =
            configurationBuilder.formAdvanced.widgets.smartBeta.options;
        this.optionsSmartBeta = {};
        var item = null;
        for (var i = 0, length = optionsSmartBeta.length; i < length; i++) {
            item = optionsSmartBeta[i];
            this.optionsSmartBeta[item["property"]["value"]] = item;
        }
        // // properties
        this.properties = environment.properties;
        this.formatRankingSelection = new RankingSelection({
            environment: environment,
            formatType: this.formatType,
        });
        // if (
        //     !("taxonomies" in params) ||
        //     params["taxonomies"] === undefined ||
        //     params["taxonomies"] == null
        // ) {
        //     throw new Error("Missing taxonomies");
        // }

        const stockClassification =
            environment.taxonomies.StockClassification.map((item: any) => {
                return [String(item.id), item];
            });

        this.taxonomyStock = new Map<string, object>(stockClassification);

        // if (
        //     !("taxonomyWhatEtf" in params["taxonomies"]) ||
        //     params["taxonomies"]["taxonomyWhatEtf"] === undefined ||
        //     params["taxonomies"]["taxonomyWhatEtf"] == null
        // ) {
        //     throw new Error("Missing taxonomyWhatEtf");
        // }
        // this.taxonomyWhatEtf = new Store({
        //     data: params["taxonomies"]["taxonomyWhatEtf"],
        // });
        // if (
        //     !("taxonomyWhatStock" in params["taxonomies"]) ||
        //     params["taxonomies"]["taxonomyWhatStock"] === undefined ||
        //     params["taxonomies"]["taxonomyWhatStock"] == null
        // ) {
        //     throw new Error("Missing taxonomyWhatStock");
        // }
        // this.taxonomyWhatStock = new Store({
        //     data: params["taxonomies"]["taxonomyWhatStock"],
        // });
        // if (
        //     !("taxonomyWhere" in params["taxonomies"]) ||
        //     params["taxonomies"]["taxonomyWhere"] === undefined ||
        //     params["taxonomies"]["taxonomyWhere"] == null
        // ) {
        //     throw new Error("Missing taxonomyWhere");
        // }
        // this.taxonomyWhere = new Store({
        //     data: params["taxonomies"]["taxonomyWhere"],
        // });
        // this._options = new Options();
    }

    // cappingPeer(value) {
    //     var formatType = this.formatType;
    //     var formatOptions = {
    //         isPercentage: true,
    //         notAvailable: {
    //             input: null,
    //             output: "",
    //         },
    //     };
    //     var weightCapping = format.custom("number", {
    //         options: formatOptions,
    //         output: formatType,
    //         value: value["weightCappedMax"],
    //         valueHelper: null,
    //     });
    //     var peerLevel =
    //         value["peerLevel"] != null
    //             ? this._options.getWeightCappingPeerLevel(
    //                   "LABEL_LOOKUP",
    //                   value["peerLevel"]
    //               )
    //             : i18n["common_not_set"];
    //     var formatted = {
    //         label: [
    //             this.strong(peerLevel),
    //             i18n["page_builder_field_weight_capping_peer_label"],
    //         ].join(" "),
    //         value: this.strong(weightCapping),
    //     };
    //     return formatted;
    // }
    // cappingSecurity(value) {
    //     var formatType = this.formatType;
    //     var options = {
    //         isPercentage: true,
    //         notAvailable: {
    //             input: null,
    //             output: "",
    //         },
    //     };
    //     var formatted = [
    //         i18n["common_interval_min"],
    //         this.strong(
    //             format.custom("number", {
    //                 options: options,
    //                 output: formatType,
    //                 value: value["weightCappedMin"],
    //                 valueHelper: null,
    //             })
    //         ),
    //         i18n["common_interval_max"],
    //         this.strong(
    //             format.custom("number", {
    //                 options: options,
    //                 output: formatType,
    //                 value: value["weightCappedMax"],
    //                 valueHelper: null,
    //             })
    //         ),
    //     ].join(" ");
    //     return formatted;
    // }
    // cash(value) {
    //     var formatted = i18n["common_unknown"];
    //     var formatType = this.formatType;
    //     var options = {
    //         isPercentage: true,
    //         notAvailable: {
    //             input: null,
    //             output: "",
    //         },
    //     };
    //     switch (value) {
    //         case 0: {
    //             formatted = i18n["page_builder_field_cash_label_flex"];
    //             break;
    //         }
    //         case 1: {
    //             formatted = i18n["page_builder_field_cash_label_full"];
    //             break;
    //         }
    //         default: {
    //             formatted = [
    //                 i18n["common_interval_min"],
    //                 format.custom("number", {
    //                     options: options,
    //                     output: formatType,
    //                     value: value,
    //                     valueHelper: null,
    //                 }),
    //             ].join(" ");
    //         }
    //     }
    //     return this.strong(formatted);
    // }
    // currency(value) {
    //     var formatted = i18n["common_unknown"];
    //     switch (value) {
    //         case "local": {
    //             formatted = i18n["common_currency_local"];
    //             break;
    //         }
    //         default: {
    //             formatted = value;
    //         }
    //     }
    //     return this.strong(formatted);
    // }
    // eligibility(value) {
    //     var sortBy = this._options.getEligibility(
    //         "LABEL_LOOKUP",
    //         value["sortBy"]
    //     );
    //     var formatted = [
    //         this.strong(value["cardinality"]),
    //         i18n["common_instruments"].toLowerCase(),
    //         i18n["common_ordered_by"],
    //         this.strong(sortBy),
    //     ].join(" ");
    //     return formatted;
    // }
    // /**
    //  * Get general type from all classification codes
    //  *
    //  * @param {array} value -  an array of ICB or ETFs classification codes
    //  *
    //  * @returns {string} returns a value indicating the composition of the array
    //  *                   Valid values: STOCK, MIXED, ETF or null if empty
    //  */
    // getTypeFromCodes(value) {
    //     var type = null;
    //     var taxonomyWhatStock = this.taxonomyWhatStock;
    //     var taxonomyWhatEtf = this.taxonomyWhatEtf;
    //     var id;
    //     for (var i = 0, length = value.length; i < length; i++) {
    //         id = value[i];
    //         if (taxonomyWhatStock.get(id)) {
    //             if (type == null || type == "STOCK") {
    //                 type = "STOCK";
    //             } else {
    //                 return "MIXED";
    //             }
    //         } else if (taxonomyWhatEtf.get(id)) {
    //             if (type == null || type == "ETF") {
    //                 type = "ETF";
    //             } else {
    //                 return "MIXED";
    //             }
    //         } else {
    //             return "MIXED";
    //         }
    //     }
    //     return type;
    // }
    // hedgingStrategy(value) {
    //     var formatted = i18n["common_unknown"];
    //     switch (value["hedgingStrategy"]) {
    //         case "HEDGING_ADVANCED": {
    //             formatted = i18n["page_builder_field_hedging_advanced_label"];
    //             break;
    //         }
    //         case "HEDGING_FULL": {
    //             formatted = i18n["page_builder_field_hedging_full_label"];
    //             break;
    //         }
    //         case "HEDGING_SMART": {
    //             formatted = i18n["page_builder_field_hedging_smart_label"];
    //             break;
    //         }
    //     }
    //     return this.strong(formatted);
    // }
    // holdings(value) {
    //     return this.strong(value);
    // }
    // inceptionDate(value) {
    //     return this.strong(value);
    // }
    // inceptionValue(value) {
    //     return this.strong(value);
    // }
    // instrument(value) {
    //     return this.strong(value);
    // }
    // /**
    //  * Format instrument type
    //  *
    //  * @param {object}  value - the value to be formatted
    //  * @param {boolean} value.domestic - true if instruments are domestic
    //  * @param {boolean} value.foreign - true if instruments are foreign
    //  * @param {string}  value.instrumentType - 'etf' or 'stock'
    //  * @param {array}   value.instrumentTypeSub - stock classification
    //  *
    //  * @returns {string} the formatted instrument type
    //  */
    instrumentType(value: any) {
        var formatted = [];
        if (value["domestic"] === true && value["foreign"] === false) {
            formatted.push(this.strong("Domestic"));
        }
        if (value["domestic"] === false && value["foreign"] === true) {
            formatted.push(this.strong("Foreign"));
        }
        switch (value["instrumentType"]) {
            case "etf": {
                formatted.push(
                    this.strong(
                        formatted.length === 1 ? "ETFs".toLowerCase() : "ETFs"
                    )
                );
                break;
            }
            case "stock": {
                formatted.push(
                    this.strong(
                        formatted.length === 1
                            ? "Stocks".toLowerCase()
                            : "Stocks"
                    )
                );
                break;
            }
            default: {
                formatted.push(this.strong("#UNKNOWN"));
            }
        }
        var length = value["instrumentTypeSub"].length;
        if (length > 0) {
            let subTypes = [];
            for (let i = 0; i < length; i++) {
                const item: any = this.taxonomyStock.get(
                    value["instrumentTypeSub"][i]
                );
                subTypes.push(this.strong(item.name));
            }
            formatted.push(["(", subTypes.join(", "), ")"].join(""));
        }
        return formatted.join(" ");
    }
    // leverage(value) {
    //     return this.strong(value);
    // }
    // /**
    //  * Format performance (price / total return)
    //  *
    //  * @param {string} value - one of 'NONE' or 'REBALANCE'
    //  *
    //  * @returns {string} the formatted rebalance frequency
    //  */
    // performance(value) {
    //     var formatted = "";
    //     switch (value) {
    //         case "NONE": {
    //             formatted = i18n["page_builder_field_performance_label_price"];
    //             break;
    //         }
    //         case "REBALANCE": {
    //             formatted =
    //                 i18n["page_builder_field_performance_label_total_return"];
    //             break;
    //         }
    //         default: {
    //             formatted = i18n["common_unknown"];
    //         }
    //     }
    //     return this.strong(formatted);
    // }
    // period(value) {
    //     var formatted = {
    //         label: i18n["page_builder_field_period_label"],
    //         value: "",
    //     };
    //     switch (value["type"]) {
    //         case "DAY": {
    //             formatted["value"] = this.strong(value["value"]);
    //             formatted["label"] =
    //                 i18n["page_builder_field_period_from_label"];
    //             break;
    //         }
    //         case "YEAR": {
    //             formatted["value"] = this.strong(
    //                 value["value"] + " " + i18n["common_years"]
    //             );
    //             break;
    //         }
    //         default: {
    //             formatted["value"] = this.strong(i18n["common_unknown"]);
    //         }
    //     }
    //     return formatted;
    // }
    ranking(value: any[]) {
        return this._rankingSelection(value, this.optionsRanking);
    }
    // /**
    //  * Format rebalance frequency
    //  *
    //  * @param {string} value - one of '05_DAYS', '20_DAYS' or '60_DAYS'
    //  *
    //  * @returns {string} the formatted rebalance frequency
    //  */
    // rebalance(value) {
    //     var formatted = "";
    //     switch (value) {
    //         case "05_DAYS": {
    //             formatted = i18n["page_builder_field_rebalance_label_05_days"];
    //             break;
    //         }
    //         case "20_DAYS": {
    //             formatted = i18n["page_builder_field_rebalance_label_20_days"];
    //             break;
    //         }
    //         case "60_DAYS": {
    //             formatted = i18n["page_builder_field_rebalance_label_60_days"];
    //             break;
    //         }
    //         default: {
    //             formatted = i18n["common_unknown"];
    //         }
    //     }
    //     return this.strong(formatted);
    // }
    // rotation(value) {
    //     var factor = null;
    //     switch (value["factor"]) {
    //         case "FACTOR_MOMENTUM": {
    //             factor = i18n["page_builder_field_rotation_momentum_label"];
    //             break;
    //         }
    //         case "FACTOR_MARKET_CAP_NEUTRAL": {
    //             factor =
    //                 i18n["page_builder_field_rotation_market_neutral_label"];
    //             break;
    //         }
    //         case "FACTOR_MOMENTUM_GROWTH": {
    //             factor =
    //                 i18n["page_builder_field_rotation_momentum_growth_label"];
    //             break;
    //         }
    //         default: {
    //             factor = i18n["common_unknown"];
    //         }
    //     }
    //     var rotate = this._options.getRotation("LABEL_LOOKUP", value["rotate"]);
    //     var formatted = [
    //         i18n["page_builder_field_rotation_rotate_label"],
    //         this.strong(rotate),
    //         i18n["page_builder_field_rotation_by_label"],
    //         this.strong(factor),
    //     ].join(" ");
    //     return formatted;
    // }
    selection(value: any[]) {
        return this._rankingSelection(value, this.optionsSelection);
    }
    // smartBeta(value) {
    //     var formatType = this.formatType;
    //     var options = {
    //         decimals: 2,
    //         notAvailable: {
    //             input: null,
    //             output: "",
    //         },
    //     };
    //     var optionsSmartBeta = this.optionsSmartBeta;
    //     var formatted = [];
    //     var item = null;
    //     for (var i = 0, length = value.length; i < length; i++) {
    //         item = value[i];
    //         var itemFormatted = [];
    //         // weight
    //         itemFormatted.push(
    //             this.strong(
    //                 format.custom("number", {
    //                     options: options,
    //                     output: formatType,
    //                     value: item["weight"],
    //                     valueHelper: null,
    //                 })
    //             )
    //         );
    //         // property
    //         itemFormatted.push(
    //             this.strong(
    //                 optionsSmartBeta[item["property"]]["property"]["label"]
    //             )
    //         );
    //         // operator
    //         switch (item["property"]) {
    //             case "rc": {
    //                 itemFormatted.push({
    //                     A: this.strong(
    //                         format.custom("number", {
    //                             options: options,
    //                             output: formatType,
    //                             value: item["operator"]["A"],
    //                             valueHelper: null,
    //                         })
    //                     ),
    //                     B: this.strong(
    //                         format.custom("number", {
    //                             options: options,
    //                             output: formatType,
    //                             value: item["operator"]["B"],
    //                             valueHelper: null,
    //                         })
    //                     ),
    //                     C: this.strong(
    //                         format.custom("number", {
    //                             options: options,
    //                             output: formatType,
    //                             value: item["operator"]["C"],
    //                             valueHelper: null,
    //                         })
    //                     ),
    //                     D: this.strong(
    //                         format.custom("number", {
    //                             options: options,
    //                             output: formatType,
    //                             value: item["operator"]["D"],
    //                             valueHelper: null,
    //                         })
    //                     ),
    //                 });
    //                 break;
    //             }
    //             default: {
    //                 switch (item["operator"]) {
    //                     case "bottom": {
    //                         itemFormatted.push(
    //                             this.strong(i18n["c_smart_beta_bottom"])
    //                         );
    //                         break;
    //                     }
    //                     case "middle": {
    //                         itemFormatted.push(
    //                             this.strong(i18n["c_smart_beta_middle"])
    //                         );
    //                         break;
    //                     }
    //                     case "top": {
    //                         itemFormatted.push(
    //                             this.strong(i18n["c_smart_beta_top"])
    //                         );
    //                         break;
    //                     }
    //                 }
    //             }
    //         }
    //         formatted.push(itemFormatted);
    //     }
    //     return formatted;
    // }
    // // utility function
    strong(value: string) {
        return "<strong>" + value + "</strong>";
    }
    // /**
    //  * Format sectors
    //  *
    //  * @param {array} value -  an array of ICB or ETFs classification codes
    //  *
    //  * @returns {string} the formatted sectors
    //  */
    // what(value) {
    //     var tokens = [];
    //     var whatTaxonomies = [this.taxonomyWhatStock, this.taxonomyWhatEtf];
    //     for (var i = 0, length = value.length; i < length; i++) {
    //         tokens.push(
    //             this._taxonFormat(
    //                 this._taxonGet(value[i], whatTaxonomies),
    //                 whatTaxonomies
    //             )
    //         );
    //     }
    //     if (tokens.length === 0) {
    //         tokens.push(i18n["common_all"]);
    //     }
    //     tokens.sort();
    //     return this.strong(tokens.join(", "));
    // }
    // weightingSchema(value) {
    //     var formatted = "";
    //     switch (value) {
    //         case "WEIGHT_EQUAL": {
    //             formatted =
    //                 i18n[
    //                     "page_builder_field_weighting_schema_label_weight_equal"
    //                 ];
    //             break;
    //         }
    //         case "WEIGHT_MARKET_CAP": {
    //             formatted =
    //                 i18n[
    //                     "page_builder_field_weighting_schema_label_weight_market_cap"
    //                 ];
    //             break;
    //         }
    //         default: {
    //             formatted = i18n["common_unknown"];
    //         }
    //     }
    //     return this.strong(formatted);
    // }
    // weightingSchemaExistingPositions(value) {
    //     var formatted = "";
    //     switch (value) {
    //         case "WEIGHT_EXISTING_POSITIONS_KEEP": {
    //             formatted =
    //                 i18n[
    //                     "page_builder_field_weighting_schema_existing_positions_label_keep"
    //                 ];
    //             break;
    //         }
    //         case "WEIGHT_EXISTING_POSITIONS_REBALANCE": {
    //             formatted =
    //                 i18n[
    //                     "page_builder_field_weighting_schema_existing_positions_label_rebalance"
    //                 ];
    //             break;
    //         }
    //         default: {
    //             formatted = i18n["common_unknown"];
    //         }
    //     }
    //     return this.strong(formatted);
    // }
    // /**
    //  * Format markets
    //  *
    //  * @param {object} value -  the parameters
    //  * @param {boolean} value.domestic - true if only domestic instruments
    //  *   must be selected, false otherwise
    //  * @param {boolean} value.foreign - true if only foreign instruments
    //  *   must be selected, false otherwise
    //  * @param {array} value.market - an array of market codes
    //  * @param {array} value.stockClassification - an array of stock
    //  *   classification codes
    //  *
    //  * @returns {string} the formatted markets
    //  */
    // where(value) {
    //     var tokens = [];
    //     for (var i = 0, length = value.length; i < length; i++) {
    //         tokens.push(this.taxonomyWhere.get(value[i])["name"]);
    //     }
    //     if (tokens.length === 0) {
    //         tokens.push(i18n["common_all"]);
    //     }
    //     tokens.sort();
    //     return this.strong(tokens.join(", "));
    // }
    // // ------------------------------------------------- private methods
    _rankingSelection(value: any, options: any) {
        const smartFormatter: any = {
            quantile: {
                4: {
                    equalTo: { 1: "Top Quartile", 4: "Bottom Quartile" },
                    lessThan: {
                        2: "Top quartile",
                        3: "Top two quartiles",
                        4: "Top three quartiles",
                    },
                    lessThanOrEqualTo: {
                        1: "Top Quartile",
                        2: "Top two Quartiles",
                        3: "Top three Quartiles",
                    },
                    greaterThan: {
                        2: "Bottom two Quartiles",
                        3: "Bottom Quartile",
                    },
                    greaterThanOrEqualTo: {
                        2: "Second Quartile",
                        3: "Bottom two Quartiles",
                        4: "Bottom Quartiles",
                    },
                },
                5: {
                    equalTo: { 1: "Top Quintile", 5: "Bottom Quintile" },
                    lessThan: {
                        2: "Top Quintile",
                        3: "Top two Quintiles",
                        4: "Top three Quintiles",
                    },
                    lessThanOrEqualTo: {
                        1: "Top Quintile",
                        2: "Top two Quintiles",
                        3: "Top three Quintiles",
                    },
                    greaterThan: {
                        3: "Bottom two Quintiles",
                        4: "Bottom Quintile",
                    },
                    greaterThanOrEqualTo: {
                        4: "Bottom two Quintiles",
                        5: "Bottom Quintile",
                    },
                },
            },
        };

        var constraint = null;
        var formattedConstraint = null;
        var formattedConstraints = [];
        var formatRankingSelection = this.formatRankingSelection;
        var propertyLabel = this._rankingSelectionPropertyLabel(options);
        for (var i = 0, length = value.length; i < length; i++) {
            constraint = value[i];

            const foundFunction = smartFormatter[constraint.function];
            // console.log("--------------------------------");
            // console.log("--------------------------------");
            // console.log(constraint);
            // console.log("--------------------------------");
            // console.log("foundFunction", constraint.function, foundFunction);
            let foundOperatorParams = null;
            if (foundFunction != null && constraint.functionParams != null) {
                const foundFunctionParams =
                    foundFunction[constraint.functionParams.value];
                // console.log(
                //     "foundFunctionParams",
                //     constraint.functionParams.value,
                //     foundFunctionParams
                // );
                if (foundFunctionParams != null) {
                    const foundOperator =
                        foundFunctionParams[constraint.operator];
                    // console.log(
                    //     "foundOperator",
                    //     constraint.operator,
                    //     foundOperator
                    // );
                    if (foundOperator != null) {
                        if (constraint.operator === "equalTo") {
                            if (constraint.operatorParams.value != null) {
                                const operatorParams =
                                    constraint.operatorParams.value;
                                if (operatorParams != null) {
                                    foundOperatorParams =
                                        foundOperator[operatorParams];
                                    // console.log(
                                    //     "foundOperatorParams",
                                    //     foundOperatorParams
                                    // );
                                }
                            }
                        } else {
                            let foundParam = null;
                            switch (constraint.operator) {
                                case "greaterThan":
                                    foundParam = "gt";
                                    break;
                                case "greaterThanOrEqualTo":
                                    foundParam = "ge";
                                    break;
                                case "lessThan":
                                    foundParam = "lt";
                                    break;
                                case "lessThanOrEqualTo":
                                    foundParam = "le";
                                    break;
                            }
                            // console.log(foundParam);

                            if (
                                foundParam != null &&
                                constraint.operatorParams.value != null &&
                                constraint.operatorParams.value.length > 0
                            ) {
                                const operatorParams =
                                    constraint.operatorParams.value[0][
                                        foundParam
                                    ];
                                if (operatorParams != null) {
                                    foundOperatorParams =
                                        foundOperator[operatorParams];
                                    // console.log(
                                    //     "foundOperatorParams",
                                    //     foundOperatorParams
                                    // );
                                }
                            }
                        }
                    }
                }
            }
            if (foundOperatorParams != null) {
                formattedConstraint = [];
                // property
                formattedConstraint.push(
                    formatRankingSelection.property(
                        constraint["property"],
                        propertyLabel,
                        null
                    )
                );
                formattedConstraint.push(foundOperatorParams);
                formattedConstraints.push(formattedConstraint);
            } else {
                formattedConstraint = [];
                // property
                formattedConstraint.push(
                    formatRankingSelection.property(
                        constraint["property"],
                        propertyLabel,
                        null
                    )
                );
                // function
                formattedConstraint.push(
                    formatRankingSelection.function(
                        constraint["property"],
                        constraint["function"],
                        constraint["functionParams"]
                    )
                );
                // operator: interface must be unified
                // sortBy must be an type of operator and the value
                // must be encapsulated within an operatorParams
                //
                // for selection
                if ("operator" in constraint) {
                    formattedConstraint.push(
                        formatRankingSelection.operator(
                            constraint["property"],
                            constraint["function"],
                            constraint["operator"],
                            constraint["operatorParams"]
                        )
                    );
                }
                // ranking
                if ("sortBy" in constraint) {
                    formattedConstraint.push(
                        formatRankingSelection.operator(
                            constraint["property"],
                            constraint["function"],
                            "sortBy",
                            {
                                value: constraint["sortBy"],
                            }
                        )
                    );
                }
                // console.log(formattedConstraint);

                if (formattedConstraint.length > 2) {
                    const [firstConstraint, ...otherConstraints] =
                        formattedConstraint;
                    formattedConstraint = [
                        firstConstraint,
                        otherConstraints.join(" "),
                    ];
                }

                formattedConstraints.push(formattedConstraint);
            }
        }
        return formattedConstraints;
    }
    _rankingSelectionPropertyLabel(options: any) {
        var option = null;
        var propertyLabel: any = {};
        for (var i = 0, length = options.length; i < length; i++) {
            option = options[i]["property"];
            propertyLabel[option["value"]] = option["label"];
        }
        return propertyLabel;
    }
    // _taxonFormat(taxon, taxonomies) {
    //     var formatted = taxon["name"];
    //     var parent;
    //     if (taxon["type"] == "3 Sector") {
    //         parent = this._taxonGet(taxon["parent"], taxonomies);
    //         formatted = parent["name"] + " - " + formatted;
    //     }
    //     return formatted;
    // }
    // _taxonGet(id, taxonomies, taxonomyIndex) {
    //     taxonomyIndex =
    //         taxonomyIndex === undefined || taxonomyIndex == null
    //             ? 0
    //             : taxonomyIndex;
    //     var taxon = taxonomies[taxonomyIndex].get(id);
    //     if (taxon !== undefined) {
    //         return taxon;
    //     } else {
    //         return this._taxonGet(id, taxonomies, taxonomyIndex + 1);
    //     }
    // }
}
