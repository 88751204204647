import { useMemo } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormatter } from "../../hooks/useFormatter";
import { PTag } from "../../trendrating/api/PTag";
import { Product } from "../../types/Product";

type ProductDetailAnalyticsFactsProps = Pick<
  Product,
  "analytics" | "info" | "strategyAnalytics"
>;

export const ProductDetailAnalyticsFacts = ({
  strategyAnalytics,
}: ProductDetailAnalyticsFactsProps) => {
  const formatter = useFormatter();
  const analyticsExtractor = useMemo(
    () => new PTag(strategyAnalytics),
    [strategyAnalytics]
  );
  const { t /*, i18n */ } = useTranslation();
  const productInfo = {
    strategyLabel: t("Portfolio"),
    benchmarkLabel: t("Benchmark"),
    differenceLabel: t("Difference"),
  };

  let cumulativeLabel = "Cumulative";
  const calendarAnalytics = analyticsExtractor.get("yearly_performances", "H");
  if (calendarAnalytics) {
    const timeframes = Object.keys(calendarAnalytics)
      .map((item) => parseInt(item))
      .sort((a, b) => (a > b ? 1 : -1));

    const minTimeFrame = timeframes[0];
    const maxTimeFrame = timeframes[timeframes.length - 1];

    if (minTimeFrame && maxTimeFrame && minTimeFrame !== maxTimeFrame) {
      const formattedMinTimeframe = formatter.custom("date", {
        options: {
          format: ["Y"],
          isMillisecond: false,
          notAvailable: {
            input: "",
            output: "",
          },
          separator: "_",
        },
        output: "HTML",
        value: minTimeFrame,
        valueHelper: null,
      });
      const formattedMaxTimeframe = formatter.custom("date", {
        options: {
          format: ["Y"],
          isMillisecond: false,
          notAvailable: {
            input: "",
            output: "",
          },
          separator: "_",
        },
        output: "HTML",
        value: maxTimeFrame,
        valueHelper: null,
      });
      cumulativeLabel = `${formattedMinTimeframe}-${formattedMaxTimeframe}<br><sup>Cumulative</sup>`;
    }
  }

  return (
    <div className="product-detail-facts-wrap">
      <Row className="g-5">
        <Col
          className="product-detail-facts-wrap-column"
          xxl={4}
          xl={6}
          sm={12}
        >
          <Table className="table product-detail-facts">
            <colgroup>
              <col className="product-detail-facts-column product-detail-facts-column-label" />
              <col className="product-detail-facts-column product-detail-facts-column-strategy" />
              <col className="product-detail-facts-column product-detail-facts-column-benchmark" />
              <col className="product-detail-facts-column product-detail-facts-column-difference" />
            </colgroup>
            <thead>
              <tr>
                <td>
                  <h5>Return</h5>
                </td>
                <th>{productInfo.strategyLabel}</th>
                <th>{productInfo.benchmarkLabel}</th>
                <th>{productInfo.differenceLabel}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    position: "relative",
                    top: "3px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: cumulativeLabel,
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("total_return", "H"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("total_return", "B"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        colored: "positive",
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("total_return", "D"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
              </tr>
              <tr>
                <td>Annualized</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get(
                        "annualized_total_return",
                        "H"
                      ),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get(
                        "annualized_total_return",
                        "B"
                      ),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        colored: "positive",
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get(
                        "annualized_total_return",
                        "D"
                      ),
                      valueHelper: null,
                    }),
                  }}
                ></td>
              </tr>
              <tr>
                <td>Last 12 months</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("last_12_months", "H"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("last_12_months", "B"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        colored: "positive",
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("last_12_months", "D"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col
          className="product-detail-facts-wrap-column"
          xxl={4}
          xl={6}
          sm={12}
        >
          <Table className="table product-detail-facts">
            <colgroup>
              <col className="product-detail-facts-column product-detail-facts-column-label" />
              <col className="product-detail-facts-column product-detail-facts-column-strategy" />
              <col className="product-detail-facts-column product-detail-facts-column-benchmark" />
              <col className="product-detail-facts-column product-detail-facts-column-difference" />
            </colgroup>
            <thead>
              <tr>
                <td>
                  <h5>Risk</h5>
                </td>
                <th>{productInfo.strategyLabel}</th>
                <th>{productInfo.benchmarkLabel}</th>
                <th>{productInfo.differenceLabel}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span title="Average yearly max drawdown">
                    Average drawdown
                  </span>
                </td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("avg_yearly_drawdown", "H"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("avg_yearly_drawdown", "B"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        colored: "positive",
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("avg_yearly_drawdown", "D"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
              </tr>
              <tr>
                <td>
                  <span title="Monthly standard deviation">
                    Monthly standard deviation
                  </span>
                </td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("monthly_std", "H"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("monthly_std", "B"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        colored: "negative",
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("monthly_std", "D"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
              </tr>
              <tr>
                <td>% Negative quarters</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get(
                        "negative_quarters_perc",
                        "H"
                      ),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get(
                        "negative_quarters_perc",
                        "B"
                      ),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        colored: "positive",
                        hasPositiveSign: true,
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get(
                        "negative_quarters_perc",
                        "D"
                      ),
                      valueHelper: null,
                    }),
                  }}
                ></td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col
          className="product-detail-facts-wrap-column"
          xxl={4}
          xl={6}
          sm={12}
        >
          <Table className="table product-detail-facts">
            <colgroup>
              <col className="product-detail-facts-column product-detail-facts-column-label" />
              <col className="product-detail-facts-column product-detail-facts-column-strategy" />
              <col className="product-detail-facts-column product-detail-facts-column-benchmark" />
              <col className="product-detail-facts-column product-detail-facts-column-difference" />
            </colgroup>
            <thead>
              <tr>
                <td>
                  <h5>Key ratios</h5>
                </td>
                <th>{productInfo.strategyLabel}</th>
                <th>{productInfo.benchmarkLabel}</th>
                <th>{productInfo.differenceLabel}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sharpe ratio</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        decimals: 4,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("sharpe", "H"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        decimals: 4,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("sharpe", "B"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        colored: "positive",
                        decimals: 4,
                        hasPositiveSign: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("sharpe", "D"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
              </tr>
              <tr>
                <td>Sterling ratio</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        decimals: 4,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("sterling", "H"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        decimals: 4,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("sterling", "B"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        colored: "positive",
                        decimals: 4,
                        hasPositiveSign: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("sterling", "D"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
              </tr>
              <tr>
                <td>Turnover</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: {
                        isPercentage: true,
                        hasPositiveSign: false,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: analyticsExtractor.get("yearly_avg_turnover", "H"),
                      valueHelper: null,
                    }),
                  }}
                ></td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};
