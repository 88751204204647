import Highcharts from "highcharts/highstock";
import { useCallback, useMemo, useRef, useState } from "react";
import { useFormatter } from "../hooks/useFormatter";
import { PTag } from "../trendrating/api/PTag";
import { Chart } from "./Chart";

type ProductHistogramQuartersDiffProps = {
  strategyAnalytics: any;
};

const sorterMap = {
  0: (a: any, b: any) => {
    if (a.value > b.value) {
      return -1;
    } else {
      if (a.value < b.value) {
        return 1;
      }
    }

    return 0;
  },
  1: (a: any, b: any) => {
    if (a.date > b.date) {
      return -1;
    } else {
      if (a.date < b.date) {
        return 1;
      }
    }

    return 0;
  },
  2: (a: any, b: any) => {
    if (a.value > b.value) {
      return 1;
    } else {
      if (a.value < b.value) {
        return -1;
      }
    }

    return 0;
  },
};

export function ProductHistogramQuartersDiff({
  strategyAnalytics,
}: ProductHistogramQuartersDiffProps) {
  const formatter = useFormatter();
  const [btnActive, setBtnActive] = useState<"distribution" | "calendar">(
    "distribution"
  );
  const [visibleSerie, setVisibleSerie] = useState(0);

  const chartRef = useRef<any>();

  const categoryFormatter = useCallback(
    (value) => {
      const valueToDate = formatter.custom("date", {
        options: {
          format: ["Y", "m"],
          isMillisecond: false,
          notAvailable: {
            input: "",
            output: "",
          },
          separator: "_",
        },
        output: "HTML",
        value: parseInt(value),
        valueHelper: null,
      });
      var tokens = valueToDate.split("_");

      var date = new Date(
        Date.UTC(parseInt(tokens[0]), parseInt(tokens[1]) - 1, 1)
      );
      var quarter = "";
      switch (tokens[1]) {
        case "01":
        case "02":
        case "03": {
          quarter = "Q1";
          break;
        }
        case "04":
        case "05":
        case "06": {
          quarter = "Q2";
          break;
        }
        case "07":
        case "08":
        case "09": {
          quarter = "Q3";
          break;
        }
        case "10":
        case "11":
        case "12": {
          quarter = "Q4";
          break;
        }
      }
      return (
        quarter +
        " " +
        formatter.custom("date", {
          options: {
            format: ["Y"],
            isMillisecond: true,
            notAvailable: {
              input: "",
              output: "",
            },
            separator: " ",
          },
          output: "HTML",
          value: date.getTime(),
          valueHelper: null,
        })
      );
    },
    [formatter]
  );

  const percentageFormatter = useCallback(
    (value) => {
      return formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: value,
        valueHelper: {
          normalizationThreshold: 1,
        },
      });
    },
    [formatter]
  );

  const analytics = useMemo(
    () => new PTag(strategyAnalytics),
    [strategyAnalytics]
  );

  const initialdata = useMemo(() => {
    const dataObjectDiff = analytics.get("quarterly_performances", "D");
    const dataObjectPerf = analytics.get("quarterly_performances", "H");

    const dataXYDiff = Object.entries(dataObjectDiff).reduce(
      (prev: any, [key, value]) => {
        prev.push({
          value,
          date: key,
        });

        return prev;
      },
      []
    );

    const dataXYPerf = Object.entries(dataObjectPerf).reduce(
      (prev: any, [key, value]) => {
        prev.push({
          value,
          date: key,
        });

        return prev;
      },
      []
    );

    dataXYPerf.sort(sorterMap[0]);
    dataXYDiff.sort(sorterMap[0]);

    return [dataXYPerf, dataXYDiff];
  }, [analytics]);

  const [data, setData] = useState(initialdata ?? []);

  const categories = useMemo(() => {
    return data?.[visibleSerie].map((item: any) =>
      categoryFormatter(item.date)
    );
  }, [categoryFormatter, data, visibleSerie]);

  const sortBy = useCallback((sortIndex: 0 | 1 | 2) => {
    setData((currentData: any) => {
      const serie1 = [...currentData[0]];
      const serie2 = [...currentData[1]];

      serie1.sort(sorterMap[sortIndex]);
      serie2.sort(sorterMap[sortIndex]);

      return [serie1, serie2];
    });
  }, []);

  const onClickDistributionBtn = useCallback(() => {
    setBtnActive("distribution");
    sortBy(0);
  }, [sortBy]);

  const onClickCalendarBtn = useCallback(() => {
    setBtnActive("calendar");
    sortBy(1);
  }, [sortBy]);

  const series = useMemo(() => {
    const valuesDiff = [];
    const valuesPerf = [];

    let max = -9999;
    let value = null;

    for (const item of data[0]) {
      value = item.value;
      max = Math.max(Math.abs(value), max);

      valuesPerf.push(value);
    }

    let maxDiff = -9999;
    let valueDiff = null;

    for (const item of data[1]) {
      valueDiff = item.value;
      maxDiff = Math.max(Math.abs(valueDiff), maxDiff);

      valuesDiff.push(valueDiff);
    }

    const serieDiff = {
      name: "Relative",
      data: valuesDiff,
      type: "bar",
      visible: visibleSerie === 1,
      max: maxDiff,
      id: "diff_perf",
    };

    const seriePerc = {
      id: "perf",
      name: "Absolute",
      data: valuesPerf,
      type: "bar",
      visible: visibleSerie === 0,
      max: max,
    };

    return [seriePerc, serieDiff];
  }, [data, visibleSerie]);

  const options: Highcharts.Options = useMemo(() => {
    const s: any = series;
    const max = s[visibleSerie].max;

    return {
      chart: {
        type: "bar",
      },
      title: {
        text: "Quarterly Performance",
      },
      subtitle: {
        text: "Strategy",
      },
      xAxis: {
        categories,
        title: {
          text: null,
        },
        labels: {
          step: 1,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        title: {
          text: null,
        },
        minPadding: 0,
        startOnTick: true,
        max,
        min: -max,

        labels: {
          formatter: function () {
            return ((this.value as any) * 100).toFixed(0) + "%";
          },
          overflow: "justify",
        },
        gridLineWidth: 0,
      },
      tooltip: {
        formatter: function () {
          return `${this.x}:  <strong>${percentageFormatter(this.y)}</strong>`;
        },
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: function (event) {
              event.preventDefault();

              const series = this.chart.series;
              let name = "";

              for (const serie of series) {
                serie.setVisible(!serie.visible);

                if (serie.visible) {
                  name = serie.getName();
                }
              }

              this.chart.subtitle.update({
                text:
                  name === "Absolute" ? "Strategy" : "Difference vs Benchmark",
              });

              const serieVisible = name === "Absolute" ? 0 : 1;
              setVisibleSerie(serieVisible);
            },
          },
        },
        bar: {
          dataLabels: {
            enabled: false,
          },
          groupPadding: 0.1,
          color: "green",
          negativeColor: "red",
        },
      },
      credits: {
        enabled: false,
      },
      series: s,
    };
  }, [categories, percentageFormatter, series, visibleSerie]);

  return (
    <div className="wSecurityAnalysisChart h-100 d-flex flex-column">
      <div className="d-flex justify-content-center gap-5 flex-grow-1 flex-shrink-1 my-2">
        <div
          className={`btn btn-outline-primary ${
            btnActive === "distribution" ? "active" : ""
          }`}
          onClick={onClickDistributionBtn}
        >
          Distribution
        </div>
        <div
          className={`btn btn-outline-primary ${
            btnActive === "calendar" ? "active" : ""
          }`}
          onClick={onClickCalendarBtn}
        >
          Calendar
        </div>
      </div>
      <div className="wSecurityAnalysisChart-chartWrap h-100 flex-grow-1 flex-shrink-1 overflow-hidden">
        <Chart
          constructorType=""
          ref={chartRef}
          className="h-100"
          options={options}
        ></Chart>
      </div>
    </div>
  );
}
