import { FormEvent, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useInput } from "../../hooks/useInput";
import { useSignInValidator } from "../../hooks/useValidator";

type LoginProps = {
    handleLogin: Function;
    hasLoginFailed: boolean;
    loginFailedMessage: string;
    showRegisterMessage: boolean;
    navigateToRegistration: Function;
};

export function Login({
    handleLogin,
    hasLoginFailed,
    loginFailedMessage,
    showRegisterMessage,
    navigateToRegistration,
}: LoginProps) {
    const [checkValidation, startingCheckValidation] = useState(false);
    const email = useInput("");
    const password = useInput("");

    const paramsLogin = {
        email: email.value.trim(),
        password: password.value,
    };

    const { state, isFormValid } = useSignInValidator(paramsLogin);

    const validInputs = state;

    const emailInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => emailInputRef.current?.focus(), []);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        startingCheckValidation(true);
        if (isFormValid) {
            handleLogin(paramsLogin, isFormValid);
        }
    };

    const addErrorClass = (isInputValid: boolean) => {
        let className = "";
        if (checkValidation) {
            if (!isInputValid) {
                className = "input-error-state";
            }
        }
        return className;
    };

    const emailErrorClass = addErrorClass(validInputs.email.isValid);
    const passwordErrorClass = addErrorClass(validInputs.password.isValid);

    return (
        <Row>
            <Col sm={12}>
                <Row>
                    <Col sm={12}>
                        <div className="form-signin">
                            <h1 className="h3 mb-3 fw-normal text-center">
                                Insert your credentials to login
                            </h1>
                            {hasLoginFailed ? (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    {loginFailedMessage}
                                </div>
                            ) : null}
                            <form onSubmit={handleSubmit} className="mt-3 mb-3">
                                <Row className="g-3">
                                    <Col sm={5}>
                                        <label
                                            className="text-light input-label"
                                            htmlFor="floatingInput"
                                        >
                                            Email address
                                        </label>
                                        <input
                                            value={email.value}
                                            onChange={email.onchange}
                                            type="email"
                                            className={`form-control ${emailErrorClass} ${
                                                validInputs.email.isValid
                                                    ? "valid-state"
                                                    : ""
                                            }`}
                                            id="floatingInputEmail"
                                            name="email"
                                            autoComplete="off"
                                            placeholder=""
                                            ref={emailInputRef}
                                        />
                                        {checkValidation ? (
                                            validInputs.email.isValid ? null : (
                                                <div className="validation-error-message">
                                                    <p>
                                                        {
                                                            validInputs.email
                                                                .message
                                                        }
                                                    </p>
                                                </div>
                                            )
                                        ) : null}
                                    </Col>
                                    <Col sm={5}>
                                        <label
                                            className="text-light input-label"
                                            htmlFor="floatingPassword"
                                        >
                                            Password
                                        </label>
                                        <input
                                            value={password.value}
                                            onChange={password.onchange}
                                            className={`form-control ${passwordErrorClass} ${
                                                validInputs.password.isValid
                                                    ? "valid-state"
                                                    : ""
                                            }`}
                                            id="floatingPassword"
                                            name="password"
                                            type="password"
                                            autoComplete="off"
                                            placeholder=""
                                        />
                                        {checkValidation ? (
                                            validInputs.password
                                                .isValid ? null : (
                                                <div className="validation-error-message">
                                                    <p>
                                                        {
                                                            validInputs.password
                                                                .message
                                                        }
                                                    </p>
                                                </div>
                                            )
                                        ) : null}
                                    </Col>
                                    <Col sm={2}>
                                        <p className="text-light input-label invisible">
                                            Button
                                        </p>
                                        <button
                                            className="w-100 btn btn btn-outline-light"
                                            type="submit"
                                        >
                                            LOG IN
                                        </button>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="text-center mb-1 mt-2">
                        <h3>Don't have an account?</h3>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="d-flex justify-content-center">
                            <button
                                className="w-25 me-1 btn btn-outline-light"
                                title="Click here to register"
                                type="button"
                                onClick={() => navigateToRegistration()}
                            >
                                SIGN UP
                            </button>
                        </div>

                        {showRegisterMessage ? (
                            <div
                                className="alert alert-primary mt-4"
                                role="alert"
                            >
                                Sending your registration...
                            </div>
                        ) : null}

                        {/* <a className="mt-3 d-block text-decoration-none" href="/#">
                Can't remember your credentials?
            </a> */}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
