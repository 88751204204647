import axios from "axios";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useQuery } from "react-query";
import { useEnvironment } from "../hooks/useEnvironment";
import { useFormatter } from "../hooks/useFormatter";
import { getFilePath } from "../queries/products";
import { PTag } from "../trendrating/api/PTag";

export function HomePortfoliosTicker() {
  const { environment } = useEnvironment();
  const formatter = useFormatter();
  const [portfolios, setPortfolios] = useState<any[]>();

  const localhostFile = environment.api.devModeProducts;
  const filepath = getFilePath(localhostFile);

  const {
    isLoading: isLoadingProducts,
    error: errorProducts,
    data: dataProducts,
  } = useQuery<any, Error>("products", () => {
    return axios(`${environment.api.uri + filepath}`);
  });

  useEffect(() => {
    if (dataProducts == null) {
      return;
    }

    let analytics = null;
    let annualizedReturns = null;
    let isPositive = null;

    setPortfolios(
      dataProducts.data
        .filter(
          (product: any) =>
            product.type === "product" || product.type === "combined-product"
        )
        .map((product: any) => {
          analytics = new PTag({
            priceAnalytics: { ...product?.priceAnalytics?.stats } ?? null,
            hPosAnalytics: { ...product?.hPosAnalytics?.stats } ?? null,
          });

          annualizedReturns = analytics.get("annualized_total_return", "H");
          isPositive = (annualizedReturns ?? 0) >= 0;

          return {
            shortName: product.info.name,
            name: `${product.info.name}${
              product.info.positions
                ? ` (${product.info.positions} Stocks)`
                : ""
            }`,
            isPositive,
            annualizedRateOfReturn: formatter.custom("number", {
              options: {
                hasPositiveSign: true,
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: annualizedReturns,
              valueHelper: null,
            }),
          };
        })
    );
  }, [dataProducts, formatter]);

  if (isLoadingProducts || portfolios == null) {
    return <>Loading...</>;
  }

  if (errorProducts) {
    return <>Error: errorList</>;
  }

  const data = (
    <>
      {portfolios.map((portfolio: any) => (
        <span key={portfolio.name}>
          {portfolio.name}:
          {portfolio.isPositive ? (
            <span className="high">{portfolio.annualizedRateOfReturn}</span>
          ) : (
            <span className="low">{portfolio.annualizedRateOfReturn}</span>
          )}
        </span>
      ))}
    </>
  );

  return (
    <Marquee
      className="home-ticker"
      style={{ backgroundColor: "#000000", color: "#FFFFFF" }}
      speed={50}
      gradientColor={[0, 0, 0]}
      pauseOnHover={true}
    >
      {data}
    </Marquee>
  );
}
