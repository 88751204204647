import React, { ReactElement } from "react";

// type SuccessStepProps = {};

export function SuccessStep(/*{}: SuccessStepProps*/): ReactElement {
    return (
        <div className="success-message-wrapper">
            <i className="bi bi-check-circle-fill success-message-wrapper__icon"></i>
            <div className="success-message-wrapper__message">
                <h3>Thank you, you will be contacted shortly</h3>
            </div>
        </div>
    );
}
