import { useEffect, useMemo, useState } from "react";
import { DispersionDetail } from "../../pages/Capture";
import { Chart } from "../Chart";

type CaptureStatistiChartProps = {
    className?: string;
    dispersion?: DispersionDetail;
    type: "winners" | "losers";
};

export default function CaptureStatisticChart({
    className,
    dispersion,
    type,
}: CaptureStatistiChartProps) {
    const [options, setOptions] = useState<Highcharts.Options>({});
    const field = useMemo(
        () => dispersion?.info?.byAgeField ?? "",
        [dispersion?.info?.byAgeField]
    );
    const topList: any[] = useMemo(
        () =>
            dispersion?.byAge?.[`rc|${field}`]?.list["0|0"]?.concat(
                dispersion?.byAge?.[`rc|${field}`]?.list["0|1"]
            ) ?? [],
        [dispersion?.byAge, field]
    );
    const bottomList: any[] = useMemo(
        () =>
            dispersion?.byAge?.[`rc|${field}`]?.list["1|0"]?.concat(
                dispersion?.byAge?.[`rc|${field}`]?.list["1|1"]
            ) ?? [],
        [dispersion?.byAge, field]
    );

    useEffect(() => {
        var points: any[] = [];
        var securities =
            (type === "winners"
                ? topList?.filter((item) => item.rc > 0)
                : bottomList?.filter((item) => item.rc < 0)) ?? [];

        securities.sort((a, b) =>
            a[field] < b[field] ? -1 : b[field] < a[field] ? 1 : 0
        );

        const series: Highcharts.SeriesColumnOptions[] = [];

        let min = 99999999999;
        let max = -9999999999999;
        // if (type === "winners") {
        for (let i = 0; i < securities.length; i++) {
            const y = securities[i][field];
            points.push({
                y: y,
                data: securities[i],
                color: y < 0 ? "red" : "green",
            });
            if (y < min) {
                min = y;
            }
            if (y > max) {
                max = y;
            }
        }
        let serieName = "Performance";
        if (dispersion?.info.byAgefMnemonic) {
            serieName = dispersion.info.byAgefMnemonic + " performance";
        }
        series.push({
            type: "column",
            name: serieName,
            data: points,
            // lineWidth: 1,
            dataGrouping: {
                enabled: false,
            },
            color: type === "winners" ? "green" : "red", //#0072C6'
        });

        const xAxis: Highcharts.XAxisOptions = {
            visible: false,
        };

        const yAxis = {
            showLastLabel: true,
            title: { text: "return" },
            opposite: false,
            labels: {
                formatter: function (): any {
                    return ((this as any).value * 100).toFixed(2) + "%";
                },
            },
            min: type === "winners" ? min : undefined,
            max: type === "winners" ? max : undefined,
            startOnTick: false,
            endOnTick: false,
            maxPadding: 0,
            minPadding: 0,
            // plotLines: [
            //     {
            //         color: "#C0D0E0",
            //         width: 1,
            //         value:
            //             type === "winners"
            //                 ? dispersion?.aggregated.top.AB.pr_adjusted
            //                 : -(
            //                       dispersion?.aggregated.bottom.CD
            //                           .pr_adjusted ?? 0
            //                   ),
            //         label: {
            //             formatter: function () {
            //                 return "<b>" + dispersion != null
            //                     ? (
            //                           (type === "winners"
            //                               ? dispersion?.aggregated?.top?.AB
            //                                     ?.pr_adjusted ?? 0
            //                               : dispersion?.aggregated?.bottom?.CD
            //                                     ?.pr_adjusted ?? 0) * 100
            //                       ).toFixed(2) + "%</b>"
            //                     : "";
            //             },
            //         },
            //     },
            // ],
        };

        const options: Highcharts.Options = {
            chart: {
                animation: false,
                borderWidth: 1,
                borderColor: "#C0D0E0",
                height: 240,
                spacingTop: 20,
                spacingBottom: 20,
                marginBottom: 20,
                type: "column",
            },
            title: {
                useHTML: true,
                text:
                    type === "winners"
                        ? `<span class="rate_A">A</span><span class="rate_B" style="margin-right: 5px;">B</span><p> ${dispersion?.info?.byAgefMnemonic} performance</p>`
                        : `<span class="rate_C">C</span><span class="rate_D" style="margin-right: 5px;">D</span><p> ${dispersion?.info?.byAgefMnemonic} performance</p>`,

                style: { display: "flex" },
            },
            credits: { enabled: false },
            exporting: { enabled: false },
            legend: { enabled: false, reversed: true },
            scrollbar: { enabled: false },
            navigator: { enabled: false },
            rangeSelector: {
                enabled: false,
            },
            tooltip: {
                shared: true,
                outside: true,
                formatter: function (options: any): any {
                    const sec = (this as any).points[0].point.data;
                    let s = `<b>${sec.ticker}</b> ${sec.name}<br/>`;
                    for (const point of (this as any).points) {
                        s += `<br/>${point.series.name}: ${(
                            point.y * 100
                        ).toFixed(2)}%`;
                    }
                    return s;
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                    turboThreshold: 0,
                    states: { inactive: { opacity: 1 } },
                },
            },
            yAxis: yAxis,
            xAxis: xAxis,
            series: series,
        };
        setOptions(options);
    }, [bottomList, dispersion, field, topList, type]);

    return (
        <Chart
            className={className}
            constructorType="chart"
            useAutomaticRatio={false}
            options={options}
        />
    );
}
