import { MouseEventHandler } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DisclaimerText } from "./Disclaimer";

type HomeDisclaimerProps = {
    handleDisclaimer: MouseEventHandler<HTMLButtonElement>;
    isDisclaimerAccepted: boolean;
};

export const HomeDisclaimer = ({
    handleDisclaimer,
    isDisclaimerAccepted,
}: HomeDisclaimerProps) => {
    return (
        <Modal
            className="home-disclaimer-modal"
            centered={true}
            size="lg"
            show={!isDisclaimerAccepted}
            scrollable={true}
            onHide={() => false}
        >
            <Modal.Header closeButton={false}>
                <Modal.Title>Disclaimer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="overflow-auto">
                    <DisclaimerText />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row className="w-100">
                    <Col className="text-small text-muted">
                        This site uses cookies to deliver the services you
                        request, improve user experience and measure audience.
                        By continuing to browse our website, you are consenting
                        to our use of cookies. Find out more about this in our{" "}
                        <Link
                            className="text-decoration-none"
                            to="/privacy"
                            target="_blank"
                        >
                            Privacy policy
                        </Link>
                    </Col>
                    <Col className="col-auto ms-auto">
                        <Button
                            size="lg"
                            variant="primary"
                            onClick={handleDisclaimer}
                        >
                            I Agree
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};
