import { useContext } from "react";
import { AppContext, AppEnvironmentContext } from "../AppContextProvider";

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error(
            `useAppContext must be called within AppContextProvider`
        );
    }
    return context;
};

export const useEnvironment = () => {
    const context = useContext(AppEnvironmentContext);
    if (context === undefined) {
        throw new Error(
            `useEnvironment must be called within AppContextProvider`
        );
    }
    return context;
};
