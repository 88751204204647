import { ProductChart } from "../../components/ProductChart";
import { Product } from "../../types/Product";

type ProductDetailYearChartProps = Pick<
  Product,
  | "analytics"
  | "priceList"
  | "priceBenchmark"
  | "info"
  | "strategy"
  | "benchmark"
> & {
  setActiveKey: Function;
};

export function ProductDetailYearChart({
  benchmark,
  info,
  priceList,
  priceBenchmark,
  strategy,
  setActiveKey,
}: ProductDetailYearChartProps) {
  const value = {
    info: info,
    list: strategy,
    benchmark: benchmark,
    response: {
      benchmark: {
        data: [benchmark],
      },
      priceList: priceList,
      priceBenchmark: priceBenchmark,
    },
  };

  return (
    <div
      className="product-analytics-chart-wrap"
      onClick={() => setActiveKey("chart")}
    >
      <ProductChart
        enableRelative={false}
        isInteractive={false}
        showBar={false}
        useAutomaticRatio={false}
        value={value}
      />
    </div>
  );
}
