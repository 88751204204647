import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { RefObject, useEffect, useRef, useState } from "react";
import { useEnvironment } from "../hooks/useEnvironment";
import { useFormatter } from "../hooks/useFormatter";
import * as taxon from "../trendrating/api/compute/Taxon";
// import * as Exporting from 'highcharts/modules/exporting';
// Exporting(Highcharts);
import { _rate } from "../trendrating/formatter/_rate";

type PeerWeightPieProps = {
    alignChartOnTop?: boolean;
    data: any[];
    isPieClickable?: boolean;
    isTcrMode: boolean;
};

export const PeerWeightPie = ({
    alignChartOnTop,
    data,
    isPieClickable,
    isTcrMode,
}: PeerWeightPieProps) => {
    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: RefObject<HTMLDivElement>;
    }>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [serie, setSerie] = useState<any[]>();
    const [options, setOptions] = useState<Highcharts.Options>();

    const { environment } = useEnvironment();
    const formatter = useFormatter();

    // Prepare data for chart
    useEffect(() => {
        var taxonomies = [
            environment.taxonomies["MarketsFinancial"],
            environment.taxonomies["ICB"],
            environment.taxonomies["ETFclassification"],
        ];

        var dataSerie = [];
        var item = null;
        var rateMeta = null;
        var ratingScale: any = _rate.trendCaptureRating;
        var totalWeight = 0;

        for (var i = 0, length = data.length; i < length; i++) {
            item = data[i];

            const name =
                item.type === "etf"
                    ? taxon.formatTaxonPrefixingParent(
                          taxon.getTaxonById(item.id, taxonomies, null),
                          taxonomies,
                          item.type === "where" ? "Region" : "3 Sector"
                      )
                    : taxon.getTaxonById(item.id, taxonomies, null)["name"];

            rateMeta = ratingScale[item.rate != null ? String(item.rate) : "U"];
            totalWeight = totalWeight + item["weight"];
            dataSerie.push({
                color: isTcrMode ? rateMeta["colorChart"] : null,
                name: name,
                trendrating: item,
                y: item["weight"],
            });
        }

        if (totalWeight < 1) {
            rateMeta = ratingScale["CASH"];
            dataSerie.push({
                //color: isTcrMode ? rateMeta["colorChart"] : null,
                color: rateMeta["colorChart"],
                name: rateMeta["label"],
                trendrating: null,
                y: 1 - totalWeight,
            });
        }

        setSerie(dataSerie);
    }, [data, environment, isTcrMode]);

    useEffect(() => {
        let colors = undefined;
        if (isTcrMode === false) {
            const baseColor = "#2a7092";
            // Array.map needs to have a value to iterate
            colors = Array(25)
                .fill(baseColor)
                .map((item, index) =>
                    Highcharts.color(baseColor)
                        .brighten((index - 5) / 20)
                        .get()
                );
        }

        const options: Highcharts.Options = {
            chart: {
                animation: false,
                backgroundColor: "transparent",
                margin: [0, 0, 0, 0],
                spacing: [0, 0, 0, 0],
                type: "pie",
            },
            credits: { enabled: false },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    borderColor: "#FFFFFF",
                    borderWidth: 1,
                    center: ["50%", "50%"],
                    colors: colors,
                    cursor: isPieClickable ? "pointer" : undefined,
                    dataLabels: { enabled: false },
                    point: {
                        events: {
                            click: function (event: any) {
                                event.stopPropagation();
                                var item = event.point.options.trendrating;
                                // skip cash
                                if (isPieClickable && item != null) {
                                    // that._listenerClick(item, event);
                                    // set listener(item, event);
                                }
                            },
                        },
                    },
                    shadow: false,
                    states: {
                        hover: {
                            enabled: false,
                        },
                        inactive: {
                            opacity: 1,
                        },
                    },
                },
            },
            series: [
                {
                    type: "pie",
                    name: "Rating",
                    animation: true,
                    data: serie,
                },
            ],
            title: { text: undefined },
            tooltip: {
                enabled: true,
                formatter: function () {
                    var value = formatter.custom("number", {
                        options: {
                            decimals: 2,
                            isPercentage: true,
                            notAvailable: {
                                input: "",
                                output: "",
                            },
                        },
                        output: "HTML",
                        value: this.point.y,
                        valueHelper: null,
                    });

                    return "<strong>" + this.point.name + "</strong>: " + value;
                },
                outside: true,
            },
        };
        setOptions(options);
    }, [alignChartOnTop, formatter, serie, isTcrMode, isPieClickable]);

    if (alignChartOnTop && options !== undefined) {
        if (options.chart !== undefined) {
            options.chart.height = "100%";
        }
        if (
            options.plotOptions !== undefined &&
            options.plotOptions.pie !== undefined
        ) {
            options.plotOptions.pie.size = "100%";
            options.plotOptions.pie.slicedOffset = 5;
        }
    }

    if (serie === undefined) {
        return <>Loading...</>;
    }
    // TODO constructorType="stockChart"

    return (
        <div
            ref={containerRef}
            className="ratio ratio-16x9 peerWeightPie-chart"
        >
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
};
