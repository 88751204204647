import { useEffect, useRef, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { ProductChart } from "../components/ProductChart";
import { useProduct } from "../hooks/data/useProduct";
import { useAuth } from "../hooks/useAuth";
import { useDisclaimer } from "../hooks/useDisclaimer";
import { useEnvironment } from "../hooks/useEnvironment";
import { useFormatter } from "../hooks/useFormatter";
import { useKeen } from "../hooks/useKeen";
import { getProductFromDataList, getProducts } from "../queries/products";
import { paths } from "../types/Defaults";
import { ProductDetailAnalytics } from "./detail/ProductDetailAnalytics";
import { ProductDetailHoldings } from "./detail/ProductDetailHoldings";
import { ProductDetailOverview } from "./detail/ProductDetailOverview";
import { ProductSelect } from "./list/ProductSelect";
import { PageWrap } from "./PageWrap";
import { ProductHistogramQuartersDiff } from "../components/ProductHistogramQuartersDiff";

export const ProductDetail = () => {
  const formatter = useFormatter();
  const [activeKey, setActiveKey] = useState<string>("historical");
  const checkDisclaimer = useDisclaimer();
  const { isReady, user } = useAuth();
  const { id } = useParams<{ id?: string }>();
  const chartRef = useRef<any>();
  const analyticsRef = useRef<any>();
  const overviewChartRef = useRef<Tabulator>();
  const { environment } = useEnvironment();
  const { i18n } = useTranslation();

  const { recordEvent } = useKeen();

  //gridInstanceRef?.current?.redraw(true);

  useEffect(() => {
    // TODO If want to enable also anonymous users, check user === undefined
    if (id == null || user == null) {
      return;
    }
    recordEvent("landing", id, user);
  }, [recordEvent, id, user]);

  const {
    isLoading: isLoadingProducts,
    error: errorProducts,
    data: dataProducts,
  } = useQuery<any, Error>(
    "products",
    getProducts(environment.api.uri, environment.api.devModeProducts)
  );

  const {
    isLoading: isLoadingProduct,
    error: errorProduct,
    data: dataProduct,
  } = useQuery<any, Error>(
    ["product", { id, dataProducts }],
    getProductFromDataList(environment.api.uri),
    {
      // The query will not execute until the userId exists
      enabled: !!id && !!dataProducts,
    }
  );

  let products: any[] = [];
  if (dataProducts != null) {
    products = [
      ...dataProducts.data.filter(
        (product: any) =>
          product.type === "product" || product.type === "combined-product"
      ),
    ];
  }

  const productType = products.find((item) => item.name === id);

  if (dataProduct) {
    dataProduct.data.type = productType.type;
  }

  const product = useProduct({
    data: dataProduct?.data ?? null,
    productId: id,
    isTcrMode: false,
  });

  // If auth is null (still fetching data)
  // or false (logged out, above hook will redirect)
  // then show loading indicator.
  if (!isReady) {
    <PageWrap mode="fixed">
      <div className="head-content mb-5 pb-3">
        <Container>
          <div>
            <h1 className="d-flex justify-content-between align-items-start">
              Loading...
            </h1>
          </div>
        </Container>
      </div>
    </PageWrap>;
  }

  // Start app logic
  if (id === null) {
    return (
      <PageWrap mode="fixed">
        <div className="head-content mb-5 pb-3">
          <Container>
            <h1>Missing product id</h1>
          </Container>
        </div>
        <div className="mt-5 pb-3">
          <Container>
            <div>
              <Link className="fs-4 text-decoration-none" to={paths.home}>
                Click here to return to the home page.
              </Link>
            </div>
          </Container>
        </div>
      </PageWrap>
    );
  }

  if (errorProducts) {
    return (
      <PageWrap mode="fixed">
        <div className="head-content mb-5 pb-3">
          <Container>
            <h1>{"Cannot load product list"}</h1>
          </Container>
        </div>
        <div className="mt-5 pb-3">
          <Container>
            <div>
              <Link className="fs-4 text-decoration-none" to={paths.home}>
                Click here to return to the home page.
              </Link>
            </div>
          </Container>
        </div>
      </PageWrap>
    );
  }

  if (errorProduct) {
    return (
      <PageWrap mode="fixed">
        <div className="head-content mb-5 pb-3">
          <Container>
            <h1>{"Cannot load product id " + id}</h1>
          </Container>
        </div>
        <div className="mt-5 pb-3">
          <Container>
            <div>
              <Link className="fs-4 text-decoration-none" to={paths.home}>
                Click here to return to the home page.
              </Link>
            </div>
          </Container>
        </div>
      </PageWrap>
    );
  }

  if (isLoadingProduct || isLoadingProducts || !product) {
    return (
      <PageWrap mode="fixed">
        <div className="head-content mb-5 pb-3">
          <Container>
            <div>
              <h1 className="d-flex justify-content-between align-items-start">
                Loading...
              </h1>
            </div>
          </Container>
        </div>
      </PageWrap>
    );
  }

  // Prepare data
  const alignChartOnTop = false;
  const isPieClickable = true;

  const afterHoldingsChartCreated = (instance: any) => {
    overviewChartRef.current = instance;
  };

  const handleSelect = (eventKey: any, event?: any) => {
    if (eventKey === "overview") {
      setTimeout(() => {
        // $(chart).highcharts().reflow();
        overviewChartRef.current?.redraw(true);
      });
    } else if (eventKey === "historical") {
      setTimeout(() => analyticsRef.current?.refresh());
    } else if (eventKey === "chart") {
      setTimeout(() => chartRef.current?.refresh());
    }
    setActiveKey(eventKey);
  };

  if (product == null) {
    return <PageWrap mode="fixed">Loading...</PageWrap>;
  }

  // if (product.strategy == null) {
  //     return <PageWrap mode="fixed">Loading...</PageWrap>;
  // }

  const value = {
    info: product.info,
    list: product.strategy,
    benchmark: product.benchmark,
    response: {
      benchmark: {
        data: [product.benchmark],
      },
      priceList: product.priceList,
      priceListLastDate: product.priceListLastDate,
      priceBenchmark: product.priceBenchmark,
    },
  };

  let priceListLastDateFormatted = null;
  if (product.priceListLastDate != null) {
    priceListLastDateFormatted = formatter.custom("date", {
      options: {
        format: i18n.language === "en-US" ? ["m", "D", "Y"] : ["D", "m", "Y"],
        isMillisecond: false,
        notAvailable: {
          input: "",
          output: "",
        },
        separator: "/",
      },
      output: "HTML",
      value: product.priceListLastDate,
      valueHelper: null,
    });
  }

  let productOptions = products;

  return (
    <PageWrap
      mode="fixed"
      headerChildren={
        <div className="d-flex justify-content-center">
          <ProductSelect selectedId={id} products={productOptions} />
          <Link to={paths.product} className="btn btn-outline-light ms-2">
            Catalogue
          </Link>
        </div>
      }
    >
      {checkDisclaimer}
      <Tab.Container
        mountOnEnter={true}
        id="product-tab"
        activeKey={activeKey}
        onSelect={handleSelect}
        transition={false}
        unmountOnExit={false}
      >
        <div className="d-flex flex-column h-100">
          <div className="content-top-wrap nav-wrap flex-shrink-0 flex-grow-0">
            <div className="g-0 mx-2 d-block d-md-flex">
              <div className="mt-2 mt-md-0 text-center text-md-start d-block d-md-flex justify-content-between align-items-center">
                <h1
                  className="product-detail-title"
                  title={
                    priceListLastDateFormatted
                      ? `${dataProduct.data.info.name} as of ${priceListLastDateFormatted}`
                      : `${dataProduct.data.info.name}`
                  }
                >
                  {dataProduct.data.info.name}
                </h1>
              </div>
              <div className="ms-3">
                <div className="flex-grow-0 flex-shrink-0">
                  <Nav variant="pills" className="justify-content-center">
                    <Nav.Item>
                      <Nav.Link eventKey="historical">
                        Historical performance
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="holdings">Holdings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="chart">Chart</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>
          </div>
          <Row className="h-100 g-0 flex-shrink-1 flex-grow-1 overflow-hidden">
            <Col
              lg={3}
              md={4}
              className="mainContentColumn mainContentColumn--left h-100 d-flex flex-column"
            >
              <div className="contentScroller flex-grow-1 flex-shrink-1 overflow-auto">
                {activeKey === "chart" ? (
                  <ProductHistogramQuartersDiff {...product} />
                ) : (
                  <ProductDetailOverview {...product} />
                )}
              </div>
            </Col>
            <Col
              lg={9}
              md={8}
              className="mainContentColumn mainContentColumn--right h-100 d-flex flex-column"
            >
              <div className="contentScroller flex-grow-1 flex-shrink-1 overflow-auto">
                <Tab.Content className="h-100">
                  <Tab.Pane
                    eventKey="historical"
                    title="Historical performance"
                  >
                    <ProductDetailAnalytics
                      ref={analyticsRef}
                      product={product}
                      setActiveKey={setActiveKey}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="holdings">
                    <ProductDetailHoldings
                      alignChartOnTop={alignChartOnTop}
                      callback={afterHoldingsChartCreated}
                      product={product}
                      isPieClickable={isPieClickable}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="chart" className="h-100">
                    <ProductChart ref={chartRef} value={value}></ProductChart>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </div>
      </Tab.Container>
    </PageWrap>
  );
};
