import Button from "@restart/ui/esm/Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ProductInfo } from "../../types/Product";
import { orderedKeys } from "../ProductList";
import { ProductListFilter } from "./ProductListFilter";
import { ProductListFilterRadio } from "./ProductListFilterRadio";

// Returns a function to sort by a specific list of strings
const sortByStructure = (list: string[]) =>
  function (a: string, b: string) {
    let numberA = list.indexOf(a);
    if (numberA === -1) {
      numberA = Infinity;
    }
    let numberB = list.indexOf(b);
    if (numberB === -1) {
      numberB = Infinity;
    }
    if (numberA < numberB) {
      return -1;
    } else if (numberA > numberB) {
      return 1;
    } else {
      return 0;
    }
  };

type ProductListFiltersProps = {
  filter: any;
  products: ProductInfo[];
  setFilter: Function;
};

export const ProductListFilters = ({
  filter,
  products,
  setFilter,
}: ProductListFiltersProps) => {
  const [showWarning, setShowWarning] = useState(false);

  const filterProductByMethod = useCallback(
    (product: ProductInfo): boolean => {
      // Explicity ignore filter if length is 0
      if ((filter?.method?.length ?? 0) > 0) {
        return filter.method.includes(product.method);
      }
      return true;
    },
    [filter]
  );

  const filterProductByGeo = useCallback(
    (product: ProductInfo): boolean => {
      // Explicity ignore filter if length is 0
      if ((filter?.geo?.length ?? 0) > 0) {
        return filter.geo.includes(product.geo);
      }
      return true;
    },
    [filter]
  );

  // Build
  const geoList: string[] = useMemo(() => {
    const geoList: any = Array.from(
      new Set(
        products
          .filter(filterProductByMethod)
          .map((product) => product.geo)
          .filter(Boolean)
          .sort(sortByStructure(orderedKeys.geo))
      )
    );

    geoList.push("any");

    return geoList;
  }, [filterProductByMethod, products]);

  const themeList = useMemo(
    () =>
      Array.from(
        new Set(
          products
            .filter(filterProductByMethod)
            .filter(filterProductByGeo)
            .map((product) => product.theme)
            .filter(Boolean)
            .sort(sortByStructure(orderedKeys.theme))
        )
      ),
    [filterProductByGeo, filterProductByMethod, products]
  );

  useEffect(() => {
    // Check if there is a theme filter active that is not anymore inside
    // the themeList array
    let resetThemeFilter = false;
    let validThemeFilters: string[] = [];
    for (const themeFilter of filter.theme) {
      if (!themeList.includes(themeFilter)) {
        resetThemeFilter = true;
      } else {
        validThemeFilters.push(themeFilter);
      }
      if (resetThemeFilter) {
        setFilter((prevFilter: any) => ({
          ...prevFilter,
          theme: validThemeFilters,
        }));
      }
    }
  }, [filter.theme, themeList, setFilter]);

  // Do NOT filter by method! It will remove the other options
  const methodList = useMemo(() => {
    const methods = Array.from(
      new Set(
        products
          .map((product) => product.method)
          .filter(Boolean)
          .sort(sortByStructure(orderedKeys.method))
      )
    ).filter(Boolean);

    return methods;
  }, [products]);

  return (
    <Row>
      {methodList.length > 1 && (
        <Col lg={12} className="mb-3">
          <h4 className="mb-2">Exposure:</h4>
          <div className="me-0 me-sm-2">
            {methodList.map((method) => (
              <ProductListFilterRadio
                filter={filter}
                setFilter={setFilter}
                value={method}
                reset={true}
                key={method}
                type={"method"}
              />
            ))}
          </div>
        </Col>
      )}
      <Col lg={12}>
        <h4 className="mb-2">Filter by:</h4>
        <Row>
          <Col sm={10}>
            <Row>
              {geoList.length > 0 && (
                <Col sm="auto" className="mb-2">
                  <h5>Investment Universe</h5>
                  <div className="me-0 me-sm-2">
                    {geoList.map((geo: string) => (
                      <ProductListFilter
                        filter={filter}
                        setFilter={setFilter}
                        value={geo}
                        key={geo}
                        type={"geo"}
                      />
                    ))}
                  </div>
                </Col>
              )}
              {/* {themeList.length > 0 && (
                <Col sm="auto" className="mb-2">
                  <h5>Theme</h5>
                  <div className="me-0 me-sm-2">
                    {themeList.map((theme) => (
                      <ProductListFilter
                        filter={filter}
                        setFilter={setFilter}
                        value={theme}
                        key={theme}
                        type={"theme"}
                      />
                    ))}
                  </div>
                </Col>
              )} */}
            </Row>
          </Col>
          <Col sm={2} className="mb-3 align-self-end text-end">
            {/*Risk button*/}
            <Button
              className={`btn ${
                showWarning ? "btn-light" : "btn-outline-light"
              }`}
              onClick={() =>
                setShowWarning((prevShowWarning) => !prevShowWarning)
              }
            >
              RISK FACTS
            </Button>
          </Col>
          {showWarning && (
            <Col sm={12} className="mb-2">
              <p className="">
                <strong className="d-block">
                  Model portfolios present several risks
                </strong>
                While a model portfolio tends to deliver superior returns on a
                medium-to-long term basis, it may well record a sequence of
                negative months either in absolute performance as well as in
                terms of differential vs. the benchmark. The drawdowns can be
                substantial and even exceed the benchmark drawdowns. A long-term
                perspective is required to make sense of model portfolios.
                Please familiarize with the statistics in the “historical
                performance” section of any portfolio and double check if the
                risk/return key metrics fit your investment profile. Look at the
                “chart” section to analyze the distribution of quarterly returns
                across the last 10 years . It is also possible to combine two
                portfolios to diversify the exposure. Some model portfolios may
                require a high turn-over in order to maximize the performance.
              </p>
            </Col>
          )}
          <Col sm={12} className="mb-2">
            <p>Table is sortable by clicking the column header.</p>
          </Col>
          {/* <Col sm="auto" className="mb-2">
                        <h5>Positions</h5>
                        <div className="me-0 me-sm-2">
                            {positionsList.map((positions) => (
                                <ProductListFilter
                                    filter={filter}
                                    setFilter={setFilter}
                                    value={positions}
                                    key={positions}
                                    type={"positions"}
                                />
                            ))}
                        </div>
                    </Col> */}
        </Row>
      </Col>
    </Row>
  );
};
