import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { paths } from "../types/Defaults";
import { PageWrap } from "./PageWrap";

export const PrivacyPolicyText = () => {
    return (
        <div>
            <p>
                Trendrating (hereinafter referred to as ‘we’ or ‘us’) is
                committed to maintaining the privacy of personal information
                that you provide to us when using the Trendrating websites. This
                Privacy Policy describes how we treat personal information
                received about you when you visit the Trendrating
                websites.&nbsp;
            </p>

            <h2>Information we collect</h2>

            <p>
                When you visit our websites as an unregistered user, no personal
                data about you are collected by us. Only the current IP address
                of your computer, date and time, your browser type and computer
                operating system, along with a record of the pages on our site
                which you access, is logged. It is not possible to identify you
                as a person from this information. The data about your visit to
                the Trendrating websites, which we store automatically in log
                files, is used purely for statistical and security purposes or
                to improve our websites.&nbsp;
            </p>

            <p>
                When you contact us directly or when you subscribe to the
                password-protected area of the Trendrating websites, we will
                collect personally-identifying information from you that may
                specifically include your name, company, position, address,
                e-mail address, telephone number and password. &nbsp;
            </p>

            <h2>How we use personal data</h2>

            <p>
                We may track and analyse your use of the Trendrating websites
                and use your personal data for the following purposes:&nbsp;
            </p>

            <p>
                • To allow us to develop the Trendrating websites, our products
                and services;
                <br />• To allow us to personalise the content of the
                Trendrating websites;
                <br />• To allow us to verify your authorisation to use the
                Trendrating websites;
            </p>

            <p>
                Our employees are obliged to respect data confidentiality.
                &nbsp;
            </p>

            <h2>Cookies</h2>

            <p>
                A cookie is a string of information that is sent by a websites
                and stored on your device. Cookies contain various information
                in regards to websites visits and can identify your device. You
                can delete the cookies stored on your device at any time. &nbsp;
            </p>

            <p>
                We employ cookie technology primarily to facilitate access
                procedures and provide the requested services. Our cookies are
                transient cookies that are meant to be deleted by Internet
                browsers at the end of each session. Since certain browser
                default configurations allow such cookies to persist, we do not
                accept cookies older than 24 hours for general websites access
                and 4 hours for access to password-restricted pages. Every time
                a cookie is deleted or expires, you will be asked to confirm
                your agreement with the terms and conditions for the use of our
                websites again and/or to re-enter your username and password for
                access to password-restricted pages. &nbsp;
            </p>

            <p>
                We employ Google Analytics technology to collect usage data used
                purely for statistical purposes or to improve our websites. We
                do not send data that might identify users to Google or any of
                its partners. You can learn more on how Google uses data when
                you use our websites here:{" "}
                <a href="https://www.google.com/analytics/terms/us.html">
                    https://www.google.com/analytics/terms/us.html
                </a>
                .
            </p>

            <p>
                For compliance with European ePrivacy law (Directive
                2002/58/EC), users are informed that usage of the websites
                requires consent with the manner in which we use cookies. While
                our uses of cookies mentioned at paragraph 3.2. are exempt from
                consent according to the{" "}
                <a href="http://ec.europa.eu/justice/data-protection/article-29/documentation/opinion-recommendation/files/2012/wp194_en.pdf">
                    EU advisory body on data protection
                </a>
                , consent is required for measurement of audience as described
                at paragraph 3.3.
            </p>

            <h2>Security</h2>

            <p>
                We have implemented appropriate technical and organisational
                measures, such as limiting access to the data to authorised
                personnel only and storing data on a secure server, to protect
                your personally-identifiable information against accidental or
                intentional manipulation, loss, destruction and access by
                unauthorised persons. Our security procedures are continually
                enhanced as new technology becomes available. &nbsp;
            </p>

            <p>
                Your password is personal and strictly confidential. If you have
                forgotten your password, you will be able to ask for a password
                reset online. We will not be able to send you back your current
                password. For security reasons, we do not store your personal
                account details for payment.&nbsp;
            </p>

            <h2>Review and correction</h2>

            <p>
                You may review, update and correct the personally-identifiable
                information that you have provided to us by contacting us by
                e-mail at{" "}
                <a href="mailto:info@trendrating.net">info@trendrating.net</a>.
                You may request the deletion of the personally-identifiable
                information that you have provided to us by contacting us by
                e-mail at{" "}
                <a href="mailto:info@trendrating.net">info@trendrating.net</a>,
                or by postal mail at Trendrating SA via Cantonale 19, Lugano
                6900 (CH). Upon request, we will inform you in writing of what
                personal data we store as defined by applicable law. &nbsp;
            </p>

            <h2>Policy modifications</h2>

            <p>
                We may revise this Privacy Policy from time to time and post the
                new version on our websites, which will be applicable upon
                posting. Please check our websites from time to time and make
                sure that you are aware of the most recent version of this
                Privacy Policy. You will find the date that the policy was last
                revised at the top of this Privacy Policy. &nbsp;
            </p>

            <h2>Links to other sites</h2>

            <p>
                Through links located on the Trendrating websites, we may make
                content or services available to you from other websites
                (including affiliated or co-branded websites) not operated or
                controlled by Trendrating. These other websites are not subject
                to this Privacy Policy and Trendrating is not responsible for
                the content or the privacy practices of these other websites
                (including affiliated or co-branded websites). We recommend that
                you review the privacy policy at each such websites to determine
                how that site protects your privacy. &nbsp;
            </p>

            <h2>Collection of Data</h2>
            <p>
                Our site uses technologies of third-party partners such as
                NextRoll to help us recognize your device and understand how you
                use our site(s) so that we can improve our services to reflect
                your interests and serve you advertisements about the [products
                and/or services] that are likely to be of more interest to you.
                Specifically, NextRoll collect information about your activity
                on our site(s) to enable us to:
            </p>

            <ul>
                <li>
                    measure and analyze traffic and browsing activity on our
                    site(s);
                </li>
                <li>
                    show advertisements for our products and/or services to you
                    on third-party sites;
                </li>
                <li>
                    measure and analyze the performance of our advertising
                    campaigns;
                </li>
            </ul>

            <h2>Cross-Device</h2>
            <p>
                We may share data, such as hashed email derived from emails or
                other online identifiers collected on our site(s) with NextRoll.
                This allows our partners to recognize and deliver you ads across
                devices and browsers. To read more about the technologies used
                by NextRoll and their cross device capabilities please refer to{" "}
                <a
                    href="https://www.nextroll.com/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    NextRoll's Privacy Notice
                </a>
                .
            </p>

            <h2>Opting-Out&nbsp;</h2>
            <p>
                Our partners such as NextRoll may use non-cookie technologies
                that may not be impacted by browser settings that block cookies.
                Your browser may not permit you to block such technologies. For
                this reason you can use the following third party tools to
                decline the collection and use of information for the purpose of
                serving you interest based advertising:
            </p>

            <ul>
                <li>
                    <a
                        href="http://www.networkadvertising.org/choices/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        The NAI's opt-out platform
                    </a>
                </li>
                <li>
                    <a
                        href="http://www.youronlinechoices.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        The EDAA's opt-out platform
                    </a>
                </li>
                <li>
                    <a
                        href="https://optout.aboutads.info/?c=2&amp;lang=EN"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        The DAA's opt-out platform
                    </a>
                </li>
            </ul>

            <h2>Comments and questions</h2>

            <p>
                If you have any questions, comments or concerns about our
                Privacy Policy, you may contact us by e-mail at{" "}
                <a href="mailto:info@trendrating.net">info@trendrating.net</a>.
            </p>
        </div>
    );
};

export const PrivacyPolicy = () => {
    return (
        <PageWrap>
            <div className="head-content mb-5 pb-3">
                <Container>
                    <h1>Privacy Policy</h1>
                </Container>
            </div>
            <div className="mt-5 pb-3">
                <Container>
                    <PrivacyPolicyText />
                    <div className="mt-5 pb-3">
                        <Link className="text-decoration-none" to={paths.home}>
                            Back to home
                        </Link>
                    </div>
                </Container>
            </div>
        </PageWrap>
    );
};
