import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { paths } from "../types/Defaults";
import { PageWrap } from "./PageWrap";

export const DisclaimerText = () => {
    return (
        <div>
            <p>
                Trendrating (and its content) is for INVESTMENT PROFESSIONAL USE
                ONLY and is not intended for the retail investment public.
            </p>
            <h2>LIMITATION ON TRENDRATING LIABILITY</h2>
            <p>
                To the extent not prohibited by law, each subscriber and
                potential subscriber agrees, prior to accessing or using
                Trendrating’s data or receiving information provided by
                Trendrating, to release and hold harmless Trendrating and its
                directors, officers, shareholders, employees, and agents from
                any and all liability in connection with accessing or using
                Trendrating’s data or receiving information provided by
                Trendrating. In all other cases, Trendrating’s liability to a
                subscriber, whether in contract, tort, negligence, or otherwise,
                shall be limited in the aggregate to direct and actual damages
                of the subscriber, not to exceed the fees received by
                Trendrating from the subscriber. Trendrating will not be liable
                for any consequential, incidental, punitive, special, exemplary,
                or indirect damages resulting directly or indirectly from the
                use of or reliance upon any material provided by Trendrating or
                derived from Trendrating’s data. Without limitation, Trendrating
                shall not be responsible or liable for any loss or damages
                related to, either directly or indirectly, (1) any decline in
                market value or loss of any investment; (2) a subscriber’s
                inability to use or any delay in accessing Trendrating data or
                any other source of material provided by Trendrating; (3) any
                absence of material on Trendrating data; (4) Trendrating’s
                failure to deliver or delay in delivering any material or (5)
                any kind of error in transmission of material. Trendrating and
                each subscriber acknowledge that, without limitation, the
                above-enumerated conditions cannot be the probable result of any
                breach of any agreement between Trendrating and the subscriber.
            </p>
            <h2>DISCLAIMER OF WARRANTY</h2>
            <p>
                ANY AND ALL INFORMATION PROVIDED BY TRENDRATINGOR DERIVED FROM
                TRENDRATING’S DATA IS PROVIDED “AS IS” AND TRENDRATING MAKES NO
                WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                LIMITATION, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                PARTICULAR PURPOSE.
            </p>
            <h2>ACKNOWLEDGEMENT AND AGREEMENT</h2>
            <p>
                Notwithstanding any other agreement or other communications
                between Trendrating and Subscriber to the contrary, receipt or
                use of any material provided by Trendrating, at any time and
                through any means, whether directly or indirectly, represents
                acknowledgement by such person of this disclaimer and agreement
                with its terms and conditions.
            </p>
            <h2>BROKERS, INVESTMENT ADVICE &amp; PORTFOLIO MANAGEMENT</h2>
            <p>
                Trendrating is an Internet based subscription service (the
                “Service”) and is not affiliated with an investment advisor or
                Broker/Dealer. Neither Trendrating nor the Service is registered
                as an investment adviser with any federal or state regulatory
                agency, or in any other regulatory capacity. To the extent that
                any portion of the Service content would need to rely upon such
                an exemption, Trendrating would rely upon the “publisher’s
                exclusion” from the definition of “investment adviser” as
                provided under Section 202(a)(11) of the Investment Advisers Act
                of 1940 and corresponding state securities laws.
            </p>
            <p>
                The Service is limited to the dissemination of impersonal and
                objective investment-related information. The publication of the
                Service on the Internet or any Private Network should not be
                construed by any user and/or prospective user as Trendrating’s
                (i) solicitation to effect, or attempt to effect transactions in
                securities, or (ii) provision of any investment related advice
                or services tailored to any individual’s financial situation or
                investment objective(s).
            </p>
            <p>
                Users do not receive investment advisory, investment supervisory
                or investment management services, nor the initial or ongoing
                review or monitoring of the user’s individual investment
                portfolio or individual needs. Therefore, no user should assume
                that his/her/its use serves as a substitute for individual
                personalized advice from an investment professional of the
                user’s choosing. Rather, the Service is designed solely to
                provide impersonal information.
            </p>
            <p>
                The user maintains absolute discretion as to whether to follow
                any portion of the Service content. Trendrating does not offer
                or provide investment implementation services, nor does it offer
                or provide initial or ongoing individual personalized advice
                (neither in person nor via the Internet). It remains the user’s
                exclusive responsibility to review and evaluate the content and
                to determine whether to accept or reject any Service content.
                Trendrating expresses no opinion as to whether any of the
                Service content is appropriate for a user’s investment
                portfolio, strategy, financial situation, or investment
                objective(s).
            </p>
            <p>
                It is the user’s exclusive responsibility to determine if any
                portion of the information is suitable or appropriate for
                his/her financial situation and/or investment objectives, both
                initially and on an ongoing basis. No current or prospective
                user should assume that the future performance of any specific
                Service content will be suitable or profitable for a user’s
                portfolio, equal historical or anticipated performance level(s),
                or prove to be correct.
            </p>
            <p>
                Users do not receive investment advisory, investment supervisory
                or investment management services, nor the initial or ongoing
                review or monitoring of the user’s individual investment
                portfolio or individual needs. Therefore, no user should assume
                that any information contained on the Service serves as a
                substitute their own professional discretion.
            </p>
            <h2>REPRESENTATIONS &amp; WARRANTIES</h2>
            <p>
                Trendrating makes no representations or warranties about the
                accuracy or completeness of the information contained on or
                derived from Trendrating’s data or otherwise provided by
                Trendrating. Our personnel (and affiliates) may own positions in
                and/or trade the securities mentioned in the information that we
                provide. We are not compensated in any way for publishing
                information about companies mentioned by the Service.
            </p>
        </div>
    );
};

export const Disclaimer = () => {
    return (
        <PageWrap>
            <div className="head-content mb-5 pb-3">
                <Container>
                    <h1>TRENDRATING DISCLOSURE AND DISCLAIMERS</h1>
                </Container>
            </div>
            <div className="mt-5 pb-3">
                <Container>
                    <DisclaimerText />
                    <div className="mt-5 pb-3">
                        <Link className="text-decoration-none" to={paths.home}>
                            Back to home
                        </Link>
                    </div>
                </Container>
            </div>
        </PageWrap>
    );
};
