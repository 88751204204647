/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/formatter/Formatter
 * @summary Trendrating data formatter. Format data suitable for HTML rendering,
 * text export (CSV, Excel) and PDF generation (ReportLab - www.reportlab.com)
 *
 */

import { TDate } from "../date/TDate";
import { Taxonomy } from "./Taxonomy";
import { _currency } from "./_currency";
import { _rate } from "./_rate";

export class Formatter {
    baseClass = "format";

    strategyFormatter = null;

    /*
     * Params object structure
     * {
     *     'value': string | number
     *
     *     'valueHelper': {} this object is different for each formatter and
     *                    specifies additional info that help to format the
     *                    value correctly
     *
     *     'options': {} this object is different for each formatter.
     *                  Contains optional formatting information
     *
     *     'output': HTML | PDF | TEXT
     * }
     */

    /**
     * Format number with additional bar
     *
     * @param {object} params - same parameters of number, but with
     *      additional option and valueHelper
     *
     * @param {string}  params.options.width - The width of bar in px or em.
     *      Default "4.5em"
     * @param {object}  params.valueHelper - contains the property to
     *      normalize values
     * @param {number}  params.valueHelper.normalizationThreshold - The
     *      threshold against normalize values. Default 1
     *
     * @returns {string} a formatted string
     */
    bar(params) {
        // The threshold against normalize values
        var normalizationThreshold =
            params["valueHelper"] != null &&
            params["valueHelper"]["normalizationThreshold"] != null
                ? params["valueHelper"]["normalizationThreshold"]
                : 1;
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", null);
        // the width of the bar
        var width = this._getOption(params, "width", "4.5em");

        var output = params.output;
        var value = params.value;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        var formatted = [this.number(params)];

        switch (output) {
            case "HTML": {
                var barValue = 0;
                if (Math.abs(value) > normalizationThreshold) {
                    barValue = 100;
                } else {
                    barValue = Math.abs(
                        parseInt((value * 100) / normalizationThreshold)
                    );
                }

                if (Math.abs(value) > 0 && barValue === 0) {
                    barValue = 1;
                }

                barValue = (barValue * 50) / 100;

                var cssClassBar = this.baseClass + "-bar";
                var cssClassBarBlock = cssClassBar + "Block";
                var bar = document.createElement("div");
                bar.className = cssClassBar;
                bar.style.width = width;
                if (value > 0) {
                    let barSpanContent = document.createElement("span");
                    barSpanContent.className = cssClassBarBlock;
                    barSpanContent.style.width = "50%";
                    bar.appendChild(barSpanContent);

                    let barSpanFill = document.createElement("span");
                    barSpanFill.className = cssClassBarBlock;
                    barSpanFill.style.backgroundColor = "green";
                    barSpanFill.style.width = `${barValue}%`;
                    bar.appendChild(barSpanFill);
                }
                if (value < 0) {
                    let barSpanFillOpposite = document.createElement("span");
                    barSpanFillOpposite.className = cssClassBarBlock;
                    barSpanFillOpposite.style.backgroundColor = "white";
                    barSpanFillOpposite.style.width = `${50 - barValue}%`;
                    bar.appendChild(barSpanFillOpposite);

                    let barSpanFill = document.createElement("span");
                    barSpanFill.className = cssClassBarBlock;
                    barSpanFill.style.backgroundColor = "red";
                    barSpanFill.style.width = `${Math.abs(barValue)}%`;
                    bar.appendChild(barSpanFill);

                    let barSpanContent = document.createElement("span");
                    barSpanContent.className = cssClassBarBlock;
                    barSpanContent.style.width = "50%";
                    bar.appendChild(barSpanContent);
                }

                formatted.push(bar.outerHTML);

                break;
            }
            case "PDF":
            case "TEXT":
            default: {
                // no bar to add
            }
        }

        return formatted.join(" ");
    }

    /**
     * Format date
     *
     * @param {object}  params - value and formatting options
     * @param {object}  params.options - formatting options
     * @param {array}   params.options.format - The format to be used. Default ["Y", "m", "D"]
     * @param {boolean} params.options.isMillisecond - if value unit is millisecond instead of day. Default false
     * @param {object}  params.options.notAvailable - (Required) Directive to manage not available case
     * @param {any}     params.options.notAvailable.input - not available value
     * @param {any}     params.options.notAvailable.output - what to return if params.value === to params.options.notAvailable.input
     * @param {string}  params.options.separator - The separator to be used. Default '-'
     * @param {string}  params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number}  params.value - the value to be formatted
     * @param {object}  params.valueHelper - null or undefined
     *
     * @returns {string} a formatted string
     */
    date(params) {
        // output format
        var format = this._getOption(params, "format", ["Y", "m", "D"]);
        // value unit is millisecond: default is day
        var isMillisecond = this._getOption(params, "isMillisecond", false);
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", null);
        // the date separator
        var separator = this._getOption(params, "separator", "-");

        var output = params.output;
        var value = params.value;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        if (!isMillisecond) {
            value = new TDate().daysToMilliseconds(value);
        }

        var formatted = "";
        var rawDate = new Date(value);
        var Y = rawDate.getUTCFullYear();
        var d = rawDate.getUTCDate();
        var m = rawDate.getUTCMonth() + 1;
        var day = rawDate.getUTCDay();

        var M = m < 10 ? "0" + m : "" + m;
        var D = d < 10 ? "0" + d : "" + d;

        var days = {
            1: "Mon",
            2: "Thu",
            3: "Wed",
            4: "Tue",
            5: "Fri",
            6: "Sat",
            7: "Sun",
        };

        var months = {
            "01": "Jan",
            "02": "Feb",
            "03": "Mar",
            "04": "Apr",
            "05": "May",
            "06": "Jun",
            "07": "Jul",
            "08": "Aug",
            "09": "Sep",
            "10": "Oct",
            "11": "Nov",
            "12": "Dec",
        };

        switch (output) {
            case "HTML":
            case "PDF":
            case "TEXT":
            default: {
                var token = null;
                for (var i = 0, length = format.length; i < length; i++) {
                    switch (format[i]) {
                        case "DAY":
                        case "day": {
                            token = days[day];
                            break;
                        }
                        case "D":
                        case "d": {
                            token = D;
                            break;
                        }
                        case "m": {
                            token = M;
                            break;
                        }
                        case "M": {
                            token = months[M];
                            break;
                        }
                        case "y":
                        case "Y": {
                            token = Y;
                            break;
                        }
                        default:
                            break;
                    }
                    formatted += token;
                    if (i < length - 1) {
                        formatted += separator;
                    }
                }
                break;
            }
        }

        return formatted;
    }

    /**
     * Format duration, magnitude and UPI
     * Special case of number with fixed constraints and icon support
     *
     * @param {object}  params - value and formatting options
     * @param {object}  params.options - formatting options
     * @param {boolean} params.options.hasIcon - if true it postfixes icon to value. Default true
     * @param {object}  params.options.notAvailable - Directive to manage not available case
     * @param {any}     params.options.notAvailable.input - (Required) not available value
     * @param {any}     params.options.notAvailable.output - (Required) what to return if params.value === to params.options.notAvailable.input
     * @param {string}  params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number}  params.value - the value to be formatted
     * @param {object}  params.valueHelper - contains the property to evalute if print out notification icon
     * @param {string}  params.valueHelper.days - (Required) the value of 'lduration', 'lmagnitude' or 'lupi'
     *
     * @returns {string} a formatted string
     */
    durationMagnitudeUpi(params) {
        var hasIcon = this._getOption(params, "hasIcon", true);
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", {
            input: null,
            output: "",
        });

        var output = params.output;
        var value = params.value;
        var valueHelper = params.valueHelper;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        var formatted = this.number({
            options: {
                constraints: {
                    max: 95,
                    min: 5,
                },
                decimals: 0,
                isPercentage: true,
                notAvailable: {
                    input: null,
                    output: "",
                },
            },
            output: "TEXT",
            value: value,
            valueHelper: null,
        });

        switch (output) {
            case "HTML": {
                if (valueHelper["days"] === 0 && hasIcon === true) {
                    formatted =
                        formatted + ' <strong class="format-alert">!</strong>';
                }
                break;
            }
            case "PDF":
            case "TEXT":
            default: {
                break;
            }
        }

        return formatted;
    }

    /**
     * Format new high new low data
     *
     * @param {object}  params - value and formatting options
     * @param {object}  params.options - formatting options
     * @param {boolean} params.options.hasAbbreviatedLabel - if true, prints out abbreviated labels. Default false
     * @param {object}  params.options.notAvailable - (Required) Directive to manage not available case
     * @param {any}     params.options.notAvailable.input - not available value
     * @param {any}     params.options.notAvailable.output - what to return if params.value === to params.options.notAvailable.input
     * @param {object}  params.options.template - How output string must be formatted. Default '${icon} ${label}' for both downgrade and upgrade
     * @param {string}  params.options.template.downgrade - template for downgrade
     * @param {string}  params.options.template.upgrade - template for upgrade
     * @param {string}  params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number}  params.value - the value to be formatted
     * @param {object}  params.valueHelper - null or undefined
     *
     * @returns {string} a formatted string
     */
    newHighNewLow(params) {
        var hasAbbreviatedLabel = this._getOption(
            params,
            "hasAbbreviatedLabel",
            false
        );
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", null);

        var output = params.output;
        var value = params.value;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        var formatted = "";
        var icon = null;
        var label = null;
        var options = {
            icon: {
                html: {
                    high: '<span class="format-number format-number--positive">&#9650;</span>',
                    low: '<span class="format-number format-number--negative">&#9660;</span>',
                },
                pdf: {
                    high: '<span color="#008000" fontName="Times">&#9650;</span>',
                    low: '<span color="#F00000" fontName="Times">&#9660;</span>',
                },
                text: {
                    high: "High",
                    low: "Low",
                },
            },
            label: {
                abbr: {
                    "1m": "1 M",
                    "3m": "3 M",
                    "6m": "6 M",
                    "12m": "12 M",
                },
                standard: {
                    "1m": "1 month",
                    "3m": "3 months",
                    "6m": "6 months",
                    "12m": "12 months",
                },
            },
        };

        value = parseInt(value); // to be sure to work on Number type

        var isUpgrade = true;
        switch (output) {
            case "HTML": {
                if (value > 0) {
                    icon = options.icon.html.high;
                } else {
                    icon = options.icon.html.low;
                    isUpgrade = false;
                }
                break;
            }
            case "PDF": {
                if (value > 0) {
                    icon = options.icon.pdf.high;
                } else {
                    icon = options.icon.pdf.low;
                    isUpgrade = false;
                }
                break;
            }
            case "TEXT": {
                if (value > 0) {
                    icon = options.icon.text.high;
                } else {
                    icon = options.icon.text.low;
                    isUpgrade = false;
                }
                break;
            }
            default:
                break;
        }

        label = this._newHighNewLowHelper(
            value,
            hasAbbreviatedLabel === true
                ? options.label.abbr
                : options.label.standard
        );
        if (label !== "") {
            // template
            var template = this._getOption(params, "template", {
                downgrade: `${icon} ${label}`,
                upgrade: `${icon} ${label}`,
            });
            formatted = isUpgrade ? template["upgrade"] : template["downgrade"];
        }

        return formatted;
    }

    /**
     * Format number
     *
     * @param {object}  params - value and formatting options
     * @param {object}  params.options - formatting options
     * @param {string}  params.options.colored - 'positive' or 'negative'.
     *      If 'positive' the string has a style with a green color else if
     *      'negative' has a style with a red color. Default null
     * @param {object}  params.options.contraints - interval of suitable values. Default null
     * @param {number}  params.options.contraints.max - the maximum value
     * @param {number}  params.options.contraints.min - the minimum value
     * @param {number}  params.options.decimals - number of digits to appear after the decimal point. Default 2
     * @param {boolean} params.options.hasPositiveSign - add plus symbol before the value. Default false
     * @param {boolean} params.options.isPercentage - add percentage symbol after the value. Default false
     * @param {object}  params.options.notAvailable - (Required) Directive to manage not available case
     * @param {any}     params.options.notAvailable.input - not available value
     * @param {any}     params.options.notAvailable.output - what to return if params.value === to params.options.notAvailable.input
     * @param {boolean} params.options.zero - if true returns 0 when value is 0, an empty string otherwise. Default true
     * @param {string}  params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number}  params.value - the value to be formatted
     * @param {object}  params.valueHelper - null or undefined
     *
     * @returns {string} a formatted string
     */
    number(params) {
        // if positive the string has a style with a green color else
        // if negative has a style with a red color
        var colored = this._getOption(params, "colored", null);
        // a min a max interval of suitable values
        var contraints = this._getOption(params, "constraints", null);
        // how many decimals must be considered
        var decimals = this._getOption(params, "decimals", 2);
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", null);
        // add percentage symbol after the value
        var isPercentage = this._getOption(params, "isPercentage", false);
        // add plus symbol before the value
        var hasPositiveSign = this._getOption(params, "hasPositiveSign", false);
        // if true returns 0 when value is 0, an empty string otherwise.
        // Default true
        var zero = this._getOption(params, "zero", true);

        var output = params.output;
        var value = params.value;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        value = parseFloat(value); // to be sure to work on Number type

        if (isNaN(value)) {
            return notAvailable["output"];
        }

        if (isPercentage) {
            value = value * 100;
        }

        if (contraints) {
            if (value > contraints.max) {
                value = contraints.max;
            }
            if (value < contraints.min) {
                value = contraints.min;
            }
        }

        if (zero && value === 0) {
            return (0).toFixed(decimals) + (isPercentage ? "%" : "");
        } else if (!zero && value === 0) {
            return "";
        }

        var valueFixed = Math.abs(value).toFixed(decimals);
        var zeroFixed = (0).toFixed(decimals);

        if (valueFixed === zeroFixed) {
            return zeroFixed + (isPercentage ? "%" : "");
        }

        var formatted =
            (hasPositiveSign && value > 0 ? "+" : "") +
            value.toFixed(decimals) +
            (isPercentage ? "%" : "");

        var color = "";
        switch (output) {
            case "HTML": {
                if (colored) {
                    if (
                        (colored === "positive" && value > 0) ||
                        (colored === "negative" && value < 0)
                    ) {
                        color = "format-number format-number--positive";
                    } else if (
                        (colored === "positive" && value < 0) ||
                        (colored === "negative" && value > 0)
                    ) {
                        color = "format-number format-number--negative";
                    }
                }
                if (color) {
                    formatted =
                        '<span class="' + color + '">' + formatted + "</span>";
                }
                break;
            }
            case "PDF": {
                if (colored) {
                    if (
                        (colored === "positive" && value > 0) ||
                        (colored === "negative" && value < 0)
                    ) {
                        color = _rate["colors"]["A"];
                    } else if (
                        (colored === "positive" && value < 0) ||
                        (colored === "negative" && value > 0)
                    ) {
                        color = _rate["colors"]["D"];
                    }
                }
                if (color) {
                    formatted =
                        '<span color="' + color + '">' + formatted + "</span>";
                }
                break;
            }
            case "TEXT":
            default: {
                break;
            }
        }

        return formatted;
    }

    /**
     * Format big number
     *
     * @param {object} params - value and formatting options
     * @param {object} params.options - formatting options
     * @param {number} params.options.decimals - number of digits to appear after the decimal point. Default 2
     * @param {object} params.options.notAvailable - (Required) Directive to manage not available case
     * @param {any}    params.options.notAvailable.input - not available value
     * @param {any}    params.options.notAvailable.output - what to return if params.value === to params.options.notAvailable.input
     * @param {string} params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number} params.value - the value to be formatted
     * @param {object} params.valueHelper - null or undefined
     *
     * @returns {string} a formatted string
     */
    numberBig(params) {
        // number of digits to appear after the decimal point: default 2
        var decimals = this._getOption(params, "decimals", 2);
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", null);

        var value = params.value;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        var abbr = "";
        var rounded = 0;

        value = parseFloat(value); // to be sure to work on Number type

        if (value >= 1e12) {
            abbr = "T";
        } else if (value >= 1e9) {
            abbr = "B";
        } else if (value >= 1e6) {
            abbr = "M";
        } else if (value >= 1e3) {
            abbr = "K";
        }

        switch (abbr) {
            case "T": {
                rounded = value / 1e12;
                break;
            }
            case "B": {
                rounded = value / 1e9;
                break;
            }
            case "M": {
                rounded = value / 1e6;
                break;
            }
            case "K": {
                rounded = value / 1e3;
                break;
            }
            default: {
                rounded = value;
            }
        }

        var criterion = new RegExp("\\.\\d{" + (decimals + 1) + ",}$");
        if (criterion.test(String(rounded))) {
            rounded = rounded.toFixed(decimals);
        }
        rounded = Number(rounded).toFixed(decimals);

        return rounded + abbr;
    }

    /**
     * Format security price
     *
     * @param {object} params - value and formatting options
     * @param {object} params.options - null or undefined
     * @param {object} params.options.notAvailable - (Required) Directive to manage not available case
     * @param {any}    params.options.notAvailable.input - not available value
     * @param {any}    params.options.notAvailable.output - what to return if params.value === to params.options.notAvailable.input
     * @param {string} params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number} params.value - the value to be formatted
     * @param {object} params.valueHelper - additional info that help to format the value correctly
     * @param {string} params.valueHelper.currency - the ISO 4217 code of currency
     * @param {string} params.valueHelper.date - date expressed in days
     *
     * @returns {string} a formatted string
     */
    price(params) {
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", null);

        var output = params.output;
        var value = params.value;
        var valueHelper = params.valueHelper;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        var currency = valueHelper.currency;
        var formatted = "";
        var formattedValue = "";

        formattedValue = this.numberBig({
            options: {
                notAvailable: {
                    input: null,
                    output: "",
                },
            },
            output: output,
            value: value,
        });

        switch (output) {
            case "HTML": {
                if (currency in _currency.symbol) {
                    currency = _currency.symbol[currency];
                }

                formatted = [
                    '<span title="',
                    currency,
                    " ",
                    formattedValue,
                    " | ",
                    this.date({
                        options: {
                            notAvailable: {
                                input: null,
                                output: "",
                            },
                        },
                        output: "TEXT",
                        value: valueHelper.date,
                    }),
                    '">',
                    currency,
                    " ",
                    formattedValue,
                    "</span>",
                ].join("");
                break;
            }
            case "PDF":
            case "TEXT":
            default: {
                formatted = currency + " " + formattedValue;
            }
        }

        return formatted;
    }

    /**
     * Format rating (basket, portfolio, peer, instrument)
     *
     * @param {object}  params - value and formatting options
     * @param {object}  params.options - formatting options
     * @param {boolean} params.options.hasTrendArrow - if true it postfixes trend arrow to rating. Default true
     * @param {object}  params.options.notAvailable - (Required) Directive to manage not available case
     * @param {any}     params.options.notAvailable.input - not available value
     * @param {any}     params.options.notAvailable.output - what to return if params.value is equal to params.options.notAvailable.input
     * @param {string}  params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number}  params.value - the value to be formatted
     * @param {object}  params.valueHelper - additional info that help to format the value correctly
     * @param {number}  params.valueHelper.rateChange - date rating change (lr, lrr)
     * @param {number}  params.valueHelper.rateDate - date of rating (dr, drr)
     * @param {number}  params.valueHelper.ratePrev - previuos rating (usually rrr or null)
     *
     * @returns {string} a formatted string
     */
    rating(params) {
        var hasTrendArrow = this._getOption(params, "hasTrendArrow", true);
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", {
            input: null,
            output: "",
        });

        var output = params.output;
        var value = params.value;
        var valueHelper = params.valueHelper;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        var className = null;
        var formatted = "";
        var scale = _rate["rating"];
        var rateMeta = scale[value];

        if (rateMeta == null) {
            rateMeta = {
                class: "format-rate format-rate--missing",
                color: "#FF0000",
                colorChart: "#FF0000",
                label: "-",
                value: Infinity,
            };
        }

        switch (output) {
            case "HTML": {
                if (
                    valueHelper == null ||
                    !(
                        "rateChange" in valueHelper &&
                        "rateDate" in valueHelper &&
                        "ratePrev" in valueHelper
                    )
                ) {
                    throw new Error(
                        'Missing valueHelper or one of "rateChange", "rateDate", "ratePrev" properties in valueHelper'
                    );
                }

                var rateChange = valueHelper["rateChange"];
                var rateDate = valueHelper["rateDate"];
                var ratePrev = valueHelper["ratePrev"];
                var ratePrevMeta = scale[ratePrev];

                if (rateChange === 0) {
                    if (ratePrev !== undefined) {
                        // from to
                        if (ratePrev !== 0 && value !== 0) {
                            formatted = [
                                '<strong class="',
                                rateMeta["class"],
                                '" title="Today move from ',
                                ratePrevMeta["label"],
                                " to ",
                                rateMeta["label"],
                                '">',
                                rateMeta["label"],
                                "</strong>",
                            ];

                            if (hasTrendArrow === true) {
                                className =
                                    value > ratePrev
                                        ? "format-alert i-upgrade"
                                        : "format-alert i-downgrade";

                                formatted.push(
                                    [
                                        '<span class="',
                                        className,
                                        '"></span>',
                                    ].join("")
                                );
                            }

                            formatted = formatted.join("");
                        }
                        // first rate
                        if (ratePrev === 0 && value !== 0) {
                            formatted = [
                                '<strong class="',
                                rateMeta["class"],
                                '" title="Just rated!">',
                                rateMeta["label"],
                                "</strong>",
                            ];

                            if (hasTrendArrow === true) {
                                className = "format-alert i-alert";

                                formatted.push(
                                    [
                                        '<strong class="',
                                        className,
                                        '">!</strong>',
                                    ].join("")
                                );
                            }

                            formatted = formatted.join("");
                        }
                    }
                } else {
                    var rateMetaTitle = null;
                    // If there is no rateDate, do not generate the
                    // title attribute on element
                    if (rateDate != null) {
                        rateMetaTitle =
                            "Rated on " +
                            this.date({
                                options: {
                                    notAvailable: {
                                        input: null,
                                        output: "",
                                    },
                                },
                                output: "TEXT",
                                value: rateDate,
                            });
                    }

                    formatted = [
                        '<strong class="',
                        rateMeta["class"],
                        rateMetaTitle != null
                            ? '" title="' + rateMetaTitle
                            : "",
                        '">',
                        rateMeta["label"],
                        "</strong>",
                    ].join("");
                }

                break;
            }
            case "PDF": {
                formatted = [
                    '<strong><span color="',
                    rateMeta["color"],
                    '">',
                    rateMeta["label"],
                    "</span></strong>",
                ].join("");

                break;
            }
            case "TEXT":
            default: {
                formatted = rateMeta.label;

                break;
            }
        }

        return formatted;
    }

    /**
     * Format string
     *
     * @param {object}  params - value and formatting options
     * @param {object}  params.options - null or undefined
     * @param {boolean} params.options.hasToEscapeXmlEntities - if true, & are
     *      replaced with &amp;. Dafault false
     * @param {object}  params.options.notAvailable - (Required) Directive to manage not available case
     * @param {any}     params.options.notAvailable.input - not available value
     * @param {any}     params.options.notAvailable.output - what to return if params.value === to params.options.notAvailable.input
     * @param {string}  params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number}  params.value - the value to be formatted
     * @param {object}  params.valueHelper - null or undefined
     *
     * @returns {string} a formatted string
     */
    string(params) {
        var hasToEscapeXmlEntities = this._getOption(
            params,
            "hasToEscapeXmlEntities",
            false
        );
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", null);

        var output = params.output;
        var value = params.value;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        // Set value as string, if if wasn't the case before
        // Do not do this before checking notAvailable
        value = String(value);

        function escapeXmlEntities(string) {
            return string.replace(/&/gi, "&amp;");
        }

        var formatted = "";
        switch (output) {
            case "HTML": {
                formatted = [
                    '<span title="',
                    hasToEscapeXmlEntities === true
                        ? escapeXmlEntities(value)
                        : value,
                    '">',
                    value,
                    "</span>",
                ].join("");

                break;
            }
            case "PDF":
            case "TEXT":
            default: {
                formatted =
                    hasToEscapeXmlEntities === true
                        ? escapeXmlEntities(value)
                        : value;

                break;
            }
        }

        return formatted;
    }

    /**
     * Format taxon
     *
     * @param {object} params - value and formatting options
     * @param {object} params.options - formatting options
     * @param {string} params.options.ancestorAtLevel - print ancestor
     *       at the ancestorAtLevel instead of the node
     * @param {object} params.options.notAvailable - (Required) Directive to manage not available case
     * @param {any}    params.options.notAvailable.input - not available value
     * @param {any}    params.options.notAvailable.output - what to return if params.value === to params.options.notAvailable.input
     * @param {object} params.options.taxonomy - the taxonony to which the taxon belongs. Default null
     * @param {string} params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number} params.value - the value to be formatted
     * @param {object} params.valueHelper - null or undefined
     *
     * @returns {string} a formatted string
     */
    taxon(params) {
        // if specified, formatter get the node name up to the specified
        // level starting from value.
        var ancestorAtLevel = this._getOption(params, "ancestorAtLevel", null);
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", null);
        // taxonomy
        var taxonomy = this._getOption(params, "taxonomy", null);

        var output = params.output;
        var value = params.value;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        var formatted = "";

        if (taxonomy == null || !(value in taxonomy)) {
            return notAvailable["output"];
        }

        const taxonomyUtils = new Taxonomy();

        switch (output) {
            case "HTML": {
                var _formatted = null;
                if (ancestorAtLevel != null) {
                    const taxonId = taxonomyUtils.getAncestorByLevel(
                        taxonomy,
                        value,
                        ancestorAtLevel
                    );
                    _formatted = taxonomy[taxonId].name;
                } else {
                    _formatted = taxonomy[value].name;
                }

                formatted = `<span title="${_formatted}">${_formatted}</span>`;

                break;
            }
            case "PDF":
            case "TEXT":
            default: {
                if (ancestorAtLevel != null) {
                    const taxonId = taxonomyUtils.getAncestorByLevel(
                        taxonomy,
                        value,
                        ancestorAtLevel
                    );
                    formatted = taxonomy[taxonId].name;
                } else {
                    formatted = taxonomy[value].name;
                }

                break;
            }
        }

        return formatted;
    }

    /**
     * Format Trend Capture Value (TCR)
     *
     * @param {object}  params - value and formatting options
     * @param {object}  params.options - formatting options
     * @param {object}  params.options.notAvailable - (Required) Directive to manage not available case
     * @param {any}     params.options.notAvailable.input - not available value
     * @param {any}     params.options.notAvailable.output - what to return if params.value is equal to params.options.notAvailable.input
     * @param {string}  params.output - supported outputs are: "HTML" | "PDF" | "TEXT"
     * @param {number}  params.value - the value to be formatted
     * @param {object}  params.valueHelper - null or undefined
     *
     * @returns {string} a formatted string
     */
    tcr(params) {
        // notAvailable is an object with input and output properties
        // if input to be formatted is equal to notAvailable.input
        // then notAvailable.output is returned
        var notAvailable = this._getOption(params, "notAvailable", {
            input: null,
            output: "",
        });

        var output = params.output;
        var value = params.value;

        if (value === notAvailable["input"]) {
            return notAvailable["output"];
        }

        var formatted = "";
        var scale = _rate["trendCaptureRating"];
        var rateMeta = scale[value];

        switch (output) {
            case "HTML": {
                formatted = [
                    '<strong class="',
                    rateMeta["class"],
                    '">',
                    rateMeta["label"],
                    "</strong>",
                ].join("");

                break;
            }
            case "PDF": {
                formatted = [
                    '<strong><span color="',
                    rateMeta["color"],
                    '">',
                    rateMeta["label"],
                    "</span></strong>",
                ].join("");

                break;
            }
            case "TEXT":
            default: {
                formatted = rateMeta.label;

                break;
            }
        }

        return formatted;
    }
    // ----------------------------------------------------- private methods
    /**
     *
     * Retrieve the specified option. If not available return defaultValue.
     * If defaultValue is not specified returns null
     *
     * @param {object} params - see params structure at the top of this file
     * @param {string} option - the name of the option to be retrived
     * @param {any} defaultValue - the default value to be returned if the
     *     option is not defined
     *
     * @returns the value of option
     *
     * @ignore
     */
    _getOption(params, option, defaultValue) {
        defaultValue = defaultValue !== undefined ? defaultValue : null;

        let _option =
            "options" in params &&
            params["options"] !== undefined &&
            params["options"] != null &&
            option in params["options"] &&
            params["options"][option] !== undefined &&
            params["options"][option] != null
                ? params["options"][option]
                : null;

        if (_option == null) {
            _option = defaultValue;
        }

        return _option;
    }

    /**
     *
     * Helper function to get label for new high new low data
     *
     * @param {number} value - newHL value (integer)
     * @param {object} labels - an object literal with available labels
     *
     * @ignore
     */
    _newHighNewLowHelper(value, labels) {
        var label = "";

        if (value >= 260) {
            label = labels["12m"];
        } else if (value >= 120) {
            label = labels["6m"];
        } else if (value >= 60) {
            label = labels["3m"];
        } else if (value >= 20) {
            label = labels["1m"];
        } else if (value <= -260) {
            label = labels["12m"];
        } else if (value <= -120) {
            label = labels["6m"];
        } else if (value <= -60) {
            label = labels["3m"];
        } else if (value <= -20) {
            label = labels["1m"];
        }

        return label;
    }
    // --------------------------------------------------- getters / setters
}
