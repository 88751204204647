import { animated, useTransition } from "@react-spring/web";
import { ProductItem } from "../../types/Product";
import { ProductListItemBody } from "./ProductListItemBody";

// const filterName = (name: string): string => {
//     // const names = name.split(" ");
//     // if (names.length > 0) {
//     //     if (!Number.isNaN(parseInt(names[names.length - 1], 10))) {
//     //         names[names.length - 1] = "";
//     //         return names.join(" "); BUG if there is no number, it does attach all text
//     //     }
//     // }
//     return name;
// };

type ProductListItemProps = {
  filter: any;
  position?: string; // re-enable for filters
  product: ProductItem;
  totalRows: number;
};

export const ProductListItem = ({
  filter,
  product,
  totalRows,
}: ProductListItemProps) => {
  let isVisible = true;
  if (filter?.geo?.length > 0) {
    isVisible = isVisible && filter.geo.includes(product.geo);
  }
  if (filter?.theme?.length > 0) {
    isVisible = isVisible && filter.theme.includes(product.theme);
  }
  if (filter?.method?.length > 0) {
    isVisible = isVisible && filter.method.includes(product.method);
  }

  const transitions = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: isVisible,
    delay: 200,
    config: { mass: 0.5, tension: 200, friction: 0, clamp: true },
  });

  const productInfo: any = product.isSingle
    ? product.singleItem ?? ""
    : product.grouped[product.positions[0]] ?? "";
  const productName =
    productInfo.title != null ? productInfo.title : productInfo.name;

  const title = `<h3 title="${productName}" className="my-0">${productName}</h3>`;

  return transitions(
    (styles, item) =>
      item && (
        <animated.div style={styles} className="col-md-4 col-sm-6">
          <div
            className={`card mb-4 rounded-3 shadow-sm card-flag card-flag-${product.geo.toLowerCase()}`}
          >
            <div
              className="card-header d-grid align-items-center"
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
            <div className="card-body">
              <ProductListItemBody product={product} totalRows={totalRows} />
            </div>
          </div>
        </animated.div>
      )
  );
};
