// Methods used by react-query.
// Use currying to prepare functions with associated url
import axios from "axios";
import { ProductQuery } from "../types/Product";
import { environmentTemplate, KnownHostnames } from "../types/Defaults";

export const baseUrl = environmentTemplate.api.uri;

// type getProductFromDataListQuery = {}

//The param path key is needed to select the filepath whe the app is running uder the localhost domain
export const getFilePath = (pathKey?: "all" | "longOnly" | "longShort") => {
  const hostname: KnownHostnames = window.location.hostname as KnownHostnames;
  const timestamp = Date.now();

  const paths = {
    develop: `/dashboard/main_develop/main_develop.json?ts=${timestamp}`,
    all: `/dashboard/main/main.json?ts=${timestamp}`,
    longShort: `/dashboard/main_shortLong/main_shortLong.json?ts=${timestamp}`,
  };
  switch (hostname) {
    // case "localhost":
    //   return paths[pathKey ?? "all"];
    case "localhost":
    case "longonly.trendrating.com":
    case "develop.portfolios.trendrating.com":
    case "portfolios.trendrating.com":
      return paths["develop"];
    // case "longonly.trendrating.com":
    //   return paths["longOnly"];
    case "longshortd.trendrating.com":
    case "longshort.trendrating.com":
      return paths["longShort"];

    default:
      return paths["all"];
  }
};

export const getProducts = (
  baseUrl: string,
  localhostFile: "all" | "longOnly" | "longShort"
) => {
  return ({ queryKey }: any) => {
    const filepath = getFilePath(localhostFile);
    // const [, { id, dataList }] = queryKey; // ignore first element, not used
    return axios(`${baseUrl + filepath}`);
  };
};

export const getProductFromDataList = (baseUrl: string) => {
  return ({ queryKey }: any) => {
    const timestamp = Date.now();
    const [, { id, dataProducts }] = queryKey; // ignore first element, not used

    const product: ProductQuery | undefined = dataProducts.data.find(
      (product: any) => product.name === id
    );

    if (product == null) {
      throw new Error(`Cannot find product id ${id}`);
    }

    const url = `${baseUrl}/${product.type}/${id}/${id}.json?ts=${timestamp}`;
    return axios(url);
  };
};
