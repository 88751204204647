import classNames from "classnames";
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { LongShortBarChart } from "./LongShortBarChart";
import { PeerWeightPie } from "./PeerWeightPie";
import { PeerWeightTable } from "./PeerWeightTable";

type PeerWeightProps = {
    alignChartOnTop?: boolean;
    peerWeights: any;
    isPieClickable?: boolean;
    isTcrMode: boolean;
    mode: "market" | "sector";
    chartType: "pie" | "histogram";
};

type PeerWeightMarketSelectProps = {
    handleChange: ChangeEventHandler<HTMLSelectElement>;
};

type PeerWeightSectorSelectProps = {
    handleChange: ChangeEventHandler<HTMLSelectElement>;
};

const PeerWeightMarketSelect = ({
    handleChange,
}: PeerWeightMarketSelectProps) => {
    return <div className="mx-2">Countries</div>;
};

const PeerWeightSectorSelect = ({
    handleChange,
}: PeerWeightSectorSelectProps) => {
    return (
        <select
            className="form-select w-25"
            defaultValue="Industry"
            onChange={handleChange}
        >
            <option value="Industry">Sectors</option>
            <option value="Sector">Industries</option>
        </select>
    );
};

export const PeerWeight = ({
    alignChartOnTop,
    peerWeights,
    isPieClickable,
    isTcrMode,
    mode,
    chartType,
}: PeerWeightProps) => {
    const [data, setData] = useState<any>({
        weights: [],
        weightsLong: [],
        weightsShort: [],
    });
    const [filter, setFilter] = useState("");

    // peerWeightsIndustry

    useEffect(() => {
        if (mode === "market") {
            // Ignore filter, always Country
            setData({
                weights: peerWeights.Country,
                weightsLong: peerWeights.CountryLong,
                weightsShort: peerWeights.CountryShort,
            });
        } else if (mode === "sector") {
            if (filter === "Sector") {
                setData({
                    weights: peerWeights.Sector,
                    weightsLong: peerWeights.SectorLong,
                    weightsShort: peerWeights.SectorShort,
                });
            } else {
                setData({
                    weights: peerWeights.Industry,
                    weightsLong: peerWeights.IndustryLong,
                    weightsShort: peerWeights.IndustryShort,
                });
            }
        }
    }, [filter, mode, peerWeights]);

    const handleMarketChange = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setFilter("Country");
    };

    const handleSectorChange = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setFilter(event.target.value);
    };

    return (
        <div className="peerWeightPie mb-4">
            <div
                className={classNames("hide", {
                    "peerWeightPie-isClickable": isPieClickable,
                })}
            >
                <div className="peerWeightPie-box px-2">
                    <div className="peerWeightPie-title mt-1 d-flex align-items-center">
                        {mode === "market" ? (
                            <PeerWeightMarketSelect
                                handleChange={handleMarketChange}
                            />
                        ) : (
                            <PeerWeightSectorSelect
                                handleChange={handleSectorChange}
                            />
                        )}
                    </div>
                    <div className="peerWeightPie-content ">
                        <div className="row">
                            {chartType === "pie" ? (
                                <>
                                    <div className="col-sm-4 d-flex align-items-stretch">
                                        <PeerWeightPie
                                            alignChartOnTop={alignChartOnTop}
                                            data={data.weights}
                                            isPieClickable={isPieClickable}
                                            isTcrMode={isTcrMode}
                                        />
                                    </div>
                                    <div className="col-sm-8">
                                        <PeerWeightTable
                                            alignChartOnTop={alignChartOnTop}
                                            data={data.weights}
                                            isPieClickable={isPieClickable}
                                            isTcrMode={isTcrMode}
                                            type="stock"
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className="col-sm-8 d-flex align-items-stretch">
                                    <LongShortBarChart
                                        alignChartOnTop={alignChartOnTop}
                                        data={data}
                                        isPieClickable={isPieClickable}
                                        isTcrMode={isTcrMode}
                                        showFlags={
                                            mode === "market" ? true : false
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
