import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Product } from "../../types/Product";
import { ProductDetailYearChart } from "./ProductDetailYearChart";
import { ProductDetailAnalyticsFacts } from "./ProductDetailAnalyticsFacts";
import { ProductDetailAnalyticsGrid } from "./ProductDetailAnalyticsGrid";
import { ProductDetailPerformanceCharts } from "./ProductDetailPerformanceCharts";
import { ProductDetailAnalyticsPerformanceGrid } from "./ProductDetailAnalyticsPerformanceGrid";
import { PTag } from "../../trendrating/api/PTag";
import { useFormatter } from "../../hooks/useFormatter";

type ProductDetailAnalyticsProps = {
  product: Product;
  setActiveKey: Function;
};

export const ProductDetailAnalytics = forwardRef(
  ({ product, setActiveKey }: ProductDetailAnalyticsProps, ref) => {
    const gridRef: any = useRef();
    useImperativeHandle(ref, () => ({
      refresh() {
        gridRef.current?.refresh();
      },
    }));

    const formatter = useFormatter();

    const analyticsExtractor = useMemo(() => {
      return new PTag(product.strategyAnalytics);
    }, [product.strategyAnalytics]);

    let yearsLabel = "";
    const calendarAnalytics = analyticsExtractor.get(
      "yearly_performances",
      "H"
    );
    if (calendarAnalytics) {
      const timeframes = Object.keys(calendarAnalytics)
        .map((item) => parseInt(item))
        .sort((a, b) => (a > b ? 1 : -1));

      const minTimeFrame = timeframes[0];
      const maxTimeFrame = timeframes[timeframes.length - 1];

      if (minTimeFrame && maxTimeFrame && minTimeFrame !== maxTimeFrame) {
        const formattedMinTimeframe = formatter.custom("date", {
          options: {
            format: ["Y"],
            isMillisecond: false,
            notAvailable: {
              input: "",
              output: "",
            },
            separator: "_",
          },
          output: "HTML",
          value: minTimeFrame,
          valueHelper: null,
        });
        const formattedMaxTimeframe = formatter.custom("date", {
          options: {
            format: ["Y"],
            isMillisecond: false,
            notAvailable: {
              input: "",
              output: "",
            },
            separator: "_",
          },
          output: "HTML",
          value: maxTimeFrame,
          valueHelper: null,
        });
        yearsLabel = `${formattedMinTimeframe}-${formattedMaxTimeframe}`;
      }
    }

    return (
      <div className="mt-4 pb-4">
        <div className="product-analytics-facts">
          <Container fluid className="g-3">
            <Row>
              <Col sm={12}>
                <ProductDetailAnalyticsFacts {...product} />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="product-analytics-charts m-3 p-3">
          <div className="d-lg-flex align-items-stretch">
            <div className="product-analytics-chart pe-lg-3 mb-2">
              <h5>Historical Price</h5>
              <ProductDetailYearChart
                {...product}
                setActiveKey={setActiveKey}
              />
              <ProductDetailAnalyticsPerformanceGrid {...product} />
            </div>
            <div className="product-analytics-chart ps-lg-3 mb-2">
              <h5>Performance {yearsLabel}</h5>
              <ProductDetailPerformanceCharts {...product} />
            </div>
          </div>
          <div className="product-analytics-performance-grid pt-1"></div>
        </div>
        <div className="product-analytics-grid pt-1">
          <ProductDetailAnalyticsGrid ref={gridRef} {...product} />
        </div>
      </div>
    );
  }
);
