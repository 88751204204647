import { useEffect, useMemo, useRef } from "react";
import Tabulator, { ColumnDefinition, Options } from "tabulator-tables"; //import Tabulator library
import { useFormatter } from "../hooks/useFormatter";
import { deepClone } from "../trendrating/deepClone";

type GridProps = {
    callback?: Function;
    className?: string;
    columns: ColumnDefinition[];
    options?: Options;
    data?: any[];
};

export const Grid = ({
    callback,
    className,
    columns,
    options,
    data = [],
}: GridProps) => {
    const formatter = useFormatter();
    const gridRef = useRef<HTMLDivElement>(null);
    const gridInstanceRef = useRef<Tabulator | null>(null);

    const preparedColumns = useMemo(
        () =>
            columns.map<ColumnDefinition>((column: ColumnDefinition) => ({
                sorter: "string",
                formatter: function (cell, formatterParams, onRendered) {
                    return formatter.table(cell.getField(), "table", {
                        ...cell.getData(),
                        type: (cell.getData() as any)?.type ?? "Stock",
                    });
                },
                ...column,
            })),
        [columns, formatter]
    );

    useEffect(() => {
        // Need to also check gridInstance to do not have loops
        if (gridRef.current != null) {
            let userOptions; // clone options because Tabulator change the content
            if (options) {
                userOptions = deepClone<Options>(options);
            }
            if (gridInstanceRef.current == null) {
                const newGridInstance = new Tabulator(gridRef.current, {
                    columnHeaderVertAlign: "bottom",
                    columns: preparedColumns,
                    data: data,
                    virtualDomBuffer: 300, //set virtual DOM buffer to 300px
                    // responsiveLayout: true,
                    layout: "fitColumns",
                    cellVertAlign: "middle",
                    selectable: false,
                    ...userOptions,
                });
                if (callback) {
                    callback(newGridInstance);
                }
                gridInstanceRef.current = newGridInstance;
            } else {
                gridInstanceRef.current.setColumns(preparedColumns);
                if (preparedColumns != null && preparedColumns.length > 0) {
                    gridInstanceRef.current.clearSort();
                    if (
                        userOptions != null &&
                        userOptions.initialSort != null
                    ) {
                        gridInstanceRef.current.setSort(
                            userOptions.initialSort
                        );
                    } else {
                        // Get first column to sort, when changing columns
                        const column = preparedColumns[0].field;
                        if (column != null) {
                            gridInstanceRef.current.setSort(column, "asc");
                        }
                    }
                }
            }
        }
    }, [callback, data, preparedColumns, options]);

    useEffect(() => {
        return () => {
            if (gridInstanceRef.current != null) {
                try {
                    gridInstanceRef.current.destroy();
                } catch (exception) {
                    // TODO check why this happens
                    // to reproduce: click Price Index and Holdings, then
                    // login. Then click again those tabs and click logout
                    console.error("Error on destroying tabulator", exception);
                }
                gridInstanceRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (gridInstanceRef.current !== null) {
            gridInstanceRef.current.replaceData(data);
        }
    }, [data]);

    return (
        <div
            className={`${className ?? "table-bordered table-striped"}`}
            ref={gridRef}
        />
    );
};
