import { MouseEvent } from "react";
import { useEnvironment } from "../hooks/useEnvironment";
import { useFormatter } from "../hooks/useFormatter";
import * as taxon from "../trendrating/api/compute/Taxon";

type PeerWeightPieProps = {
    alignChartOnTop?: boolean;
    data: any[];
    isPieClickable?: boolean;
    isTcrMode: boolean;
    type: "stock" | "etf";
};

export const PeerWeightTable = ({
    alignChartOnTop,
    data,
    isPieClickable,
    isTcrMode,
    type,
}: PeerWeightPieProps) => {
    const { environment } = useEnvironment();
    const formatter = useFormatter();

    var taxonomies = [
        environment.taxonomies["MarketsFinancial"],
        environment.taxonomies["ICB"],
        environment.taxonomies["ETFclassification"],
    ];

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        console.log(event);
        return true;
    };

    return (
        <div className="peerWeightPie-tableContainer mb-2">
            <table className="peerWeightPie-table">
                <tbody>
                    {data.map((item) => {
                        const innerHTML =
                            type === "etf"
                                ? taxon.formatTaxonPrefixingParent(
                                      taxon.getTaxonById(
                                          item.id,
                                          taxonomies,
                                          null
                                      ),
                                      taxonomies,
                                      item.type === "where"
                                          ? "Region"
                                          : "3 Sector"
                                  )
                                : taxon.getTaxonById(item.id, taxonomies, null)[
                                      "name"
                                  ];

                        return (
                            <tr
                                key={item.id}
                                onClick={
                                    isPieClickable ? undefined : handleClick
                                }
                            >
                                <th
                                    className="peerWeightPie-itemName"
                                    title={innerHTML}
                                >
                                    {item.type === "where" ? (
                                        <>
                                            <img
                                                src={`/images/flags/24/${item.id}.png`}
                                                alt={item.id}
                                            />
                                            &nbsp;&nbsp;
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <span className="peerWeightPie-itemNameValue">
                                        {innerHTML}
                                    </span>
                                </th>
                                {isTcrMode ? (
                                    <td className="peerWeightPie-itemTcr">
                                        {item.rate != null
                                            ? formatter.tcr(item.rate, "HTML")
                                            : "-"}
                                    </td>
                                ) : null}
                                <td className="peerWeightPie-itemWeight">
                                    {formatter.custom("number", {
                                        options: {
                                            isPercentage: true,
                                            notAvailable: {
                                                input: 0,
                                                output: 0,
                                            },
                                        },
                                        output: "HTML",
                                        value: item.weight,
                                        valueHelper: null,
                                    })}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
