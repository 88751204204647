import React, { useState } from "react";

export const useInput = (initialValue: string) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setValue(event.target.value);
    };

    return {
        value,
        onchange: handleChange,
    };
};
