import { useState } from "react";
import { Properties } from "../trendrating/api/Properties";
import { useEnvironment } from "./useEnvironment";

export const useProperties = () => {
    const { environment } = useEnvironment();

    const [properties] = useState<any>(() => {
        return new Properties({
            properties: environment.properties,
        });
    });

    return properties;
};
