import { FormEvent, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useInput } from "../../hooks/useInput";
import { useSignUpValidator } from "../../hooks/useValidator";
import { CountrySelect, Select } from "../Select";

type LoginData = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  firm: string;
  country: string;
  role: string;
};

type RegistrationProps = {
  handleRegister: (params: LoginData) => void;
  hasLoginFailed: boolean;
  loginFailedMessage: string;
  showRegisterMessage: boolean;
  navigateToSuccess: Function;
  navigateToLogin: Function;
};

export function Registration({
  hasLoginFailed,
  handleRegister,
  loginFailedMessage,
  showRegisterMessage,
  navigateToLogin,
  navigateToSuccess,
}: RegistrationProps) {
  const [checkValidation, startingCheckValidation] = useState(false);
  const email = useInput("");
  const password = useInput("");
  const confirmPassword = useInput("");
  const firstName = useInput("");
  const lastName = useInput("");
  const firm = useInput("");
  const country = useInput("");
  const role = useInput("");

  const paramsLogin = {
    email: email.value.trim(),
    password: password.value,
    confirmPassword: confirmPassword.value,
    firstName: firstName.value.trim(),
    lastName: lastName.value.trim(),
    firm: firm.value.trim(),
    country: country.value,
    role: role.value,
  };

  const { state, isFormValid } = useSignUpValidator(paramsLogin);

  const validInputs = state;

  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => emailInputRef.current?.focus(), []);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    startingCheckValidation(true);
    if (isFormValid) {
      handleRegister(paramsLogin);
      navigateToSuccess();
    }
  };

  const addErrorClass = (isInputValid: boolean) => {
    let className = "";
    if (checkValidation) {
      if (!isInputValid) {
        className = "input-error-state";
      }
    }
    return className;
  };

  const emailErrorClass = addErrorClass(validInputs.email.isValid);
  const firstNameErrorClass = addErrorClass(validInputs.firstName.isValid);
  const lastNameErrorClass = addErrorClass(validInputs.lastName.isValid);
  // const passwordErrorClass = addErrorClass(validInputs.password.isValid);
  // const confirmPasswordErrorClass = addErrorClass(
  //     validInputs.password.isValid
  // );
  const firmErrorClass = addErrorClass(validInputs.firm.isValid);
  const countryErrorClass = addErrorClass(validInputs.country.isValid);
  const roleErrorClass = addErrorClass(validInputs.role.isValid);
  return (
    <Row>
      <Col sm={12}>
        <Row className="mt-2">
          <Col>
            <div className="form-signin">
              <h1 className="h3 mb-3 fw-normal text-center">
                Complete the form below and get access to the list of stocks.
              </h1>
              {hasLoginFailed ? (
                <div className="alert alert-danger" role="alert">
                  {loginFailedMessage}
                </div>
              ) : null}
              <form
                onSubmit={handleSubmit}
                className="mt-3 mb-3"
                id="auth-form"
              >
                <Row className="g-3">
                  <Col sm={6}>
                    <label
                      className="text-light input-label"
                      htmlFor="floatingInputEmail"
                    >
                      Email address
                    </label>
                    <input
                      value={email.value}
                      onChange={email.onchange}
                      type="email"
                      className={`form-control ${emailErrorClass} ${
                        validInputs.email.isValid ? "valid-state" : ""
                      }`}
                      id="floatingInputEmail"
                      name="email"
                      autoComplete="off"
                      placeholder=""
                      ref={emailInputRef}
                    />
                    {checkValidation ? (
                      validInputs.email.isValid ? null : (
                        <div className="validation-error-message">
                          <p>{validInputs.email.message}</p>
                        </div>
                      )
                    ) : null}
                  </Col>
                  <Col sm={6}>
                    <Select
                      className={`form-control ${roleErrorClass} ${
                        validInputs.role.isValid ? "valid-state" : ""
                      }`}
                      selectedValue={role.value}
                      onSelect={role.onchange}
                      placeholder="Choose an option"
                      options={[
                        "Portfolio_Manager",
                        "Fund_Manager",
                        "Wealth_Manager",
                        "Financial_Advisor",
                        "Hedge_Fund_Manager",
                        "Family_office",
                        "Broker",
                        "Others",
                      ]}
                      selectTitle="Investor profile"
                    >
                      {checkValidation ? (
                        validInputs.role.isValid ? undefined : (
                          <div className="validation-error-message">
                            <p>{validInputs.role.message}</p>
                          </div>
                        )
                      ) : undefined}
                    </Select>
                  </Col>
                </Row>
                <Row className="g-3 mt-1">
                  <Col sm={6}>
                    <label
                      className="text-light input-label"
                      htmlFor="floatingInputFirstName"
                    >
                      First Name
                    </label>
                    <input
                      value={firstName.value}
                      onChange={firstName.onchange}
                      type="text"
                      className={`form-control ${firstNameErrorClass} ${
                        validInputs.firstName.isValid ? "valid-state" : ""
                      }`}
                      id="floatingInputFirstName"
                      name="firstName"
                      autoComplete="off"
                      placeholder=""
                    />
                    {checkValidation ? (
                      validInputs.firstName.isValid ? null : (
                        <div className="validation-error-message">
                          <p>{validInputs.firstName.message}</p>
                        </div>
                      )
                    ) : null}
                  </Col>
                  <Col sm={6}>
                    <label
                      className="text-light input-label"
                      htmlFor="floatingLastName"
                    >
                      Last Name
                    </label>
                    <input
                      value={lastName.value}
                      onChange={lastName.onchange}
                      type="text"
                      className={`form-control ${lastNameErrorClass} ${
                        validInputs.lastName.isValid ? "valid-state" : ""
                      }`}
                      id="floatingLastName"
                      name="lastName"
                      autoComplete="off"
                      placeholder=""
                    />
                    {checkValidation ? (
                      validInputs.lastName.isValid ? null : (
                        <div className="validation-error-message">
                          <p>{validInputs.lastName.message}</p>
                        </div>
                      )
                    ) : null}
                  </Col>
                </Row>
                <Row className="g-3 mt-1">
                  <Col sm={6}>
                    <CountrySelect
                      className={`form-control ${countryErrorClass} ${
                        validInputs.country.isValid ? "valid-state" : ""
                      }`}
                      selectedValue={country.value}
                      onSelect={country.onchange}
                    >
                      {checkValidation ? (
                        validInputs.country.isValid ? undefined : (
                          <div className="validation-error-message">
                            <p>{validInputs.country.message}</p>
                          </div>
                        )
                      ) : undefined}
                    </CountrySelect>
                  </Col>
                  <Col sm={6}>
                    <label
                      className="text-light input-label"
                      htmlFor="floatingInputFirm"
                    >
                      Firm
                    </label>
                    <input
                      value={firm.value}
                      onChange={firm.onchange}
                      type="text"
                      className={`form-control ${firmErrorClass} ${
                        validInputs.firm.isValid ? "valid-state" : ""
                      }`}
                      id="floatingInputFirm"
                      name="firm"
                      autoComplete="off"
                      placeholder=""
                    />
                    {checkValidation ? (
                      validInputs.firm.isValid ? null : (
                        <div className="validation-error-message">
                          <p>{validInputs.firm.message}</p>
                        </div>
                      )
                    ) : null}
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <div className="d-flex justify-content-center">
              <button
                className="w-25 me-2 btn btn-outline-light"
                title="go-to-sign-in"
                type="button"
                //Attach the go previuous method
                onClick={() => navigateToLogin()}
              >
                BACK
              </button>
              <button
                className="w-25 ms-2 btn btn-outline-light"
                title="Click here to sign up"
                type="submit"
                form="auth-form"
              >
                SIGN UP
              </button>
            </div>

            {showRegisterMessage ? (
              <div className="alert alert-primary mt-4" role="alert">
                Please wait...
              </div>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
