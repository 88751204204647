import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useEffect, useState } from "react";
import { useEnvironment } from "../hooks/useEnvironment";
import { useFormatter } from "../hooks/useFormatter";
import * as taxon from "../trendrating/api/compute/Taxon";
// import * as Exporting from 'highcharts/modules/exporting';
// Exporting(Highcharts);
import { _rate } from "../trendrating/formatter/_rate";

type LongShortBarChartProps = {
  alignChartOnTop?: boolean;
  data: any;
  isPieClickable?: boolean;
  isTcrMode: boolean;
  showFlags: boolean;
};

const prepareSerie = (data: any, environment: any, isTcrMode: boolean) => {
  if (data) {
    var taxonomies = [
      environment.taxonomies["MarketsFinancial"],
      environment.taxonomies["ICB"],
      environment.taxonomies["ETFclassification"],
    ];
    var dataSerie = [];
    var item = null;
    var rateMeta = null;
    var ratingScale: any = _rate.trendCaptureRating;
    var totalWeight = 0;
    for (var i = 0, length = data.length; i < length; i++) {
      item = data[i];
      const name =
        item.type === "etf"
          ? taxon.formatTaxonPrefixingParent(
              taxon.getTaxonById(item.id, taxonomies, null),
              taxonomies,
              item.type === "where" ? "Region" : "3 Sector"
            )
          : taxon.getTaxonById(item.id, taxonomies, null)["name"];
      rateMeta = ratingScale[item.rate != null ? String(item.rate) : "U"];
      totalWeight = totalWeight + item["weight"];
      dataSerie.push({
        color: isTcrMode ? rateMeta["colorChart"] : null,
        name: name,
        trendrating: item,
        y: item["weight"],
      });
    }
    if (totalWeight < 1) {
      rateMeta = ratingScale["CASH"];
      dataSerie.push({
        color: isTcrMode ? rateMeta["colorChart"] : null,
        name: rateMeta["label"],
        trendrating: null,
        y: 1 - totalWeight,
      });
    }

    return dataSerie;
  }
};

const buildChartData = (
  serie: any[] | undefined,
  serieLong: any[] | undefined,
  serieShort: any[] | undefined
) => {
  const chartLabels =
    serie?.filter((item) => item.trendrating).map((item) => item.name) ?? [];

  const chartData =
    chartLabels.map((label: string) => {
      let net = 0;
      let long = 0;
      let short = 0;

      if (serie) {
        net = serie.find((item) => item.name === label)?.y ?? 0;
      }

      if (serieShort) {
        short = serieShort.find((item) => item.name === label)?.y ?? 0;
      }

      if (serieLong) {
        long = serieLong.find((item) => item.name === label)?.y ?? 0;
      }

      return { net, long, short, label };
    }) ?? [];

  return chartData;
};

export const LongShortBarChart = ({
  alignChartOnTop,
  data,
  isPieClickable,
  isTcrMode,
  showFlags,
}: LongShortBarChartProps) => {
  const [serie, setSerie] = useState<any[]>();
  const [serieLong, setSerieLong] = useState<any[]>();
  const [serieShort, setSerieShort] = useState<any[]>();

  const [options, setOptions] = useState<Highcharts.Options>();
  const { environment } = useEnvironment();
  const formatter = useFormatter();

  // Prepare data for chart
  useEffect(() => {
    setSerie(prepareSerie(data.weights, environment, isTcrMode));
    setSerieShort(prepareSerie(data.weightsShort, environment, isTcrMode));
    setSerieLong(prepareSerie(data.weightsLong, environment, isTcrMode));
  }, [data, environment, isTcrMode]);

  useEffect(() => {
    var categories: any = serie
      ?.filter((item) => item.trendrating)
      .map((item) => item?.name?.substring(0, 20));

    const chartData = buildChartData(serie, serieLong, serieShort);

    const labelsConfigNoFlags: any = {
      step: 1,
      reserveSpace: true,
      useHTML: true,
      enabled: true,
      padding: 0,
      formatter: function () {
        let fontSize = "";
        if (categories.length < 10) {
          fontSize = "12px";
        } else if (categories.length <= 12) {
          fontSize = "10px";
        } else {
          fontSize = "6px";
        }
        return '<p style="font-size: ' + fontSize + '" >' + this.value + "</p>";
      },
    };
    const labelsConfigCountries: any = {
      step: 1,
      useHTML: true,
      enabled: true,
      padding: 0,
      formatter: function () {
        const data = serie?.filter((item) => item.trendrating);
        const i: any = this.pos;
        const id = data![i].trendrating.id;
        const imagePath = `/images/flags/24/${id}.png`;
        return (
          "<div class='long-short__histogram__country__labels'><p>" +
          this.value +
          '</p><img src="' +
          imagePath +
          '"' +
          "/></div>"
        );
      },
    };

    const options: Highcharts.Options = {
      chart: {
        type: "bar",
      },
      title: {
        text: undefined,
      },

      credits: { enabled: false },

      xAxis: [
        {
          reversed: false,
          labels: showFlags ? labelsConfigCountries : labelsConfigNoFlags,
          categories: categories,
        },
      ],
      yAxis: {
        title: {
          text: null,
        },
        tickInterval: 0.05,
        labels: {
          enabled: false,
          formatter: function () {
            const value =
              this.value < 0
                ? "-" + Math.abs((this.value as number) * 100).toFixed(2) + "%"
                : Math.abs((this.value as number) * 100).toFixed(2) + "%";
            return value;
          },
        },
        plotLines: [
          {
            color: "darkgrey",
            width: 2,
            value: 0,
            label: {
              text: "Short",
              verticalAlign: "bottom",
              textAlign: "right",
              align: "left",

              x: -10,
              rotation: 0,
              style: {
                color: "red",
                fontWeight: "normal",
              },
            },
          },
          {
            color: "green",
            width: 0,
            value: 0,
            label: {
              text: "Long",
              verticalAlign: "bottom",
              textAlign: "left",
              align: "right",
              x: 10,
              rotation: 0,
              style: {
                color: "green",
                fontWeight: "normal",
              },
            },
          },
        ],
      },

      legend: { enabled: false },
      tooltip: {
        borderWidth: 0,
        backgroundColor: "rgba(255,255,255,0)",
        borderRadius: 0,
        shadow: false,
        useHTML: true,
        shared: true,
        formatter: function () {
          const blue = "#194b88";
          const pointsInfo = this.points;

          const tooltipValue = pointsInfo?.map((point) => {
            let value = "";

            if (point.color === "green") {
              value =
                point.x +
                " long: " +
                Highcharts.numberFormat(Math.abs(point.y as number) * 100, 1) +
                "%";
            }

            if (point.color === "red") {
              value =
                "<br>" +
                point.x +
                " short: -" +
                Highcharts.numberFormat(Math.abs(point.y as number) * 100, 1) +
                "%";
            }

            if (point.color === blue) {
              if (point.y < 0) {
                value =
                  "<br>" +
                  point.x +
                  " net: -" +
                  Highcharts.numberFormat(
                    Math.abs(point.y as number) * 100,
                    1
                  ) +
                  "%";
              } else {
                value =
                  "<br>" +
                  point.x +
                  " net: " +
                  Highcharts.numberFormat(
                    Math.abs(point.y as number) * 100,
                    1
                  ) +
                  "%";
              }
            }

            return value;
          });

          const tooltip =
            "<div class='tooltip__highcharts'>" + tooltipValue + "</div>";
          return tooltip;
        },
      },

      series: [
        {
          stacking: "normal",
          maxPointWidth: 25,
          type: "bar",
          data: chartData.map(
            (value: { net: number; short: number; long: number }) => ({
              y: value.long,
              color: "green",
            })
          ),
        },
        {
          stacking: "normal",
          maxPointWidth: 25,
          type: "bar",
          data: chartData.map(
            (value: { net: number; short: number; long: number }) => ({
              y: value.short,
              color: "red",
            })
          ),
        },
        {
          maxPointWidth: 25,
          type: "bar",
          data: chartData.map(
            (value: { net: number; short: number; long: number }) => ({
              y: value.net,
              color: "#194b88",
            })
          ),
        },
      ],
    };
    setOptions(options);
  }, [
    alignChartOnTop,
    formatter,
    serie,
    isTcrMode,
    isPieClickable,
    showFlags,
    serieShort,
    serieLong,
  ]);
  if (alignChartOnTop && options !== undefined) {
    if (options.chart !== undefined) {
      options.chart.height = "100%";
    }
  }
  if (serie === undefined) {
    return <>Loading...</>;
  }
  // TODO constructorType="stockChart"
  return (
    <div className="ratio ratio-16x9 peerWeightPie-chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
