import { Col, Container, Row } from "react-bootstrap";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";
import { useAuth } from "./hooks/useAuth";
import { useAppContext } from "./hooks/useEnvironment";
import { Disclaimer } from "./pages/Disclaimer";
import { Home } from "./pages/Home";
import { NoMatch } from "./pages/NoMatch";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { ProductDetail } from "./pages/ProductDetail";
import { ProductList } from "./pages/ProductList";
import { TermsOfService } from "./pages/TermsOfService";

export const AppAuthenticated = () => {
    const isReady = useAppContext();
    const { isLogged } = useAuth();

    if (!isReady) {
        // TODO put loading banner
        return (
            <Container className="mb-2">
                <Row className="mb-2">
                    <Col>
                        <div className="d-flex justify-content-center mt-5">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <div className={`app ${isLogged ? "authenticated" : ""}`}>
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/product/:id">
                        <ProductDetail />
                    </Route>
                    <Route path="/product">
                        <ProductList />
                    </Route>
                    <Route path="/disclaimer">
                        <Disclaimer />
                    </Route>
                    <Route path="/privacy">
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/terms">
                        <TermsOfService />
                    </Route>
                    <Route path="*">
                        <NoMatch />
                    </Route>
                </Switch>
            </Router>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="cookieconsent"
                style={{ background: "#2a6fc4" }}
                buttonStyle={{
                    backgroundColor: "#003153",
                    color: "#ffffff",
                    fontSize: "16px",
                }}
                expires={150}
            >
                This website uses cookies to improve user experience and site
                performance. By clicking 'accept', you agree to our use of
                cookies and privacy policy terms.
            </CookieConsent>
        </div>
    );
};
