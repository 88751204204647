type TagsUI =
  | "total_return"
  | "annualized_total_return"
  | "last_12_months"
  | "max_drawdown"
  | "avg_yearly_drawdown"
  | "monthly_std"
  | "sharpe"
  | "sterling"
  | "beta"
  | "one_months_return"
  | "three_months_return"
  | "six_months_return"
  | "one_year_return"
  | "two_years_return_annualized"
  | "three_years_return_annualized"
  | "five_years_return_annualized"
  | "ten_years_return_annualized"
  | "yearly_performances"
  | "quarterly_performances"
  | "monthly_performances"
  | "yearly_drawdown"
  | "quarterly_drawdown"
  | "monthly_drawdown"
  | "yearly_standard_dev"
  | "quarterly_standard_dev"
  | "monthly_standard_dev"
  | "positive_quarters_perc"
  | "negative_quarters_perc"
  | "outperforming_quarters_perc"
  | "YTD"
  | "number_of_holdings"
  | "yearly_avg_turnover"
  | "performance_equity_1_Y"
  | "performance_equity_3_Y"
  | "performance_equity_5_Y"
  | "annualized_rate_of_Return_equity";

type AnalyticsDict = {
  [key in TagsUI]: {
    tag: string;
    property: "priceAnalytics" | "hPosAnalytics";
    single: boolean;
  };
};

const ANALYTICS_DICT: AnalyticsDict = {
  performance_equity_1_Y: {
    tag: "r#null,null,FULL,YEARLY,1,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  performance_equity_3_Y: {
    tag: "r#null,null,FULL,YEARLY,3,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  performance_equity_5_Y: {
    tag: "r#null,null,FULL,YEARLY,5,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  annualized_rate_of_Return_equity: {
    tag: "r#null,null,FULL,YEARLY,10,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  total_return: {
    tag: "r#null,null,FULL,null,null,FULL,H",
    property: "priceAnalytics",
    single: true,
  },
  annualized_total_return: {
    tag: "r#null,null,FULL,null,null,FULL,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  last_12_months: {
    tag: "r#null,null,FULL,YEARLY,1,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  max_drawdown: {
    tag: "md#null,null,FULL,null,null,FULL,H",
    property: "priceAnalytics",
    single: true,
  },
  avg_yearly_drawdown: {
    tag: "avg_md_Y#null,null,FULL,null,null,FULL,H",
    property: "priceAnalytics",
    single: true,
  },
  monthly_std: {
    tag: "sd#null,null,FULL,null,null,FULL,H,MONTHLY",
    property: "priceAnalytics",
    single: true,
  },
  sharpe: {
    tag: "sharpe#null,null,FULL,null,null,null,H",
    property: "priceAnalytics",
    single: true,
  },
  sterling: {
    tag: "sterling#null,null,FULL,null,null,null,H",
    property: "priceAnalytics",
    single: true,
  },
  beta: {
    tag: "beta#null,null,FULL,null,null,null,H,B",
    property: "priceAnalytics",
    single: true,
  },
  one_months_return: {
    tag: "r#null,null,FULL,MONTHLY,1,SOLAR,H",
    property: "priceAnalytics",
    single: true,
  },
  three_months_return: {
    tag: "r#null,null,FULL,QUARTERLY,1,SOLAR,H",
    property: "priceAnalytics",
    single: true,
  },
  six_months_return: {
    tag: "r#null,null,FULL,MONTHLY,6,TD,H",
    property: "priceAnalytics",
    single: true,
  },
  one_year_return: {
    tag: "r#null,null,FULL,YEARLY,1,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  two_years_return_annualized: {
    tag: "r#null,null,FULL,YEARLY,2,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  three_years_return_annualized: {
    tag: "r#null,null,FULL,YEARLY,3,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  five_years_return_annualized: {
    tag: "r#null,null,FULL,YEARLY,5,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },

  ten_years_return_annualized: {
    tag: "r#null,null,FULL,YEARLY,10,SOLAR,H,YEARLY",
    property: "priceAnalytics",
    single: true,
  },
  positive_quarters_perc: {
    tag: "winlose#null,null,FULL,null,null,FULL,H,false,1,true,QUARTERLY",
    property: "priceAnalytics",
    single: true,
  },
  negative_quarters_perc: {
    tag: "winlose#null,null,FULL,null,null,FULL,H,false,-1,true,QUARTERLY",
    property: "priceAnalytics",
    single: true,
  },
  outperforming_quarters_perc: {
    tag: "winloseX#null,null,FULL,null,null,FULL,H,false,1,true,QUARTERLY",
    property: "priceAnalytics",
    single: true,
  },
  YTD: {
    tag: "r#null,null,FULL,YEARLY,1,TD,H",
    property: "priceAnalytics",
    single: true,
  },

  yearly_performances: {
    tag: "r#YEARLY,1,CALENDAR,YEARLY,1,TD,H",
    property: "priceAnalytics",
    single: false,
  },
  quarterly_performances: {
    tag: "r#QUARTERLY,1,CALENDAR,QUARTERLY,1,TD,H",
    property: "priceAnalytics",
    single: false,
  },
  monthly_performances: {
    tag: "r#MONTHLY,1,CALENDAR,MONTHLY,1,TD,H",
    property: "priceAnalytics",
    single: false,
  },
  yearly_drawdown: {
    tag: "md#YEARLY,1,CALENDAR,YEARLY,1,TD,H",
    property: "priceAnalytics",
    single: false,
  },
  quarterly_drawdown: {
    tag: "md#QUARTERLY,1,CALENDAR,QUARTERLY,1,TD,H",
    property: "priceAnalytics",
    single: false,
  },
  monthly_drawdown: {
    tag: "md#MONTHLY,1,CALENDAR,MONTHLY,1,TD,H",
    property: "priceAnalytics",
    single: false,
  },
  yearly_standard_dev: {
    tag: "sd#YEARLY,1,CALENDAR,YEARLY,1,TD,H,MONTHLY",
    property: "priceAnalytics",
    single: false,
  },
  quarterly_standard_dev: {
    tag: "sd#QUARTERLY,1,CALENDAR,QUARTERLY,1,TD,H,MONTHLY",
    property: "priceAnalytics",
    single: false,
  },
  monthly_standard_dev: {
    tag: "sd#MONTHLY,1,CALENDAR,MONTHLY,1,TD,H,MONTHLY",
    property: "priceAnalytics",
    single: false,
  },
  number_of_holdings: {
    tag: "constituents#NULL,NULL,NULL,NULL,NULL,NULL,NULL,count",
    property: "hPosAnalytics",
    single: false,
  },
  yearly_avg_turnover: {
    tag: "turnover#NULL,NULL,FULL,NULL,NULL,FULL,YEARLY",
    property: "hPosAnalytics",
    single: true,
  },
};

export class PTag {
  analyticsMap: typeof ANALYTICS_DICT;

  constructor(
    private readonly data: Required<{ priceAnalytics: any; hPosAnalytics: any }>
  ) {
    this.analyticsMap = ANALYTICS_DICT;
  }

  get(analytics: TagsUI, curve: "H" | "B" | "D") {
    const field = this.analyticsMap?.[analytics]?.property ?? null;
    const tag = this.analyticsMap?.[analytics]?.tag ?? null;
    const isSingleReturn = this.analyticsMap?.[analytics]?.single ?? null;

    const curveToSerieID = {
      H: "H",
      B: "B",
      D: "DIFF",
    };

    const tagWithSerieId = tag?.replace(",H", `,${curveToSerieID[curve]}`);

    if (field && tagWithSerieId && isSingleReturn != null) {
      if (isSingleReturn) {
        return (
          Object.values(this.data?.[field]?.[tagWithSerieId] ?? [])?.[0] ?? ""
        );
      } else {
        return this.data?.[field]?.[tagWithSerieId] ?? [];
      }
    }
  }
}
