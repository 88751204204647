import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { paths } from "../types/Defaults";
import { eventBus } from "../eventBus";

export function PortfolioHomeClassic() {
  // const onClickSignUpBtn = useCallback(() => {
  //   eventBus.dispatch("show-user-registration-box", {});
  // }, []);

  return (
    <>
      <div className="home-header-content--part2b">
        <Container className="pt-4 pb-4">
          {/* <Row className="d-flex justify-content-center">
            <Col md={10}>
              <h1 className="d-flex">
                <span className="home-first-impression flex-grow-1 flex-shrink-1">
                  <strong>
                    ONLY ONE GOAL: TO MAXIMIZE THE PERFORMANCE EVERY YEAR.
                  </strong>
                </span>
              </h1>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center mt-5">
            <Col className="d-flex justify-content-center" md={10}>
              <h3 className="home-subtitles-xl">
                We do not follow fashionable themes.
              </h3>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center mt-5">
            <Col className="d-flex justify-content-center" md={10}>
              <h3 className="home-subtitles-xl">
                We do not believe in conventional approaches.
              </h3>
            </Col>
          </Row> */}
          <div className="row mt-5">
            <div className="col">
              <p style={{ fontSize: "1.8em" }}>
                <strong>
                  When we talk to the Family Offices that we serve we hear a
                  growing, common complaint.
                </strong>
                Many of them think they are underserved by equity products and
                actively managed portfolios. Over a 15-year horizon, more than{" "}
                <strong style={{color:"red"}}>70%</strong> of actively managed funds failed to
                outperform their comparison index in 38 out of the 39 categories
                of equity funds, according to SPIVA. If you own funds and ETFs
                and are not happy with the returns you receive it is time to
                consider smarter alternatives. Asset owners can aim at getting
                better returns. Now, discover something different, designed to
                consistently beat the indices and maximize the returns.
                <br />
                <strong style={{ fontSize: "1.5em"}}>
                  We offer smart model portfolios for asset owners who want more
                  and we have a disruptive offer - pay only if and after you see
                  the value.
                </strong>
              </p>
            </div>
          </div>
          <Row className="align-items-md-stretch justify-content-center pb-2 ">
            <Col
              md={10}
              className="home-header-content-cell text-center mb-5 mt-5"
            >
              <Link
                to={paths.product}
                className="home-button-action button-zoom btn btn-lg mt-3 me-1 btn-primary px-5 fs-3 btn-rounded"
              >
                Discover our Smart Model Portfolios
              </Link>
            </Col>
          </Row>
        </Container>

        <div className="home-section-1">
          <div className="home-card-text-wrapper">
            <p>
              At Trendrating, we like to execute{" "}
              <strong>hard testing and objective validation</strong> of the
              selection process across a long history covering{" "}
              <strong>different market cycles</strong>. We like to objectively
              assess and prove where <strong>alpha</strong> is coming from, and
              to <strong>check all the right boxes</strong>, that improve the
              odds to deliver superior performance consistently.
            </p>
          </div>
          <div className="home-card-image-wrapper"></div>
        </div>

        <Container className="pt-4 pb-4">
          <Row className="d-flex justify-content-center">
            <Col md={12}>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h3 className="faq-title">
                      How many strategies can produce on average double digit
                      annual returns?
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="faq-paragraph">
                      Explore our catalogue and analyze the return/risk
                      statistics across 10 years of market history. Select the
                      model portfolios that you prefer and start tracking the
                      performance vs. the benchmark.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h3 className="faq-title">
                      Why do our model portfolios outperform many active and
                      passive products?
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex flex-column">
                      <p className="faq-paragraph">
                        Because our approach is 100% rule-based and systematic.
                        At Trendrating, we like to execute hard testing and
                        objective validation of the selection process across a
                        long history covering different market cycles. We like
                        to objectively assess and prove where alpha is coming
                        from, and to check all the right boxes, that improve the
                        odds to deliver superior performance on a consistent
                        basis.
                      </p>
                      <table className="systematicApproachTable mt-4 mb-4">
                        <tbody>
                          <tr>
                            <td></td>
                            <td>Systematic Approach</td>
                            <td>Discretionary Approach</td>
                          </tr>
                          <tr>
                            <td>
                              Clearly defined investment rules offer rationality
                              and coherence.
                            </td>
                            <td className="bgGreen"><strong>OK</strong></td>
                            <td className="bgRed">X</td>
                          </tr>
                          <tr>
                            <td>
                              Possibility to run historical tests of the rules
                              provides the ability to discover and select the
                              best combinations, and validate the superior
                              impact with facts.
                            </td>
                            <td className="bgGreen"><strong>OK</strong></td>
                            <td className="bgRed">X</td>
                          </tr>
                          <tr>
                            <td>
                              Objective, prompt and disciplined risk control.
                            </td>
                            <td className="bgGreen"><strong>OK</strong></td>
                            <td className="bgRed">X</td>
                          </tr>
                          <tr>
                            <td>
                              Efficient, rigorous execution, consistent to the
                              chosen investment process.
                            </td>
                            <td className="bgGreen"><strong>OK</strong></td>
                            <td className="bgRed">X</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h3 className="faq-title">
                      How many providers ask you to pay only if satisfied?
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="faq-paragraph">
                      We charge a license fee contingent to satisfaction.
                    </p>
                    <p className="faq-paragraph">
                      Pay only if and after you see the value.
                    </p>
                  </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h3 className="faq-title">
                      How can you profit from trendrating smart model
                      portfolios?
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="faq-paragraph">
                      Analyze and compare the model portfolios performance and
                      key ratios. Decide if you want to have access to the
                      holdings and sign up. Easily replicate the model portfolio
                      you prefer, following the investment adjustments. At the
                      rebalance date, have your broker/banker to execute the
                      trades. Start with a small allocation and decide to
                      increase over time if you see the difference. Pay only a
                      fixed, capped fee that is a fraction of any management fee
                      you usually pay.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <h3 className="d-flex home-subtitles-m">
                <span className="home-first-impression-big-margin flex-grow-1 flex-shrink-1">
                  <strong>CHANGING THE GAME FOR ASSET OWNERS</strong>
                </span>
              </h3>

              <div className="advantage-box">
                <div>
                  <p>
                    <strong>Generate higher returns.</strong> If you want to get
                    more from your listed equities exposure consider our smart
                    model portfolios. Go to the catalogue and analyse the
                    performance across years. Compare to what you currently have
                    and decide.
                  </p>
                  <p>
                    <strong>Save on the fees.</strong> Stop paying rich fees for
                    average products and managed accounts. Just pay a lower,
                    flat fee.
                  </p>
                  <p>
                    <strong>
                      Get a more robust, consistent portfolio management.
                    </strong>{" "}
                    Our systematic approach guarantees active strategies based
                    on well-defined rules and extensive validation via massive
                    testing. Avoid the uncertain quality of investment decisions
                    based on a discretionary, subjective approach, that can
                    produce inconsistent results.
                  </p>
                  <p>
                    <strong>Gain full transparency.</strong> Use our model
                    portfolios to get total transparency on the holdings and
                    gain extra quality control for your portfolio.
                  </p>
                  <p>
                    <strong>Easy execution.</strong> The model portfolios
                    require a simple rebalancing at the end of the
                    month/quarter. All it takes is to send the list of stocks to
                    your broker/bank for execution.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-md-stretch justify-content-center pt-2">
            <Col md={10} className="home-header-content-cell text-center  mt-5">
              <Button
                onClick={() => eventBus.dispatch("show-user-login-box", true)}
                className="home-button-action button-zoom btn btn-lg mt-3 me-1 btn-primary px-5 fs-3 btn-rounded"
              >
                Sign up to learn more
              </Button>
            </Col>
          </Row>
          <Row className="align-items-md-stretch justify-content-center pb-2 ">
            <Col
              md={10}
              className="home-header-content-cell text-center mb-5 mt-5"
            >
              <Link
                to={paths.product}
                className="home-button-action button-zoom btn btn-lg mt-3 me-1 btn-primary px-5 fs-3 btn-rounded"
              >
                Discover our Smart Model Portfolios
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Container className="my-5"> */}
      {/* <Row
          className="d-flex justify-content-center align-items-center"
          style={{ flex: 1 }}
        >
          <Col md={12}>
            <div className="cta-wrapper">
              <p className="cta-text">
                Sign up and discover Trendrating's latest generation of Smart
                Model Portfolios. Track the portfolios, compare the key data and
                perform your own evaluation.
              </p>

              <Button className="cta-btn" onClick={onClickSignUpBtn}>
                Sign up
              </Button>
            </div>
          </Col>
        </Row> */}

      {/* </Container> */}

      <div className="image-page-break">
        <div className="last-section-content">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col md={8}>
                <div className="neumorphism-container">
                  <h3 style={{ marginBottom: "1.5em" }}>HOW IT WORKS</h3>
                  <p>
                    <strong>Our approach is 100% systematic</strong>, with no
                    human interaction and subjective influence. The rules used
                    to select stocks are massively tested across 15 years and
                    are combined and weighted using our algorithmic models.{" "}
                    <strong>Profiting</strong> from the broad performance
                    dispersion across stocks requires a well-tested sequence of
                    selection rules. Our Smart Model Portfolios select stocks
                    that combine the{" "}
                    <strong>most productive fundamentals</strong> with{" "}
                    <strong>confirmed positive price trends</strong>.
                  </p>

                  <ul className="bullet-point-list">
                    <li>
                      The sound <strong>fundamentals</strong> provide the
                      foundation of strong expected returns.
                    </li>
                    <li>
                      The <strong>trend validation</strong> filters out down
                      trends and substantiates the market interest (selecting
                      only good stocks).
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div className="neumorphism-container">
                  <div className="brand-image"></div>
                </div>
              </Col>
            </Row>
            <Row className="align-items-md-stretch justify-content-center pt-2">
              <Col
                md={10}
                className="home-header-content-cell text-center  mt-5"
              >
                <Button
                  onClick={() => eventBus.dispatch("show-user-login-box", true)}
                  className="home-button-action button-zoom btn btn-lg mt-3 me-1 btn-primary px-5 fs-3 btn-rounded"
                >
                  Sign up to learn more
                </Button>
              </Col>
            </Row>
            <Row className="align-items-md-stretch justify-content-center pb-2 pt-2">
              <Col
                md={10}
                className="home-header-content-cell text-center mb-5 mt-5"
              >
                <Link
                  to={paths.product}
                  className="home-button-action button-zoom btn btn-lg mt-3 me-1 btn-primary px-5 fs-3 btn-rounded"
                >
                  Discover our Smart Model Portfolios
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="image-page-break-last">
        <div className="last-section-content">
          <Container>
            <Row className="d-flex justify-content-center" id={"FAQ"}>
              <Col md={12}>
                <h3 style={{ marginBottom: "1.5em" }}>FAQ</h3>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h3 className="faq-title">
                        Why these model portfolios better than most others?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="faq-paragraph">
                        Because they combine two key elements. A systematic
                        methodology and a smarter decision process. A systematic
                        methodology offers:
                      </p>
                      <ul>
                        <li className="faq-paragraph">
                          Transparent, well defined working rules.
                        </li>
                        <li className="faq-paragraph">
                          Easy to test and validate.
                        </li>
                        <li className="faq-paragraph">
                          A flawless, coherent execution.
                        </li>
                        <li className="faq-paragraph">
                          No human biases, emotions, unproven assumptions.
                        </li>
                        <li className="faq-paragraph">
                          A smarter decision process.
                        </li>
                        <li className="faq-paragraph">
                          Uses only well tested fundamentals, selecting only the
                          criteria that deliver real alpha.
                        </li>
                        <li className="faq-paragraph">
                          Respects and exploits price trends.
                        </li>
                        <li className="faq-paragraph">
                          Can react fast to new risks and new opportunities.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h3 className="faq-title">
                        What is the rationale / foundation of the selection
                        process?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="faq-paragraph">
                        Our rigorous selection process is checking the two key
                        boxes to perform – good growth metrics (sales and
                        earnings) and validated, actual positive price trends.
                        This offers the opportunity to exploit the performance
                        dispersion across stocks capturing the outperformers
                        that show a strong uptrends supported by sound
                        fundamentals.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h3 className="faq-title">
                        {" "}
                        Why is the turn-over high in some strategies?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="faq-paragraph">
                        The market is moving fast and maximizing returns may
                        require a prompt, active management. The turn-over is a
                        price to pay if one wants to achieve superior
                        performance in a consistent way.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <h3 className="faq-title">
                        How many stocks are in a portfolio?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="faq-paragraph">
                        Each model portfolio holds a maximum number of stocks
                        exposed in the description. Sometimes the number of
                        stocks selected can be lower than the maximum number, if
                        there are not enough stocks satisfying the quality rules
                        of the model during a given rebalance period.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <h3 className="faq-title">
                        How consistent is the expected outperformance?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="faq-paragraph">
                        You can check the number of positive years and quarters
                        in the tables to get an idea of the historical
                        consistency in terms of outperformance vs. the
                        benchmark.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <h3 className="faq-title">
                        {" "}
                        What are the differences across the Smart Model
                        Portfolios?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="faq-paragraph">
                        The main differences are:{" "}
                      </p>
                      <ul>
                        <li className="faq-paragraph">The number of stocks.</li>
                        <li className="faq-paragraph">
                          The different combination and sequence of rules and
                          parameters based on a similar rationale.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <h3 className="faq-title">
                        How can you profit from trendrating smart model
                        portfolios?
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="faq-paragraph">
                        Analyze and compare the model portfolios performance and
                        key ratios. Decide if you want to have access to the
                        holdings and sign up. Easily replicate the model
                        portfolio you prefer, following the investment
                        adjustments. At the rebalance date, have your
                        broker/banker to execute the trades. Start with a small
                        allocation and decide to increase over time if you see
                        the difference. Pay only a fixed, capped fee that is a
                        fraction of any management fee you usually pay.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
          <Container className="my-5">
            <Row className="align-items-md-stretch justify-content-center pt-2">
              <Col
                md={10}
                className="home-header-content-cell text-center  mt-5"
              >
                <Button
                  onClick={() => eventBus.dispatch("show-user-login-box", true)}
                  className="home-button-action button-zoom btn btn-lg mt-3 me-1 btn-primary px-5 fs-3 btn-rounded"
                >
                  Sign up to learn more
                </Button>
              </Col>
            </Row>
            <Row className="align-items-md-stretch justify-content-center pb-2 ">
              <Col
                md={10}
                className="home-header-content-cell text-center  mt-5"
              >
                <Link
                  to={paths.product}
                  className="home-button-action button-zoom btn btn-lg mt-3 me-1 btn-primary px-5 fs-3 btn-rounded"
                >
                  Discover our Smart Model Portfolios
                </Link>
              </Col>
            </Row>
            {/* <Row
              className="d-flex justify-content-center align-items-center"
              style={{ flex: 1 }}
            >
               <Col md={12}>
                <div className="cta-wrapper">
                  <p className="cta-text">
                    Sign up and discover Trendrating's latest generation of
                    Smart Model Portfolios. Track the portfolios, compare the
                    key data and perform your own evaluation.
                  </p>

                  <Button className="cta-btn" onClick={onClickSignUpBtn}>
                    Sign up
                  </Button>
                </div>
              </Col> 
            </Row> */}
          </Container>
        </div>
      </div>
    </>
  );
}
