type AppEnvironmentApi = {
  baseEndPoint: string;
  domain: string;
  node?: string;
};

export type AppEnvironment = {
  account: {
    product: {
      codeName: string;
      configuration: any;
      id: number;
      productCode: string;
      typeName: string;
    };
    user: any | null;
  };
  api: {
    account: AppEnvironmentApi;
    compute: AppEnvironmentApi;
    isDebug: boolean;
    report: AppEnvironmentApi;
    rest: AppEnvironmentApi;
    uri: string;
    devModeProducts: "all" | "longOnly" | "longShort";
    usage: {
      storeKey: string;
      storeUrl: string;
    };
  };
  configuration: any | null;
  customerCare: {
    isImpersonating: boolean;
    userId: number | null;
  };
  properties: any | null;
  taxonomies: any | null;
};

export type EndpointMeta = {
  baseEndPoint: string;
  domain: string;
};

export type AppRoutes = {
  home: string;
  productDetail: string;
  product: string;
  disclaimer: string;
  privacy: string;
  termsOfService: string;
};

export const paths: AppRoutes = {
  home: "/",
  productDetail: "/product/:id",
  product: "/product",
  disclaimer: "/disclaimer",
  privacy: "/privacy",
  termsOfService: "/terms",
};

export type KnownHostnames =
  | "localhost"
  | "develop.portfolios.trendrating.com"
  | "portfolios.trendrating.com"
  | "longshortd.trendrating.com"
  | "longshort.trendrating.com"
  | "longonly.trendrating.com";

const pathResolver = (forcedEnv?: "test" | "prod") => {
  const testPath = "/data/tr.aws.bucket.share-test";
  const prodPath = "/data/tr.aws.bucket.share-prod";

  if (forcedEnv != null) {
    if (forcedEnv === "test") {
      return testPath;
    } else if (forcedEnv === "prod") {
      return prodPath;
    }
  }

  const hostname: KnownHostnames = window.document.location
    .hostname as KnownHostnames;

  switch (hostname) {
    case "localhost":
    case "develop.portfolios.trendrating.com":
    case "longshortd.trendrating.com":
      return testPath;

    case "longonly.trendrating.com":
    case "longshort.trendrating.com":
    case "portfolios.trendrating.com":
      return prodPath;
  }
};

export const environmentTemplate: AppEnvironment = {
  account: {
    product: {
      codeName: "Strategy Management Solution",
      configuration: {
        analysis_list: {
          enabled: true,
          label: "menu_analysis_collection",
          overview_tabs: [
            {
              enabled: true,
              id: "overview",
            },
            {
              enabled: true,
              id: "holdings",
              widgets: {
                viewer: {
                  table: {
                    columns: {
                      security: [
                        "weight",
                        "ticker",
                        "name",
                        "vc",
                        "rc",
                        "dr",
                        "mc",
                        "px",
                        "lhl",
                        "pr",
                        "pw",
                        "marketcap",
                        "industry",
                      ],
                      Stock: [
                        "weight",
                        "ticker",
                        "name",
                        "vc",
                        "rc",
                        "dr",
                        "mc",
                        "px",
                        "lhl",
                        "pr",
                        "pw",
                        "marketcap",
                        "industry",
                      ],
                      Index: [
                        "weight",
                        "ticker",
                        "name",
                        "vc",
                        "rc",
                        "dr",
                        "mc",
                        "px",
                        "lhl",
                        "pr",
                        "pw",
                      ],
                      ETF: [
                        "weight",
                        "ticker",
                        "name",
                        "vc",
                        "rc",
                        "dr",
                        "mc",
                        "px",
                        "lhl",
                        "pr",
                        "pw",
                        "marketcap",
                      ],
                      Sector: [
                        "weight",
                        "ticker",
                        "name",
                        "vc",
                        "rc",
                        "dr",
                        "mc",
                        "px",
                        "lhl",
                        "pr",
                        "pw",
                        "marketcap",
                        "industry",
                      ],
                      Currency: [
                        "weight",
                        "ticker",
                        "name",
                        "vc",
                        "rc",
                        "dr",
                        "lhl",
                        "pr",
                        "pd",
                        "pw",
                      ],
                    },
                    columns_available: [
                      {
                        field: "ticker",
                      },
                      {
                        field: "isin",
                      },
                      {
                        field: "country",
                      },
                      {
                        field: "currency",
                      },
                      {
                        field: "vc",
                      },
                      {
                        field: "dc",
                      },
                      {
                        field: "name",
                      },
                      {
                        field: "domicile",
                      },
                      {
                        field: "marketcap",
                        headerIndex: 2,
                      },
                      {
                        field: "weight",
                      },
                      {
                        field: "industry",
                      },
                      {
                        field: "sector",
                      },
                      {
                        field: "icb",
                      },
                      {
                        field: "etfclass",
                        panelIndex: 1,
                      },
                      {
                        field: "etfgeo",
                        panelIndex: 1,
                      },
                      {
                        field: "rc",
                      },
                      {
                        field: "mc",
                      },
                      {
                        field: "px",
                      },
                      {
                        field: "dr",
                        formatter: 1,
                      },
                      {
                        field: "dt",
                        formatter: 1,
                      },
                      {
                        field: "rrr",
                      },
                      {
                        field: "lhl",
                      },
                      {
                        field: "duration",
                      },
                      {
                        field: "magnitude",
                      },
                      {
                        field: "upi",
                      },
                      {
                        field: "ts",
                      },
                      {
                        field: "pd",
                        headerIndex: 2,
                      },
                      {
                        field: "pw",
                      },
                      {
                        field: "pm",
                      },
                      {
                        field: "pq",
                      },
                      {
                        field: "py",
                      },
                      {
                        field: "pr",
                      },
                      {
                        field: "f_pe",
                      },
                      {
                        field: "f_ps",
                      },
                      {
                        field: "f_pb",
                      },
                      {
                        field: "f_pc",
                      },
                      {
                        field: "f_pd",
                      },
                      {
                        field: "f_sps_CC_g_3",
                      },
                      {
                        field: "f_sps_CC_g_12",
                      },
                      {
                        field: "f_eps_CC_g_3",
                      },
                      {
                        field: "f_eps_CC_g_12",
                      },
                    ],
                  },
                },
                viewerFilter: {
                  table: [
                    [
                      {
                        label: "Rating",
                        field: "rc",
                        display: {
                          widget: "RangeRate",
                        },
                      },
                      {
                        label: "Alert",
                        field: ["lr", "direction", "px", "rc"],
                        display: {
                          widget: "RangeAlert",
                        },
                      },
                      {
                        label: "Notification",
                        field: ["lduration", "lmagnitude", "lupi"],
                        display: {
                          widget: "RangeNotification",
                        },
                      },
                    ],
                  ],
                },
              },
            },
            {
              enabled: true,
              id: "allocation",
            },
            {
              enabled: true,
              id: "trends",
            },
            {
              enabled: true,
              id: "ranking",
              widgets: {
                ranking: {
                  create: {
                    default_template: [],
                    preset_templates: [],
                    functions: {
                      decile: {
                        label: "Decile",
                        widget: {
                          label: "Decile",
                          type: "numberHidden",
                          value: 10,
                        },
                      },
                      outlier: {
                        label: "Outlier",
                        widget: {
                          constraints: {
                            max: 1,
                            min: 0,
                          },
                          label: "Outlier",
                          type: "number",
                          value: 0.1,
                        },
                      },
                      quantile: {
                        label: "Quantile",
                        widget: {
                          constraints: {
                            max: 100,
                            min: 1,
                          },
                          label: "N-iles",
                          type: "number",
                          value: 5,
                        },
                      },
                      quartile: {
                        label: "Quartile",
                        widget: {
                          label: "Quartile",
                          type: "numberHidden",
                          value: 4,
                        },
                      },
                      quintile: {
                        label: "Quintile",
                        widget: {
                          label: "Quintile",
                          type: "numberHidden",
                          value: 5,
                        },
                      },
                      threshold: {
                        label: "Threshold",
                        widget: {
                          constraints: {
                            max: 999999,
                            min: -99999,
                          },
                          label: "Threshold",
                          type: "number",
                          value: 0,
                        },
                      },
                      value: {
                        label: "Value",
                      },
                    },
                    operators: {
                      sortByNumeric: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Largest to Smallest",
                              value: "desc",
                            },
                            {
                              label: "Smallest to Largest",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByOutlier: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Outlier at bottom",
                              value: "desc",
                            },
                            {
                              label: "Outlier at top",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByQuantile: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Top quantile first",
                              value: "asc",
                            },
                            {
                              label: "Bottom quantile first",
                              value: "desc",
                            },
                          ],
                          type: "select",
                          value: "asc",
                        },
                      },
                      sortByPerformance: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Highest to lowest ",
                              value: "desc",
                            },
                            {
                              label: "Lowest to highest",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByRating: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "A to D",
                              value: "desc",
                            },
                            {
                              label: "D to A",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByRetracement: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Largest to Smallest",
                              value: "desc",
                            },
                            {
                              label: "Smallest to Largest",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByThreshold: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Greatest at Top",
                              value: "desc",
                            },
                            {
                              label: "Smallest at Top",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                    },
                    options: [
                      {
                        functions: ["value"],
                        operators: [["sortByRating"]],
                        property: "rc",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: [
                          "value",
                          "quartile",
                          "quintile",
                          "decile",
                          "threshold",
                        ],
                        operators: [
                          ["sortByNumeric"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByThreshold"],
                        ],
                        property: "mc",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "pr",
                        propertyLabelIndex: 2,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "pt",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: [
                          "value",
                          "quartile",
                          "quintile",
                          "decile",
                          "outlier",
                        ],
                        operators: [
                          ["sortByRetracement"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByOutlier"],
                        ],
                        property: "px",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value"],
                        operators: [["sortByNumeric"]],
                        property: "marketcap",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "lr",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "lt",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "f_pe",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "f_ps",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "f_pb",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "f_pc",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "f_pd",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "f_sps_CC_g_3",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "f_sps_CC_g_12",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "f_eps_CC_g_3",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quartile", "quintile", "decile"],
                        operators: [
                          ["sortByPerformance"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                          ["sortByQuantile"],
                        ],
                        property: "f_eps_CC_g_12",
                        propertyLabelIndex: 0,
                      },
                    ],
                  },
                  edit: {
                    default_template: [
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByRating",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "rc",
                      },
                      {
                        function: "quantile",
                        functionParams: {
                          value: 5,
                        },
                        operator: "sortByQuantile",
                        operatorParams: {
                          value: "asc",
                        },
                        property: "mc",
                      },
                      {
                        function: "outlier",
                        functionParams: {
                          value: 0.1,
                        },
                        operator: "sortByOutlier",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "px",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByPerformance",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "pt",
                      },
                    ],
                    preset_templates: [
                      {
                        name: "Value and Earnings Growth",
                        rules: [
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByRating",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "rc",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByPerformance",
                            operatorParams: {
                              value: "asc",
                            },
                            property: "f_pe",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByPerformance",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "f_eps_CC_g_12",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByNumeric",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "mc",
                          },
                        ],
                      },
                      {
                        name: "Value and Sales Growth",
                        rules: [
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByRating",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "rc",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByPerformance",
                            operatorParams: {
                              value: "asc",
                            },
                            property: "f_ps",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByPerformance",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "f_sps_CC_g_12",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByNumeric",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "mc",
                          },
                        ],
                      },
                      {
                        name: "Sales Growth",
                        rules: [
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByRating",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "rc",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByPerformance",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "f_sps_CC_g_12",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByNumeric",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "mc",
                          },
                        ],
                      },
                      {
                        name: "Earnings Growth",
                        rules: [
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByRating",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "rc",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByPerformance",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "f_eps_CC_g_12",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByNumeric",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "mc",
                          },
                        ],
                      },
                      {
                        name: "Value P/S",
                        rules: [
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByRating",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "rc",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByPerformance",
                            operatorParams: {
                              value: "asc",
                            },
                            property: "f_ps",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByNumeric",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "mc",
                          },
                        ],
                      },
                      {
                        name: "Value P/E",
                        rules: [
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByRating",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "rc",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByPerformance",
                            operatorParams: {
                              value: "asc",
                            },
                            property: "f_pe",
                          },
                          {
                            function: "value",
                            functionParams: null,
                            operator: "sortByNumeric",
                            operatorParams: {
                              value: "desc",
                            },
                            property: "mc",
                          },
                        ],
                      },
                    ],
                    functions: {
                      outlier: {
                        label: "Outlier",
                        widget: {
                          constraints: {
                            max: 1,
                            min: 0,
                          },
                          label: "Outlier",
                          type: "number",
                          value: 0.1,
                        },
                      },
                      quantile: {
                        label: "Quantile",
                        widget: {
                          constraints: {
                            max: 100,
                            min: 1,
                          },
                          label: "N-iles",
                          type: "number",
                          value: 5,
                        },
                      },
                      threshold: {
                        label: "Threshold",
                        widget: {
                          constraints: {
                            max: 999999,
                            min: -99999,
                          },
                          label: "Threshold",
                          type: "number",
                          value: 0,
                        },
                      },
                      value: {
                        label: "Value",
                      },
                    },
                    operators: {
                      sortByNumeric: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Largest to Smallest",
                              value: "desc",
                            },
                            {
                              label: "Smallest to Largest",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByOutlier: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Outlier at bottom",
                              value: "desc",
                            },
                            {
                              label: "Outlier at top",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByQuantile: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Top quantile first",
                              value: "asc",
                            },
                            {
                              label: "Bottom quantile first",
                              value: "desc",
                            },
                          ],
                          type: "select",
                          value: "asc",
                        },
                      },
                      sortByPerformance: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Highest to lowest ",
                              value: "desc",
                            },
                            {
                              label: "Lowest to highest",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByRating: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "A to D",
                              value: "desc",
                            },
                            {
                              label: "D to A",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByRetracement: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Largest to Smallest",
                              value: "desc",
                            },
                            {
                              label: "Smallest to Largest",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                      sortByThreshold: {
                        label: "Sort by",
                        widget: {
                          constraints: null,
                          label: "",
                          options: [
                            {
                              label: "Greatest at Top",
                              value: "desc",
                            },
                            {
                              label: "Smallest at Top",
                              value: "asc",
                            },
                          ],
                          type: "select",
                          value: "desc",
                        },
                      },
                    },
                    options: [
                      {
                        functions: ["value"],
                        operators: [["sortByRating"]],
                        property: "rc",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile", "threshold"],
                        operators: [
                          ["sortByNumeric"],
                          ["sortByQuantile"],
                          ["sortByThreshold"],
                        ],
                        property: "mc",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "pr",
                        propertyLabelIndex: 2,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "pt",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile", "outlier"],
                        operators: [
                          ["sortByRetracement"],
                          ["sortByQuantile"],
                          ["sortByOutlier"],
                        ],
                        property: "px",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value"],
                        operators: [["sortByNumeric"]],
                        property: "marketcap",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "lr",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "lt",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "f_pe",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "f_ps",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "f_pb",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "f_pc",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "f_pd",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "f_sps_CC_g_3",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "f_sps_CC_g_12",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "f_eps_CC_g_3",
                        propertyLabelIndex: 0,
                      },
                      {
                        functions: ["value", "quantile"],
                        operators: [["sortByPerformance"], ["sortByQuantile"]],
                        property: "f_eps_CC_g_12",
                        propertyLabelIndex: 0,
                      },
                    ],
                  },
                },
                viewer: {
                  table: {
                    columns: {
                      security: [
                        "weight",
                        "ticker",
                        "name",
                        "vc",
                        "rc",
                        "dr",
                        "mc",
                        "px",
                        "lhl",
                        "pr",
                        "pw",
                        "marketcap",
                        "industry",
                      ],
                    },
                    columns_available: [
                      {
                        field: "ticker",
                      },
                      {
                        field: "isin",
                      },
                      {
                        field: "country",
                      },
                      {
                        field: "currency",
                      },
                      {
                        field: "vc",
                      },
                      {
                        field: "dc",
                      },
                      {
                        field: "name",
                      },
                      {
                        field: "domicile",
                      },
                      {
                        field: "marketcap",
                        headerIndex: 2,
                      },
                      {
                        field: "weight",
                      },
                      {
                        field: "industry",
                      },
                      {
                        field: "sector",
                      },
                      {
                        field: "icb",
                      },
                      {
                        field: "etfclass",
                        panelIndex: 1,
                      },
                      {
                        field: "etfgeo",
                        panelIndex: 1,
                      },
                      {
                        field: "rc",
                      },
                      {
                        field: "mc",
                      },
                      {
                        field: "px",
                      },
                      {
                        field: "dr",
                        formatter: 1,
                      },
                      {
                        field: "dt",
                        formatter: 1,
                      },
                      {
                        field: "rrr",
                      },
                      {
                        field: "lhl",
                      },
                      {
                        field: "duration",
                      },
                      {
                        field: "magnitude",
                      },
                      {
                        field: "upi",
                      },
                      {
                        field: "pd",
                        headerIndex: 2,
                      },
                      {
                        field: "pw",
                      },
                      {
                        field: "pm",
                      },
                      {
                        field: "pq",
                      },
                      {
                        field: "py",
                      },
                      {
                        field: "pr",
                      },
                      {
                        field: "f_pe",
                      },
                      {
                        field: "f_ps",
                      },
                      {
                        field: "f_pb",
                      },
                      {
                        field: "f_pc",
                      },
                      {
                        field: "f_pd",
                      },
                      {
                        field: "f_sps_CC_g_3",
                      },
                      {
                        field: "f_sps_CC_g_12",
                      },
                      {
                        field: "f_eps_CC_g_3",
                      },
                      {
                        field: "f_eps_CC_g_12",
                      },
                    ],
                  },
                },
                viewerFilter: {
                  table: [
                    [
                      {
                        label: "Market",
                        field: "country",
                        display: {
                          widget: "FilterMarket",
                          structure: [
                            "MarketsFinancial",
                            "StockClassification",
                          ],
                        },
                      },
                      {
                        label: "Domicile",
                        field: "domicile",
                        display: {
                          widget: "FilterDomicile",
                          structure: "Markets",
                        },
                      },
                      {
                        label: "Sector",
                        field: "icb",
                        display: {
                          widget: "FilterSector",
                          structure: "ui_sectors",
                        },
                      },
                      {
                        label: "Mkt. Cap.",
                        field: "marketcap",
                        display: {
                          widget: "RangeMktCap",
                        },
                      },
                    ],
                  ],
                },
              },
            },
            {
              enabled: false,
              id: "pointInTime",
              widgets: {
                viewer: {
                  table: {
                    columns: {
                      security: [
                        "ticker",
                        "name",
                        "vc",
                        "rc",
                        "dr",
                        "mc",
                        "industry",
                        "country",
                      ],
                    },
                    columns_available: [
                      {
                        field: "ticker",
                      },
                      {
                        field: "country",
                      },
                      {
                        field: "currency",
                      },
                      {
                        field: "vc",
                      },
                      {
                        field: "dc",
                      },
                      {
                        field: "name",
                      },
                      {
                        field: "domicile",
                      },
                      {
                        field: "weight",
                      },
                      {
                        field: "industry",
                      },
                      {
                        field: "sector",
                      },
                      {
                        field: "icb",
                      },
                      {
                        field: "etfclass",
                        panelIndex: 1,
                      },
                      {
                        field: "etfgeo",
                        panelIndex: 1,
                      },
                      {
                        field: "rc",
                      },
                      {
                        field: "mc",
                      },
                      {
                        field: "fm",
                      },
                      {
                        field: "fq",
                      },
                      {
                        field: "fy",
                      },
                    ],
                  },
                },
                viewerFilter: {
                  table: [
                    [
                      {
                        label: "Rating",
                        field: "rc",
                        display: {
                          widget: "RangeRate",
                        },
                      },
                    ],
                  ],
                },
              },
            },
          ],
          workflow: {
            optimize: {
              enabled: false,
            },
            rank: {
              enabled: true,
            },
          },
        },
        landing_page: "home",
        landing_page_options: ["home"],
        screening: {
          enabled: true,
          label: "menu_screening",
          widgets: {
            ranking: {
              create: {
                default_template: [],
                preset_templates: [],
                functions: {
                  decile: {
                    label: "Decile",
                    widget: {
                      label: "Decile",
                      type: "numberHidden",
                      value: 10,
                    },
                  },
                  outlier: {
                    label: "Outlier",
                    widget: {
                      constraints: {
                        max: 1,
                        min: 0,
                      },
                      label: "Outlier",
                      type: "number",
                      value: 0.1,
                    },
                  },
                  quantile: {
                    label: "Quantile",
                    widget: {
                      constraints: {
                        max: 100,
                        min: 1,
                      },
                      label: "N-iles",
                      type: "number",
                      value: 5,
                    },
                  },
                  quartile: {
                    label: "Quartile",
                    widget: {
                      label: "Quartile",
                      type: "numberHidden",
                      value: 4,
                    },
                  },
                  quintile: {
                    label: "Quintile",
                    widget: {
                      label: "Quintile",
                      type: "numberHidden",
                      value: 5,
                    },
                  },
                  threshold: {
                    label: "Threshold",
                    widget: {
                      constraints: {
                        max: 999999,
                        min: -99999,
                      },
                      label: "Threshold",
                      type: "number",
                      value: 0,
                    },
                  },
                  value: {
                    label: "Value",
                  },
                },
                operators: {
                  sortByNumeric: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Largest to Smallest",
                          value: "desc",
                        },
                        {
                          label: "Smallest to Largest",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByOutlier: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Outlier at bottom",
                          value: "desc",
                        },
                        {
                          label: "Outlier at top",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByQuantile: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Top quantile first",
                          value: "asc",
                        },
                        {
                          label: "Bottom quantile first",
                          value: "desc",
                        },
                      ],
                      type: "select",
                      value: "asc",
                    },
                  },
                  sortByPerformance: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Highest to lowest ",
                          value: "desc",
                        },
                        {
                          label: "Lowest to highest",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByRating: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "A to D",
                          value: "desc",
                        },
                        {
                          label: "D to A",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByRetracement: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Largest to Smallest",
                          value: "desc",
                        },
                        {
                          label: "Smallest to Largest",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByThreshold: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Greatest at Top",
                          value: "desc",
                        },
                        {
                          label: "Smallest at Top",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                },
                options: [
                  {
                    functions: ["value"],
                    operators: [["sortByRating"]],
                    property: "rc",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: [
                      "value",
                      "quartile",
                      "quintile",
                      "decile",
                      "threshold",
                    ],
                    operators: [
                      ["sortByNumeric"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByThreshold"],
                    ],
                    property: "mc",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "pr",
                    propertyLabelIndex: 2,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "pt",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: [
                      "value",
                      "quartile",
                      "quintile",
                      "decile",
                      "outlier",
                    ],
                    operators: [
                      ["sortByRetracement"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByOutlier"],
                    ],
                    property: "px",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value"],
                    operators: [["sortByNumeric"]],
                    property: "marketcap",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "lr",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "lt",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "f_pe",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "f_ps",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "f_pb",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "f_pc",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "f_pd",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "f_sps_CC_g_3",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "f_sps_CC_g_12",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "f_eps_CC_g_3",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["sortByPerformance"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                      ["sortByQuantile"],
                    ],
                    property: "f_eps_CC_g_12",
                    propertyLabelIndex: 0,
                  },
                ],
              },
              edit: {
                default_template: [
                  {
                    function: "value",
                    functionParams: null,
                    operator: "sortByRating",
                    operatorParams: {
                      value: "desc",
                    },
                    property: "rc",
                  },
                  {
                    function: "quantile",
                    functionParams: {
                      value: 5,
                    },
                    operator: "sortByQuantile",
                    operatorParams: {
                      value: "asc",
                    },
                    property: "mc",
                  },
                  {
                    function: "outlier",
                    functionParams: {
                      value: 0.1,
                    },
                    operator: "sortByOutlier",
                    operatorParams: {
                      value: "desc",
                    },
                    property: "px",
                  },
                  {
                    function: "value",
                    functionParams: null,
                    operator: "sortByPerformance",
                    operatorParams: {
                      value: "desc",
                    },
                    property: "pt",
                  },
                ],
                preset_templates: [
                  {
                    name: "Value and Earnings Growth",
                    rules: [
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByRating",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "rc",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByPerformance",
                        operatorParams: {
                          value: "asc",
                        },
                        property: "f_pe",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByPerformance",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "f_eps_CC_g_12",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByNumeric",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "mc",
                      },
                    ],
                  },
                  {
                    name: "Value and Sales Growth",
                    rules: [
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByRating",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "rc",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByPerformance",
                        operatorParams: {
                          value: "asc",
                        },
                        property: "f_ps",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByPerformance",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "f_sps_CC_g_12",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByNumeric",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "mc",
                      },
                    ],
                  },
                  {
                    name: "Sales Growth",
                    rules: [
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByRating",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "rc",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByPerformance",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "f_sps_CC_g_12",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByNumeric",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "mc",
                      },
                    ],
                  },
                  {
                    name: "Earnings Growth",
                    rules: [
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByRating",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "rc",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByPerformance",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "f_eps_CC_g_12",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByNumeric",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "mc",
                      },
                    ],
                  },
                  {
                    name: "Value P/S",
                    rules: [
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByRating",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "rc",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByPerformance",
                        operatorParams: {
                          value: "asc",
                        },
                        property: "f_ps",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByNumeric",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "mc",
                      },
                    ],
                  },
                  {
                    name: "Value P/E",
                    rules: [
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByRating",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "rc",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByPerformance",
                        operatorParams: {
                          value: "asc",
                        },
                        property: "f_pe",
                      },
                      {
                        function: "value",
                        functionParams: null,
                        operator: "sortByNumeric",
                        operatorParams: {
                          value: "desc",
                        },
                        property: "mc",
                      },
                    ],
                  },
                ],
                functions: {
                  outlier: {
                    label: "Outlier",
                    widget: {
                      constraints: {
                        max: 1,
                        min: 0,
                      },
                      label: "Outlier",
                      type: "number",
                      value: 0.1,
                    },
                  },
                  quantile: {
                    label: "Quantile",
                    widget: {
                      constraints: {
                        max: 100,
                        min: 1,
                      },
                      label: "N-iles",
                      type: "number",
                      value: 5,
                    },
                  },
                  threshold: {
                    label: "Threshold",
                    widget: {
                      constraints: {
                        max: 999999,
                        min: -99999,
                      },
                      label: "Threshold",
                      type: "number",
                      value: 0,
                    },
                  },
                  value: {
                    label: "Value",
                  },
                },
                operators: {
                  sortByNumeric: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Largest to Smallest",
                          value: "desc",
                        },
                        {
                          label: "Smallest to Largest",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByOutlier: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Outlier at bottom",
                          value: "desc",
                        },
                        {
                          label: "Outlier at top",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByQuantile: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Top quantile first",
                          value: "asc",
                        },
                        {
                          label: "Bottom quantile first",
                          value: "desc",
                        },
                      ],
                      type: "select",
                      value: "asc",
                    },
                  },
                  sortByPerformance: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Highest to lowest ",
                          value: "desc",
                        },
                        {
                          label: "Lowest to highest",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByRating: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "A to D",
                          value: "desc",
                        },
                        {
                          label: "D to A",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByRetracement: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Largest to Smallest",
                          value: "desc",
                        },
                        {
                          label: "Smallest to Largest",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                  sortByThreshold: {
                    label: "Sort by",
                    widget: {
                      constraints: null,
                      label: "",
                      options: [
                        {
                          label: "Greatest at Top",
                          value: "desc",
                        },
                        {
                          label: "Smallest at Top",
                          value: "asc",
                        },
                      ],
                      type: "select",
                      value: "desc",
                    },
                  },
                },
                options: [
                  {
                    functions: ["value"],
                    operators: [["sortByRating"]],
                    property: "rc",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile", "threshold"],
                    operators: [
                      ["sortByNumeric"],
                      ["sortByQuantile"],
                      ["sortByThreshold"],
                    ],
                    property: "mc",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "pr",
                    propertyLabelIndex: 2,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "pt",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile", "outlier"],
                    operators: [
                      ["sortByRetracement"],
                      ["sortByQuantile"],
                      ["sortByOutlier"],
                    ],
                    property: "px",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value"],
                    operators: [["sortByNumeric"]],
                    property: "marketcap",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "lr",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "lt",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "f_pe",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "f_ps",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "f_pb",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "f_pc",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "f_pd",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "f_sps_CC_g_3",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "f_sps_CC_g_12",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "f_eps_CC_g_3",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [["sortByPerformance"], ["sortByQuantile"]],
                    property: "f_eps_CC_g_12",
                    propertyLabelIndex: 0,
                  },
                ],
              },
            },
            viewer: {
              table: {
                columns: {
                  security: [
                    "ticker",
                    "name",
                    "vc",
                    "rc",
                    "dr",
                    "lhl",
                    "pr",
                    "pw",
                  ],
                  Stock: [
                    "ticker",
                    "name",
                    "vc",
                    "rc",
                    "dr",
                    "mc",
                    "px",
                    "lhl",
                    "pr",
                    "pw",
                    "marketcap",
                    "industry",
                  ],
                  Index: [
                    "ticker",
                    "name",
                    "vc",
                    "rc",
                    "dr",
                    "mc",
                    "px",
                    "lhl",
                    "pr",
                    "pw",
                  ],
                  ETF: [
                    "ticker",
                    "name",
                    "vc",
                    "rc",
                    "dr",
                    "mc",
                    "px",
                    "lhl",
                    "pr",
                    "pw",
                    "marketcap",
                  ],
                  Sector: [
                    "ticker",
                    "name",
                    "vc",
                    "rc",
                    "dr",
                    "mc",
                    "px",
                    "lhl",
                    "pr",
                    "pw",
                    "marketcap",
                    "industry",
                  ],
                  Commodity: [
                    "ticker",
                    "name",
                    "vc",
                    "rc",
                    "dr",
                    "lhl",
                    "pr",
                    "pw",
                  ],
                  Currency: [
                    "ticker",
                    "name",
                    "vc",
                    "rc",
                    "dr",
                    "lhl",
                    "pr",
                    "pd",
                    "pw",
                  ],
                },
                columns_available: [
                  {
                    field: "ticker",
                  },
                  {
                    field: "isin",
                  },
                  {
                    field: "country",
                  },
                  {
                    field: "currency",
                  },
                  {
                    field: "vc",
                  },
                  {
                    field: "dc",
                  },
                  {
                    field: "name",
                  },
                  {
                    field: "domicile",
                  },
                  {
                    field: "marketcap",
                    headerIndex: 2,
                  },
                  {
                    field: "industry",
                  },
                  {
                    field: "sector",
                  },
                  {
                    field: "icb",
                  },
                  {
                    field: "etfclass",
                    panelIndex: 1,
                  },
                  {
                    field: "etfgeo",
                    panelIndex: 1,
                  },
                  {
                    field: "rc",
                  },
                  {
                    field: "mc",
                  },
                  {
                    field: "px",
                  },
                  {
                    field: "dr",
                    formatter: 1,
                  },
                  {
                    field: "dt",
                    formatter: 1,
                  },
                  {
                    field: "rrr",
                  },
                  {
                    field: "lhl",
                  },
                  {
                    field: "duration",
                  },
                  {
                    field: "magnitude",
                  },
                  {
                    field: "upi",
                  },
                  {
                    field: "ts",
                  },
                  {
                    field: "pd",
                    headerIndex: 2,
                  },
                  {
                    field: "pw",
                  },
                  {
                    field: "pm",
                  },
                  {
                    field: "pq",
                  },
                  {
                    field: "py",
                  },
                  {
                    field: "pr",
                  },
                  {
                    field: "f_pe",
                  },
                  {
                    field: "f_ps",
                  },
                  {
                    field: "f_pb",
                  },
                  {
                    field: "f_pc",
                  },
                  {
                    field: "f_pd",
                  },
                  {
                    field: "f_sps_CC_g_3",
                  },
                  {
                    field: "f_sps_CC_g_12",
                  },
                  {
                    field: "f_eps_CC_g_3",
                  },
                  {
                    field: "f_eps_CC_g_12",
                  },
                ],
              },
            },
            viewerFilter: {
              table: [
                {
                  Stock: [
                    {
                      label: "Universe",
                      field: null,
                      display: {
                        widget: "RelationUniverse",
                      },
                    },
                    {
                      label: "Market",
                      field: "country",
                      display: {
                        widget: "FilterMarket",
                        structure: ["MarketsFinancial", "StockClassification"],
                      },
                    },
                    {
                      label: "Domicile",
                      field: "domicile",
                      display: {
                        widget: "FilterDomicile",
                        structure: "Markets",
                      },
                    },
                    {
                      label: "Sector",
                      field: "icb",
                      display: {
                        widget: "FilterSector",
                        structure: "ui_sectors",
                      },
                    },
                    {
                      label: "Mkt. Cap.",
                      field: "marketcap",
                      display: {
                        widget: "RangeMktCap",
                      },
                    },
                  ],
                  Commodity: [
                    {
                      label: "Universe",
                      field: null,
                      display: {
                        widget: "RelationUniverse",
                      },
                    },
                  ],
                  Currency: [
                    {
                      label: "Universe",
                      field: null,
                      display: {
                        widget: "RelationUniverse",
                      },
                    },
                  ],
                  Index: [
                    {
                      label: "Universe",
                      field: null,
                      display: {
                        widget: "RelationUniverse",
                      },
                    },
                    {
                      label: "Market",
                      field: "country",
                      display: {
                        widget: "FilterMarket",
                        widgetOptions: {
                          hasDomesticForeign: false,
                        },
                        structure: ["MarketsFinancial"],
                      },
                    },
                    {
                      label: "Family",
                      field: "subtype",
                      display: {
                        widget: "FilterFamily",
                        structure: "Indicesclassification",
                      },
                    },
                  ],
                  ETF: [
                    {
                      label: "Universe",
                      field: null,
                      display: {
                        widget: "RelationUniverse",
                      },
                    },
                    {
                      label: "Asset Class",
                      field: "etfclass",
                      display: {
                        widget: "FilterETFType",
                        structure: "ETFclassification",
                      },
                    },
                    {
                      label: "Investment Region",
                      field: "etfgeo",
                      display: {
                        widget: "FilterRegion",
                        structure: "MarketsFinancial",
                      },
                    },
                    {
                      label: "Issuer",
                      field: "subtype",
                      display: {
                        widget: "FilterDomicile",
                        widgetOptions: {
                          isSortEnabled: false,
                        },
                        structure: "ui_etf_provider",
                      },
                    },
                    {
                      label: "Market",
                      field: "country",
                      display: {
                        widget: "FilterMarket",
                        structure: ["MarketsFinancial"],
                      },
                    },
                    {
                      label: "Domicile",
                      field: "domicile",
                      display: {
                        widget: "FilterDomicile",
                        structure: "Markets",
                      },
                    },
                    {
                      label: "Mkt. Cap.",
                      field: "marketcap",
                      display: {
                        widget: "RangeMktCap",
                      },
                    },
                  ],
                  Sector: [
                    {
                      label: "Universe",
                      field: null,
                      display: {
                        widget: "RelationUniverse",
                      },
                    },
                    {
                      label: "Market",
                      field: "country",
                      display: {
                        widget: "FilterMarket",
                        widgetOptions: {
                          hasDomesticForeign: false,
                        },
                        structure: ["MarketsFinancial"],
                      },
                    },
                    {
                      label: "Sector",
                      field: "icb",
                      display: {
                        widget: "FilterSector",
                        structure: "ui_sectors",
                      },
                    },
                  ],
                },
                [
                  {
                    label: "Rating",
                    field: "rc",
                    display: {
                      widget: "RangeRate",
                    },
                  },
                  {
                    label: "Alert",
                    field: ["lr", "direction", "px", "rc"],
                    display: {
                      widget: "RangeAlert",
                    },
                  },
                  {
                    label: "New high/low",
                    field: "lhl",
                    display: {
                      widget: "RangeNewHL",
                    },
                  },
                  {
                    label: "Duration",
                    field: "duration",
                    display: {
                      widget: "RangeDuration",
                    },
                  },
                  {
                    label: "Magnitude",
                    field: "magnitude",
                    display: {
                      widget: "RangeMagnitude",
                    },
                  },
                  {
                    label: "UPI",
                    field: "upi",
                    display: {
                      widget: "RangeUPI",
                    },
                  },
                  {
                    label: "Notification",
                    field: ["lduration", "lmagnitude", "lupi"],
                    display: {
                      widget: "RangeNotification",
                    },
                  },
                  {
                    label: "Smart momentum",
                    field: "mc",
                    display: {
                      widget: "RangeMomentum",
                    },
                  },
                ],
              ],
            },
          },
        },
        strategy_builder: {
          enabled: true,
          label: "menu_builder_strategy",
          form_advanced: {
            enabled: true,
            selection_universe: {
              create: {
                default_template: [],
                functions: {
                  decile: {
                    label: "Decile",
                    widget: {
                      label: "Decile",
                      type: "numberHidden",
                      value: 10,
                    },
                  },
                  quantile: {
                    label: "Quantile",
                    widget: {
                      constraints: {
                        max: 100,
                        min: 1,
                      },
                      label: "N-iles",
                      type: "number",
                      value: 5,
                    },
                  },
                  quartile: {
                    label: "Quartile",
                    widget: {
                      label: "Quartile",
                      type: "numberHidden",
                      value: 4,
                    },
                  },
                  quintile: {
                    label: "Quintile",
                    widget: {
                      label: "Quintile",
                      type: "numberHidden",
                      value: 5,
                    },
                  },
                  value: {
                    label: "Value",
                  },
                },
                operators: {
                  bottom: {
                    label: "Bottom",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "number",
                      value: 10,
                    },
                  },
                  equalTo: {
                    label: "Is equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "number",
                      value: 0,
                    },
                  },
                  equalToPercentage: {
                    label: "Is equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberPercentage",
                      value: 0,
                    },
                  },
                  equalToRate: {
                    label: "Is equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "rate",
                      value: {
                        A: true,
                        B: true,
                        C: false,
                        D: false,
                      },
                    },
                  },
                  greaterThan: {
                    label: "Is greater than",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberOperator",
                      value: [
                        {
                          gt: 0,
                        },
                      ],
                    },
                  },
                  greaterThanPercentage: {
                    label: "Is greater than",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberOperatorPercentage",
                      value: [
                        {
                          gt: 0,
                        },
                      ],
                    },
                  },
                  greaterThanOrEqualTo: {
                    label: "Is greater than or equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberOperator",
                      value: [
                        {
                          ge: 0,
                        },
                      ],
                    },
                  },
                  greaterThanOrEqualToPercentage: {
                    label: "Is greater than or equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberOperatorPercentage",
                      value: [
                        {
                          ge: 0,
                        },
                      ],
                    },
                  },
                  lessThan: {
                    label: "Is less than",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberOperator",
                      value: [
                        {
                          lt: 0,
                        },
                      ],
                    },
                  },
                  lessThanPercentage: {
                    label: "Is less than",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberOperatorPercentage",
                      value: [
                        {
                          lt: 0,
                        },
                      ],
                    },
                  },
                  lessThanOrEqualTo: {
                    label: "Is less than or equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberOperator",
                      value: [
                        {
                          le: 0,
                        },
                      ],
                    },
                  },
                  lessThanOrEqualToPercentage: {
                    label: "Is less than or equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberOperatorPercentage",
                      value: [
                        {
                          le: 0,
                        },
                      ],
                    },
                  },
                  range: {
                    label: "Between",
                    widget: {
                      constraints: null,
                      label: "Between",
                      type: "numberSingleInterval",
                      value: [
                        {
                          ge: 0,
                          le: 0,
                        },
                      ],
                    },
                  },
                  rangeDecile: {
                    label: "Decile",
                    widget: {
                      constraints: null,
                      label: null,
                      options: [
                        {
                          label: "1st decile",
                          value: "1",
                        },
                        {
                          label: "2nd decile",
                          value: "2",
                        },
                        {
                          label: "3rd decile",
                          value: "3",
                        },
                        {
                          label: "4th decile",
                          value: "4",
                        },
                        {
                          label: "5th decile",
                          value: "5",
                        },
                        {
                          label: "6th decile",
                          value: "6",
                        },
                        {
                          label: "7th decile",
                          value: "7",
                        },
                        {
                          label: "8th decile",
                          value: "8",
                        },
                        {
                          label: "9th decile",
                          value: "9",
                        },
                        {
                          label: "10th decile",
                          value: "10",
                        },
                      ],
                      type: "rangeQuantile",
                      value: [
                        {
                          ge: 1,
                          le: 1,
                        },
                      ],
                    },
                  },
                  rangePercentage: {
                    label: "Between",
                    widget: {
                      constraints: null,
                      label: "Between",
                      type: "numberSingleIntervalPercentage",
                      value: [
                        {
                          ge: 0,
                          le: 0,
                        },
                      ],
                    },
                  },
                  rangeQuartile: {
                    label: "Quartile",
                    widget: {
                      constraints: null,
                      label: null,
                      options: [
                        {
                          label: "1st quartile",
                          value: "1",
                        },
                        {
                          label: "2nd quartile",
                          value: "2",
                        },
                        {
                          label: "3rd quartile",
                          value: "3",
                        },
                        {
                          label: "4th quartile",
                          value: "4",
                        },
                      ],
                      type: "rangeQuantile",
                      value: [
                        {
                          ge: 1,
                          le: 1,
                        },
                      ],
                    },
                  },
                  rangeQuintile: {
                    label: "Quintile",
                    widget: {
                      constraints: null,
                      label: null,
                      options: [
                        {
                          label: "1st quintile",
                          value: "1",
                        },
                        {
                          label: "2nd quintile",
                          value: "2",
                        },
                        {
                          label: "3rd quintile",
                          value: "3",
                        },
                        {
                          label: "4th quintile",
                          value: "4",
                        },
                        {
                          label: "5th quintile",
                          value: "5",
                        },
                      ],
                      type: "rangeQuantile",
                      value: [
                        {
                          ge: 1,
                          le: 1,
                        },
                      ],
                    },
                  },
                  rangeMarketCap: {
                    label: "Market Cap. in range",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "rangeMarketCap",
                      value: [
                        {
                          ge: 0,
                          le: 0,
                        },
                      ],
                    },
                  },
                  rangeVolatility: {
                    label: "Volatility in range",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "rangeVolatility",
                      value: [
                        {
                          ge: 0,
                          le: 0,
                        },
                      ],
                    },
                  },
                  top: {
                    label: "Top",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "number",
                      value: 10,
                    },
                  },
                },
                options: [
                  {
                    functions: ["value"],
                    operators: [["equalToRate"]],
                    property: "rc",
                    propertyLabelIndex: 0,
                    value: {
                      function: "value",
                      functionParams: null,
                      operator: "equalToRate",
                      operatorParams: {
                        A: true,
                        B: true,
                        C: false,
                        D: false,
                      },
                    },
                  },
                  {
                    functions: ["value"],
                    operators: [
                      [
                        "rangeMarketCap",
                        "greaterThan",
                        "lessThan",
                        "top",
                        "bottom",
                      ],
                    ],
                    property: "marketcap",
                    propertyLabelIndex: 3,
                    transformation: {
                      value: {
                        equalTo: 1000000,
                        greaterThan: 1000000,
                        greaterThanOrEqualTo: 1000000,
                        lessThan: 1000000,
                        lessThanOrEqualTo: 1000000,
                        rangeMarketCap: 1000000,
                      },
                    },
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "mc",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      [
                        "rangeVolatility",
                        "lessThanPercentage",
                        "greaterThanPercentage",
                        "top",
                        "bottom",
                      ],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "sd",
                    propertyLabelIndex: 0,
                    transformation: {
                      value: {
                        equalToPercentage: 100,
                        greaterThanPercentage: 100,
                        greaterThanOrEqualToPercentage: 100,
                        lessThanPercentage: 100,
                        lessThanOrEqualToPercentage: 100,
                        rangeVolatility: 100,
                      },
                    },
                  },
                  {
                    functions: ["value"],
                    operators: [["greaterThan"]],
                    property: "tradedvalue",
                    propertyLabelIndex: 0,
                    transformation: {
                      value: {
                        greaterThan: 1000,
                      },
                    },
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      [
                        "greaterThanPercentage",
                        "lessThanPercentage",
                        "rangePercentage",
                        "top",
                        "bottom",
                      ],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "px",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      [
                        "greaterThanPercentage",
                        "lessThanPercentage",
                        "rangePercentage",
                        "top",
                        "bottom",
                      ],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "pr",
                    propertyLabelIndex: 2,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      [
                        "greaterThanPercentage",
                        "lessThanPercentage",
                        "rangePercentage",
                        "top",
                        "bottom",
                      ],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "pt",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      [
                        "greaterThanPercentage",
                        "lessThanPercentage",
                        "rangePercentage",
                        "top",
                        "bottom",
                      ],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "pw",
                    propertyLabelIndex: 1,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      [
                        "greaterThanPercentage",
                        "lessThanPercentage",
                        "rangePercentage",
                        "top",
                        "bottom",
                      ],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "pm",
                    propertyLabelIndex: 1,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      [
                        "greaterThanPercentage",
                        "lessThanPercentage",
                        "rangePercentage",
                        "top",
                        "bottom",
                      ],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "pq",
                    propertyLabelIndex: 1,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      [
                        "greaterThanPercentage",
                        "lessThanPercentage",
                        "rangePercentage",
                        "top",
                        "bottom",
                      ],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "py",
                    propertyLabelIndex: 1,
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "ts",
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "f_pe",
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "f_ps",
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "f_pb",
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "f_pc",
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "f_pd",
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "f_sps_CC_g_3",
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "f_sps_CC_g_12",
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "f_eps_CC_g_3",
                  },
                  {
                    functions: ["value", "quartile", "quintile", "decile"],
                    operators: [
                      ["greaterThan", "lessThan", "range", "top", "bottom"],
                      ["rangeQuartile"],
                      ["rangeQuintile"],
                      ["rangeDecile"],
                    ],
                    property: "f_eps_CC_g_12",
                  },
                ],
              },
              edit: {
                default_template: [],
                functions: {
                  quantile: {
                    label: "Quantile",
                    widget: {
                      constraints: {
                        max: 100,
                        min: 1,
                      },
                      label: "N-iles",
                      type: "number",
                      value: 5,
                    },
                  },
                  value: {
                    label: "Value",
                  },
                },
                operators: {
                  bottom: {
                    label: "Bottom",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "number",
                      value: 10,
                    },
                  },
                  equalTo: {
                    label: "Is equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "number",
                      value: 0,
                    },
                  },
                  equalToPercentage: {
                    label: "Is equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberPercentage",
                      value: 0,
                    },
                  },
                  equalToRate: {
                    label: "Is equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "rate",
                      value: {
                        A: true,
                        B: true,
                        C: false,
                        D: false,
                      },
                    },
                  },
                  greaterThan: {
                    label: "Is greater than",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberInterval",
                      value: [
                        {
                          gt: 0,
                        },
                      ],
                    },
                  },
                  greaterThanPercentage: {
                    label: "Is greater than",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberIntervalPercentage",
                      value: [
                        {
                          gt: 0,
                        },
                      ],
                    },
                  },
                  greaterThanOrEqualTo: {
                    label: "Is greater than or equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberInterval",
                      value: [
                        {
                          ge: 0,
                        },
                      ],
                    },
                  },
                  greaterThanOrEqualToPercentage: {
                    label: "Is greater than or equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberIntervalPercentage",
                      value: [
                        {
                          ge: 0,
                        },
                      ],
                    },
                  },
                  lessThan: {
                    label: "Is less than",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberInterval",
                      value: [
                        {
                          lt: 0,
                        },
                      ],
                    },
                  },
                  lessThanPercentage: {
                    label: "Is less than",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberIntervalPercentage",
                      value: [
                        {
                          lt: 0,
                        },
                      ],
                    },
                  },
                  lessThanOrEqualTo: {
                    label: "Is less than or equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberInterval",
                      value: [
                        {
                          le: 0,
                        },
                      ],
                    },
                  },
                  lessThanOrEqualToPercentage: {
                    label: "Is less than or equal to",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "numberIntervalPercentage",
                      value: [
                        {
                          le: 0,
                        },
                      ],
                    },
                  },
                  range: {
                    label: "Between",
                    widget: {
                      constraints: null,
                      label: "Between",
                      type: "numberInterval",
                      value: [
                        {
                          ge: 0,
                          le: 0,
                        },
                      ],
                    },
                  },
                  rangePercentage: {
                    label: "Between",
                    widget: {
                      constraints: null,
                      label: "Between",
                      type: "numberIntervalPercentage",
                      value: [
                        {
                          ge: 0,
                          le: 0,
                        },
                      ],
                    },
                  },
                  rangeMarketCap: {
                    label: "Market Cap. in range",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "rangeMarketCap",
                      value: [
                        {
                          ge: 0,
                          le: 0,
                        },
                      ],
                    },
                  },
                  rangeVolatility: {
                    label: "Volatility in range",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "rangeVolatility",
                      value: [
                        {
                          ge: 0,
                          le: 0,
                        },
                      ],
                    },
                  },
                  top: {
                    label: "Top",
                    widget: {
                      constraints: null,
                      label: "",
                      type: "number",
                      value: 10,
                    },
                  },
                },
                options: [
                  {
                    functions: ["value"],
                    operators: [["equalToRate"]],
                    property: "rc",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value"],
                    operators: [
                      [
                        "bottom",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "rangeMarketCap",
                        "top",
                      ],
                    ],
                    property: "marketcap",
                    propertyLabelIndex: 3,
                    transformation: {
                      value: {
                        equalTo: 1000000,
                        greaterThan: 1000000,
                        greaterThanOrEqualTo: 1000000,
                        lessThan: 1000000,
                        lessThanOrEqualTo: 1000000,
                        rangeMarketCap: 1000000,
                      },
                    },
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "mc",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalToPercentage",
                        "greaterThanPercentage",
                        "greaterThanOrEqualToPercentage",
                        "lessThanPercentage",
                        "lessThanOrEqualToPercentage",
                        "rangeVolatility",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "sd",
                    propertyLabelIndex: 0,
                    transformation: {
                      value: {
                        equalToPercentage: 100,
                        greaterThanPercentage: 100,
                        greaterThanOrEqualToPercentage: 100,
                        lessThanPercentage: 100,
                        lessThanOrEqualToPercentage: 100,
                        rangeVolatility: 100,
                      },
                    },
                  },
                  {
                    functions: ["value"],
                    operators: [["greaterThan"]],
                    property: "tradedvalue",
                    propertyLabelIndex: 0,
                    transformation: {
                      value: {
                        greaterThan: 1000,
                      },
                    },
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalToPercentage",
                        "greaterThanPercentage",
                        "greaterThanOrEqualToPercentage",
                        "lessThanPercentage",
                        "lessThanOrEqualToPercentage",
                        "rangePercentage",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "px",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalToPercentage",
                        "greaterThanPercentage",
                        "greaterThanOrEqualToPercentage",
                        "lessThanPercentage",
                        "lessThanOrEqualToPercentage",
                        "rangePercentage",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "pr",
                    propertyLabelIndex: 2,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalToPercentage",
                        "greaterThanPercentage",
                        "greaterThanOrEqualToPercentage",
                        "lessThanPercentage",
                        "lessThanOrEqualToPercentage",
                        "rangePercentage",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "pt",
                    propertyLabelIndex: 0,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalToPercentage",
                        "greaterThanPercentage",
                        "greaterThanOrEqualToPercentage",
                        "lessThanPercentage",
                        "lessThanOrEqualToPercentage",
                        "rangePercentage",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "pw",
                    propertyLabelIndex: 1,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalToPercentage",
                        "greaterThanPercentage",
                        "greaterThanOrEqualToPercentage",
                        "lessThanPercentage",
                        "lessThanOrEqualToPercentage",
                        "rangePercentage",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "pm",
                    propertyLabelIndex: 1,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalToPercentage",
                        "greaterThanPercentage",
                        "greaterThanOrEqualToPercentage",
                        "lessThanPercentage",
                        "lessThanOrEqualToPercentage",
                        "rangePercentage",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "pq",
                    propertyLabelIndex: 1,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalToPercentage",
                        "greaterThanPercentage",
                        "greaterThanOrEqualToPercentage",
                        "lessThanPercentage",
                        "lessThanOrEqualToPercentage",
                        "rangePercentage",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "py",
                    propertyLabelIndex: 1,
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "ts",
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "f_pe",
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "f_ps",
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "f_pb",
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "f_pc",
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "f_pd",
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "f_sps_CC_g_3",
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "f_sps_CC_g_12",
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "f_eps_CC_g_3",
                  },
                  {
                    functions: ["value", "quantile"],
                    operators: [
                      [
                        "bottom",
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                        "top",
                      ],
                      [
                        "equalTo",
                        "greaterThan",
                        "greaterThanOrEqualTo",
                        "lessThan",
                        "lessThanOrEqualTo",
                        "range",
                      ],
                    ],
                    property: "f_eps_CC_g_12",
                  },
                ],
              },
            },
            smartBeta: {
              functions: {
                weight: {
                  label: "Weight",
                  widget: {
                    constraints: {
                      max: 100,
                      min: 1,
                    },
                    label: "Weight",
                    type: "numberPercentage",
                    value: 1,
                  },
                },
              },
              operators: {
                premium: {
                  label: "Premium",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Top",
                        value: "top",
                      },
                      {
                        label: "Middle",
                        value: "middle",
                      },
                      {
                        label: "Bottom",
                        value: "bottom",
                      },
                    ],
                    type: "select",
                    value: "top",
                  },
                },
                rate: {
                  label: "Rating",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rate",
                    value: {
                      A: 1,
                      B: 1,
                      C: 0,
                      D: 0,
                    },
                  },
                },
              },
              options: [
                {
                  functions: ["weight"],
                  operators: [["rate"]],
                  property: "rc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "tradedvalue",
                  propertyLabelIndex: 1,
                  transformation: {
                    value: {
                      weight: 1000,
                    },
                  },
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "py",
                  propertyLabelIndex: 1,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "pr",
                  propertyLabelIndex: 2,
                  transformation: {
                    value: {
                      weight: 100,
                    },
                  },
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "pt",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "px",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "marketcap",
                  propertyLabelIndex: 2,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "mc",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "sd",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "ts",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "f_pe",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "f_ps",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "f_pb",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "f_pc",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "f_pd",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "f_sps_CC_g_3",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "f_sps_CC_g_12",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "f_eps_CC_g_3",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
                {
                  functions: ["weight"],
                  operators: [["premium"]],
                  property: "f_eps_CC_g_12",
                  propertyLabelIndex: 0,
                  transformation: null,
                },
              ],
            },
          },
          form_alpha: {
            enabled: false,
          },
          form_compare: {
            enabled: true,
          },
          form_long_short: {
            enabled: true,
          },
          form_macro_rotation: {
            enabled: false,
          },
          form_smart_beta: {
            enabled: false,
          },
          "//_1": "Hedging configuration is shared among all types of form",
          "//_2": "and used for encoding/decoding operations",
          hedging: {
            create: {
              default_template: [],
              functions: {
                decile: {
                  label: "Decile",
                  widget: {
                    label: "Decile",
                    type: "numberHidden",
                    value: 10,
                  },
                },
                quartile: {
                  label: "Quartile",
                  widget: {
                    label: "Quartile",
                    type: "numberHidden",
                    value: 4,
                  },
                },
                quintile: {
                  label: "Quintile",
                  widget: {
                    label: "Quintile",
                    type: "numberHidden",
                    value: 5,
                  },
                },
                value: {
                  label: "Value",
                },
              },
              operators: {
                bottom: {
                  label: "Bottom",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 10,
                  },
                },
                equalTo: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 0,
                  },
                },
                equalToPercentage: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberPercentage",
                    value: 0,
                  },
                },
                equalToRate: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rate",
                    value: {
                      A: true,
                      B: true,
                      C: false,
                      D: false,
                    },
                  },
                },
                greaterThan: {
                  label: "Is greater than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperator",
                    value: [
                      {
                        gt: 0,
                      },
                    ],
                  },
                },
                greaterThanPercentage: {
                  label: "Is greater than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperatorPercentage",
                    value: [
                      {
                        gt: 0,
                      },
                    ],
                  },
                },
                greaterThanOrEqualTo: {
                  label: "Is greater than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperator",
                    value: [
                      {
                        ge: 0,
                      },
                    ],
                  },
                },
                greaterThanOrEqualToPercentage: {
                  label: "Is greater than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperatorPercentage",
                    value: [
                      {
                        ge: 0,
                      },
                    ],
                  },
                },
                lessThan: {
                  label: "Is less than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperator",
                    value: [
                      {
                        lt: 0,
                      },
                    ],
                  },
                },
                lessThanPercentage: {
                  label: "Is less than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperatorPercentage",
                    value: [
                      {
                        lt: 0,
                      },
                    ],
                  },
                },
                lessThanOrEqualTo: {
                  label: "Is less than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperator",
                    value: [
                      {
                        le: 0,
                      },
                    ],
                  },
                },
                lessThanOrEqualToPercentage: {
                  label: "Is less than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperatorPercentage",
                    value: [
                      {
                        le: 0,
                      },
                    ],
                  },
                },
                range: {
                  label: "Between",
                  widget: {
                    constraints: null,
                    label: "Between",
                    type: "numberSingleInterval",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeDecile: {
                  label: "Decile",
                  widget: {
                    constraints: null,
                    label: null,
                    options: [
                      {
                        label: "1st decile",
                        value: "1",
                      },
                      {
                        label: "2nd decile",
                        value: "2",
                      },
                      {
                        label: "3rd decile",
                        value: "3",
                      },
                      {
                        label: "4th decile",
                        value: "4",
                      },
                      {
                        label: "5th decile",
                        value: "5",
                      },
                      {
                        label: "6th decile",
                        value: "6",
                      },
                      {
                        label: "7th decile",
                        value: "7",
                      },
                      {
                        label: "8th decile",
                        value: "8",
                      },
                      {
                        label: "9th decile",
                        value: "9",
                      },
                      {
                        label: "10th decile",
                        value: "10",
                      },
                    ],
                    type: "rangeQuantile",
                    value: [
                      {
                        ge: 1,
                        le: 1,
                      },
                    ],
                  },
                },
                rangePercentage: {
                  label: "Between",
                  widget: {
                    constraints: null,
                    label: "Between",
                    type: "numberSingleIntervalPercentage",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeQuartile: {
                  label: "Quartile",
                  widget: {
                    constraints: null,
                    label: null,
                    options: [
                      {
                        label: "1st quartile",
                        value: "1",
                      },
                      {
                        label: "2nd quartile",
                        value: "2",
                      },
                      {
                        label: "3rd quartile",
                        value: "3",
                      },
                      {
                        label: "4th quartile",
                        value: "4",
                      },
                    ],
                    type: "rangeQuantile",
                    value: [
                      {
                        ge: 1,
                        le: 1,
                      },
                    ],
                  },
                },
                rangeQuintile: {
                  label: "Quintile",
                  widget: {
                    constraints: null,
                    label: null,
                    options: [
                      {
                        label: "1st quintile",
                        value: "1",
                      },
                      {
                        label: "2nd quintile",
                        value: "2",
                      },
                      {
                        label: "3rd quintile",
                        value: "3",
                      },
                      {
                        label: "4th quintile",
                        value: "4",
                      },
                      {
                        label: "5th quintile",
                        value: "5",
                      },
                    ],
                    type: "rangeQuantile",
                    value: [
                      {
                        ge: 1,
                        le: 1,
                      },
                    ],
                  },
                },
                rangeMarketCap: {
                  label: "Market Cap. in range",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rangeMarketCap",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeVolatility: {
                  label: "Volatility in range",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rangeVolatility",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                top: {
                  label: "Top",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 10,
                  },
                },
              },
              options: [
                {
                  functions: ["value"],
                  operators: [["equalToRate"]],
                  property: "rc",
                  propertyLabelIndex: 0,
                  value: {
                    function: "value",
                    functionParams: null,
                    operator: "equalToRate",
                    operatorParams: {
                      A: true,
                      B: true,
                      C: false,
                      D: false,
                    },
                  },
                },
                {
                  functions: ["value"],
                  operators: [
                    [
                      "rangeMarketCap",
                      "greaterThan",
                      "lessThan",
                      "top",
                      "bottom",
                    ],
                  ],
                  property: "marketcap",
                  propertyLabelIndex: 3,
                  transformation: {
                    value: {
                      equalTo: 1000000,
                      greaterThan: 1000000,
                      greaterThanOrEqualTo: 1000000,
                      lessThan: 1000000,
                      lessThanOrEqualTo: 1000000,
                      rangeMarketCap: 1000000,
                    },
                  },
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "mc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "rangeVolatility",
                      "lessThanPercentage",
                      "greaterThanPercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "sd",
                  propertyLabelIndex: 0,
                  transformation: {
                    value: {
                      equalToPercentage: 100,
                      greaterThanPercentage: 100,
                      greaterThanOrEqualToPercentage: 100,
                      lessThanPercentage: 100,
                      lessThanOrEqualToPercentage: 100,
                      rangeVolatility: 100,
                    },
                  },
                },
                {
                  functions: ["value"],
                  operators: [["greaterThan"]],
                  property: "tradedvalue",
                  propertyLabelIndex: 0,
                  transformation: {
                    value: {
                      greaterThan: 1000,
                    },
                  },
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "greaterThanPercentage",
                      "lessThanPercentage",
                      "rangePercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "px",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "greaterThanPercentage",
                      "lessThanPercentage",
                      "rangePercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "pr",
                  propertyLabelIndex: 2,
                  transformation: {
                    value: {
                      equalToPercentage: 100,
                      greaterThanPercentage: 100,
                      greaterThanOrEqualToPercentage: 100,
                      lessThanPercentage: 100,
                      lessThanOrEqualToPercentage: 100,
                      rangePercentage: 100,
                    },
                  },
                },
              ],
            },
            edit: {
              default_template: [],
              functions: {
                quantile: {
                  label: "Quantile",
                  widget: {
                    constraints: {
                      max: 100,
                      min: 1,
                    },
                    label: "N-iles",
                    type: "number",
                    value: 5,
                  },
                },
                value: {
                  label: "Value",
                },
              },
              operators: {
                bottom: {
                  label: "Bottom",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 10,
                  },
                },
                equalTo: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 0,
                  },
                },
                equalToPercentage: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberPercentage",
                    value: 0,
                  },
                },
                equalToRate: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rate",
                    value: {
                      A: true,
                      B: true,
                      C: false,
                      D: false,
                    },
                  },
                },
                greaterThan: {
                  label: "Is greater than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberInterval",
                    value: [
                      {
                        gt: 0,
                      },
                    ],
                  },
                },
                greaterThanPercentage: {
                  label: "Is greater than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        gt: 0,
                      },
                    ],
                  },
                },
                greaterThanOrEqualTo: {
                  label: "Is greater than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberInterval",
                    value: [
                      {
                        ge: 0,
                      },
                    ],
                  },
                },
                greaterThanOrEqualToPercentage: {
                  label: "Is greater than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        ge: 0,
                      },
                    ],
                  },
                },
                lessThan: {
                  label: "Is less than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberInterval",
                    value: [
                      {
                        lt: 0,
                      },
                    ],
                  },
                },
                lessThanPercentage: {
                  label: "Is less than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        lt: 0,
                      },
                    ],
                  },
                },
                lessThanOrEqualTo: {
                  label: "Is less than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberInterval",
                    value: [
                      {
                        le: 0,
                      },
                    ],
                  },
                },
                lessThanOrEqualToPercentage: {
                  label: "Is less than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        le: 0,
                      },
                    ],
                  },
                },
                range: {
                  label: "Between",
                  widget: {
                    constraints: null,
                    label: "Between",
                    type: "numberInterval",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangePercentage: {
                  label: "Between",
                  widget: {
                    constraints: null,
                    label: "Between",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeMarketCap: {
                  label: "Market Cap. in range",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rangeMarketCap",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeVolatility: {
                  label: "Volatility in range",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rangeVolatility",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                top: {
                  label: "Top",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 10,
                  },
                },
              },
              options: [
                {
                  functions: ["value"],
                  operators: [["equalToRate"]],
                  property: "rc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "rangeMarketCap",
                      "top",
                    ],
                  ],
                  property: "marketcap",
                  propertyLabelIndex: 3,
                  transformation: {
                    value: {
                      equalTo: 1000000,
                      greaterThan: 1000000,
                      greaterThanOrEqualTo: 1000000,
                      lessThan: 1000000,
                      lessThanOrEqualTo: 1000000,
                      rangeMarketCap: 1000000,
                    },
                  },
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                    ],
                  ],
                  property: "mc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangeVolatility",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                    ],
                  ],
                  property: "sd",
                  propertyLabelIndex: 0,
                  transformation: {
                    value: {
                      equalToPercentage: 100,
                      greaterThanPercentage: 100,
                      greaterThanOrEqualToPercentage: 100,
                      lessThanPercentage: 100,
                      lessThanOrEqualToPercentage: 100,
                      rangeVolatility: 100,
                    },
                  },
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangePercentage",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                    ],
                  ],
                  property: "px",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangePercentage",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                    ],
                  ],
                  property: "pr",
                  propertyLabelIndex: 2,
                  transformation: {
                    value: {
                      equalToPercentage: 100,
                      greaterThanPercentage: 100,
                      greaterThanOrEqualToPercentage: 100,
                      lessThanPercentage: 100,
                      lessThanOrEqualToPercentage: 100,
                      rangePercentage: 100,
                    },
                  },
                },
              ],
            },
          },
          "//_3": "Ranking configuration is shared among all types of form",
          "//_4": "and used for encoding/decoding operations",
          ranking: {
            create: {
              default_template: [],
              functions: {
                decile: {
                  label: "Decile",
                  widget: {
                    label: "Decile",
                    type: "numberHidden",
                    value: 10,
                  },
                },
                outlier: {
                  label: "Outlier",
                  widget: {
                    constraints: {
                      max: 1,
                      min: 0,
                    },
                    label: "Outlier",
                    type: "number",
                    value: 0.1,
                  },
                },
                quantile: {
                  label: "Quantile",
                  widget: {
                    constraints: {
                      max: 100,
                      min: 1,
                    },
                    label: "N-iles",
                    type: "number",
                    value: 5,
                  },
                },
                quartile: {
                  label: "Quartile",
                  widget: {
                    label: "Quartile",
                    type: "numberHidden",
                    value: 4,
                  },
                },
                quintile: {
                  label: "Quintile",
                  widget: {
                    label: "Quintile",
                    type: "numberHidden",
                    value: 5,
                  },
                },
                threshold: {
                  label: "Threshold",
                  widget: {
                    constraints: {
                      max: 999999,
                      min: -99999,
                    },
                    label: "Threshold",
                    type: "number",
                    value: 0,
                  },
                },
                value: {
                  label: "Value",
                },
              },
              operators: {
                sortByExists: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "At the top",
                        value: "desc",
                      },
                      {
                        label: "At the bottom",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByNumeric: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Largest to Smallest",
                        value: "desc",
                      },
                      {
                        label: "Smallest to Largest",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByOutlier: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Outlier at bottom",
                        value: "desc",
                      },
                      {
                        label: "Outlier at top",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByQuantile: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Top quantile first",
                        value: "asc",
                      },
                      {
                        label: "Bottom quantile first",
                        value: "desc",
                      },
                    ],
                    type: "select",
                    value: "asc",
                  },
                },
                sortByPerformance: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Highest to lowest ",
                        value: "desc",
                      },
                      {
                        label: "Lowest to highest",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByRating: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "A to D",
                        value: "desc",
                      },
                      {
                        label: "D to A",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByRetracement: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Largest to Smallest",
                        value: "desc",
                      },
                      {
                        label: "Smallest to Largest",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByString: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "A to Z",
                        value: "asc",
                      },
                      {
                        label: "Z to A",
                        value: "desc",
                      },
                    ],
                    type: "select",
                    value: "asc",
                  },
                },
                sortByThreshold: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Greatest at Top",
                        value: "desc",
                      },
                      {
                        label: "Smallest at Top",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
              },
              options: [
                {
                  functions: ["value"],
                  operators: [["sortByExists"]],
                  property: "exists",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [["sortByRating"]],
                  property: "rc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile", "threshold"],
                  operators: [
                    ["sortByNumeric"],
                    ["sortByQuantile"],
                    ["sortByThreshold"],
                  ],
                  property: "mc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pr",
                  propertyLabelIndex: 2,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pt",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pw",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pm",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pq",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "py",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile", "outlier"],
                  operators: [
                    ["sortByRetracement"],
                    ["sortByQuantile"],
                    ["sortByOutlier"],
                  ],
                  property: "px",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [["sortByNumeric"]],
                  property: "marketcap",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [["sortByNumeric"]],
                  property: "tradedvalue",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [["sortByString"]],
                  property: "ticker",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile", "threshold"],
                  operators: [
                    ["sortByNumeric"],
                    ["sortByQuantile"],
                    ["sortByThreshold"],
                  ],
                  property: "sd",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "ts",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_pe",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_ps",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_pb",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_pc",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_pd",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_sps_CC_g_3",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_sps_CC_g_12",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_eps_CC_g_3",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_eps_CC_g_12",
                },
              ],
            },
            edit: {
              default_template: [
                {
                  function: "value",
                  functionParams: null,
                  operator: "sortByPerformance",
                  operatorParams: {
                    value: "desc",
                  },
                  property: "pt",
                },
              ],
              functions: {
                outlier: {
                  label: "Outlier",
                  widget: {
                    constraints: {
                      max: 1,
                      min: 0,
                    },
                    label: "Outlier",
                    type: "number",
                    value: 0.1,
                  },
                },
                quantile: {
                  label: "Quantile",
                  widget: {
                    constraints: {
                      max: 100,
                      min: 1,
                    },
                    label: "N-iles",
                    type: "number",
                    value: 5,
                  },
                },
                threshold: {
                  label: "Threshold",
                  widget: {
                    constraints: {
                      max: 999999,
                      min: -99999,
                    },
                    label: "Threshold",
                    type: "number",
                    value: 0,
                  },
                },
                value: {
                  label: "Value",
                },
              },
              operators: {
                sortByExists: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "At the top",
                        value: "desc",
                      },
                      {
                        label: "At the bottom",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByNumeric: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Largest to Smallest",
                        value: "desc",
                      },
                      {
                        label: "Smallest to Largest",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByOutlier: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Outlier at bottom",
                        value: "desc",
                      },
                      {
                        label: "Outlier at top",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByQuantile: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Top quantile first",
                        value: "asc",
                      },
                      {
                        label: "Bottom quantile first",
                        value: "desc",
                      },
                    ],
                    type: "select",
                    value: "asc",
                  },
                },
                sortByPerformance: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Highest to lowest ",
                        value: "desc",
                      },
                      {
                        label: "Lowest to highest",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByRating: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "A to D",
                        value: "desc",
                      },
                      {
                        label: "D to A",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByRetracement: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Largest to Smallest",
                        value: "desc",
                      },
                      {
                        label: "Smallest to Largest",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
                sortByString: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "A to Z",
                        value: "asc",
                      },
                      {
                        label: "Z to A",
                        value: "desc",
                      },
                    ],
                    type: "select",
                    value: "asc",
                  },
                },
                sortByThreshold: {
                  label: "Sort by",
                  widget: {
                    constraints: null,
                    label: "",
                    options: [
                      {
                        label: "Greatest at Top",
                        value: "desc",
                      },
                      {
                        label: "Smallest at Top",
                        value: "asc",
                      },
                    ],
                    type: "select",
                    value: "desc",
                  },
                },
              },
              options: [
                {
                  functions: ["value"],
                  operators: [["sortByExists"]],
                  property: "exists",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [["sortByRating"]],
                  property: "rc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile", "threshold"],
                  operators: [
                    ["sortByNumeric"],
                    ["sortByQuantile"],
                    ["sortByThreshold"],
                  ],
                  property: "mc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pr",
                  propertyLabelIndex: 2,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pt",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pw",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pm",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "pq",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "py",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile", "outlier"],
                  operators: [
                    ["sortByRetracement"],
                    ["sortByQuantile"],
                    ["sortByOutlier"],
                  ],
                  property: "px",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [["sortByNumeric"]],
                  property: "marketcap",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [["sortByNumeric"]],
                  property: "tradedvalue",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [["sortByString"]],
                  property: "ticker",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile", "threshold"],
                  operators: [
                    ["sortByNumeric"],
                    ["sortByQuantile"],
                    ["sortByThreshold"],
                  ],
                  property: "sd",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "ts",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_pe",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_ps",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_pb",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_pc",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_pd",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_sps_CC_g_3",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_sps_CC_g_12",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_eps_CC_g_3",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [["sortByPerformance"], ["sortByQuantile"]],
                  property: "f_eps_CC_g_12",
                },
              ],
            },
          },
          "//_5": "Selection configuration is shared among all types of form",
          "//_6": "and used for encoding/decoding operations",
          selection: {
            create: {
              default_template: [],
              functions: {
                decile: {
                  label: "Decile",
                  widget: {
                    label: "Decile",
                    type: "numberHidden",
                    value: 10,
                  },
                },
                quantile: {
                  label: "Quantile",
                  widget: {
                    constraints: {
                      max: 100,
                      min: 1,
                    },
                    label: "N-iles",
                    type: "number",
                    value: 5,
                  },
                },
                quartile: {
                  label: "Quartile",
                  widget: {
                    label: "Quartile",
                    type: "numberHidden",
                    value: 4,
                  },
                },
                quintile: {
                  label: "Quintile",
                  widget: {
                    label: "Quintile",
                    type: "numberHidden",
                    value: 5,
                  },
                },
                value: {
                  label: "Value",
                },
              },
              operators: {
                bottom: {
                  label: "Bottom",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 10,
                  },
                },
                equalTo: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 0,
                  },
                },
                equalToPercentage: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberPercentage",
                    value: 0,
                  },
                },
                equalToRate: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rate",
                    value: {
                      A: true,
                      B: true,
                      C: false,
                      D: false,
                    },
                  },
                },
                greaterThan: {
                  label: "Is greater than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperator",
                    value: [
                      {
                        gt: 0,
                      },
                    ],
                  },
                },
                greaterThanPercentage: {
                  label: "Is greater than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperatorPercentage",
                    value: [
                      {
                        gt: 0,
                      },
                    ],
                  },
                },
                greaterThanOrEqualTo: {
                  label: "Is greater than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperator",
                    value: [
                      {
                        ge: 0,
                      },
                    ],
                  },
                },
                greaterThanOrEqualToPercentage: {
                  label: "Is greater than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperatorPercentage",
                    value: [
                      {
                        ge: 0,
                      },
                    ],
                  },
                },
                lessThan: {
                  label: "Is less than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperator",
                    value: [
                      {
                        lt: 0,
                      },
                    ],
                  },
                },
                lessThanPercentage: {
                  label: "Is less than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperatorPercentage",
                    value: [
                      {
                        lt: 0,
                      },
                    ],
                  },
                },
                lessThanOrEqualTo: {
                  label: "Is less than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperator",
                    value: [
                      {
                        le: 0,
                      },
                    ],
                  },
                },
                lessThanOrEqualToPercentage: {
                  label: "Is less than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberOperatorPercentage",
                    value: [
                      {
                        le: 0,
                      },
                    ],
                  },
                },
                range: {
                  label: "Between",
                  widget: {
                    constraints: null,
                    label: "Between",
                    type: "numberSingleInterval",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeDecile: {
                  label: "Decile",
                  widget: {
                    constraints: null,
                    label: null,
                    options: [
                      {
                        label: "1st decile",
                        value: "1",
                      },
                      {
                        label: "2nd decile",
                        value: "2",
                      },
                      {
                        label: "3rd decile",
                        value: "3",
                      },
                      {
                        label: "4th decile",
                        value: "4",
                      },
                      {
                        label: "5th decile",
                        value: "5",
                      },
                      {
                        label: "6th decile",
                        value: "6",
                      },
                      {
                        label: "7th decile",
                        value: "7",
                      },
                      {
                        label: "8th decile",
                        value: "8",
                      },
                      {
                        label: "9th decile",
                        value: "9",
                      },
                      {
                        label: "10th decile",
                        value: "10",
                      },
                    ],
                    type: "rangeQuantile",
                    value: [
                      {
                        ge: 1,
                        le: 1,
                      },
                    ],
                  },
                },
                rangePercentage: {
                  label: "Between",
                  widget: {
                    constraints: null,
                    label: "Between",
                    type: "numberSingleIntervalPercentage",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeQuartile: {
                  label: "Quartile",
                  widget: {
                    constraints: null,
                    label: null,
                    options: [
                      {
                        label: "1st quartile",
                        value: "1",
                      },
                      {
                        label: "2nd quartile",
                        value: "2",
                      },
                      {
                        label: "3rd quartile",
                        value: "3",
                      },
                      {
                        label: "4th quartile",
                        value: "4",
                      },
                    ],
                    type: "rangeQuantile",
                    value: [
                      {
                        ge: 1,
                        le: 1,
                      },
                    ],
                  },
                },
                rangeQuintile: {
                  label: "Quintile",
                  widget: {
                    constraints: null,
                    label: null,
                    options: [
                      {
                        label: "1st quintile",
                        value: "1",
                      },
                      {
                        label: "2nd quintile",
                        value: "2",
                      },
                      {
                        label: "3rd quintile",
                        value: "3",
                      },
                      {
                        label: "4th quintile",
                        value: "4",
                      },
                      {
                        label: "5th quintile",
                        value: "5",
                      },
                    ],
                    type: "rangeQuantile",
                    value: [
                      {
                        ge: 1,
                        le: 1,
                      },
                    ],
                  },
                },
                rangeMarketCap: {
                  label: "Market Cap. in range",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rangeMarketCap",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeVolatility: {
                  label: "Volatility in range",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rangeVolatility",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                top: {
                  label: "Top",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 10,
                  },
                },
              },
              options: [
                {
                  functions: ["value"],
                  operators: [["equalToRate"]],
                  property: "rc",
                  propertyLabelIndex: 0,
                  value: {
                    function: "value",
                    functionParams: null,
                    operator: "equalToRate",
                    operatorParams: {
                      A: true,
                      B: true,
                      C: false,
                      D: false,
                    },
                  },
                },
                {
                  functions: ["value"],
                  operators: [
                    [
                      "rangeMarketCap",
                      "greaterThan",
                      "lessThan",
                      "top",
                      "bottom",
                    ],
                  ],
                  property: "marketcap",
                  propertyLabelIndex: 3,
                  transformation: {
                    value: {
                      equalTo: 1000000,
                      greaterThan: 1000000,
                      greaterThanOrEqualTo: 1000000,
                      lessThan: 1000000,
                      lessThanOrEqualTo: 1000000,
                      rangeMarketCap: 1000000,
                    },
                  },
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "mc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "rangeVolatility",
                      "lessThanPercentage",
                      "greaterThanPercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "sd",
                  propertyLabelIndex: 0,
                  transformation: {
                    value: {
                      equalToPercentage: 100,
                      greaterThanPercentage: 100,
                      greaterThanOrEqualToPercentage: 100,
                      lessThanPercentage: 100,
                      lessThanOrEqualToPercentage: 100,
                      rangeVolatility: 100,
                    },
                  },
                },
                {
                  functions: ["value"],
                  operators: [["greaterThan"]],
                  property: "tradedvalue",
                  propertyLabelIndex: 0,
                  transformation: {
                    value: {
                      greaterThan: 1000,
                    },
                  },
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "greaterThanPercentage",
                      "lessThanPercentage",
                      "rangePercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "px",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "greaterThanPercentage",
                      "lessThanPercentage",
                      "rangePercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "pr",
                  propertyLabelIndex: 2,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "greaterThanPercentage",
                      "lessThanPercentage",
                      "rangePercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "pt",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "greaterThanPercentage",
                      "lessThanPercentage",
                      "rangePercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "pw",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "greaterThanPercentage",
                      "lessThanPercentage",
                      "rangePercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "pm",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "greaterThanPercentage",
                      "lessThanPercentage",
                      "rangePercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "pq",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    [
                      "greaterThanPercentage",
                      "lessThanPercentage",
                      "rangePercentage",
                      "top",
                      "bottom",
                    ],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "py",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "ts",
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "f_pe",
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "f_ps",
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "f_pb",
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "f_pc",
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "f_pd",
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "f_sps_CC_g_3",
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "f_sps_CC_g_12",
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "f_eps_CC_g_3",
                },
                {
                  functions: ["value", "quartile", "quintile", "decile"],
                  operators: [
                    ["greaterThan", "lessThan", "range", "top", "bottom"],
                    ["rangeQuartile"],
                    ["rangeQuintile"],
                    ["rangeDecile"],
                  ],
                  property: "f_eps_CC_g_12",
                },
              ],
            },
            edit: {
              default_template: [
                {
                  function: "value",
                  functionParams: null,
                  operator: "equalToRate",
                  operatorParams: {
                    value: {
                      A: true,
                      B: true,
                      C: false,
                      D: false,
                    },
                  },
                  property: "rc",
                },
              ],
              functions: {
                quantile: {
                  label: "Quantile",
                  widget: {
                    constraints: {
                      max: 100,
                      min: 1,
                    },
                    label: "N-iles",
                    type: "number",
                    value: 5,
                  },
                },
                value: {
                  label: "Value",
                },
              },
              operators: {
                bottom: {
                  label: "Bottom",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 10,
                  },
                },
                equalTo: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 0,
                  },
                },
                equalToPercentage: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberPercentage",
                    value: 0,
                  },
                },
                equalToRate: {
                  label: "Is equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rate",
                    value: {
                      A: true,
                      B: true,
                      C: false,
                      D: false,
                    },
                  },
                },
                greaterThan: {
                  label: "Is greater than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberInterval",
                    value: [
                      {
                        gt: 0,
                      },
                    ],
                  },
                },
                greaterThanPercentage: {
                  label: "Is greater than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        gt: 0,
                      },
                    ],
                  },
                },
                greaterThanOrEqualTo: {
                  label: "Is greater than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberInterval",
                    value: [
                      {
                        ge: 0,
                      },
                    ],
                  },
                },
                greaterThanOrEqualToPercentage: {
                  label: "Is greater than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        ge: 0,
                      },
                    ],
                  },
                },
                lessThan: {
                  label: "Is less than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberInterval",
                    value: [
                      {
                        lt: 0,
                      },
                    ],
                  },
                },
                lessThanPercentage: {
                  label: "Is less than",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        lt: 0,
                      },
                    ],
                  },
                },
                lessThanOrEqualTo: {
                  label: "Is less than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberInterval",
                    value: [
                      {
                        le: 0,
                      },
                    ],
                  },
                },
                lessThanOrEqualToPercentage: {
                  label: "Is less than or equal to",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        le: 0,
                      },
                    ],
                  },
                },
                range: {
                  label: "Between",
                  widget: {
                    constraints: null,
                    label: "Between",
                    type: "numberInterval",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangePercentage: {
                  label: "Between",
                  widget: {
                    constraints: null,
                    label: "Between",
                    type: "numberIntervalPercentage",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeMarketCap: {
                  label: "Market Cap. in range",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rangeMarketCap",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                rangeVolatility: {
                  label: "Volatility in range",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "rangeVolatility",
                    value: [
                      {
                        ge: 0,
                        le: 0,
                      },
                    ],
                  },
                },
                top: {
                  label: "Top",
                  widget: {
                    constraints: null,
                    label: "",
                    type: "number",
                    value: 10,
                  },
                },
              },
              options: [
                {
                  functions: ["value"],
                  operators: [["equalToRate"]],
                  property: "rc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value"],
                  operators: [
                    [
                      "bottom",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "rangeMarketCap",
                      "top",
                    ],
                  ],
                  property: "marketcap",
                  propertyLabelIndex: 3,
                  transformation: {
                    value: {
                      equalTo: 1000000,
                      greaterThan: 1000000,
                      greaterThanOrEqualTo: 1000000,
                      lessThan: 1000000,
                      lessThanOrEqualTo: 1000000,
                      rangeMarketCap: 1000000,
                    },
                  },
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "mc",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangeVolatility",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "sd",
                  propertyLabelIndex: 0,
                  transformation: {
                    value: {
                      equalToPercentage: 100,
                      greaterThanPercentage: 100,
                      greaterThanOrEqualToPercentage: 100,
                      lessThanPercentage: 100,
                      lessThanOrEqualToPercentage: 100,
                      rangeVolatility: 100,
                    },
                  },
                },
                {
                  functions: ["value"],
                  operators: [["greaterThan"]],
                  property: "tradedvalue",
                  propertyLabelIndex: 0,
                  transformation: {
                    value: {
                      greaterThan: 1000,
                    },
                  },
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangePercentage",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "px",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangePercentage",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "pr",
                  propertyLabelIndex: 2,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangePercentage",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "pt",
                  propertyLabelIndex: 0,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangePercentage",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "pw",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangePercentage",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "pm",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangePercentage",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "pq",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalToPercentage",
                      "greaterThanPercentage",
                      "greaterThanOrEqualToPercentage",
                      "lessThanPercentage",
                      "lessThanOrEqualToPercentage",
                      "rangePercentage",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "py",
                  propertyLabelIndex: 1,
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "ts",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "f_pe",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "f_ps",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "f_pb",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "f_pc",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "f_pd",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "f_sps_CC_g_3",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "f_sps_CC_g_12",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "f_eps_CC_g_3",
                },
                {
                  functions: ["value", "quantile"],
                  operators: [
                    [
                      "bottom",
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                      "top",
                    ],
                    [
                      "equalTo",
                      "greaterThan",
                      "greaterThanOrEqualTo",
                      "lessThan",
                      "lessThanOrEqualTo",
                      "range",
                    ],
                  ],
                  property: "f_eps_CC_g_12",
                },
              ],
            },
          },
          workflow: {
            optimize: {
              enabled: false,
            },
            track: {
              enabled: true,
            },
            comparison: {
              enabled: true,
            },
          },
        },
      },
      id: 21,
      productCode: "SYSTEMATIC_ENGINE",
      typeName: "SYSTEMATIC_ENGINE",
    },
    user: null,
  },
  api: {
    account: {
      baseEndPoint: "trendrating-rest/api",
      domain: "{{ info.domain }}",
    },
    compute: {
      baseEndPoint: "trendrating-rest/api",
      domain: "{{ info.domain }}",
      node: "{{ info.system.node }}",
    },
    isDebug: false,
    report: {
      baseEndPoint: "api/v1/reports",
      domain: "{{ info.domain }}",
    },
    rest: {
      baseEndPoint: "api/v1",
      domain: "{{ info.domain }}",
    },
    //uri: "/data-local",
    // uri: window.document.location.host.startsWith("develop.")
    //     ? // ||
    //       // window.document.location.host.startsWith("localhost")
    //       "/data/tr.aws.bucket.share-test"
    //     : "/data/tr.aws.bucket.share-prod-2",

    //!Actually not forced to point on a specific api
    //! to enforce it pass "test" | "prod" as argument
    uri: pathResolver(),
    devModeProducts: "longOnly",
    usage: {
      storeKey: "{{ info.keenio.WRITE_KEY }}",
      storeUrl:
        "/log/projects/{{ info.keenio.PROJECT_ID }}/events/{{ info.keenio.COLLECTION }}",
    },
  },
  configuration: null,

  customerCare: {
    isImpersonating: false,
    userId: null,
  },
  properties: null,
  taxonomies: null,
};

export type User = {
  active: boolean;
  billingStart: string;
  contractType: string;
  conversionDate: string;
  country: string;
  creationTime: string;
  email: string;
  firm: string;
  firstName: string;
  id: number;
  lastName: string;
  owner: string;
  productMax: number;
  products: string;
  profile: string;
  role: string;
  stripeId: string | null;
  type: string;
  userName: string;
};
