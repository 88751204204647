import { ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from "../hooks/useAuth";

type LockProps = {
    children?: ReactNode;
};

export const Lock = ({ children }: LockProps) => {
    const { isLogged } = useAuth();

    if (isLogged) {
        return <>{children}</>;
    }

    return (
        <OverlayTrigger
            placement="auto"
            overlay={
                <Tooltip id="tooltip-unlock">
                    <i className="bi bi-lock-fill"></i> Sign up to see the full
                    list of stocks
                </Tooltip>
            }
        >
            <div className="app-lock">
                <div className="app-lock-content">{children}</div>
            </div>
        </OverlayTrigger>
    );
};
