import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../hooks/useAuth";
import { useDisclaimer } from "../hooks/useDisclaimer";
import { useEnvironment } from "../hooks/useEnvironment";
import { useRouter } from "../hooks/useRouter";
import { getFilePath } from "../queries/products";
import { ProductDefinition, ProductInfo } from "../types/Product";
import { PageWrap } from "./PageWrap";
import { ProductListFilters } from "./list/ProductListFilters";
import { ProductListItems } from "./list/ProductListItems";
import { ProductListTableItems } from "./list/ProductListTableItems";
import { useCookies } from "react-cookie";
import { ProductListTableItemsLS } from "./list/ProductListTableItemsLS";
import { KnownHostnames } from "../types/Defaults";

// Beware of upper/lower case
export const orderedKeys = {
  capture: ["US", "Europe", "Asia"],
  geo: ["us", "eurozone", "europe", "usandeurope", "asia"],
  theme: ["growth", "value", "growthvalue", "trend"],
  turnover: ["high", "low"],
  method: ["longonly", "longshort"],
};

export const sortByStructure = (a: string, b: string, list: string[]) => {
  let numberA = list.indexOf(a);
  if (numberA === -1) {
    numberA = Infinity;
  }
  let numberB = list.indexOf(b);
  if (numberB === -1) {
    numberB = Infinity;
  }
  if (numberA < numberB) {
    return -1;
  } else if (numberA > numberB) {
    return 1;
  } else {
    return 0;
  }
};

export const sortNumeric = (a: string, b: string) => {
  const numberA = parseInt(a, 10);
  const numberB = parseInt(b, 10);
  if (numberA < numberB) {
    return -1;
  } else if (numberA > numberB) {
    return 1;
  } else {
    return 0;
  }
};

export const getOrderedKeys = (obj: any, id: string): any[] => {
  if (obj == null) {
    return [];
  }

  const keys = Object.keys(obj);

  switch (id) {
    case "geo":
    case "turnover":
    case "theme":
      keys.sort((a, b) => sortByStructure(a, b, orderedKeys[id]));
      break;
    case "positions":
      keys.sort((a, b) => sortNumeric(a, b));
      break;
  }

  return keys;
};

export const ProductList = () => {
  const { query } = useRouter();
  const [showTable] = useState(
    // localStorage.getItem("catalog-mode") != null
    //   ? localStorage.getItem("catalog-mode") === "table"
    //   : "table"
    true
  );
  const checkDisclaimer = useDisclaimer();
  const auth = useAuth();
  const { environment } = useEnvironment();
  const [cookie, setCookie] = useCookies(["productFilters"]);
  const hostname = window.location.hostname as KnownHostnames;
  const isLongShort =
    hostname === "longshort.trendrating.com" ||
    hostname === "longshortd.trendrating.com";

  const initState = useMemo(
    () =>
      cookie?.productFilters ?? {
        geo: [],
        method: [],
        theme: [],
        positions: [],
      },
    [cookie?.productFilters]
  );

  const [filter, setFilter] = useState(initState);

  useEffect(() => {
    setCookie("productFilters", filter);
  }, [filter, setCookie]);

  useEffect(() => {
    // if (query.get("view")) {
    //   if (query.get("view") === "performers") {
    //     localStorage.setItem("catalog-mode", "table");
    //     setShowTable(true);
    //   } else if (query.get("view") === "catalog") {
    //     localStorage.setItem("catalog-mode", "catalog");
    //     setShowTable(false);
    //   }
    // }
  }, [query]);

  // useEffect(() => {
  //   localStorage.setItem("catalog-mode", showTable ? "table" : "catalog");
  // }, [showTable]);

  const localhostFile = environment.api.devModeProducts;
  const filepath = getFilePath(localhostFile);

  const {
    isLoading: isLoadingList,
    error: errorList,
    data: dataList,
  } = useQuery<any, Error>("products", () => {
    return axios(`${environment.api.uri + filepath}`);
  });

  const products: ProductInfo[] = useMemo(() => {
    if (dataList == null) {
      return [];
    }

    const combinedProducts: ProductDefinition[] = dataList.data.filter(
      (item: ProductDefinition) =>
        item.type === "product" || item.type === "combined-product"
    );

    return combinedProducts.map((combinedProduct) => {
      const product: ProductInfo = {
        analytics: combinedProduct.analytics,
        exposure: combinedProduct.info.exposure ?? null,
        id: combinedProduct.name,
        name: combinedProduct.info.name,
        geo: combinedProduct.info.geo?.toLowerCase() as ProductInfo["geo"],
        method: (combinedProduct.info.method?.toLowerCase() ??
          "longonly") as ProductInfo["method"],
        theme:
          combinedProduct.info.theme?.toLowerCase() as ProductInfo["theme"],
        turnover:
          combinedProduct.info.turnover?.toLowerCase() as ProductInfo["turnover"],
        positions: combinedProduct.info.positions,
        type: combinedProduct.type,
        description: combinedProduct.info.description,
        rules: combinedProduct.info.rules,
        title: combinedProduct.info.title,
        strategyAnalytics: {
          priceAnalytics: combinedProduct?.priceAnalytics?.stats ?? null,
          hPosAnalytics: combinedProduct?.hPosAnalytics?.stats ?? null,
        },
      };
      return product;
    });
  }, [dataList]);

  if (isLoadingList) {
    return (
      <PageWrap>
        <div className="head-content pb-3 mb-5">
          <Container>
            <div>
              <h1 className="d-flex justify-content-between align-items-start">
                Loading...
              </h1>
            </div>
          </Container>
        </div>
      </PageWrap>
    );
  }

  if (errorList) {
    return <PageWrap>Error: {errorList.message}</PageWrap>;
  }

  if (!auth.isReady) {
    return <PageWrap>Check user...</PageWrap>;
  }

  return (
    <PageWrap>
      {checkDisclaimer}
      <div className="head-content">
        <Container>
          <Row>
            <Col lg={12}>
              <h1>SMART MODEL PORTFOLIOS</h1>
              <p className="fs-5">
                Designed to profit from the performance dispersion and to select
                the best performing stocks using a well-tested combination of
                the most rewarding fundamental parameters and a factual
                validation of positive price trends in action.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="subhead-content sticky-top">
        <Container>
          {products != null ? (
            <ProductListFilters
              filter={filter}
              setFilter={setFilter}
              products={products}
            />
          ) : (
            <></>
          )}
        </Container>
      </div>
      <div className="list-content">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col sm={3} className="mb-3 align-self-end text-start">
              {/* <Button
                className={`btn ${
                  showTable ? "btn-outline-primary" : "btn-outline-primary"
                }`}
                onClick={() => setShowTable((prevShowTable) => !prevShowTable)}
              > 
                {showTable ? (
                  <span className="big-button-catalog">Show Catalog</span>
                ) : (
                  <span className="big-button-catalog">
                    Quick Search
                    <br />
                    <span className="text-md">See the best performers</span>
                  </span>
                )}
              </Button> */}
            </Col>
            <Col sm={9}>
              <p className="fs-5 text-end" style={{ marginBottom: 0 }}>
                <strong>
                  The model portfolios performance is price only, and does not
                  incorporate the dividend income.
                </strong>
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          {products != null ? (
            showTable ? (
              <>
                {isLongShort === true ? (
                  <ProductListTableItemsLS
                    filter={filter}
                    products={products}
                  />
                ) : (
                  <ProductListTableItems filter={filter} products={products} />
                )}
              </>
            ) : (
              <ProductListItems filter={filter} products={products} />
            )
          ) : (
            "No data"
          )}
        </Container>
      </div>
    </PageWrap>
  );
};
