import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";

// Reset filters all other filters
type ProductListFilterRadioProps = {
    value: any;
    filter: any;
    reset?: boolean;
    setFilter: Function;
    type: string;
};

export const ProductListFilterRadio = ({
    value,
    filter,
    reset = false,
    setFilter,
    type,
}: ProductListFilterRadioProps) => {
    const filterByValue = filter[type];
    const { t } = useTranslation();

    // Logic for radio inputs
    const handleInput: ChangeEventHandler<HTMLInputElement> = (event) => {
        const target = event.currentTarget;
        setFilter((prevFilter: any) => {
            let updatedFilter = prevFilter;
            if (target.checked && !prevFilter[type].includes(value)) {
                updatedFilter = {
                    ...prevFilter,
                    [type]: [value],
                };
            }
            if (reset) {
                for (const key of Object.keys(updatedFilter)) {
                    if (key === type) { // Exclude this filter
                        continue;
                    }
                    updatedFilter[key] = []; // Reset filters
                }
            }
            return updatedFilter;
        });
    };

    return (
        <span className="me-0 me-sm-2 d-block d-sm-inline-block">
            <input
                checked={filterByValue?.includes(value)}
                type="radio"
                className="btn-check"
                id={`${type}-${value}-input`}
                onChange={handleInput}
                autoComplete="off"
                name={`${type}-option`}
                value={value}
            />
            <label
                className="btn btn-outline-light btn-filter d-block d-sm-inline-block mb-2"
                htmlFor={`${type}-${value}-input`}
            >
                {t(`${type}-${value}`, value)}
            </label>
        </span>
    );
};
