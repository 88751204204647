import axios from "axios";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { CaptureChart } from "../components/capture/CaptureChart";
import { CaptureDetail } from "../components/capture/CaptureDetail";
import { CaptureStatistic } from "../components/capture/CaptureStatistic";
import CaptureStatisticChart from "../components/capture/CaptureStatisticChart";
import { Grid } from "../components/Grid";
import { useAuth } from "../hooks/useAuth";
import { useFormatter } from "../hooks/useFormatter";
import { useKeen } from "../hooks/useKeen";
import { environmentTemplate } from "../types/Defaults";
import { orderedKeys, sortByStructure } from "./ProductList";

const getDispersions = (baseUrl: string) => {
    return async ({ queryKey }: any) => {
        // const [, { id, dataList }] = queryKey; // ignore first element, not used
        const response = await axios(
            `${baseUrl}/dashboard/webdispersion/webdispersion.json`
        );
        return response.data.sort((a: any, b: any) =>
            sortByStructure(a.name, b.name, orderedKeys.capture)
        );
    };
};

const getDispersionById = (baseUrl: string) => {
    return async ({ queryKey }: any) => {
        const [, { activeDispersion, dispersions }] = queryKey; // ignore first element, not used

        const dispersion: Dispersion | undefined = dispersions.find(
            (dispersion: Dispersion) =>
                dispersion.name === activeDispersion.name &&
                dispersion.type === activeDispersion.type
        );

        if (dispersion == null) {
            throw new Error(
                `Cannot find dispersion ${activeDispersion.name} with type ${activeDispersion.type}`
            );
        }

        const url = `${baseUrl}/${activeDispersion.type}/${activeDispersion.name}/${activeDispersion.name}.json`;
        const response = await axios(url);
        return response.data;
    };
};

export type Dispersion = {
    info: {
        bottom: number;
        byAgeField: string;
        byAgefMnemonic: string;
        field: string;
        longName: string;
        name: string;
        perfMnemonic: string;
        top: number;
    };
    name: string;
    type: string;
};

export type DispersionDetail = {
    aggregated: {
        top: {
            AB: {
                ABPerc: number;
                capture: number;
                pr_adjusted: number;
                perf: number;
                card: number;
            };
            CD: {
                CDPerc: number;
                capture: number;
                pr_adjusted: number;
                perf: number;
                card: number;
            };
            any: {
                capture: number;
                pr_adjusted: number;
                perf: number;
                card: number;
            };
        };
        bottom: {
            AB: {
                ABPerc: number;
                capture: number;
                pr_adjusted: number;
                perf: number;
                card: number;
            };
            CD: {
                capture: number;
                pr_adjusted: number;
                perf: number;
                card: number;
                CDPerc: number;
            };
            any: {
                capture: number;
                pr_adjusted: number;
                perf: number;
                card: number;
            };
        };
        card: number;
    };
    bottomList: {
        capture: number;
        currency: string;
        icb: string;
        name: string;
        perf: number;
        pr_adjusted: number;
        rc: number;
        symbol: string;
        ticker: string;
    }[];
    byAge: Record<string, any>;
    info: Dispersion["info"];
    topList: {
        capture: number;
        currency: string;
        icb: string;
        name: string;
        perf: number;
        pr_adjusted: number;
        rc: number;
        symbol: string;
        ticker: string;
    }[];
};

type CaptureProps = {
    children?: ReactNode;
};

const baseUrl = environmentTemplate.api.uri;

export function Capture({ children }: CaptureProps) {
    const { isReady } = useAuth();
    const [activeDispersion, setActiveDispersion] = useState<Dispersion>();
    const { recordEvent } = useKeen();
    const formatter = useFormatter();
    const [showTopList, setShowTopList] = useState(false);
    const [showBottomList, setShowBottomList] = useState(false);
    const [showTopCaptureList, setShowTopCaptureList] = useState(false);
    const [showBottomCaptureList, setShowBottomCaptureList] = useState(false);

    const formatPercentageNoDecimal = useCallback(
        (value) =>
            formatter != null &&
            formatter.custom("number", {
                options: {
                    hasPositiveSign: false,
                    isPercentage: true,
                    decimals: 0,
                    notAvailable: {
                        input: null,
                        output: "",
                    },
                },
                output: "TEXT",
                value: value,
                valueHelper: null,
            }),
        [formatter]
    );
    // const formatter = useFormatter();

    useEffect(() => {
        // TODO If want to enable also anonymous users, check user === undefined
        if (activeDispersion == null) {
            return;
        }
        recordEvent("landing", activeDispersion.name, null);
    }, [recordEvent, activeDispersion]);

    const {
        isLoading: isLoadingDispersions,
        error: errorDispersions,
        data: dispersions,
    } = useQuery<Dispersion[], Error>("dispersions", getDispersions(baseUrl));

    const {
        isLoading: isLoadingDispersion,
        error: errorDispersion,
        data: dispersion,
    } = useQuery<DispersionDetail, Error>(
        ["dispersion", { activeDispersion, dispersions }],
        getDispersionById(baseUrl),
        {
            // The query will not execute until the userId exists
            enabled:
                !!activeDispersion?.name &&
                !!activeDispersion?.type &&
                !!dispersions,
        }
    );

    const field = useMemo(
        () => dispersion?.info?.byAgeField ?? "",
        [dispersion?.info?.byAgeField]
    );

    const optionsTopList = useMemo<Tabulator.Options>(() => {
        return {
            height: 400,
            initialSort: [
                {
                    column: field,
                    dir: "desc",
                },
            ],
        };
    }, [field]);

    const optionsBottomList = useMemo<Tabulator.Options>(() => {
        return {
            height: 400,
            initialSort: [
                {
                    column: field,
                    dir: "asc",
                },
            ],
        };
    }, [field]);

    const optionsCaptureBottomList = useMemo<Tabulator.Options>(() => {
        return {
            height: 400,
            initialSort: [
                {
                    column: "perf",
                    dir: "asc",
                },
            ],
        };
    }, []);

    const optionsCaptureTopList = useMemo<Tabulator.Options>(() => {
        return {
            height: 400,
            initialSort: [
                {
                    column: "perf",
                    dir: "desc",
                },
            ],
        };
    }, []);

    const topList: any[] = useMemo(
        () =>
            dispersion?.byAge?.[`rc|${field}`]?.list["0|0"]?.concat(
                dispersion?.byAge?.[`rc|${field}`]?.list["0|1"]
            ) ?? [],
        [dispersion?.byAge, field]
    );
    const bottomList: any[] = useMemo(
        () =>
            dispersion?.byAge?.[`rc|${field}`]?.list["1|0"]?.concat(
                dispersion?.byAge?.[`rc|${field}`]?.list["1|1"]
            ) ?? [],
        [dispersion?.byAge, field]
    );

    const columns = useMemo<Tabulator.ColumnDefinition[]>(() => {
        return [
            {
                field: "rc",
                hozAlign: "center",
                title: "Rating",
                titleFormatter: () => '<span title="Rating">Rating</span>',
                sorter: "number",
                headerSortStartingDir: "desc",
                widthGrow: 1,
            },
            {
                field: "ticker",
                title: "Ticker",
                titleFormatter: () => '<span title="Ticker">Ticker</span>',
                widthGrow: 1,
            },
            {
                field: "name",
                title: "Name",
                titleFormatter: () => '<span title="Name">Name</span>',
                widthGrow: 2,
            },
            {
                field: field,
                formatter: (cell) =>
                    formatter.custom("number", {
                        options: {
                            hasPositiveSign: true,
                            isPercentage: true,
                            notAvailable: {
                                input: null,
                                output: "0%",
                            },
                        },
                        output: "HTML",
                        value: cell.getValue(),
                        valueHelper: null,
                    }),
                headerHozAlign: "right",
                hozAlign: "right",
                title: "Perf.",
                titleFormatter: () =>
                    `<span title="Performance ${
                        dispersion?.info.byAgefMnemonic ?? ""
                    }">Perf.</span>`,
                sorter: "number",
                widthGrow: 1.5,
            },
            //     {
            //         field: "pr_adjusted",
            //         formatter: (cell) =>
            //             formatter.custom("number", {
            //                 options: {
            //                     hasPositiveSign: true,
            //                     isPercentage: true,
            //                     notAvailable: {
            //                         input: null,
            //                         output: "0%",
            //                     },
            //                 },
            //                 output: "HTML",
            //                 value: cell.getValue(),
            //                 valueHelper: null,
            //             }),
            //         hozAlign: "right",
            //         title: "Rating Perf.",
            //         titleFormatter: () =>
            //             '<span title="Performance since rating">Rating Perf.</span>',
            //         sorter: "number",
            //         widthGrow: 1.5,
            //     },
            //     {
            //         field: "capture",
            //         formatter: (cell) =>
            //             formatter.custom("number", {
            //                 options: {
            //                     hasPositiveSign: false,
            //                     isPercentage: true,
            //                     notAvailable: {
            //                         input: null,
            //                         output: "0%",
            //                     },
            //                 },
            //                 output: "HTML",
            //                 value: cell.getValue(),
            //                 valueHelper: null,
            //             }),
            //         hozAlign: "right",
            //         title: "Capture",
            //         titleFormatter: () => '<span title="Capture">Capture</span>',
            //         sorter: "number",
            //         widthGrow: 1.3,
            //     },
        ];
    }, [dispersion?.info.byAgefMnemonic, field, formatter]);

    const columnsCapture = useMemo<Tabulator.ColumnDefinition[]>(() => {
        return [
            {
                field: "rc",
                hozAlign: "center",
                title: "Rating",
                titleFormatter: () => '<span title="Rating">Rating</span>',
                sorter: "number",
                headerSortStartingDir: "desc",
                widthGrow: 1,
            },
            {
                field: "ticker",
                title: "Ticker",
                titleFormatter: () => '<span title="Ticker">Ticker</span>',
                widthGrow: 1,
            },
            {
                field: "name",
                title: "Name",
                titleFormatter: () => '<span title="Name">Name</span>',
                widthGrow: 2,
            },
            {
                field: "perf",
                formatter: (cell) =>
                    formatter.custom("number", {
                        options: {
                            hasPositiveSign: true,
                            isPercentage: true,
                            notAvailable: {
                                input: null,
                                output: "0%",
                            },
                        },
                        output: "HTML",
                        value: cell.getValue(),
                        valueHelper: null,
                    }),
                headerHozAlign: "right",
                hozAlign: "right",
                title: "Perf.",
                titleFormatter: () =>
                    `<span title="Performance ${
                        dispersion?.info.byAgefMnemonic ?? ""
                    }">Perf.</span>`,
                sorter: "number",
                widthGrow: 1.5,
            },
            {
                field: "pr_adjusted",
                formatter: (cell) =>
                    formatter.custom("number", {
                        options: {
                            hasPositiveSign: true,
                            isPercentage: true,
                            notAvailable: {
                                input: null,
                                output: "0%",
                            },
                        },
                        output: "HTML",
                        value: cell.getValue(),
                        valueHelper: null,
                    }),
                hozAlign: "right",
                title: "Rating Perf.",
                titleFormatter: () =>
                    '<span title="Performance since rating">Rating Perf.</span>',
                sorter: "number",
                widthGrow: 1.5,
            },
            {
                field: "capture",
                formatter: (cell) =>
                    formatter.custom("number", {
                        options: {
                            hasPositiveSign: false,
                            isPercentage: true,
                            notAvailable: {
                                input: null,
                                output: "0%",
                            },
                        },
                        output: "HTML",
                        value: cell.getValue(),
                        valueHelper: null,
                    }),
                hozAlign: "right",
                title: "Capture",
                titleFormatter: () => '<span title="Capture">Capture</span>',
                sorter: "number",
                widthGrow: 1.3,
            },
        ];
    }, [dispersion?.info.byAgefMnemonic, formatter]);

    useEffect(() => {
        if (dispersions == null || dispersions.length === 0) {
            return;
        }
        setActiveDispersion(dispersions[0]);
    }, [dispersions]);

    // const [timestamp] = useState(new Date().getTime());

    // Check cookie before setting disclaimer status

    if (!isReady || isLoadingDispersions) {
        return <div>"Loading..."</div>;
    }

    if (errorDispersions || errorDispersion || dispersions === undefined) {
        return <div>Error during loading data</div>;
    }

    return (
        <Container fluid className="page-capture gx-5">
            <Row className="gx-5">
                <Col className="d-flex justify-content-center" sm={12}>
                    {dispersions.map((dispersion) => (
                        <Button
                            active={dispersion.name === activeDispersion?.name}
                            className="m-2 capture-selector"
                            key={dispersion.name}
                            variant="outline-primary"
                            onClick={() => setActiveDispersion(dispersion)}
                        >
                            {dispersion.info.name}{" "}
                            {(isLoadingDispersion &&
                                dispersion.name === activeDispersion?.name) ?? (
                                <>Loading...</>
                            )}
                        </Button>
                    ))}
                </Col>
            </Row>
            {/* <Row className="gx-5 mt-4 mb-4 text-center">
                <Col sm={12}>
                    <h1 className="mb-1">
                        {dispersion ? dispersion.info.longName : <>&nbsp;</>}
                    </h1>
                    <div className="fs-4">
                        {dispersion ? (
                            `${dispersion.aggregated.card} Stocks`
                        ) : (
                            <>&nbsp;</>
                        )}
                    </div>
                </Col>
            </Row> */}
            <Row className="gx-5 mt-5 mb-5">
                <Col sm={12}>
                    <h2 className="text-center">
                        Statistics of all the ratings with{" "}
                        {dispersions?.[0]?.info?.byAgefMnemonic} performance
                        history{" "}
                        <span className="text-small">(daily update)</span>
                    </h2>
                </Col>
                <Col sm={6}>
                    <CaptureStatistic dispersion={dispersion} type="winners" />
                    <CaptureStatisticChart
                        dispersion={dispersion}
                        type="winners"
                    />
                    <div className="d-flex justify-content-center">
                        <Button
                            active={showTopList}
                            onClick={() => setShowTopList(!showTopList)}
                            className="mt-4 capture-selector"
                            variant="outline-primary"
                        >
                            See track record
                        </Button>
                    </div>

                    {showTopList && (
                        <Grid
                            className="mt-4"
                            columns={columns}
                            data={topList}
                            options={optionsTopList}
                        />
                    )}
                </Col>
                <Col sm={6}>
                    <CaptureStatistic dispersion={dispersion} type="losers" />
                    <CaptureStatisticChart
                        dispersion={dispersion}
                        type="losers"
                    />
                    <div className="d-flex justify-content-center">
                        <Button
                            active={showBottomList}
                            onClick={() => setShowBottomList(!showBottomList)}
                            className="mt-4 capture-selector"
                            variant="outline-primary"
                        >
                            See track record
                        </Button>
                    </div>

                    {showBottomList && (
                        <Grid
                            className="mt-4"
                            columns={columns}
                            data={bottomList}
                            options={optionsBottomList}
                        />
                    )}
                </Col>
            </Row>
            <hr />
            <Row className="gx-5 mt-5 mb-5">
                <Col sm={12}>
                    <h2 className="text-center">
                        Statistics of the big movers.
                        <br /> Stocks recording a trend above{" "}
                        {
                            /* uses dispersions[0] to not wait the loading of data */ dispersions[0]
                                ?.info?.top != null
                                ? formatPercentageNoDecimal(
                                      dispersions[0]?.info?.top
                                  )
                                : "%"
                        }
                        , either up or down in the last{" "}
                        {dispersions?.[0]?.info?.perfMnemonic}.
                        <br />
                        (daily update)
                    </h2>
                </Col>
            </Row>
            <Row className="gx-5">
                <Col sm={6}>
                    <CaptureDetail
                        dispersion={dispersion}
                        title="Outperformers"
                        type="winners"
                    />
                    <CaptureChart
                        className="table-striped mt-4"
                        dispersion={dispersion}
                        type="winners"
                    />
                    <div className="d-flex justify-content-center">
                        <Button
                            active={showTopCaptureList}
                            onClick={() =>
                                setShowTopCaptureList(!showTopCaptureList)
                            }
                            className="mt-4 capture-selector"
                            variant="outline-primary"
                        >
                            See track record
                        </Button>
                    </div>
                    {showTopCaptureList && (
                        <Grid
                            className="mt-4"
                            columns={columnsCapture}
                            data={dispersion?.topList}
                            options={optionsCaptureTopList}
                        />
                    )}
                </Col>
                <Col sm={6} className="mt-md-0 mt-5">
                    <CaptureDetail
                        dispersion={dispersion}
                        title="Underperformers"
                        type="losers"
                    />
                    <CaptureChart
                        className="table-striped mt-4"
                        dispersion={dispersion}
                        type="losers"
                    />
                    <div className="d-flex justify-content-center">
                        <Button
                            active={showBottomCaptureList}
                            onClick={() =>
                                setShowBottomCaptureList(!showBottomCaptureList)
                            }
                            className="mt-4 capture-selector"
                            variant="outline-primary"
                        >
                            See track record{" "}
                        </Button>
                    </div>
                    {showBottomCaptureList && (
                        <Grid
                            className="mt-4"
                            columns={columnsCapture}
                            data={dispersion?.bottomList}
                            options={optionsCaptureBottomList}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
}
