import { parseISO, subMonths, subYears } from "date-fns";
import Highcharts from "highcharts/highstock";
import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { useFormatter } from "../hooks/useFormatter";
import { TDate } from "../trendrating/date/TDate";
import { deepClone } from "../trendrating/deepClone";
import { Chart } from "./Chart";

type ProductChartProps = {
  enableRelative?: boolean;
  isInteractive?: boolean;
  showBar?: boolean;
  useAutomaticRatio?: boolean;
  setIsChartEnabled?: (value: boolean) => void;
  value: any;
};

// type PeriodOption = {
//   index: number;
//   label: string;
//   selected?: boolean;
//   value: string;
// };

export const ProductChart = forwardRef(
  (
    {
      enableRelative = true,
      isInteractive = true,
      showBar = true,
      useAutomaticRatio = true,
      value,
      setIsChartEnabled,
    }: ProductChartProps,
    ref
  ) => {
    const [scale, setScale] = useState<Highcharts.AxisTypeValue>("logarithmic");
    const [period] = useState<string>("MAX");
    const [options, setOptions] = useState<Highcharts.Options>({});
    // const [periodOptions, setPeriodOptions] = useState<PeriodOption[]>([]);
    const [showRelative, setShowRelative] = useState<boolean>(false);
    // const [hasTooltip, setHasTooltip] = useState<boolean>(false);
    // const [hasMeasure, setHasMeasure] = useState<boolean>(false);
    const chartRef = useRef<Highcharts.Chart>();
    const formatter = useFormatter();

    useEffect(() => {
      if (setIsChartEnabled) {
        setIsChartEnabled(true);
      }
    }, [setIsChartEnabled]);

    // useEffect(() => {
    //   const priceList = value["response"]["priceList"];
    //   let selectOptions: PeriodOption[] = [];
    //   var len = priceList.length;
    //   if (len > 10 * 260) {
    //     selectOptions.push({
    //       label: "10Y",
    //       value: "10Y",
    //       index: 100,
    //     });
    //   }
    //   if (len > 5 * 260) {
    //     selectOptions.push({ label: "5Y", value: "5Y", index: 50 });
    //   }
    //   if (len > 3 * 260) {
    //     selectOptions.push({ label: "3Y", value: "3Y", index: 30 });
    //   }
    //   if (len > 1 * 260) {
    //     selectOptions.push({ label: "1Y", value: "1Y", index: 10 });
    //   }
    //   if (len > 3 * 20) {
    //     selectOptions.push({ label: "3M", value: "3M", index: 3 });
    //   }
    //   if (len > 1 * 20) {
    //     selectOptions.push({ label: "1M", value: "1M", index: 1 });
    //   }
    //   selectOptions.push({ label: "YTD", value: "YTD", index: 9 });
    //   selectOptions.push({
    //     label: "MAX",
    //     value: "MAX",
    //     index: 999,
    //   });
    //   selectOptions.sort(function (a: any, b: any) {
    //     return a["index"] < b["index"] ? -1 : a["index"] > b["index"] ? 1 : 0;
    //   });
    //   for (let i = 0; i < selectOptions.length; i++) {
    //     if (selectOptions[i].label === period) {
    //       selectOptions[i].selected = true;
    //       break; // Just one can be active at time
    //     }
    //   }
    //   setPeriodOptions(selectOptions);
    // }, [period, value]);

    useEffect(() => {
      let fieldDate = "d";
      let fieldValue = "v";
      let firstDate: any = 0;
      let lastDate: any = 0;
      let subject: any = null;
      let benchmark: any = null;
      let priceList: any = null;
      let priceBenchmark: any = null;

      const _startDateFromPeriod = () => {
        let result = 0;
        if (lastDate != null) {
          const quot = Math.floor(lastDate / 5);
          const rem = Math.floor(lastDate % 5);
          const u = Math.floor((quot * 7 + rem + 4) * 86400 * 1000);
          const tmpDate = new Date(u);
          const Y = tmpDate.getFullYear();
          const d = tmpDate.getDate();
          const m = tmpDate.getMonth() + 1;
          const M = m < 10 ? "0" + m : "" + m;
          const D = d < 10 ? "0" + d : "" + d;
          const toStr = Y + "-" + M + "-" + D;
          const to = parseISO(toStr);
          if (to != null) {
            let from: Date;
            switch (period) {
              case "1Y":
                from = subYears(to, 1);
                break;
              case "3Y":
                from = subYears(to, 3);
                break;
              case "5Y":
                from = subYears(to, 5);
                break;
              case "10Y":
                from = subYears(to, 10);
                break;
              case "1M":
                from = subMonths(to, 1);
                break;
              case "3M":
                from = subMonths(to, 3);
                break;
              case "YTD":
                var ytdDate = Y - 1 + "-12-31";
                from = parseISO(ytdDate);
                break;
              case "MAX":
                const tDate = new TDate();
                from = tDate.daysToDate(firstDate);
                break;
              default:
                from = to;
            }
            if (from != null) {
              const milliseconds = from.getTime();
              const seconds = milliseconds / 1000;
              const t = ~~(seconds / 86400) - 4;
              const quot = ~~(t / 7);
              const rem = ~~(t % 7);
              const d = quot * 5 + rem;
              result = Math.max(d, firstDate);
            }
          }
        }
        return result;
      };

      // TODO deal with missing period
      if (value != null && period != null) {
        //_set("value", value);
        // Start adding instruments, starting with the primary one

        /*'symbol'   : sec.symbol,
            'name'	   : sec.ticker,
            'currency' : sec.currency,
            'H'		   : H,
            
            
        chart.addSecurity(
            other.information,
            other.history
        );*/

        if (
          // value["list"] != null &&
          value["response"]["priceList"] != null
        ) {
          priceList = deepClone(value["response"]["priceList"]);
          firstDate = Math.min(
            priceList[0]["d"],
            priceList[priceList.length - 1]["d"]
          );
          lastDate = Math.max(
            priceList[0]["d"],
            priceList[priceList.length - 1]["d"]
          );
          firstDate = _startDateFromPeriod();
          // if (chart == null) {
          //     var chartOptions: any = {
          //         fieldDate: "d",
          //         fieldValue: "v",
          //         firstDate: startDate,

          //         // 2020-07-27
          //         // if != null, on the chart will be rendered a line
          //         // to indicate the separation between backtest and
          //         // the product
          //         backtestEndsAt: value["backtestEndsAt"],

          //         functions: {},
          //         historicalRating: false,
          //         scale: "logarithmic",
          //         scaleButton: false,
          //     };
          //     chartOptions["functions"]["measure"] = lang.hitch(
          //         this,
          //         "_listenerMeasure"
          //     );
          //     chart = new Chart(nodeChart, chartOptions);
          // }
          // Does reset all secondary securities

          // Use info.name instead of list.name
          subject = {
            symbol: value["info"]["name"],
            ticker: value["info"]["name"],
            name: value["info"]["name"],
            currency: null,
          };
          // Additional instruments
          if (
            value["benchmark"] != null &&
            value["response"]["priceBenchmark"] != null
          ) {
            priceBenchmark = deepClone(value["response"]["priceBenchmark"]);
            benchmark = {
              symbol: value["benchmark"]["name"],
              name: value["benchmark"]["name"],
              ticker: value["benchmark"]["name"],
              currency: null,
            };
            if (value["response"]["benchmark"] != null) {
              benchmark["symbol"] =
                value["response"]["benchmark"]["data"][0]["symbol"];
              benchmark["name"] =
                value["response"]["benchmark"]["data"][0]["name"];
              benchmark["ticker"] =
                value["response"]["benchmark"]["data"][0]["name"];
            }
          }

          // widgetRangeSelector.set("options", options);
        }

        //refresh();
      }
      /**
       *
       * @param {string} symbol
       * @param {number} date
       * @param {boolean} returnNullIfEmpty - if not found, returns null, otherwise
       *                                      returns the last value
       * @returns {object} a single day ({d:, v:})
       */
      // const dayAtDate = (
      //     symbol: any,
      //     date: any,
      //     returnNullIfEmpty: any
      // ) => {
      //     var H = null;
      //     if (symbol != null) {
      //         if (symbol === data.mainSec.symbol) {
      //             H = data.mainSec.H;
      //         } else if (
      //             showRelative &&
      //             symbol === data.relativeSec.symbol
      //         ) {
      //             H = data.relativeSec.H;
      //         } else if (symbol === data.benchmarkSec.symbol) {
      //             H = data.benchmarkSec.H;
      //         }
      //     } else {
      //         H = data.mainSec.H;
      //     }

      //     for (var i = 0, N = H.length; i < N; i++) {
      //         if (H[i][fieldDate] <= date) {
      //             return H[i];
      //         }
      //     }

      //     if (returnNullIfEmpty) {
      //         return null;
      //     }

      //     return H[H.length - 1];
      // };

      // const getPointsAtDate = (date: any) => {
      //     var points: any = {};

      //     points[data.mainSec.symbol] = {
      //         current: dayAtDate(data.mainSec.symbol, date, true),
      //         last: data.mainSec.H[0],
      //         first: dayAtDate(data.mainSec.symbol, firstDate, false),
      //     };

      //     if (data.benchmarkSec.symbol != null) {
      //         points[data.benchmarkSec.symbol] = {
      //             current: dayAtDate(
      //                 data.benchmarkSec.symbol,
      //                 date,
      //                 true
      //             ),
      //             last: data.benchmarkSec.H[0],
      //             first: dayAtDate(
      //                 data.benchmarkSec.symbol,
      //                 firstDate,
      //                 false
      //             ),
      //         };
      //     }

      //     if (showRelative) {
      //         points[data.relativeSec.symbol] = {
      //             current: dayAtDate(data.relativeSec.symbol, date, true),
      //             last: data.relativeSec.H[0],
      //             first: dayAtDate(
      //                 data.relativeSec.symbol,
      //                 firstDate,
      //                 false
      //             ),
      //         };
      //     }

      //     return points;
      // };

      // const _formatCellDate = (date: any, value: any, object: any) => {
      //     return {
      //         html: formatter.custom("price", {
      //             options: {
      //                 isPercentage: false,
      //                 notAvailable: {
      //                     input: null,
      //                     output: "",
      //                 },
      //             },
      //             output: "HTML",
      //             value: value,
      //             valueHelper: {
      //                 currency: object["currency"],
      //                 date: date,
      //             },
      //         }),
      //     };
      // };

      // const _formatCellPercent = (value: any, object: any) => {
      //     return formatter.custom("number", {
      //         options: {
      //             hasPositiveSign: true,
      //             isPercentage: true,
      //             notAvailable: {
      //                 input: null,
      //                 output: "",
      //             },
      //         },
      //         output: "HTML",
      //         value: value,
      //         valueHelper: null,
      //     });
      // };

      // const _formatDate = (value: any) => {
      //     return formatter.custom("date", {
      //         options: {
      //             format: ["D", "M", "Y"],
      //             isMillisecond: false,
      //             notAvailable: {
      //                 input: null,
      //                 output: "",
      //             },
      //             separator: " ",
      //         },
      //         output: "HTML",
      //         value: value,
      //         valueHelper: null,
      //     });
      // };

      const _date2Milliseconds = (T: number): number => {
        const quot = Math.floor(T / 5);
        const rem = Math.floor(T % 5);
        const u = Math.floor((quot * 7 + rem + 4) * 86400 * 1000);
        return u;
      };

      // const _millisecondsToDate = (milliseconds: any) => {
      //     const t = ~~(milliseconds / 1000 / 86400) - 4;
      //     const quot = ~~(t / 7);
      //     const rem = ~~(t % 7);
      //     const d = quot * 5 + rem;
      //     return d;
      // };

      const _setCrossPoint = (date: any) => {
        if (showRelative && enableRelative) {
          let newCrossAtPoint =
            data.relativeSec.H[data.relativeSec.H.length - 1];
          for (let i = 0; i < data.relativeSec.H.length; i++) {
            if (data.relativeSec.H[i][fieldDate] <= date) {
              newCrossAtPoint = data.relativeSec.H[i];
              break;
            }
          }
          data.crossAtPoint = newCrossAtPoint;
        } else {
          let newCrossAtPoint = data.mainSec.H[data.mainSec.H.length - 1];
          for (let i = 0; i < data.mainSec.H.length; i++) {
            if (data.mainSec.H[i][fieldDate] <= date) {
              newCrossAtPoint = data.mainSec.H[i];
              break;
            }
          }
          data.crossAtPoint = newCrossAtPoint;
        }
      };

      const setRelative = (relative: boolean) => {
        if (chartRef.current != null) {
          if (showRelative) {
            chartRef.current.series[0]?.show();
            chartRef.current.series[1]?.show();
            chartRef.current.series[2]?.hide();
          } else {
            chartRef.current.series[0]?.hide();
            chartRef.current.series[1]?.hide();
            chartRef.current.series[2]?.show();
          }
          if (enableRelative) {
            setShowRelative(!showRelative);
          }
          chartRef.current.redraw();
        }
      };

      // backtestEndsAt is here!! not inside chart
      let backtestEndsAt = null;

      let data: any = {
        benchmarkSec: {
          H: null,
          color: "#FFCC00",
          lineWidth: 2,
          name: null,
          shadow: true,
          symbol: null,
        },
        // 2020-07-27
        // if != null, on the chart will be rendered a line to indicate the
        // separation between backtest and the product
        backtestEndsAt: backtestEndsAt,
        crossAtPoint: null,
        mainSec: {
          H: null,
          color: "#2A7092",
          lineWidth: 2,
          name: value["info"]["name"],
          shadow: true,
          symbol: null,
        },
        relativeSec: {
          H: null,
          color: "#23BF0C", // green
          enabled: false,
          lineWidth: 2,
          name: "Relative performance",
          shadow: true,
          symbol: "relative",
        },
      };

      // setData = function (sec, H, benchmark, B) {
      // subject priceList benchmark priceBenchmark
      // Deep copy + sort
      var history = [];
      for (let i = 0; i < priceList.length; i++) {
        let historyElement: any = {};
        historyElement[fieldDate] = priceList[i][fieldDate];
        historyElement[fieldValue] = priceList[i][fieldValue];
        history.push(historyElement);
      }
      history.sort(function (a, b) {
        return a[fieldDate] > b[fieldDate]
          ? -1
          : b[fieldDate] > a[fieldDate]
          ? 1
          : 0;
      });
      // End deep copy + sort
      data.mainSec.H = history;
      data.mainSec.symbol = subject.symbol;
      data.mainSec.name = subject.ticker;
      data.mainSec.currency = subject.currency;
      data.crossAtPoint = priceList[priceList.length - 1];

      if (benchmark != null) {
        // Deep copy + sort
        history = [];
        for (let i = 0; i < priceBenchmark.length; i++) {
          let historyElement: any = {};
          historyElement[fieldDate] = priceBenchmark[i][fieldDate];
          historyElement[fieldValue] = priceBenchmark[i][fieldValue];
          history.push(historyElement);
        }
        history.sort(function (a, b) {
          return a[fieldDate] > b[fieldDate]
            ? -1
            : b[fieldDate] > a[fieldDate]
            ? 1
            : 0;
        });
        // End deep copy + sort
        data.benchmarkSec.H = history;
        data.benchmarkSec.symbol = benchmark.symbol;
        data.benchmarkSec.name = benchmark.ticker;
        data.benchmarkSec.currency = benchmark.currency;

        // _calcRelative
        var Hlong: any = data.mainSec.H;
        var Hshort: any = data.benchmarkSec.H;
        var datesLong: any = {};
        var datesShort: any = {};
        for (let i = 0; i < Hlong.length; i++) {
          let h = Hlong[i];
          datesLong[h[fieldDate]] = h[fieldValue];
        }
        for (let i = 0; i < Hshort.length; i++) {
          let h = Hshort[i];
          datesShort[h[fieldDate]] = h[fieldValue];
        }
        var Hdata: any = [];
        for (var date in datesLong) {
          if (datesShort[date] === undefined)
            Hdata.push({ d: parseInt(date), v: null });
          else
            Hdata.push({
              d: parseInt(date),
              v: datesLong[date] / datesShort[date],
            });
        }
        var first: any = 100.0;
        for (let i = 0; i < Hdata.length; i++) {
          first = Hdata[i]["v"];
          if (first != null) {
            break;
          }
        }
        for (let i = Hdata.length - 1; i >= 0; i--) {
          if (Hdata[i]["v"] != null) {
            Hdata[i]["v"] *= 100.0 / first;
          }
        }
        Hdata.sort(function (a: any, b: any) {
          return a.d < b.d ? 1 : b.d < a.d ? -1 : 0;
        });
        data.relativeSec.H = Hdata;
      } else {
        data.benchmarkSec.H = null;
      }
      if (firstDate === 0) {
        // _draw();
      } else {
        _setCrossPoint(firstDate);
        // _draw();
      }

      const fromJsonToSerieRescale = (
        data: any,
        targetData: any,
        rescaleAt: any,
        stopAtDate: any
      ) => {
        var dataLength = data.length;
        var serie = [];
        var v, d;
        var rescaleRatio = 1.0;

        if (rescaleAt !== false) {
          let firstDate = data[dataLength - 1][fieldDate];
          if (firstDate > rescaleAt[fieldDate]) {
            // storia piu corta di quella del target
            for (let i = 0; i < targetData.length; i++) {
              if (targetData[i][fieldDate] <= firstDate) {
                rescaleAt = targetData[i];
                break;
              }
            }
          }
          var rescaleAtDate = rescaleAt[fieldDate];
          var rescaleAtValue = rescaleAt[fieldValue];
          var firstValidDate = null;
          for (let i = dataLength - 1; i >= 0; i--) {
            if (data[i][fieldValue] != null) {
              firstValidDate = data[i][fieldDate];
              break;
            }
          }
          for (let i = 0; i < dataLength; i++) {
            d = data[i][fieldDate];
            v = data[i][fieldValue];
            if (d < rescaleAtDate || d < firstValidDate) {
              break;
            }
            if (d === rescaleAtDate) {
              if (rescaleAtValue != null) {
                rescaleRatio = rescaleAtValue / v;
              }
              break;
            }
          }
        }
        for (let i = 0; i < dataLength; i++) {
          d = data[i][fieldDate];
          if (d < stopAtDate) {
            break;
          }
          v = data[i][fieldValue] * rescaleRatio;
          serie.push([_date2Milliseconds(d), v <= 0 ? null : v]);
        }
        serie.sort(function (a: any, b: any) {
          return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
        });
        return serie;
      };

      var serieDataPrice = fromJsonToSerieRescale(
        data.mainSec.H,
        data.mainSec.H,
        data.crossAtPoint,
        firstDate
      );

      var series: any = [
        {
          allowPointSelect: true,
          clip: false,
          color: data.mainSec.color,
          data: serieDataPrice,
          dataGrouping: { enabled: false },
          events: {
            legendItemClick: (event: any) => {
              event.preventDefault();
              setRelative(false);
              // public
              // console.log("CHANGE NO RELATIVE");
            },
          },
          id: data.mainSec.symbol,
          currency: data.mainSec.currency,
          lineWidth: data.mainSec.lineWidth,
          name: data.mainSec.name,
          shadow: !showRelative && data.mainSec.shadow,
          states: {
            hover: {
              enabled: true,
              lineWidthPlus: 0,
            },
          },
          type: "line",
          visible: !showRelative,
          yAxis: 0,
          zIndex: 9,
        },
      ];

      if (data.benchmarkSec.H != null) {
        series.push({
          type: "line",
          id: data.benchmarkSec.symbol,
          name: data.benchmarkSec.name,
          currency: data.benchmarkSec.currency,
          data: fromJsonToSerieRescale(
            data.benchmarkSec.H,
            data.mainSec.H,
            data.crossAtPoint,
            firstDate
          ),
          yAxis: 0,
          lineWidth: data.benchmarkSec.lineWidth,
          color: data.benchmarkSec.color,
          dataGrouping: { enabled: false },
          zIndex: 9,
          visible: !showRelative,
          shadow: false,
          allowPointSelect: true,
          events: {
            legendItemClick: (event: any) => {
              event.preventDefault();
              setRelative(false);
              // console.log("CHANGE NO RELATIVE");
            },
          },
          states: {
            hover: {
              enabled: true,
              lineWidthPlus: 0,
            },
          },
        });
      }

      if (enableRelative && data.relativeSec.H != null) {
        series.push({
          type: "line",
          id: data.relativeSec.symbol,
          name: data.relativeSec.name,
          currency: null,
          data: fromJsonToSerieRescale(
            data.relativeSec.H,
            data.mainSec.H,
            data.crossAtPoint,
            firstDate
          ),
          lineWidth: data.relativeSec.lineWidth,
          color: data.relativeSec.color,
          dataGrouping: { enabled: false },
          zIndex: 9,
          visible: showRelative,
          shadow: false,
          allowPointSelect: true,
          yAxis: 1,
          events: {
            legendItemClick: (event: any) => {
              event.preventDefault();
              setRelative(true);
              // console.log("CHANGE RELATIVE");
            },
          },
          states: {
            hover: {
              enabled: true,
              lineWidthPlus: 0,
            },
          },
        });
      }

      var yAxis: Highcharts.YAxisOptions[] = [
        {
          labels: { align: "right", x: -3 },
          title: { text: null },
          type: scale,
          lineWidth: 1,
          opposite: false,
          showEmpty: false,
          startOnTick: true,
          zoomEnabled: false,
        },
        {
          labels: { align: "right", x: -3 },
          title: { text: null },
          type: scale,
          lineWidth: 1,
          opposite: false,
          showEmpty: false,
          startOnTick: true,
          zoomEnabled: false,
        },
      ];

      // No render-to div
      let options: Highcharts.Options = {
        chart: {
          alignTicks: false,
          animation: false,
          borderWidth: 0,
          height: null /* displayRatio */,
          // zoomType: "x",
          resetZoomButton: { theme: { display: "none" } },
          // events: {
          //     selection: (e: any) => {
          //         // if (measureFunction != null) {
          //         //     var measure: any = {
          //         //         dateFrom: _millisecondsToDate(e.xAxis[0].min),
          //         //         dateTo: _millisecondsToDate(e.xAxis[0].max),
          //         //         secs: {},
          //         //     };
          //         //     var pointsFrom = getPointsAtDate(measure.dateFrom);
          //         //     var pointsTo = getPointsAtDate(measure.dateTo);
          //         //     var vFrom, vTo;
          //         //     for (let symbol in pointsFrom) {
          //         //         if (pointsTo[symbol] !== undefined) {
          //         //             vFrom = pointsFrom[symbol].current[fieldValue];
          //         //             vTo = pointsTo[symbol].current[fieldValue];
          //         //             measure.secs[symbol] = {
          //         //                 vFrom: vFrom,
          //         //                 vTo: vTo,
          //         //                 perf: vTo / vFrom - 1,
          //         //             };
          //         //         }
          //         //     }
          //         //     measureFunction(measure);
          //         //     return e.originalEvent.shiftKey; // zoom only with shift
          //         // } else {
          //         //     return true;
          //         // }
          //         return true;
          //     },
          // },
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        legend: { enabled: true, margin: 0 },
        navigator: { enabled: false },
        plotOptions: {
          series: {
            connectNulls: false,
            animation: false,
            cursor: "pointer",
            enableMouseTracking: true,
            // point: {
            //     events: {
            //         mouseOver: (e: any) => {
            //             // var date = _millisecondsToDate(e.target.x);
            //             // if (tooltipFunction != null) {
            //             //     tooltipFunction(getPointsAtDate(date));
            //             //     return (
            //             //         e.target.series.userOptions.type === "flags"
            //             //     );
            //             // }
            //             return true;
            //         },
            //         click: (e: any) => {
            //             var date = _millisecondsToDate(e.point.x);
            //             _setCrossPoint(date);
            //         },
            //         legendItemClick: () => {
            //             return false;
            //         },
            //     },
            // },
            states: {
              hover: {
                enabled: isInteractive,
              },
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        rangeSelector: { enabled: false },
        scrollbar: { enabled: false },
        series: series,
        title: { text: undefined },
        tooltip: {
          enabled: isInteractive, // callbackTooltip
          /*formatter: function(tooltip) {

                    // TODO check
                    if (series != null && series.userOptions.type === "flags") {
                        var date = new Date(point.x);
                        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        return '<div style="font-size:1.2em;font-weight:600;color:' + point.fillColor + '">' + point.title + '</div>' +
                            '<div style="margin-top: 3px;">' + months[date.getUTCMonth()] + " " + date.getUTCDate() + " " + date.getUTCFullYear() + '</div>';
                    }
                    if (thisChart.tooltipFunction != null) {
                        return false;
                    } else {
                        var date = thisChart._millisecondsToDate(point.x)
                        var systemDate = new Date(point.x);
                        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        var stringDate = months[systemDate.getUTCMonth()] + " " + systemDate.getUTCDate() + " " + systemDate.getUTCFullYear();
                        var info = thisChart.getPointsAtDate(date);
                        var symbol = series.userOptions.id;
                        // TODO use external formatter instead of duplicating code
                        var currency = series.userOptions.currency;
                        var currencies = {
                            'EUR': '&euro;',
                            'GBP': '&pound;',
                            'JPY': '&yen;',
                            'USD': '$'
                        };
                        if (currencies[currency] != null) {
                            currency = currencies[currency];
                        }
                        return '<span style="color:' + series.color + ';">\u25CF</span> ' + stringDate + ": " + (currency ? currency : "") + " " + info[symbol].current[thisChart.fieldValue].toFixed(4);
                    }
                },*/
          hideDelay: 100,
          split: false,
          shared: true,
          useHTML: true,
          valueDecimals: 2,
        },
        xAxis: [
          {
            title: { text: null },
            labels: { style: { fontSize: "9" } },
            plotLines: undefined,
            zoomEnabled: false,
          },
        ],
        yAxis: yAxis,
      };

      // separation between backtest and the product
      if (data.backtestEndsAt != null) {
        let plotLines: Highcharts.XAxisPlotLinesOptions[] = [
          {
            color: "transparent",
            id: "backtestSeparatorAfter",
            label: {
              style: {
                color: "#666",
                fontFamily: "'Open Sans', sans-serif",
                fontSize: "9px",
              },
              text: "actual",
              verticalAlign: "middle",
              x: 4,
            },
            value: _date2Milliseconds(data.backtestEndsAt),
            width: 1,
          },
          {
            color: "#d3d3d3",
            id: "backtestSeparator",
            label: {
              align: "right",
              rotation: 0,
              style: {
                color: "#666",
                fontFamily: "'Open Sans', sans-serif",
                fontSize: "9px",
              },
              text: "backtest",
              x: -5,
            },
            value: _date2Milliseconds(data.backtestEndsAt),
            width: 1,
          },
        ];

        (options.xAxis as Highcharts.XAxisOptions[])[0].plotLines = plotLines;
      }
      setOptions(options);
    }, [
      chartRef,
      enableRelative,
      formatter,
      isInteractive,
      period,
      scale,
      showRelative,
      value,
    ]);

    useImperativeHandle(ref, () => ({
      refresh: () => {
        // console.log("Refresh", chartRef.current);
        if (chartRef.current != null) {
          // console.log("Flow");
          chartRef.current.reflow();
        }
      },
    }));

    // console.log(options);

    return (
      <div className="wSecurityAnalysisChart h-100 d-flex flex-column">
        {showBar && (
          <div className="wSecurityAnalysisChart-bar flex-grow-0 flex-shrink-0">
            <div className="wSecurityAnalysisChart-bar-right">
              {/* <ButtonGroup className="wSecurityAnalysisChart-rangeSelector">
                                {periodOptions.map((periodOption) => (
                                    <Button
                                        size="sm"
                                        key={periodOption.value}
                                        variant={
                                            periodOption.selected
                                                ? "primary"
                                                : "outline-primary"
                                        }
                                        onClick={() =>
                                            setPeriod(periodOption.value)
                                        }
                                    >
                                        {periodOption.label}
                                    </Button>
                                ))}
                            </ButtonGroup> */}
              <Form.Check
                checked={scale === "logarithmic"}
                type="checkbox"
                label="Log scale"
                id="chart-log-toggle"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  event.target.checked
                    ? setScale("logarithmic")
                    : setScale("linear")
                }
              />
            </div>
          </div>
        )}
        <div className="wSecurityAnalysisChart-chartWrap h-100 flex-grow-1 flex-shrink-1 overflow-hidden">
          <Chart
            className="h-100"
            ref={chartRef}
            options={options}
            useAutomaticRatio={useAutomaticRatio}
          ></Chart>
        </div>
        {/* <div
                className="nonModal tPriceChartInfoCommon"
                data-dojo-attach-point="widgetPopupMeasure"
                data-dojo-props="style:'width: 520px', draggable: true, resizable: true"
                data-dojo-type="dijit/Dialog"
            ></div> */}
      </div>
    );
  }
);
