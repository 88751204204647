/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/api/Properties
 * @summary Retrieves a property label
 *
 */

export class Properties {
    properties: any = null;

    constructor(params: any) {
        if (
            "properties" in params &&
            params["properties"] !== undefined &&
            params["properties"] != null
        ) {
            // NOTE: priority or property override is not managed
            // Should be?
            this.properties = {
                etf: {},
                index: {},
                security: {},
                stock: {},
            };
            var typeSpecificProperties = ["ETF", "Index", "Stock"];
            for (var property in params["properties"]) {
                if (typeSpecificProperties.includes(property)) {
                    this.properties[property.toLowerCase()] =
                        params["properties"][property];
                } else {
                    this.properties["security"][property] =
                        params["properties"][property];
                }
            }
        } else {
            throw new Error("Properties not specified.");
        }
    }

    /**
     *
     * Get the label of the property
     *
     * @param {string} property - The property for which get the label
     * @param {number} labelIndex - the index of label to get. Default 0
     * @param {string} instrumentType - the type of instrument. If "auto",
     *      it looks for the first available match. Default "security"
     *
     * @returns {string} the label
     */
    get(property: string, labelIndex: number, instrumentType: string) {
        return this._get(property, labelIndex, instrumentType, "name");
    }

    getForSecurityType(
        securityType: string,
        property: string,
        labelIndex: number
    ) {
        console.log(
            "[TODO] getForSecurityType",
            securityType,
            property,
            labelIndex
        );

        return property;
    }

    getTag(property: string, labelIndex: number, instrumentType: string) {
        return this._get(property, labelIndex, instrumentType, "tags");
    }

    _get(
        property: string,
        labelIndex: number,
        instrumentType: string,
        key: string
    ) {
        var defaultInstrumentType = "security";

        instrumentType =
            instrumentType != null
                ? instrumentType.toLowerCase()
                : defaultInstrumentType;
        labelIndex =
            labelIndex !== undefined && labelIndex != null ? labelIndex : 0;
        var properties = this.properties;

        // auto option: looks for a match. The first match is returned.
        if (instrumentType === "auto") {
            for (var _instrumentType in properties) {
                if (property in properties[_instrumentType]) {
                    if (properties[_instrumentType][property][key] == null) {
                        return null;
                    }

                    return properties[_instrumentType][property][key][
                        labelIndex
                    ];
                }
            }
        }

        var propertyType =
            instrumentType in properties
                ? instrumentType
                : defaultInstrumentType;

        if (propertyType == null) {
            return property;
        }

        if (!(property in properties[propertyType])) {
            propertyType = defaultInstrumentType;
            if (!(property in properties[propertyType])) {
                return property;
            }
        }

        var label = properties[propertyType][property][key][labelIndex];
        if (label === undefined) {
            throw new Error(
                "LabelIndex " +
                    labelIndex +
                    " out of range: " +
                    JSON.stringify(properties[propertyType][property][key])
            );
        }

        return label;
    }
}
