/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/api/accounts/Decoder
 * @summary Decode server object as UI suitable object
 *
 */

import { format } from "date-fns";
import { User } from "../../../types/Defaults";

export const decodeUser = (response: any) => {
    const rawData = response;

    const user: User = {
        //
        // TODO JSESSIONID cookies not actually used, for now
        // these cookies have the path '/trendrating-rest'
        // and are used by Web App Python API to perform session
        // validation againt Core API to protect endpoints such as send
        // emails to support and generate PDF reports
        //
        // additionalCookies: [
        //     {
        //         name: "JSESSIONID",
        //         path: "/trendrating-rest",
        //         value: response["data"]["JSESSIONID"],
        //     },
        // ],

        id: rawData.id,
        firstName: rawData.firstName,
        lastName: rawData.lastName,
        email: rawData.email,
        userName: rawData.userName,
        role: rawData.role,
        profile: rawData.profile,
        type: rawData.type,
        firm: rawData.firm,
        creationTime: format(rawData.creationTime, "MM/dd/yyyy"),
        active: rawData.active,
        country: rawData.country,
        products: rawData.products,
        contractType: rawData.contractType,
        productMax: rawData.productMax,
        billingStart: rawData.billingStart,
        conversionDate: rawData.conversionDate,
        owner: rawData.owner,
        stripeId: rawData.stripeId,

        // groups: [rawData.level, rawData.role],
    };

    return user;
};
