import { useCallback } from "react";
import { useFormatter } from "../../hooks/useFormatter";
import { DispersionDetail } from "../../pages/Capture";

type CaptureProps = {
  dispersion?: DispersionDetail;
  title: string;
  type: "winners" | "losers";
};

export function CaptureDetail({ dispersion, title, type }: CaptureProps) {
  const formatter = useFormatter();
  const formatPercentage = useCallback(
    (value) =>
      formatter != null &&
      formatter.custom("number", {
        options: {
          hasPositiveSign: false,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: value,
        valueHelper: null,
      }),
    [formatter]
  );

  // const maxThreshold = dispersion.info.top;
  //     const minThreshold = dispersion.info.bottom;
  //     const cluster = dispersion.aggregated.top;

  return (
    <>
      <h2>{title}</h2>

      <table className="table tableABvsCDPublic">
        <tbody>
          <tr>
            <td>
              Number of{" "}
              {type === "winners" ? "outperformers" : "underperformers"} posting
              a {type === "winners" ? "gain" : "loss"} &gt;{" "}
              {formatPercentage(
                type === "winners"
                  ? dispersion?.info.top
                  : dispersion?.info.bottom
              )}{" "}
              in last {dispersion ? dispersion.info.perfMnemonic : "..."}
            </td>
            <td className="text-end">
              {dispersion &&
                (type === "winners"
                  ? dispersion.aggregated.top.any.card
                  : dispersion.aggregated.bottom.any.card)}
            </td>
          </tr>
          <tr>
            <td>
              Number of{" "}
              {type === "winners" ? "outperformers" : "underperformers"} rated{" "}
              {type === "winners" ? (
                <>
                  <span className="rate_A">A</span>
                  <span className="rate_B">B</span>
                </>
              ) : (
                <>
                  <span className="rate_C">C</span>
                  <span className="rate_D">D</span>
                </>
              )}
            </td>
            <td className="text-end">
              {dispersion &&
                (type === "winners"
                  ? dispersion.aggregated.top.AB.card
                  : dispersion.aggregated.bottom.CD.card)}
            </td>
          </tr>
          <tr>
            <td>
              <strong className="fs-6">
                % of captured{" "}
                {type === "winners" ? "outperformers" : "underperformers"}
              </strong>
            </td>
            <td className="text-end">
              <strong className="fs-6">
                {dispersion &&
                  formatPercentage(
                    type === "winners"
                      ? dispersion.aggregated.top.AB.ABPerc
                      : dispersion.aggregated.bottom.CD.CDPerc
                  )}
              </strong>
            </td>
          </tr>
          {/* <tr>
                        <td>
                            {type === "winners"
                                ? "Outperformers average gain"
                                : "Underperformers average loss"}
                        </td>
                        <td className="text-end">
                            {dispersion &&
                                formatPercentage(
                                    type === "winners"
                                        ? dispersion.aggregated.top.any.perf
                                        : dispersion.aggregated.bottom.any.perf
                                )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {type === "winners"
                                ? "% of average performance captured by"
                                : "% of average loss avoided by"}{" "}
                            {type === "winners" ? (
                                <>
                                    <span className="rate_A">A</span>
                                    <span className="rate_B">B</span>
                                </>
                            ) : (
                                <>
                                    <span className="rate_C">C</span>
                                    <span className="rate_D">D</span>
                                </>
                            )}{" "}
                            rating
                        </td>
                        <td className="text-end">
                            {dispersion &&
                                formatPercentage(
                                    type === "winners"
                                        ? dispersion.aggregated.top.AB
                                              .pr_adjusted
                                        : dispersion.aggregated.bottom.CD
                                              .pr_adjusted
                                )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong className="fs-6">
                                {type === "winners"
                                    ? "% of captured gain"
                                    : "% of avoided loss"}
                            </strong>
                        </td>
                        <td className="text-end">
                            <strong className="fs-6">
                                {dispersion &&
                                    formatPercentage(
                                        type === "winners"
                                            ? dispersion.aggregated.top.AB
                                                  .capture
                                            : dispersion.aggregated.bottom.CD
                                                  .capture
                                    )}
                            </strong>
                        </td>
                    </tr> */}
        </tbody>
      </table>
    </>
  );
}
