/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/api/Configuration
 * @summary Parse configuration and return objects suitable for UI
 *
 */

import { deepClone } from "../deepClone";
import { Properties } from "./Properties";

export class Configuration {
    // parsed and ready to use configuration
    configuration: any;

    product: any;
    properties: any;

    /**
     * @constructor
     *
     * @param {object} params
     * @param {object} params.product - raw product configuration
     * @param {object} params.properties - instrument's properties
     *
     * @returns {undefined}
     */
    constructor({ configuration, product, properties }: any) {
        this.product = {
            flavourCode: product.productCode,
            flavourName: product.codeName,
            id: product.id,
            name: "Trendrating Web App",
        };
        this.properties = properties;

        this.generate(deepClone(configuration));
    }

    checkColumns(item: any) {
        //
        // columns available
        //
        // if the field is IGNORE, the current product does not have
        // to use the field
        //
        var column = null;
        var columnsAvailable = item["columns_available"];
        var ready = [];
        for (var i = 0, length = columnsAvailable.length; i < length; i++) {
            column = columnsAvailable[i];
            if (column["field"] !== "IGNORE") {
                ready.push(column);
            }
        }

        item["columns_available"] = ready;

        return item;
    }

    generate(items: any) {
        // partial: only in some pages
        var configuration: any = {
            landing: {
                options: this._snakeCase2CamelCase(
                    items["landing_page_options"]
                ),
                page: this._snakeCase2CamelCase([items["landing_page"]])[0],
            },
            pages: {
                alerts: {
                    enabled: false,
                    widgets: null,
                    workflow: null,
                },
                analysisInstrument: {
                    enabled: false,
                    widgets: null,
                    workflow: null,
                },
                analysisList: {
                    enabled: false,
                    widgets: null,
                    workflow: null,
                },
                analysisPeer: {
                    enabled: false,
                    widgets: null,
                    workflow: null,
                },
                ranking: {
                    enabled: false,
                    widgets: null,
                    workflow: null,
                },
                screening: {
                    enabled: false,
                    widgets: null,
                    workflow: null,
                },
                strategyBuilder: {
                    enabled: false,
                    widgets: null,
                    workflow: null,
                },
                strategyTracker: {
                    enabled: false,
                    widgets: null,
                    workflow: null,
                },
                systematicPortfolios: {
                    enabled: false,
                    widgets: null,
                    workflow: null,
                },
            },
        };

        var page = null;

        if ("alerts" in items) {
            page = items["alerts"];
            configuration["pages"]["alerts"]["enabled"] = page["enabled"];
        }

        if ("analysis_instrument" in items) {
            page = items["analysis_instrument"];
            configuration["pages"]["analysisInstrument"]["enabled"] =
                page["enabled"];
        }

        if ("analysis_list" in items) {
            page = items["analysis_list"];
            configuration["pages"]["analysisList"]["enabled"] = page["enabled"];
            configuration["pages"]["analysisList"]["tabs"] = [];
            configuration["pages"]["analysisList"]["tabsIndex"] = {};

            var item = null;
            for (
                var i = 0, length = page["overview_tabs"].length;
                i < length;
                i++
            ) {
                item = page["overview_tabs"][i];

                if ("widgets" in item) {
                    if ("ranking" in item["widgets"]) {
                        item["widgets"]["ranking"] = this._ranking(
                            item["widgets"]["ranking"]
                        );
                    }

                    if ("viewer" in item["widgets"]) {
                        item["widgets"]["viewer"] = this.checkColumns(
                            item["widgets"]["viewer"]["table"]
                        );
                    }

                    if ("viewerFilter" in item["widgets"]) {
                        item["widgets"]["viewerFilter"] =
                            item["widgets"]["viewerFilter"]["table"];
                    }
                }

                configuration["pages"]["analysisList"]["tabs"].push(item);
                configuration["pages"]["analysisList"]["tabsIndex"][
                    item["id"]
                ] = i;
            }

            configuration["pages"]["analysisList"]["workflow"] =
                page["workflow"];
        }

        if ("analysis_peer" in items) {
            page = items["analysis_peer"];
            configuration["pages"]["analysisPeer"]["enabled"] = page["enabled"];

            configuration["pages"]["analysisPeer"]["widgets"] = {
                viewer: {
                    what: null,
                    where: null,
                    whereWhat: null,
                },
                viewerFilter: {
                    what: null,
                    where: null,
                    whereWhat: null,
                },
            };

            for (var viewer in page["widgets"]["viewer"]) {
                switch (viewer) {
                    case "market": {
                        configuration["pages"]["analysisPeer"]["widgets"][
                            "viewer"
                        ]["where"] = page["widgets"]["viewer"][viewer];

                        configuration["pages"]["analysisPeer"]["widgets"][
                            "viewerFilter"
                        ]["where"] = page["widgets"]["viewerFilter"][viewer];

                        break;
                    }
                    case "market_sector": {
                        configuration["pages"]["analysisPeer"]["widgets"][
                            "viewer"
                        ]["whereWhat"] = page["widgets"]["viewer"][viewer];

                        configuration["pages"]["analysisPeer"]["widgets"][
                            "viewerFilter"
                        ]["whereWhat"] =
                            page["widgets"]["viewerFilter"][viewer];

                        break;
                    }
                    case "sector": {
                        configuration["pages"]["analysisPeer"]["widgets"][
                            "viewer"
                        ]["what"] = page["widgets"]["viewer"][viewer];

                        configuration["pages"]["analysisPeer"]["widgets"][
                            "viewerFilter"
                        ]["what"] = page["widgets"]["viewerFilter"][viewer];

                        break;
                    }
                }
            }
        }

        if ("ranking" in items) {
            page = items["ranking"];
            configuration["pages"]["ranking"]["enabled"] = page["enabled"];

            if (page["widgets"] != null) {
                configuration["pages"]["ranking"]["widgets"] = {};

                if ("ranking" in page["widgets"]) {
                    configuration["pages"]["ranking"]["widgets"]["ranking"] =
                        this._ranking(page["widgets"]["ranking"]);
                }

                if ("viewer" in page["widgets"]) {
                    configuration["pages"]["ranking"]["widgets"]["viewer"] =
                        page["widgets"]["viewer"]["table"];
                }

                if ("viewerFilter" in page["widgets"]) {
                    configuration["pages"]["ranking"]["widgets"][
                        "viewerFilter"
                    ] = page["widgets"]["viewerFilter"];
                }
            }

            if (page["workflow"] != null) {
                configuration["pages"]["ranking"]["workflow"] =
                    page["workflow"];
            }
        }

        if ("screening" in items) {
            page = items["screening"];
            configuration["pages"]["screening"]["enabled"] = page["enabled"];

            configuration["pages"]["screening"]["widgets"] = {
                ranking: null,
                viewer: null,
                viewerFilter: null,
            };

            if ("ranking" in page["widgets"]) {
                configuration["pages"]["screening"]["widgets"]["ranking"] =
                    this._ranking(page["widgets"]["ranking"]);
            }

            if ("viewer" in page["widgets"]) {
                configuration["pages"]["screening"]["widgets"]["viewer"] =
                    this.checkColumns(page["widgets"]["viewer"]["table"]);
            }

            if ("viewerFilter" in page["widgets"]) {
                configuration["pages"]["screening"]["widgets"]["viewerFilter"] =
                    page["widgets"]["viewerFilter"]["table"];
            }
        }

        if ("strategy_tracker" in items) {
            page = items["strategy_tracker"];
            configuration["pages"]["strategyTracker"]["enabled"] =
                page["enabled"];

            if (page["enabled"] === true) {
                configuration["pages"]["strategyTracker"]["maxCardinality"] =
                    page["maxCardinality"];
            }
        }

        if ("strategy_builder" in items) {
            page = items["strategy_builder"];
            configuration["pages"]["strategyBuilder"]["enabled"] =
                page["enabled"];

            if (page["enabled"] === true) {
                // shared
                configuration["pages"]["strategyBuilder"]["hedging"] =
                    this._selection(page["hedging"]);
                configuration["pages"]["strategyBuilder"]["ranking"] =
                    this._ranking(page["ranking"]);

                configuration["pages"]["strategyBuilder"]["selection"] =
                    this._selection(page["selection"]);

                // form advanced
                configuration["pages"]["strategyBuilder"]["formAdvanced"] = {
                    enabled: page["form_advanced"]["enabled"],
                };
                if (page["form_advanced"]["enabled"] === true) {
                    configuration["pages"]["strategyBuilder"]["formAdvanced"][
                        "widgets"
                    ] = {
                        // hedging:
                        //     "hedging" in page["form_advanced"]
                        //         ? this._selection(
                        //               page["form_advanced"]["hedging"]
                        //           )
                        //         : null,
                        selectionUniverse:
                            "selection_universe" in page["form_advanced"]
                                ? this._selection(
                                      page["form_advanced"][
                                          "selection_universe"
                                      ]
                                  )
                                : null,
                        smartBeta:
                            "smartBeta" in page["form_advanced"]
                                ? this._smartBeta(
                                      page["form_advanced"]["smartBeta"]
                                  )
                                : null,
                    };
                }
                // form alpha
                configuration["pages"]["strategyBuilder"]["formAlpha"] = {
                    enabled: page["form_alpha"]["enabled"],
                };
                // form macro rotation
                configuration["pages"]["strategyBuilder"]["formMacroRotation"] =
                    {
                        enabled: page["form_macro_rotation"]["enabled"],
                    };
                // form smart beta
                configuration["pages"]["strategyBuilder"]["formSmartBeta"] = {
                    enabled: page["form_smart_beta"]["enabled"],
                };
                // form compare
                configuration["pages"]["strategyBuilder"]["formCompare"] = {
                    enabled: page["form_compare"]["enabled"],
                };
                // form long short
                configuration["pages"]["strategyBuilder"]["formLongShort"] = {
                    enabled: page["form_long_short"]["enabled"],
                };
                // TODO - hard coded: at the moment used for report
                configuration["pages"]["strategyBuilder"]["widgets"] = {
                    viewer: {
                        columns: {
                            security: [
                                "weight",
                                "ticker",
                                "name",
                                "currency",
                                "rc",
                                "country",
                                "icb",
                                "performance",
                                "contribution",
                            ],
                        },
                        columns_available: [
                            { field: "weight" },
                            { field: "ticker" },
                            { field: "name" },
                            { field: "currency" },
                            { field: "rc" },
                            { field: "country" },
                            { field: "icb" },
                            { field: "performance" },
                            { field: "contribution" },
                        ],
                    },
                    viewerFilter: null,
                };

                configuration["pages"]["strategyBuilder"]["workflow"] =
                    page["workflow"];
            }
        }

        if ("systematic_portfolios" in items) {
            page = items["systematic_portfolios"];
            configuration["pages"]["systematicPortfolios"]["enabled"] =
                page["enabled"];

            if (page["enabled"] === true) {
                configuration["pages"]["systematicPortfolios"]["tabs"] = [];

                for (let i = 0; i < page["tabs"].length; i++) {
                    let item = page["tabs"][i];

                    if ("widgets" in item) {
                        if ("viewer" in item["widgets"]) {
                            item["widgets"]["viewer"] = this.checkColumns(
                                item["widgets"]["viewer"]["table"]
                            );
                        }

                        if ("viewerFilter" in item["widgets"]) {
                            item["widgets"]["viewerFilter"] =
                                item["widgets"]["viewerFilter"]["table"];
                        }
                    }

                    configuration["pages"]["systematicPortfolios"]["tabs"].push(
                        item
                    );
                }
            }
        }

        this.configuration = configuration;

        return configuration;
    }

    get(property: any) {
        var configuration: any = this.configuration;
        switch (property) {
            case "landing": {
                return configuration["landing"];
            }
            case "product": {
                return this.product;
            }
            default: {
                if (property in configuration["pages"]) {
                    return configuration["pages"][property];
                }

                return null;
            }
        }
    }

    /**
     * Convert an old snake case page ID in JS format (camelCase)
     * @param {string} pageId - String in snake_case format
     *
     * @returns {string} String in camelCase format
     */
    legacyPageId2StandardPageId(pageId: any) {
        return this._snakeCase2CamelCase([pageId])[0];
    }
    // ----------------------------------------------------- private methods
    _factorConstraint(actionItems: any) {
        var properties = this.properties;
        var label = new Properties({ properties: properties });

        var options = [];
        var option: any = null;

        var _function = null;
        var _functionName = null;
        var _functions = actionItems["functions"];
        var _operator = null;
        var _operatorName = null;
        var _operators = actionItems["operators"];
        var _option = null;
        var _options = actionItems["options"];
        var _transformations: any = {};

        for (var i = 0, lengthI = _options.length; i < lengthI; i++) {
            _option = _options[i];

            option = {
                functions: [],
                operators: [],
                property: null,
            };

            // functions
            for (
                var j = 0, lengthJ = _option["functions"].length;
                j < lengthJ;
                j++
            ) {
                _functionName = _option["functions"][j];
                _function = _functions[_functionName];
                _function["value"] = _functionName;
                option["functions"].push(_function);
            }
            // property
            option["property"] = {
                isRemovable:
                    _option["isRemovable"] == null
                        ? true
                        : _option["isRemovable"],
                label: label.get(
                    _option["property"],
                    _option["propertyLabelIndex"],
                    "auto" // 2021-01-04 used for fundamental properties
                ),
                tag: label.getTag(_option["property"], 0, "auto"),
                value: _option["property"],
            };
            // operators
            for (let j = 0; j < _option["operators"].length; j++) {
                option["operators"][j] = [];
                for (
                    var k = 0, lengthK = _option["operators"][j].length;
                    k < lengthK;
                    k++
                ) {
                    _operatorName = _option["operators"][j][k];
                    _operator = _operators[_operatorName];
                    _operator["value"] = _operatorName;
                    option["operators"][j].push(_operator);
                }
            }
            // transformation
            _transformations[_option["property"]] = null;
            if (
                "transformation" in _option &&
                _option["transformation"] !== undefined &&
                _option["transformation"] != null
            ) {
                _transformations[_option["property"]] =
                    _option["transformation"];
            }
            // value
            option["value"] = null;
            if ("value" in _option && _option["value"] != null) {
                option["value"] = _option["value"];
            }

            options.push(option);
        }

        var item = {
            columnHeaders: null,
            defaultTemplate: actionItems["default_template"],
            functions: _functions,
            presetTemplates: actionItems["preset_templates"],
            operators: _operators,
            options: options,
            transformations: _transformations,
        };

        return item;
    }

    _ranking(items: any) {
        var item: any = {
            create: this._factorConstraint(items["create"]),
            edit: this._factorConstraint(items["edit"]),
        };

        var columnHeaders: any = {};
        var _function = null;
        var _functions = null;
        var _operator = null;
        var _operators = null;
        var _item = null;
        var _property = null;
        for (
            var i = 0, lengthI = item["edit"]["options"].length;
            i < lengthI;
            i++
        ) {
            _item = item["edit"]["options"][i];

            _functions = _item["functions"];
            _operators = _item["operators"];
            _property = _item["property"]["value"];

            columnHeaders[_property] = {};

            // for each function there 1 operator
            for (var j = 0, lengthJ = _functions.length; j < lengthJ; j++) {
                _function = _functions[j];

                // 0 because ranking operators.length is always equal to 1
                _operator = _operators[j][0]["widget"]["options"];

                columnHeaders[_property][_function["value"]] = {
                    label: _function["label"],
                };

                for (var k = 0, lengthK = _operator.length; k < lengthK; k++) {
                    columnHeaders[_property][_function["value"]][
                        _operator[k]["value"]
                    ] = _operator[k]["label"];
                }
            }
        }

        item["edit"]["columnHeaders"] = columnHeaders;

        return item;
    }

    _selection(items: any) {
        var item = {
            create: this._factorConstraint(items["create"]),
            edit: this._factorConstraint(items["edit"]),
        };

        return item;
    }

    _smartBeta(items: any) {
        return this._factorConstraint(items);
    }

    /**
     *
     * @param {array<string>} snakeCaseStrings
     *
     * @ignore
     */
    _snakeCase2CamelCase(snakeCaseStrings: any) {
        var converted = [];

        var tokens = null;
        for (var i = 0, length = snakeCaseStrings.length; i < length; i++) {
            tokens = snakeCaseStrings[i].split("_");

            if (tokens.length > 1) {
                for (var j = 1, lengthJ = tokens.length; j < lengthJ; j++) {
                    tokens[j] =
                        tokens[j][0].toUpperCase() + tokens[j].substring(1);
                }
            }

            converted.push(tokens.join(""));
        }

        return converted;
    }
}
