import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useEffect, useMemo, useRef, useState } from "react";
import { Product } from "../../types/Product";
import { PTag } from "../../trendrating/api/PTag";
import { useFormatter } from "../../hooks/useFormatter";

type ProductDetailPerformanceChartsProps = Pick<Product, "strategyAnalytics">;

type ChartLimits = {
  min: number;
  max: number;
};

// type ChartRow = { period: string; v: number };

function prepareOptions(
  data: any[],
  limits: ChartLimits,
  title: string,
  categories: any[],
  benchmarkData?: any[],
  diffData?: any[]
) {
  // const r = data
  //     .map((item) => ({
  //         y: item.v,
  //         color: item.v > 0 ? "#008000" : "red",
  //         d: item.period,
  //         date: item.period,
  //     }))
  //     .sort(function (a, b) {
  //         return a.y > b.y ? 1 : b.y > a.y ? -1 : 0;
  //     });

  // let firstPositiveIndex = r.length - 1;
  // // Find first positive index
  // for (let i = 0; i < r.length; i++) {
  //     if (r[i].y >= 0) {
  //         firstPositiveIndex = i;
  //         break;
  //     }
  // }

  // const series: Highcharts.SeriesOptionsType[] = [
  //     {
  //         name: "anualized return",
  //         borderWidth: 0,
  //         color: "#0072C6",
  //         type: "column",
  //         //          maxPointWidth:1,
  //         data: r,
  //     },
  // ];
  // const options: Highcharts.Options = {
  //     chart: {
  //         borderColor: "#d3d3d3",
  //         borderWidth: 1,
  //         // height: 120,
  //         //            		    margin: 0,
  //         //            	       	spacing:0,
  //     },
  //     credits: { enabled: false },
  //     exporting: { enabled: false },
  //     plotOptions: {
  //         series: {
  //             cursor: "pointer",
  //             animation: false,
  //             connectNulls: false,
  //         },
  //     },
  //     title: {
  //         text: title,
  //         floating: true,
  //         style: { fontSize: "13px" },
  //     },
  //     legend: { enabled: false },
  //     series: series,
  //     xAxis: {
  //         labels: {
  //             style: { fontSize: "8px" },
  //             formatter: function () {
  //                 return "";
  //             },
  //         },
  //         lineWidth: 0,
  //         tickColor: "transparent",
  //         plotLines: [
  //             {
  //                 label: {
  //                     style: { fontSize: "10px" },
  //                     text:
  //                         target === "difference"
  //                             ? `${(positivePercentage * 100).toFixed(
  //                                   2
  //                               )}% of the quarters the portfolio outperforms the benchmark`
  //                             : `${(positivePercentage * 100).toFixed(
  //                                   2
  //                               )}% of positive quarter`,
  //                     verticalAlign: "bottom",
  //                     rotation: 0,
  //                     y: -16,
  //                     textAlign: "left",
  //                 },
  //                 value: firstPositiveIndex,
  //                 color: "#d3d3d3",
  //                 width: 1,
  //                 zIndex: 100,
  //             },
  //         ],
  //         visible: true,
  //     },
  //     yAxis: [
  //         {
  //             min: limits.min,
  //             max: limits.max,
  //             opposite: false,
  //             labels: {
  //                 style: { fontSize: "8px" },
  //                 formatter: function () {
  //                     return ((this.value as any) * 100).toFixed(2) + "%";
  //                 },
  //             },
  //             gridLineWidth: 0,
  //             title: { text: undefined },
  //             startOnTick: false,
  //             endOnTick: false,
  //             tickAmount: 7,
  //             tickInterval: 0.01,
  //             plotLines: [
  //                 {
  //                     value: 0,
  //                     color: "#d3d3d3",
  //                     width: 1,
  //                 },
  //             ],
  //         },
  //     ],
  //     tooltip: {
  //         formatter: function () {
  //             const options: any = this.point.options;

  //             const [year, quarter] = options.date.split("_");
  //             let quarterTooltip = "";
  //             switch (quarter) {
  //                 case "01":
  //                 case "02":
  //                 case "03": {
  //                     quarterTooltip = "Q1";
  //                     break;
  //                 }
  //                 case "04":
  //                 case "05":
  //                 case "06": {
  //                     quarterTooltip = "Q2";
  //                     break;
  //                 }
  //                 case "07":
  //                 case "08":
  //                 case "09": {
  //                     quarterTooltip = "Q3";
  //                     break;
  //                 }
  //                 case "10":
  //                 case "11":
  //                 case "12": {
  //                     quarterTooltip = "Q4";
  //                     break;
  //                 }
  //             }

  //             return `${year} ${quarterTooltip}: ${(this.y * 100).toFixed(
  //                 2
  //             )}%`;
  //         },
  //     },
  // };
  const { min, max } = limits;
  const options: Highcharts.Options = {
    chart: {
      borderWidth: 1,
      borderColor: "#C0D0E0",
      type: "column",
    },
    title: {
      text: "",
      floating: true,
      style: { fontSize: "13px" },
    },
    // animation: false,
    // marginTop: 0,
    // marginBottom: 0,
    // spacingBottom: 0,
    // spacingtop: 0,
    credits: { enabled: false },
    exporting: { enabled: false },
    legend: { enabled: true },
    tooltip: {
      formatter: function () {
        let result = `<table><tr><td>${this.points?.[0]?.x ?? ""}:</tr></td>`;
        this.points?.forEach((item) => {
          result += `<tr><td>${
            item.point.series.name
          }</td><td style="margin-right: 10px; text-align: right;">${(
            item.y * 100
          ).toFixed(2)}%</td></tr>`;
        });

        return result;
      },
      shared: true,
      useHTML: true,
    },
    plotOptions: { series: { animation: false, turboThreshold: 0 } },
    yAxis: [
      {
        labels: {
          formatter: function () {
            return ((this.value as number) * 100).toFixed(0) + "%";
          },
        },
        tickAmount: 4,
        tickInterval: 0.01,
        startOnTick: false,
        endOnTick: false,
        title: { text: "performance", style: { fontSize: "8px" } },
        min: min,
        max: max,
        plotLines: [{ value: 0.0, color: "darkgray", width: 1 }],
      },
    ],
    xAxis: { categories: categories },
    series: [
      {
        data: data,
        color: "#2A7092",
        type: "column",
        name: "Portfolio",
      },
    ],
  };

  if (benchmarkData != null) {
    options.series?.push(
      {
        data: benchmarkData,
        color: "#FFCC00",
        type: "column",
        name: "Benchmark",
      },
      {
        data: diffData,
        color: "#535962",
        type: "column",
        name: "Difference",
      }
    );
  }

  return options;
}

export function ProductDetailPerformanceCharts({
  strategyAnalytics,
}: ProductDetailPerformanceChartsProps) {
  const chartEntityRef = useRef(null);
  // const chartBenchmarkRef = useRef(null);
  // const chartDifferenceRef = useRef(null);
  const [optionsEntity, setOptionsEntity] = useState<any>();
  // const [optionsBenchmark, setOptionsBenchmark] = useState<any>();
  // const [optionsDifference, setOptionsDifference] = useState<any>();
  const analyticsExtractor = useMemo(
    () => new PTag(strategyAnalytics),
    [strategyAnalytics]
  );
  const formatter = useFormatter();
  const yearlyAnalytics = useMemo(
    () => ({
      H: analyticsExtractor.get("yearly_performances", "H"),
      B: analyticsExtractor.get("yearly_performances", "B"),
      D: analyticsExtractor.get("yearly_performances", "D"),
    }),
    [analyticsExtractor]
  );

  useEffect(() => {
    // const field = "yearly";
    // const fieldEntity = "strategyYearlyPerformance";
    // const fieldBenchmark = "benchmarkYearlyPerformance";
    // const fieldDifference = "deltaYearlyPerformance"

    let dataE: any = [];
    let dataB: any = [];
    let dataD: any = [];
    let min = 0;
    let max = 0;
    let categories = [];
    let hasBenchmark = false;

    categories = [
      ...Object.keys(yearlyAnalytics["H"]).map((item) =>
        formatter.custom("date", {
          options: {
            format: ["Y"],
            isMillisecond: false,
            notAvailable: {
              input: "",
              output: "",
            },
            separator: "_",
          },
          output: "HTML",
          value: item,
          valueHelper: null,
        })
      ),
    ];
    dataE = [...Object.values(yearlyAnalytics["H"])];

    hasBenchmark =
      yearlyAnalytics["B"] != null &&
      Object.values(yearlyAnalytics["B"])?.[0] != null;

    dataB = [...Object.values(yearlyAnalytics["B"])];
    dataD = [...Object.values(yearlyAnalytics["D"])];

    min = Math.min(Math.min(...dataE), Math.min(...dataB), Math.min(...dataD));
    max = Math.max(Math.max(...dataE), Math.max(...dataB), Math.max(...dataD));

    const limits: ChartLimits = { min, max };
    if (hasBenchmark) {
      setOptionsEntity(
        prepareOptions(dataE, limits, "Portfolio", categories, dataB, dataD)
      );
    } else {
      setOptionsEntity(prepareOptions(dataE, limits, "Portfolio", categories));
    }
  }, [formatter, yearlyAnalytics]);

  if (
    optionsEntity == null
    // optionsEntity == null ||
    // optionsBenchmark == null ||
    // optionsDifference == null
  ) {
    return <></>;
  }

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <div className="ratio ratio-analytics-charts pe-2">
          <div className="h-100">
            <HighchartsReact
              ref={chartEntityRef}
              className="h-100"
              options={optionsEntity}
              highcharts={Highcharts}
            />
          </div>
        </div>
      </div>
      {/* <div className="d-flex align-items-center mb-2">
                <div className="ratio ratio-analytics-charts pe-2">
                    <HighchartsReact
                        ref={chartBenchmarkRef}
                        className="h-100"
                        options={optionsBenchmark}
                        highcharts={Highcharts}
                    ></HighchartsReact>
                </div>
            </div>
            <div className="d-flex align-items-center mb-2">
                <div className="ratio ratio-analytics-charts pe-2">
                    <HighchartsReact
                        ref={chartDifferenceRef}
                        className="h-100"
                        options={optionsDifference}
                        highcharts={Highcharts}
                    ></HighchartsReact>
                </div>
            </div> */}
    </div>
  );
}
