import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// react-app-polyfills must be always at the top!

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { App } from "./App";
import { reportWebVitals } from "./reportWebVitals";
import { AppContextProvider } from "./AppContextProvider";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { CookiesProvider } from "react-cookie";

import i18n from "./i18n";
import { ProvideAuth } from "./hooks/useAuth";
import { KeenContextProvider } from "./KeenContextProvider";

import { enableAllPlugins } from "immer";

enableAllPlugins(); // Fix IE11 issue

// IE11 polyfill
(function () {
    // If exists, CustomEvent as an object is wrong in IE11
    if (typeof window.CustomEvent === "function") return false;

    function CustomEvent(event: any, params: any) {
        params = params || {
            bubbles: false,
            cancelable: false,
            detail: undefined,
        };
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent(
            event,
            params.bubbles,
            params.cancelable,
            params.detail
        );
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    (window as any).CustomEvent = CustomEvent;
})();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0, // 5 * 60 * 1000, // 5 minutes,
            refetchOnWindowFocus: false,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
                <KeenContextProvider>
                    <AppContextProvider>
                        <ProvideAuth>
                            <CookiesProvider>
                                <App />
                            </CookiesProvider>
                        </ProvideAuth>
                    </AppContextProvider>
                </KeenContextProvider>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
