import { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormatter } from "../../hooks/useFormatter";
import { paths } from "../../types/Defaults";
import { ProductInfo, ProductItem } from "../../types/Product";

function findYearsLabel() {
  let years = `2011-${new Date().getFullYear()}`; // Constant

  return years;
}

function prepareDataFromPosition(
  productByPosition: ProductInfo,
  formatter: any
) {
  const annualizedBenchmark =
    productByPosition?.analytics?.aggregated?.total?.benchmark
      ?.AnnualizedRateOfReturn ?? 0;
  const annualizedDifference =
    productByPosition?.analytics?.aggregated?.total?.diff
      ?.AnnualizedRateOfReturn ?? 0;
  const annualizedPortfolio =
    productByPosition?.analytics?.aggregated?.total?.equity
      ?.AnnualizedRateOfReturn ?? 0;

  let years = findYearsLabel();

  return {
    key: `${productByPosition.geo}-${productByPosition.theme}-${productByPosition.method}-${productByPosition.positions}`,
    id: productByPosition.id,
    name: productByPosition.name,
    item: productByPosition,
    annualizedPerformance: {
      benchmark: formatter.custom("number", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: annualizedBenchmark,
        valueHelper: null,
      }),
      difference: formatter.custom("number", {
        options: {
          colored: "positive",
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: annualizedDifference,
        valueHelper: null,
      }),
      portfolio: formatter.custom("number", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: annualizedPortfolio,
        valueHelper: null,
      }),
      years: years,
    },
    positions: productByPosition.positions,
  };
}

type ProductListItemBodyProps = {
  product: ProductItem;
  totalRows: number;
};

export const ProductListItemBody = ({
  product,
  totalRows,
}: ProductListItemBodyProps) => {
  const [data, setData] = useState<any[]>();
  const formatter = useFormatter();

  let descriptions = [];
  if (product.isSingle) {
    descriptions = [...(product.singleItem.description ?? [])];
  } else {
    // Get first item as description
    descriptions = [
      ...(product.grouped[product.positions[0]].description ?? []),
    ];
  }

  // Used to adjust maximum height for items
  const rowDifference = totalRows - descriptions.length;
  for (let i = 0; i < rowDifference; i++) {
    descriptions.push("");
  }

  useEffect(() => {
    if (product == null) {
      return;
    }

    if (product.isSingle) {
      setData([prepareDataFromPosition(product.singleItem, formatter)]);
    }

    // if (product.isSingle) {
    //   setData([prepareDataFromPosition(product.singleItem, formatter)]);
    // } else {
    //   if (product.grouped == null) {
    //     return;
    //   }
    //   setData(
    //     product.positions.map((position) =>
    //       prepareDataFromPosition(product.grouped[String(position)], formatter)
    //     )
    //   );
    // }
  }, [formatter, product, product.positions]);

  if (data == null) {
    return <></>;
  }

  return (
    <div className="row g-2">
      <div className="card-content px-2 pt-2">
        <ul className="text-muted">
          {descriptions.map((rule: any, index: number) => (
            <li
              className={rule === "" ? "no-item" : ""}
              key={`rule-${product.geo}-${product.theme}-${index}`}
              dangerouslySetInnerHTML={{
                __html: rule === "" ? "&nbsp;" : rule,
              }}
            />
          ))}
        </ul>
      </div>
      <div className="card-content px-2 pb-2">
        <div className="d-flex align-items-center justify-content-evenly">
          {data.map((datum) => (
            <div key={`${datum.geo}-${datum.theme}-${datum.positions}`}>
              <OverlayTrigger
                key={`${datum.geo}-${datum.theme}-${datum.positions}-overlay`}
                placement="top"
                overlay={
                  <Popover
                    id={`${datum.geo}-${datum.theme}-${datum.positions}-popover`}
                  >
                    <Popover.Header as="h3">
                      Annualized performance{" "}
                      {datum.annualizedPerformance.years !== ""
                        ? `(${datum.annualizedPerformance.years})`
                        : ""}
                    </Popover.Header>
                    <Popover.Body>
                      <table className="table table-bordered text-start">
                        <tbody>
                          <tr className="pb-2">
                            <td className="pe-3">Portfolio:</td>
                            <td
                              className="text-end"
                              dangerouslySetInnerHTML={{
                                __html: datum.annualizedPerformance.portfolio,
                              }}
                            />
                          </tr>
                          <tr className="p-2">
                            <td className="pe-3">
                              {`${datum?.item?.rules?.benchmark}` ??
                                "Benchmark"}
                              :
                            </td>
                            <td
                              className="text-end"
                              dangerouslySetInnerHTML={{
                                __html: datum.annualizedPerformance.benchmark,
                              }}
                            />
                          </tr>
                          <tr className="pb-2">
                            <td className="pe-3">Difference:</td>
                            <td
                              className="text-end"
                              dangerouslySetInnerHTML={{
                                __html: datum.annualizedPerformance.difference,
                              }}
                            />
                          </tr>
                        </tbody>
                      </table>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div
                  className="text-center pb-2 selectable-item"
                  dangerouslySetInnerHTML={{
                    __html: datum.annualizedPerformance.portfolio,
                  }}
                />
              </OverlayTrigger>
              <Link
                className="btn btn-outline-primary btn-sm"
                title={datum.name}
                to={paths.productDetail.replace(":id", datum.id)}
              >
                {datum.positions !== false
                  ? `${datum.positions} Stocks`
                  : "Portfolio"}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
