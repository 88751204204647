import { useTranslation } from "react-i18next";
import { useFormatter } from "../../hooks/useFormatter";
import { useMemo } from "react";
import { PTag } from "../../trendrating/api/PTag";

type ProductDetailAnalyticsPerformanceGridProps = {
  analyticsDetail: any;
  priceListLastDate: any;
  strategyAnalytics: any;
};

export function ProductDetailAnalyticsPerformanceGrid({
  priceListLastDate,
  strategyAnalytics,
}: ProductDetailAnalyticsPerformanceGridProps) {
  const formatter = useFormatter();
  const { i18n } = useTranslation();
  const analytics = useMemo(
    () => new PTag(strategyAnalytics),
    [strategyAnalytics]
  );

  const formatPerformance = (value: any) => {
    return formatter.custom("number", {
      options: {
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: null,
    });
  };

  const formatPerformanceColor = (value: any) => {
    return formatter.custom("number", {
      options: {
        colored: "positive",
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: null,
    });
  };

  let priceListLastDateFormatted = null;
  if (priceListLastDate != null) {
    priceListLastDateFormatted = formatter.custom("date", {
      options: {
        format: i18n.language === "en-US" ? ["m", "D", "Y"] : ["D", "m", "Y"],
        isMillisecond: false,
        notAvailable: {
          input: "",
          output: "",
        },
        separator: "/",
      },
      output: "HTML",
      value: priceListLastDate,
      valueHelper: null,
    });
  }

  return (
    <div className="pt-3">
      <h5>Performance as of {priceListLastDateFormatted}</h5>
      <table className="table-compact">
        <tbody>
          <tr>
            <th></th>
            {/* <th>1 Day</th>
                        <th>1 Week</th> */}
            <th>1 Month</th>
            <th>3 Months</th>
            <th>6 Months</th>
            <th>1 Year</th>
            <th>2 Years*</th>
            <th>3 Years*</th>
            <th>5 Years*</th>
            <th>10 Years*</th>
          </tr>
          <tr>
            <th>Portfolio</th>
            {/* <td>
                                {formatPerformance(
                                    portfoliosPerformances.DAILY
                                )}
                            </td>
                            <td>
                                {formatPerformance(
                                    portfoliosPerformances.WEEKLY
                                )}
                            </td> */}
            <td>
              {formatPerformance(analytics.get("one_months_return", "H"))}
            </td>
            <td>
              {formatPerformance(analytics.get("three_months_return", "H"))}
            </td>
            <td>
              {formatPerformance(analytics.get("six_months_return", "H"))}
            </td>
            <td>{formatPerformance(analytics.get("one_year_return", "H"))}</td>
            <td>
              {formatPerformance(
                analytics.get("two_years_return_annualized", "H")
              )}
            </td>
            <td>
              {formatPerformance(
                analytics.get("three_years_return_annualized", "H")
              )}
            </td>
            <td>
              {formatPerformance(
                analytics.get("five_years_return_annualized", "H")
              )}
            </td>
            <td>
              {formatPerformance(
                analytics.get("ten_years_return_annualized", "H")
              )}
            </td>
          </tr>

          <tr>
            <th>Benchmark</th>
            {/* <td>
                                {formatPerformance(benchmarkPerformances.DAILY)}
                            </td>
                            <td>
                                {formatPerformance(
                                    benchmarkPerformances.WEEKLY
                                )}
                            </td> */}
            <td>
              {formatPerformance(analytics.get("one_months_return", "B"))}
            </td>
            <td>
              {formatPerformance(analytics.get("three_months_return", "B"))}
            </td>
            <td>
              {formatPerformance(analytics.get("six_months_return", "B"))}
            </td>
            <td>{formatPerformance(analytics.get("one_year_return", "B"))}</td>
            <td>
              {formatPerformance(
                analytics.get("two_years_return_annualized", "B")
              )}
            </td>
            <td>
              {formatPerformance(
                analytics.get("three_years_return_annualized", "B")
              )}
            </td>
            <td>
              {formatPerformance(
                analytics.get("five_years_return_annualized", "B")
              )}
            </td>
            <td>
              {formatPerformance(
                analytics.get("ten_years_return_annualized", "B")
              )}
            </td>
          </tr>

          <tr>
            <th>Difference</th>
            {/* <td>
                                {formatPerformance(
                                    differencePerformances.DAILY
                                )}
                            </td>
                            <td
                                dangerouslySetInnerHTML={{
                                    __html: formatPerformance(
                                        differencePerformances.WEEKLY
                                    ),
                                }}
                            ></td> */}
            <td
              dangerouslySetInnerHTML={{
                __html: formatPerformanceColor(
                  analytics.get("one_months_return", "D")
                ),
              }}
            ></td>
            <td
              dangerouslySetInnerHTML={{
                __html: formatPerformanceColor(
                  analytics.get("three_months_return", "D")
                ),
              }}
            ></td>
            <td
              dangerouslySetInnerHTML={{
                __html: formatPerformanceColor(
                  analytics.get("six_months_return", "D")
                ),
              }}
            ></td>
            <td
              dangerouslySetInnerHTML={{
                __html: formatPerformanceColor(
                  analytics.get("one_year_return", "D")
                ),
              }}
            ></td>
            <td
              dangerouslySetInnerHTML={{
                __html: formatPerformanceColor(
                  analytics.get("two_years_return_annualized", "D")
                ),
              }}
            ></td>
            <td
              dangerouslySetInnerHTML={{
                __html: formatPerformanceColor(
                  analytics.get("three_years_return_annualized", "D")
                ),
              }}
            ></td>
            <td
              dangerouslySetInnerHTML={{
                __html: formatPerformanceColor(
                  analytics.get("five_years_return_annualized", "D")
                ),
              }}
            ></td>
            <td>
              {formatPerformance(
                analytics.get("ten_years_return_annualized", "D")
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="text-muted text-smaller pt-2">
        * Annualized performance
      </div>
    </div>
  );
}
