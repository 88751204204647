import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { normalizeAndCutOutliers } from "../../hooks/data/useProduct";
import { PTag } from "../../trendrating/api/PTag";
import { ProductDetailAnalyticsGridMonthly } from "./ProductDetailAnalyticsGridMonthly";
import { ProductDetailAnalyticsGridQuarterly } from "./ProductDetailAnalyticsGridQuarterly";
import { ProductDetailAnalyticsGridYearly } from "./ProductDetailAnalyticsGridYearly";

type ProductDetailAnalyticsGridProps = {
  info: any;
  strategyAnalytics: any;
};

type AnalyticsTableRowData = {
  timeFrame: string;
  strategyPerformance: number | null;
  strategyMaxDrawdown: number | null;
  strategyVolatility: number | null;
  benchmarkPerformance: number | null;
  benchmarkMaxDrawdown: number | null;
  benchmarkVolatility: number | null;
  deltaPerformance: number | null;
  deltaMaxDrawdown: number | null;
  deltaVolatility: number | null;
  turnover: number | null;
  _s_normalizationThreshold: number | null;
};

export const ProductDetailAnalyticsGrid = forwardRef(
  ({ strategyAnalytics, info }: ProductDetailAnalyticsGridProps, ref) => {
    const yearlyRef = useRef<Tabulator | null>(null);
    const monthlyRef = useRef<Tabulator | null>(null);
    const quarterlyRef = useRef<Tabulator | null>(null);
    const analytics = useMemo(
      () => new PTag(strategyAnalytics),
      [strategyAnalytics]
    );

    const getAnalyticsByTimeframe = useCallback(
      (timeframe: "yearly" | "quarterly" | "monthly") => {
        const ANALYTICS_DICT: any = {
          yearly: {
            perf: "yearly_performances",
            drawdown: "yearly_drawdown",
            std: "yearly_standard_dev",
          },
          quarterly: {
            perf: "quarterly_performances",
            drawdown: "quarterly_drawdown",
            std: "quarterly_standard_dev",
          },
          monthly: {
            perf: "monthly_performances",
            drawdown: "monthly_drawdown",
            std: "monthly_standard_dev",
          },
        };

        const portfolioPerf = analytics.get(
          ANALYTICS_DICT[timeframe]["perf"],
          "H"
        );
        const benchmarkPerf = analytics.get(
          ANALYTICS_DICT[timeframe]["perf"],
          "B"
        );
        const diffPerf = analytics.get(ANALYTICS_DICT[timeframe]["perf"], "D");

        const portfolioDrawDown = analytics.get(
          ANALYTICS_DICT[timeframe]["drawdown"],
          "H"
        );
        const benchmarkDrawDown = analytics.get(
          ANALYTICS_DICT[timeframe]["drawdown"],
          "B"
        );
        const diffDrawDown = analytics.get(
          ANALYTICS_DICT[timeframe]["drawdown"],
          "D"
        );

        const portfolioStd = analytics.get(
          ANALYTICS_DICT[timeframe]["std"],
          "H"
        );
        const benchmarkStd = analytics.get(
          ANALYTICS_DICT[timeframe]["std"],
          "B"
        );
        const diffStd = analytics.get(ANALYTICS_DICT[timeframe]["std"], "D");

        return {
          portfolioPerf,
          benchmarkPerf,
          diffPerf,
          portfolioDrawDown,
          benchmarkDrawDown,
          diffDrawDown,
          portfolioStd,
          benchmarkStd,
          diffStd,
        };
      },
      [analytics]
    );

    const formatAnalytics = useCallback(
      (timeframe: "yearly" | "quarterly" | "monthly") => {
        const {
          portfolioPerf,
          benchmarkPerf,
          diffPerf,
          portfolioDrawDown,
          benchmarkDrawDown,
          diffDrawDown,
          portfolioStd,
          benchmarkStd,
          diffStd,
        } = getAnalyticsByTimeframe(timeframe);

        const aggregateAnalytics: AnalyticsTableRowData[] = [];

        for (const key in portfolioPerf) {
          aggregateAnalytics.push({
            timeFrame: key,
            strategyPerformance: portfolioPerf[key],
            strategyMaxDrawdown: portfolioDrawDown[key],
            strategyVolatility: portfolioStd[key],
            benchmarkPerformance: benchmarkPerf[key],
            benchmarkMaxDrawdown: benchmarkDrawDown[key],
            benchmarkVolatility: benchmarkStd[key],
            deltaPerformance: diffPerf[key],
            deltaMaxDrawdown: diffDrawDown[key],
            deltaVolatility: diffStd[key],
            turnover: null,
            _s_normalizationThreshold: null,
          });
        }

        const normalized = normalizeAndCutOutliers(
          aggregateAnalytics,
          "deltaPerformance"
        );

        return normalized;
      },
      [getAnalyticsByTimeframe]
    );

    const yearlyAnalytics = useMemo(() => {
      return formatAnalytics("yearly");
    }, [formatAnalytics]);

    const quarterlyAnalytics = useMemo(() => {
      return formatAnalytics("quarterly");
    }, [formatAnalytics]);

    const monthlyAnalytics = useMemo(() => {
      return formatAnalytics("monthly");
    }, [formatAnalytics]);

    useImperativeHandle(ref, () => ({
      refresh() {
        setTimeout(() => {
          yearlyRef.current?.redraw(true);
          monthlyRef.current?.redraw(true);
          quarterlyRef.current?.redraw(true);
        });
      },
    }));

    const handleSelect = (eventKey: any, event?: any) => {
      event.preventDefault();
      if (eventKey === "yearly") {
        setTimeout(() => yearlyRef.current?.redraw(true));
      } else if (eventKey === "monthly") {
        setTimeout(() => monthlyRef.current?.redraw(true));
      } else if (eventKey === "quarterly") {
        setTimeout(() => quarterlyRef.current?.redraw(true));
      }
    };

    return (
      <div className="mt-3 pb-2">
        <Container fluid className="g-3">
          <Tab.Container
            id="product-analytics-tab"
            defaultActiveKey="yearly"
            onSelect={handleSelect}
            transition={false}
          >
            <div className="d-flex align-items-center">
              <h5 className="me-4 mb-0">Performance</h5>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="yearly">Yearly</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="monthly">Monthly</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="quarterly">Quarterly</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Row>
              <Col sm={12}>
                <Tab.Content className="">
                  <Tab.Pane eventKey="yearly">
                    <ProductDetailAnalyticsGridYearly
                      analyticsDetail={yearlyAnalytics}
                      callback={(instance: any) =>
                        (yearlyRef.current = instance)
                      }
                      info={info}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="monthly">
                    <ProductDetailAnalyticsGridMonthly
                      analyticsDetail={monthlyAnalytics}
                      callback={(instance: any) =>
                        (monthlyRef.current = instance)
                      }
                      info={info}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="quarterly">
                    <ProductDetailAnalyticsGridQuarterly
                      analyticsDetail={quarterlyAnalytics}
                      callback={(instance: any) =>
                        (quarterlyRef.current = instance)
                      }
                      info={info}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    );
  }
);
