import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useImmerReducer } from "use-immer";
import { useInput } from "../hooks/useInput";

type ChangePasswordProps = {
    userData: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        userName: string;
        role: string;
        profile: string;
        type: string;
        firm: string;
        creationTime: Date;
        active: boolean;
        country: string;
        products: string;
        contractType: string;
        productMax: number;
        billingStart: string;
        conversionDate: string;
        owner: string;
        stripeId: string;
    };
    changeUserPassword: Function;
};

type Action =
    | {
          type: "VALIDATE_FORM";
          payload: {
              password: string;
              confirmPassword: string;
          };
      }
    | {
          type: "SET_LOADING";
          payload: boolean;
      }
    | {
          type: "SET_SUCCESS";
          payload: boolean;
      }
    | {
          type: "SET_ERROR_MESSAGE";
          payload: string;
      }
    | { type: "SET_SHOW_MESSAGE"; payload: boolean };

type ValidationState = {
    password: {
        isValid: boolean;
        message: string;
    };
    confirmPassword: {
        isValid: boolean;
        message: string;
    };
    formStatus: {
        isValid: boolean;
        message: string;
    };
    loadingStatus: boolean;
    success: boolean;
    errorMessage: string;
    showMessage: boolean;
};

const reducer = (draft: ValidationState, action: Action) => {
    switch (action.type) {
        case "VALIDATE_FORM":
            const {
                password: newPassword,
                confirmPassword: confirmNewPassword,
            } = action.payload;
            if (
                newPassword.length &&
                confirmNewPassword.length &&
                confirmNewPassword === newPassword
            ) {
                draft.password = {
                    isValid: true,
                    message: "",
                };
                draft.confirmPassword = {
                    isValid: true,
                    message: "",
                };
                draft.formStatus = {
                    isValid: true,
                    message: "",
                };
                draft.errorMessage = "";
                draft.showMessage = false;
            } else if (
                !newPassword.length ||
                !confirmNewPassword.length ||
                (!newPassword.length && !confirmNewPassword.length)
            ) {
                if (!newPassword.length && !confirmNewPassword.length) {
                    draft.password = {
                        isValid: false,
                        message: "This field is required",
                    };
                    draft.confirmPassword = {
                        isValid: false,
                        message: "This field is required",
                    };
                    draft.formStatus = {
                        isValid: false,
                        message: "All fields are required",
                    };
                    draft.errorMessage = "All fields are required";
                } else if (!newPassword.length) {
                    draft.password = {
                        isValid: false,
                        message: "This field is required",
                    };
                    draft.confirmPassword = {
                        isValid: true,
                        message: "",
                    };
                    draft.formStatus = {
                        isValid: false,
                        message: "New password field is required",
                    };
                    draft.errorMessage = "New password field is required";
                } else {
                    draft.password = {
                        isValid: true,
                        message: "",
                    };
                    draft.confirmPassword = {
                        isValid: false,
                        message: "This field is required",
                    };
                    draft.formStatus = {
                        isValid: false,
                        message: "Confirm password field is required",
                    };
                    draft.errorMessage = "Confirm password field is required";
                }
            } else {
                draft.password = {
                    isValid: true,
                    message: "",
                };
                draft.confirmPassword = {
                    isValid: false,
                    message: "Passwords do not match",
                };
                draft.formStatus = {
                    isValid: false,
                    message: "Passwords must to be equal",
                };
                draft.errorMessage = "Passwords must to be equal";
            }
            break;
        case "SET_LOADING":
            draft.loadingStatus = action.payload;
            break;
        case "SET_SUCCESS":
            draft.success = action.payload;
            break;
        case "SET_ERROR_MESSAGE":
            draft.errorMessage = action.payload;
            break;
        case "SET_SHOW_MESSAGE":
            draft.showMessage = action.payload;
            break;
        default:
            return draft;
    }
};

export function ChangePassword({
    userData,
    changeUserPassword,
}: ChangePasswordProps) {
    const [checkValidation, startingCheckValidation] = useState(false);

    const { id } = userData;

    const componentState = {
        password: {
            isValid: false,
            message: "",
        },
        confirmPassword: {
            isValid: false,
            message: "",
        },
        formStatus: {
            isValid: false,
            message: "",
        },
        loadingStatus: false,
        success: false,
        errorMessage: "",
        showMessage: false,
    };

    const newPassword = useInput("");
    const confirmNewPassword = useInput("");

    const [state, dispatch] = useImmerReducer(reducer, componentState);

    useEffect(() => {
        dispatch({
            type: "VALIDATE_FORM",
            payload: {
                password: newPassword.value,
                confirmPassword: confirmNewPassword.value,
            },
        });
    }, [confirmNewPassword.value, dispatch, newPassword.value]);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        startingCheckValidation(true);
        dispatch({ type: "SET_SUCCESS", payload: false });
        dispatch({ type: "SET_SHOW_MESSAGE", payload: true });
        if (state.formStatus.isValid) {
            try {
                dispatch({ type: "SET_LOADING", payload: true });
                await changeUserPassword({
                    id,
                    password: confirmNewPassword.value,
                });
                dispatch({ type: "SET_LOADING", payload: false });
                dispatch({ type: "SET_SUCCESS", payload: true });
                dispatch({ type: "SET_ERROR_MESSAGE", payload: "" });
            } catch (err) {
                if (err) {
                    dispatch({ type: "SET_LOADING", payload: false });
                    dispatch({ type: "SET_SUCCESS", payload: false });
                    dispatch({
                        type: "SET_ERROR_MESSAGE",
                        payload: "Something goes wrong please retry later",
                    });
                }
            }
        }
    };

    const handleShowMessage = () => {
        if (state.loadingStatus) {
            return (
                <div className="alert alert-primary fade show" role="alert">
                    Loading ...
                </div>
            );
        } else {
            if (!state.success) {
                return (
                    <div className="alert alert-danger" role="alert">
                        {state.errorMessage}
                    </div>
                );
            } else {
                return (
                    <div className="alert alert-success" role="alert">
                        Your profile has been correctly updated
                    </div>
                );
            }
        }
    };

    const showMessage = handleShowMessage();

    return (
        <>
            <Row className="mt-5">
                <Col sm={12}>
                    <Row className="mt-2">
                        <Col>
                            <div className="form-signin">
                                <h1 className="h3 mb-3 fw-normal text-center">
                                    Change your Password
                                </h1>
                                {checkValidation && state.showMessage
                                    ? showMessage
                                    : null}
                                <form
                                    onSubmit={handleSubmit}
                                    className="mt-3 mb-3"
                                    id="change-password-form"
                                >
                                    <Row className="g-3">
                                        <Col sm={6}>
                                            <label
                                                className="text-light input-label"
                                                htmlFor="floatingNewPassword"
                                            >
                                                New Password
                                            </label>
                                            <input
                                                value={newPassword.value}
                                                onChange={newPassword.onchange}
                                                type="password"
                                                id="floatingNewPassword"
                                                name="email"
                                                autoComplete="off"
                                                placeholder=""
                                                className={`form-control
                                                    ${
                                                        checkValidation
                                                            ? state.password
                                                                  .isValid
                                                                ? "valid-state"
                                                                : "input-error-state"
                                                            : ""
                                                    }
                                                }`}
                                            />
                                            {checkValidation
                                                ? !state.password.isValid && (
                                                      <div className="validation-error-message">
                                                          <p>
                                                              {
                                                                  state.password
                                                                      .message
                                                              }
                                                          </p>
                                                      </div>
                                                  )
                                                : null}
                                        </Col>
                                        <Col sm={6}>
                                            <label
                                                className="text-light input-label"
                                                htmlFor="floatingConfirmPassword"
                                            >
                                                Confirm Password
                                            </label>
                                            <input
                                                value={confirmNewPassword.value}
                                                onChange={
                                                    confirmNewPassword.onchange
                                                }
                                                type="password"
                                                id="floatingConfirmPassword"
                                                name="email"
                                                autoComplete="off"
                                                placeholder=""
                                                className={`form-control ${
                                                    checkValidation
                                                        ? state.confirmPassword
                                                              .isValid
                                                            ? "valid-state"
                                                            : "input-error-state"
                                                        : ""
                                                }`}
                                            />
                                            {checkValidation
                                                ? !state.confirmPassword
                                                      .isValid && (
                                                      <div className="validation-error-message">
                                                          <p>
                                                              {
                                                                  state
                                                                      .confirmPassword
                                                                      .message
                                                              }
                                                          </p>
                                                      </div>
                                                  )
                                                : null}
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        className="w-50 me-2 btn btn-outline-light mt-2"
                        form="change-password-form"
                        type="submit"
                        disabled={state.loadingStatus ? true : false}
                    >
                        Update password
                    </Button>
                </Col>
            </Row>
        </>
    );
}
