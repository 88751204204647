import KeenTracking from "keen-tracking";
import MobileDetect from "mobile-detect";
import { createContext, ReactNode, useEffect, useState } from "react";

export const KeenContext = createContext<KeenTracking | null | undefined>(
    undefined
);

type KeenContextProviderProps = {
    children?: ReactNode;
};

export const KeenContextProvider = ({ children }: KeenContextProviderProps) => {
    const [keen, setKeen] = useState<KeenTracking | null>(null);

    useEffect(() => {
        const md = new MobileDetect(window.navigator.userAgent);
        if (!md.is("bot")) {
            const client = new KeenTracking({
                projectId: "61696e936d011004768ba882",
                writeKey:
                    "1bd536cd28131be2e0905199b306972874af2b1d3fbe4b175df93226434587c314e9ba66bc1d95297fa67448a0616b45c0c0606d94c9ca10de2baf224752e27c3f7d9b3b543a61023f7d3ef608f76ebafcacad085fec0738f24cb29303a96558",
            });

            const helpers = (KeenTracking as any).helpers;
            // const utils = (KeenTracking as any).utils;

            // TODO GDPR WARNING, DO NOT ENABLE
            // const sessionCookie = utils.cookie("tr-portfolios");
            // if (!sessionCookie.get("guest_id")) {
            //     sessionCookie.set("guest_id", helpers.getUniqueId());
            // }

            /* eslint-disable no-template-curly-in-string */
            client.extendEvents(() => {
                return {
                    geo: {
                        ip_address: "${keen.ip}",
                        info: {
                            /* Enriched data from the API will be saved here */
                            /* https://keen.io/docs/api/?javascript#ip-to-geo-parser */
                        },
                    },
                    // page: {
                    //     title: document.title,
                    //     url: document.location.href,
                    //     info: {
                    //         /* Enriched */
                    //     },
                    // },
                    // referrer: {
                    //     url: document.referrer,
                    //     info: {
                    //         /* Enriched */
                    //     },
                    // },
                    tech: {
                        browser: helpers.getBrowserProfile(),
                        user_agent: "${keen.user_agent}",
                        info: {
                            /* Enriched */
                        },
                        device_type: md.tablet()
                            ? "tablet"
                            : md.mobile()
                            ? "mobile"
                            : "desktop",
                    },
                    // time: helpers.getDatetimeIndex(),
                    // visitor: {
                    //     guest_id: sessionCookie.get("guest_id"),
                    //     /* Include additional visitor info here */
                    // },
                    keen: {
                        addons: [
                            {
                                name: "keen:ip_to_geo",
                                input: {
                                    ip: "geo.ip_address",
                                    remove_ip_property: true,
                                },
                                output: "geo.info",
                            },
                            {
                                name: "keen:ua_parser",
                                input: {
                                    ua_string: "tech.user_agent",
                                },
                                output: "tech.info",
                            },
                            // {
                            //     name: "keen:url_parser",
                            //     input: {
                            //         url: "page.url",
                            //     },
                            //     output: "page.info",
                            // },
                            // {
                            //     name: "keen:referrer_parser",
                            //     input: {
                            //         referrer_url: "referrer.url",
                            //         page_url: "page.url",
                            //     },
                            //     output: "referrer.info",
                            // },
                        ],
                    },
                };
            });

            setKeen(client);
        }
    }, []);

    return <KeenContext.Provider value={keen}>{children}</KeenContext.Provider>;
};
