import { MouseEvent, useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useFormatter } from "../hooks/useFormatter";
import { useKeen } from "../hooks/useKeen";
import { useProperties } from "../hooks/useProperties";

type DownloadCsvProps = {
    download: string;
    data: any[];
    headers: any;
    productId: string;
    title?: string;
    uFEFF?: boolean;
};

const isSafari = () =>
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isIE11 = () =>
    window.navigator && (window.navigator as any).msSaveOrOpenBlob;

const getSeparator = () => {
    var list = ["a", "b"];
    var listLocaleString = list.toLocaleString();
    return listLocaleString[1];
};

const elementOrEmpty = (element: any) =>
    typeof element === "undefined" || element === null ? "" : element;

const toCSV = (
    lines: string[][],
    headers: any,
    separator: string,
    enclosingCharacter: string
): string => {
    const output = headers
        ? [headers.map((header: any) => header.label), ...lines]
        : lines;

    return output
        .filter((e) => e)
        .map((row) =>
            row
                .map((element: any) => elementOrEmpty(element))
                .map(
                    (column: any) =>
                        `${enclosingCharacter}${column}${enclosingCharacter}`
                )
                .join(separator)
        )
        .join(`\n`);
};

const buildCsv = (
    data: any[],
    formatter: any,
    properties: any,
    headers: any[] = [],
    separator: string = getSeparator(),
    enclosingCharacter: string = '"'
): string => {
    if (formatter == null || properties == null) {
        // Not ready
        return "";
    }

    let csvHeader = [];
    for (const header of headers) {
        csvHeader.push({
            label: properties.get(header),
            property: header,
        });
    }

    let lines: string[][] = [];
    for (const datum of data) {
        let line: string[] = [];
        for (const csvHeaderItem of csvHeader) {
            line.push(
                formatter.text(
                    csvHeaderItem["property"],
                    "table",
                    datum[csvHeaderItem["property"]],
                    datum,
                    datum.type
                )
            );
        }
        lines.push(line);
    }

    return toCSV(lines, csvHeader, separator, enclosingCharacter);
};

const buildUriFromCsv = (
    csv: string,
    isLogged: boolean,
    uFEFF: boolean
): string => {
    if (!isLogged) {
        return "#";
    }

    if (isIE11()) {
        return "#"; // IE11 does not use anything
    }

    const URL = window.URL || window.webkitURL;
    const type = isSafari() ? "application/csv" : "text/csv";
    if (typeof URL.createObjectURL === "undefined") {
        return `data:${type};charset=utf-8,${uFEFF ? "\uFEFF" : ""}${csv}`;
    } else {
        const blob = new Blob([uFEFF ? "\uFEFF" : "", csv], { type });
        return URL.createObjectURL(blob);
    }
};

export const DownloadCsv = ({
    download,
    data,
    headers,
    productId,
    title,
    uFEFF = false,
}: DownloadCsvProps) => {
    const [csv, setCsv] = useState<string>("");
    const [href, setHref] = useState<string>("#");
    const formatter = useFormatter();
    const properties = useProperties();
    const { isLogged, user } = useAuth();
    const { recordEvent } = useKeen();

    const handleDownloadCsv = (event: MouseEvent) => {
        if (!isLogged) {
            // a Lock component must be used to wrap this download
            // This alert is just a fallback
            alert("Please register to unlock CSV download.");
            return false;
        }

        recordEvent("download-csv", productId, user);

        // Handle legacy
        // If this browser is IE 11, it does not support the `download` attribute
        if (isIE11()) {
            event.preventDefault();
            const blob = new Blob([uFEFF ? "\uFEFF" : "", csv]);
            (window.navigator as any).msSaveBlob(blob, download);
            return false;
        }
    };

    useEffect(() => {
        setCsv(buildCsv(data, formatter, properties, headers));
    }, [data, formatter, headers, properties]);

    useEffect(() => {
        setHref(buildUriFromCsv(csv, isLogged, uFEFF));
    }, [csv, isLogged, uFEFF]);

    return (
        <a
            className="btn btn-outline-primary"
            href={href}
            download={isLogged ? download : undefined}
            onClick={handleDownloadCsv}
            target="_self"
        >
            <i className="bi-file-earmark-arrow-down"></i>{" "}
            {title ?? "Download .CSV"}
        </a>
    );
};
