import { useState } from "react";
import { useCookies } from "react-cookie";
import { HomeDisclaimer } from "../pages/HomeDisclaimer";
import { useAuth } from "./useAuth";

export const useDisclaimer = () => {
    const { isReady, user } = useAuth();
    const [cookies, setCookie] = useCookies(["disclaimer"]);
    const hasCookie = cookies.disclaimer === "true" ? true : false;
    const [isDisclaimerAccepted, setDisclaimerAccepted] =
        useState<boolean>(hasCookie);

    if (isReady && user && !isDisclaimerAccepted) {
        setDisclaimerAccepted(true);
    }

    const handleDisclaimer = () => {
        setCookie("disclaimer", "true", { path: "/" });
        setDisclaimerAccepted(true);
    };

    return (
        <HomeDisclaimer
            handleDisclaimer={handleDisclaimer}
            isDisclaimerAccepted={isDisclaimerAccepted}
        />
    );
};
