import { useEffect, useState } from "react";
import { DispersionDetail } from "../../pages/Capture";
import { Chart } from "../Chart";

type CaptureChartProps = {
    className?: string;
    dispersion?: DispersionDetail;
    type: "winners" | "losers";
};

export function CaptureChart({
    className,
    dispersion,
    type,
}: CaptureChartProps) {
    const [options, setOptions] = useState<Highcharts.Options>({});

    useEffect(() => {
        var points: any[] = [];
        var securities =
            (type === "winners"
                ? dispersion?.topList.filter((item) => item.rc > 0)
                : dispersion?.bottomList.filter((item) => item.rc < 0)) ?? [];

        securities.sort((a, b) =>
            a.pr_adjusted < b.pr_adjusted
                ? -1
                : b.pr_adjusted < a.pr_adjusted
                ? 1
                : 0
        );

        const series: Highcharts.SeriesColumnOptions[] = [];

        let min = 99999999999;
        let max = -9999999999999;
        if (type === "winners") {
            for (let i = 0; i < securities.length; i++) {
                const y = securities[i].pr_adjusted;
                points.push({
                    y: y,
                    data: securities[i],
                    color: y < 0 ? "red" : "green",
                });
                if (y < min) {
                    min = y;
                }
                if (y > max) {
                    max = y;
                }
            }
            let serieName = "Performance";
            if (dispersion?.info.perfMnemonic) {
                serieName = dispersion.info.perfMnemonic + " performance";
            }
            series.push({
                type: "column",
                name: serieName,
                data: points,
                // lineWidth: 1,
                dataGrouping: {
                    enabled: false,
                },
                color: type === "winners" ? "green" : "red", //#0072C6'
            });
        } else {
            const analytics = [];
            for (let i = 0; i < securities.length; i++) {
                const s = securities[i];

                let normalizedRatingPerf = s.pr_adjusted;
                if (s.pr_adjusted < s.perf) {
                    normalizedRatingPerf = s.perf;
                }
                if (s.pr_adjusted > 0 && s.perf < 0) {
                    normalizedRatingPerf = 0.0;
                }

                const stillLoss = s.perf - normalizedRatingPerf;
                const avoidedLoss = -normalizedRatingPerf;
                // min = Math.min(
                //     ...[
                //         min,
                //         s.perf,
                //         normalizedRatingPerf,
                //         stillLoss,
                //         avoidedLoss,
                //     ]
                // );
                // max = Math.max(
                //     ...[
                //         max,
                //         s.perf,
                //         normalizedRatingPerf,
                //         stillLoss,
                //         avoidedLoss,
                //     ]
                // );

                analytics.push({
                    sec: s,
                    rating: normalizedRatingPerf, // only for sorting
                    perf: s.perf,
                    stillLoss: stillLoss,
                    avoidedLoss: avoidedLoss,
                });
            }
            analytics.sort(function (a, b) {
                return a.rating < b.rating ? 1 : b.rating < a.rating ? -1 : 0;
            });

            // const pointPerf = [];
            // const pointStillLoss = [];
            const pointAvoidedLoss = [];
            for (let i = 0; i < analytics.length; i++) {
                const s = analytics[i];
                // pointPerf.push({
                //     y: s.perf,
                //     data: s.sec,
                //     color: s.perf < 0 ? "red" : "green",
                // });
                // pointStillLoss.push({
                //     y: s.stillLoss,
                //     data: s.sec,
                //     color: "orange",
                // });
                pointAvoidedLoss.push({
                    y: s.avoidedLoss,
                    data: s.sec,
                    color: s.avoidedLoss < 0 ? "red" : "green",
                });
            }
            // series.push({
            //     data: pointPerf,
            //     name: "Performance",
            //     type: "column",
            // });
            // series.push({
            //     data: pointStillLoss,
            //     name: "Loss",
            //     type: "column",
            // });
            series.push({
                data: pointAvoidedLoss,
                name: "Avoided Loss",
                type: "column",
            });
        }

        const xAxis: Highcharts.XAxisOptions = {
            visible: false,
        };

        const yAxis = {
            showLastLabel: true,
            title: { text: "return" },
            opposite: false,
            labels: {
                formatter: function (): any {
                    return ((this as any).value * 100).toFixed(2) + "%";
                },
            },
            min: type === "winners" ? min : undefined,
            max: type === "winners" ? max : undefined,
            startOnTick: false,
            endOnTick: false,
            maxPadding: 0,
            minPadding: 0,
            plotLines: [
                {
                    color: "#C0D0E0",
                    width: 1,
                    value:
                        type === "winners"
                            ? dispersion?.aggregated.top.AB.pr_adjusted
                            : -(
                                  dispersion?.aggregated.bottom.CD
                                      .pr_adjusted ?? 0
                              ),
                    label: {
                        formatter: function () {
                            return "<b>" + dispersion != null
                                ? (
                                      (type === "winners"
                                          ? dispersion?.aggregated?.top?.AB
                                                ?.pr_adjusted ?? 0
                                          : dispersion?.aggregated?.bottom?.CD
                                                ?.pr_adjusted ?? 0) * 100
                                  ).toFixed(2) + "%</b>"
                                : "";
                        },
                    },
                },
            ],
        };

        const options: Highcharts.Options = {
            chart: {
                animation: false,
                borderWidth: 1,
                borderColor: "#C0D0E0",
                height: 240,
                spacingTop: 20,
                spacingBottom: 20,
                marginBottom: 20,
                type: "column",
            },
            title: {
                text:
                    type === "winners"
                        ? "Distribution of captured gain"
                        : "Distribution of avoided loss",
            },
            credits: { enabled: false },
            exporting: { enabled: false },
            legend: { enabled: false, reversed: true },
            scrollbar: { enabled: false },
            navigator: { enabled: false },
            rangeSelector: {
                enabled: false,
            },
            tooltip: {
                shared: true,
                outside: true,
                formatter: function (options: any): any {
                    const sec = (this as any).points[0].point.data;
                    let s = `<b>${sec.ticker}</b> ${sec.name}<br/>`;
                    for (const point of (this as any).points) {
                        s += `<br/>${point.series.name}: ${(
                            point.y * 100
                        ).toFixed(2)}%`;
                    }
                    return s;
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                    turboThreshold: 0,
                    states: { inactive: { opacity: 1 } },
                },
            },
            yAxis: yAxis,
            xAxis: xAxis,
            series: series,
        };
        setOptions(options);
    }, [dispersion, type]);

    return (
        <Chart
            className={className}
            constructorType="chart"
            useAutomaticRatio={false}
            options={options}
        />
    );
}
