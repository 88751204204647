import axios from "axios";
import {
    Context,
    createContext,
    ReactNode,
    useEffect,
    useReducer,
    useState,
} from "react";
import { useQuery } from "react-query";
import { Loader } from "./components/Loader";
import { Configuration } from "./trendrating/api/Configuration";
import { AppEnvironment, environmentTemplate } from "./types/Defaults";

type AppContextProviderProps = {
    children?: ReactNode;
};

export const AppContext = createContext(false);

type AppConfiguration = { environment: AppEnvironment };
type AppEnvironmentContextValue = Context<AppConfiguration>;
export const AppEnvironmentContext: AppEnvironmentContextValue = createContext(
    {} as any
);

type ReducerAction = {
    type: "ADD_PROPERTIES" | "ADD_TAXONOMIES";
    payload: any;
};

const reducer = (prevState: AppEnvironment, action: ReducerAction) => {
    const { type, payload } = action;
    switch (type) {
        case "ADD_PROPERTIES":
            return {
                ...prevState,
                properties: payload,
            };
        case "ADD_TAXONOMIES":
            return {
                ...prevState,
                taxonomies: payload,
            };
        default:
            return prevState;
    }
};

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
    const [isReady, setReady] = useState(false);

    const [environment, dispatch] = useReducer(reducer, environmentTemplate);

    const [value, setValue] = useState<AppConfiguration>({
        environment: environment,
    });

    useEffect(() => {
        if (environment.properties != null && environment.taxonomies != null) {
            environment.configuration = new Configuration({
                configuration: environment.account.product.configuration,
                product: environment.account.product,
                properties: environment.properties,
            });
            setValue({
                environment: environment,
            });

            setReady(true);
        }
    }, [environment]);

    const timestamp = Date.now();

    const {
        isLoading: isLoadingFieldsConfiguration,
        error: errorFieldsConfiguration,
        data: dataFieldsConfiguration,
    } = useQuery<any, Error>("fieldsConfiguration", () =>
        axios(`/data-local/fieldsConfiguration.json?ts=${timestamp}`)
    );

    const {
        isLoading: isLoadingTaxonomies,
        error: errorTaxonomies,
        data: dataTaxonomies,
    } = useQuery<any, Error>("taxonomies", () =>
        axios(`/data-local/taxonomies.json?ts=${timestamp}`)
    );

    useEffect(() => {
        if (dataFieldsConfiguration != null) {
            dispatch({
                type: "ADD_PROPERTIES",
                payload: dataFieldsConfiguration.data,
            });
        }
    }, [dataFieldsConfiguration]);

    useEffect(() => {
        if (dataTaxonomies != null) {
            dispatch({
                type: "ADD_TAXONOMIES",
                payload: dataTaxonomies.data,
            });
        }
    }, [dataTaxonomies]);

    if (isLoadingFieldsConfiguration || isLoadingTaxonomies) {
        return <Loader />;
    }

    if (errorFieldsConfiguration) {
        return <div>{`Error: ${errorFieldsConfiguration.message}`}</div>;
    }

    if (errorTaxonomies) {
        return <div>{`Error: ${errorTaxonomies.message}`}</div>;
    }

    return (
        <AppContext.Provider value={isReady}>
            <AppEnvironmentContext.Provider value={value}>
                {children}
            </AppEnvironmentContext.Provider>
        </AppContext.Provider>
    );
};
