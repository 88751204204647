import { Col, Container, Row } from "react-bootstrap";

type LoaderProps = {
    message?: string;
};

export const Loader = (props: LoaderProps) => {
    return (
        <div className="appLoader">
            <Container className="mb-2">
                <Row className="mb-2">
                    <Col sm={12}>
                        <div className="d-flex justify-content-center mt-5">
                            <div
                                className="appLoaderSpinner spinner-border"
                                role="status"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
