import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { useFormatter } from "../../hooks/useFormatter";

type ProductDetailOverviewProps = {
  analyticsDetail: any;
  info: any;
};

export const ProductDetailOverview = ({ info }: ProductDetailOverviewProps) => {
  const { t } = useTranslation();

  return (
    <Row className="g-0">
      <Col sm={12} className="mt-3">
        <div className="px-2">
          <p className="d-flex justify-content-around flex-wrap">
            {info.geo && (
              <span className="">
                <span className="text-muted">Country:</span>
                &nbsp;
                {t(`geo-${info.geo.toLowerCase()}`)}
              </span>
            )}
            {info.positions !== false && (
              <span>
                <span className="text-muted">Stocks:</span>
                &nbsp;
                {info.positions}
              </span>
            )}
            {/* {info.theme && (
                            <span>
                                <span className="text-muted">Theme:</span>&nbsp;
                                {t(`theme-${info.theme.toLowerCase()}`)}
                            </span>
                        )} */}
          </p>
        </div>
      </Col>
      <Col sm={12} className="mt-0">
        <h5 className="product-detail-subtitle">Markets</h5>
        <div
          className="px-2"
          dangerouslySetInnerHTML={{
            __html: info.rules.markets.join(", "),
          }}
        />
      </Col>
      {info.rules.investmentUniverse && (
        <Col sm={12} className="mt-2">
          <h5 className="product-detail-subtitle">Investment Universe</h5>
          <ul className="px-2">
            {info.rules.investmentUniverse.map(
              (rule: string, index: number) => (
                <li
                  key={`universe_${index}`}
                  dangerouslySetInnerHTML={{
                    __html: rule,
                  }}
                />
              )
            )}
          </ul>
        </Col>
      )}
      {info.rules.minimumDailyLiquidity && (
        <Col sm={12} className="mt-2">
          <h5 className="product-detail-subtitle">Minimum Daily Liquidity</h5>
          <ul className="px-2">
            {info.rules.minimumDailyLiquidity.map(
              (rule: string, index: number) => (
                <li
                  key={`liquidity_${index}`}
                  dangerouslySetInnerHTML={{
                    __html: rule,
                  }}
                />
              )
            )}
          </ul>
        </Col>
      )}
      {info.rules.allocationRules && (
        <Col sm={12} className="mt-2">
          <h5 className="product-detail-subtitle">Allocation rules</h5>
          <ul className="px-2">
            {info.rules.allocationRules.map((rule: string, index: number) => (
              <li
                key={`allocation_${index}`}
                dangerouslySetInnerHTML={{
                  __html: rule,
                }}
              />
            ))}
          </ul>
        </Col>
      )}
      {info.rules.selectionRules && (
        <Col sm={12} className="mt-2">
          <h5 className="product-detail-subtitle">Selection rules</h5>
          <ul className="px-2">
            {info.rules.selectionRules.map((rule: string, index: number) => (
              <li
                key={`selection_${index}`}
                dangerouslySetInnerHTML={{
                  __html: rule,
                }}
              />
            ))}
          </ul>
        </Col>
      )}
      {info.rules.benchmark && (
        <Col sm={12} className="mt-2">
          <h5 className="product-detail-subtitle">Benchmark</h5>
          <ul className="px-2">
            <li
              key={`benchmark_0`}
              dangerouslySetInnerHTML={{
                __html: info.rules.benchmark,
              }}
            />
          </ul>
        </Col>
      )}
      {info.rules.rebalance && (
        <Col sm={12} className="mt-2">
          <h5 className="product-detail-subtitle">Rebalance</h5>
          <ul className="px-2">
            {info.rules.rebalance.map((rule: string, index: number) => (
              <li
                key={`rebalance_${index}`}
                dangerouslySetInnerHTML={{
                  __html: rule,
                }}
              />
            ))}
          </ul>
        </Col>
      )}
    </Row>
  );
};
