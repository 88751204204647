import { useState } from "react";
import { Formatter } from "../trendrating/utils/Formatter";
import { useEnvironment } from "./useEnvironment";

export const useFormatter = () => {
    const { environment } = useEnvironment();

    const [formatter] = useState<any>(() => {
        return new Formatter(environment);
    });

    return formatter;
};
