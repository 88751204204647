import { useMemo } from "react";
import { deepClone } from "../../trendrating/deepClone";
import { ProductInfo } from "../../types/Product";
import { getOrderedKeys } from "../ProductList";
import { ProductListItem } from "./ProductListItem";

type ProductListItemsProps = {
  filter: any;
  products: ProductInfo[];
};

export const ProductListItems = ({
  filter,
  products,
}: ProductListItemsProps) => {
  // Find maximum row height
  const totalRows = useMemo(
    () =>
      products.reduce(
        (maxLength: number, item: ProductInfo) =>
          Math.max(Object.keys(item.description).length, maxLength),
        0
      ),
    [products]
  );

  // TODO refactor and remove it
  // Prepare flat list used for rendering
  // Has objects of objects (geo -> theme -> method)
  // If positions
  const groupedProducts: any = useMemo(() => {
    if (products == null) {
      return {};
    }

    const groupedProducts: any = {};

    /**
     * Data structure:
     *
     * <geo>: {
     *     <theme>: {
     *          <method>: {
     *              single: <item>
     *              30: <item>,...
     *          }
     *     }
     * }
     *
     */

    // Todo can set only the key instead of the full object because
    // there is also the other "products" array with full data.

    //! Sort order comes from server, if we want to change the oreder we have to do it server side in the json dashboard generator.
    let sortedProducts = deepClone(products);
    // sortedProducts.sort((a: any, b: any) => {
    //     let exposureA = a.exposure ?? Infinity;
    //     let exposureB = b.exposure ?? Infinity;
    //     if (exposureA > exposureB) {
    //         return -1;
    //     } else if (exposureA < exposureB) {
    //         return 1;
    //     } else {
    //         return 0;
    //     }
    // });

    // sortedProducts.sort((obj1, obj2) => {
    //     const a = obj1.name;
    //     const b = obj2.name;

    //     if (a < b) {
    //         return -1;
    //     } else if (a > b) {
    //         return 1;
    //     } else {
    //         return 0;
    //     }
    // });

    for (let product of sortedProducts) {
      const geo = String(product.geo);
      const theme = String(product.theme);
      const turnover = String(product.turnover);
      const method = String(product.method);

      if (groupedProducts[geo] == null) {
        groupedProducts[geo] = {};
      }

      if (groupedProducts[geo][turnover] == null) {
        groupedProducts![geo][turnover] = {};
      }

      if (groupedProducts[geo][turnover][theme] == null) {
        groupedProducts![geo][turnover][theme] = {};
      }
      // if (
      //                 rules != null &&
      //                 product.info.rules != null &&
      //                 rules[product.info.rules] != null
      //             ) {
      //                 products[geo][theme].rules = [...rules[product.info.rules]];
      //                 for (
      //                     let i = rules[product.info.rules].length;
      //                     i < maxRulesLength;
      //                     i++
      //                 ) {
      //                     products[geo][theme].rules.push("");
      //                 }
      //             }

      if (groupedProducts[geo][turnover][theme][method] == null) {
        groupedProducts![geo][turnover][theme][method] = [];
      }

      groupedProducts![geo][turnover][theme][method].push({
        product: product,
      });
    }

    return groupedProducts;
  }, [products]);

  const renderedList = useMemo(() => {
    if (groupedProducts == null) {
      return null;
    }
    const list: any = [];
    getOrderedKeys(groupedProducts, "geo").forEach((geo) =>
      getOrderedKeys(groupedProducts[geo], "turnover").forEach((turnover) =>
        getOrderedKeys(groupedProducts[geo][turnover], "theme").forEach(
          (theme) =>
            getOrderedKeys(
              groupedProducts[geo][turnover][theme],
              "method"
            ).forEach((method) => {
              // groupedProducts[geo][theme][method] does contain
              // an array, like this:
              //
              // {position: ..., product: ...}
              // Position can be "false", or a number (as string) of
              // stocks.

              // Iterate all elements inside method to categorize
              // of "single item" or grouped stocks
              // Single items are without number of stocks, displayed
              // as a single box.
              //   let positions = [];
              //   let grouped: Record<string, ProductItem> = {};
              for (let item of groupedProducts[geo][turnover][theme][method]) {
                if (!item.position) {
                  // Add separate item
                  list.push({
                    geo,
                    theme,
                    method,
                    turnover,
                    isSingle: true,
                    singleItem: item.product,
                  });
                }
                // } else {
                //   positions.push(item.position);
                //   grouped[item.position] = item.product;
                // }
              }

              //   if (positions.length > 0) {
              //     list.push({
              //       geo,
              //       theme,
              //       method,
              //       turnover,
              //       grouped,
              //       positions,
              //       isSingle: false,
              //     });
              //   }
            })
        )
      )
    );

    // list.sort((a: any, b: any) => {
    //     let exposureA = Infinity;
    //     if (a.method === "longshort") {
    //         if (a.isSingle) {
    //             exposureA = a.singleItem.exposure ?? Infinity;
    //         } else {
    //             // Cannot have longshort/exposure with grouped items
    //         }
    //     }
    //     let exposureB = Infinity;
    //     if (b.method === "longshort") {
    //         if (b.isSingle) {
    //             exposureB = b.singleItem.exposure ?? Infinity;
    //         } else {
    //             // Cannot have longshort/exposure with grouped items
    //         }
    //     }
    //     if (exposureA > exposureB) {
    //         return -1;
    //     } else if (exposureA < exposureB) {
    //         return 1;
    //     } else {
    //         return 0;
    //     }
    // });
    // console.log(list);
    return list;
  }, [groupedProducts]);

  // // From https://stackoverflow.com/a/32494726
  // const sortByAttributes = useCallback(
  //     (
  //         array: any,
  //         referenceSortedList: Record<string, string[]> | null = null,
  //         ...attrs: any
  //     ) => {
  //         console.log("attrs", attrs);
  //         // generate an array of predicate-objects contains
  //         // property getter, and descending indicator
  //         let predicates = attrs.map((predicate: any) => {
  //             let descending = predicate.charAt(0) === "-" ? -1 : 1;
  //             predicate = predicate.replace(/^-/, "");
  //             return {
  //                 referenceList: referenceSortedList?.[predicate] ?? null,
  //                 getter: (o: any) => o[predicate],
  //                 descend: descending,
  //             };
  //         });
  //         console.log("predicates", predicates);
  //         // schwartzian transform idiom implementation. aka: "decorate-sort-undecorate"
  //         return array
  //             .map((item: any) => {
  //                 return {
  //                     src: item,
  //                     compareValues: predicates.map((predicate: any) =>
  //                         predicate.getter(item)
  //                     ),
  //                 };
  //             })
  //             .sort((a: any, b: any) => {
  //                 let i = -1;
  //                 let result = 0;
  //                 while (++i < predicates.length) {
  //                     console.log("Sort by ", predicates[i]);
  //                     if (predicates[i].referenceList != null) {
  //                         let numberA = predicates[i].referenceList.indexOf(
  //                             a.compareValues[i]
  //                         );
  //                         if (numberA === -1) {
  //                             numberA = Infinity;
  //                         }
  //                         let numberB = predicates[i].referenceList.indexOf(
  //                             b.compareValues[i]
  //                         );
  //                         if (numberB === -1) {
  //                             numberB = Infinity;
  //                         }
  //                         if (numberA < numberB) {
  //                             result = -1;
  //                         } else if (numberA > numberB) {
  //                             result = 1;
  //                         }
  //                     } else {
  //                         if (a.compareValues[i] < b.compareValues[i]) {
  //                             result = -1;
  //                         }
  //                         if (a.compareValues[i] > b.compareValues[i]) {
  //                             result = 1;
  //                         }
  //                     }
  //                     // Invert if needed
  //                     result *= predicates[i].descend;
  //                     if (result) {
  //                         break;
  //                     }
  //                 }
  //                 return result;
  //             })
  //             .map((item: any) => item.src);
  //     },
  //     []
  // );

  // TODO
  //
  // Refactor using the new method for sorting attributes.
  // Remember to group similar geo-theme-method items with different positions
  // and exclude the "single" one (positions: false)
  //
  // const renderedList2 = useMemo(() => {
  //     if (products == null) {
  //         return null;
  //     }
  //     const list: any = [];
  //     // sortByAttributes(
  //     //     products,
  //     //     orderedKeys,
  //     //     "method",
  //     //     "geo",
  //     //     "theme",
  //     //     "exposure"
  //     // )
  //     return list;
  // }, [products, sortByAttributes]);

  return (
    <div className="row mt-4">
      {renderedList &&
        renderedList.map((product: any) => {
          return (
            <ProductListItem
              key={
                `${product.singleItem.id}`
                //   product.isSingle
                //   ? `${product.singleItem.id}`
                //   : `${product.grouped[product.positions[0]].id}`
              }
              filter={filter}
              totalRows={totalRows}
              product={product}
            />
          );
        })}
    </div>
  );
};
