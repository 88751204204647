import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { paths } from "../../types/Defaults";

type ProductSelectProps = {
    selectedId?: string;
    products: any[];
};

export const ProductSelect = ({ selectedId, products }: ProductSelectProps) => {
    if (products == null) {
        return <></>;
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
                Switch portfolio
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {products
                    // .filter((product) => product.name !== selectedId)
                    .map((product: any) =>
                        product.name !== selectedId ? (
                            <Link
                                key={product.name}
                                className="dropdown-item"
                                role="button"
                                to={paths.productDetail.replace(
                                    ":id",
                                    product.name
                                )}
                            >
                                {product.info.name}
                                {product.info.positions
                                    ? ` - ${product.info.positions} Stocks`
                                    : ""}
                            </Link>
                        ) : (
                            <Link
                                key={product.name}
                                className="dropdown-item active"
                                role="button"
                                to={paths.productDetail.replace(
                                    ":id",
                                    product.name
                                )}
                            >
                                {product.info.name}
                                {product.info.positions
                                    ? ` - ${product.info.positions} Stocks`
                                    : ""}
                            </Link>
                        )
                    )}
            </Dropdown.Menu>
        </Dropdown>
    );
};
