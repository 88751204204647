import { ReactNode, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { KnownHostnames, paths } from "../types/Defaults";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { HomePortfoliosTicker } from "./HomePortfoliosTicker";
import { PortfolioHomeLongOnly } from "./PortfolioHomeLongOnly";
import { PortfolioHomeClassic } from "./PortfolioHomeClassic";
import { PortfolioHomeLongShort } from "./PortfolioHomeLongShort";

type HomeWrapProps = {
  children?: ReactNode;
  isLogged: boolean;
  viewType: "longOnly" | "longShort" | "portfolios";
};

const HomeWrap = ({ children, isLogged, viewType }: HomeWrapProps) => {
  return (
    <div className="d-flex min-vh-100 position-relative">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 home-cover"></div>
      <div className="home-cover position-relative cover-container d-flex min-vh-100 mx-auto flex-column w-100">
        {/* <div className="home-cover-mask position-absolute start-0 end-0 top-0 bottom-0"></div> */}
        {/*Keep this comment 
                {isLogged ? (
                    <Header
                        children={
                            <div className="d-flex justify-content-center">
                                <Link
                                    to={paths.product}
                                    className="btn btn-outline-light ms-2"
                                >
                                    Go to Catalog
                                </Link>
                            </div>
                        }
                    />
                ) : (
                    <Header />
                )}*/}
        <Header
          viewType={viewType}
          children={
            <div className="d-flex justify-content-center">
              <Link to={paths.product} className="btn btn-outline-light ms-2">
                Discover our Model Portfolios
              </Link>
            </div>
          }
        />
        <main
          className="main-no-bg flex-grow-1"
          style={{ overflowX: "hidden" }}
        >
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
};

export const Home = () => {
  const { isLogged, isReady } = useAuth();
  const hostname: KnownHostnames = window.location.hostname as KnownHostnames;
  const [viewType, setViewType] = useState<
    "longOnly" | "longShort" | "portfolios"
  >("longOnly");

  const portfolioViewType: "longOnly" | "longShort" | "portfolios" =
    "portfolios";

  // Check cookie before setting disclaimer status
  useEffect(() => {
    switch (hostname) {
      // case "longonly.trendrating.com":
      //   setViewType("longOnly");

      //   break;
      case "longshort.trendrating.com":
      case "longshortd.trendrating.com":
        setViewType("longShort");

        break;

      case "longonly.trendrating.com":
      case "portfolios.trendrating.com":
      case "develop.portfolios.trendrating.com":
      case "localhost":
        setViewType(portfolioViewType);

        break;

      default:
        setViewType("portfolios");
    }
  }, [hostname]);

  if (!isReady) {
    return (
      <HomeWrap isLogged={isLogged} viewType={viewType}>
        <div className="home-header">
          <div className="home-header-content">
            <Container className="home-header-content">
              <Row>
                <Col md={12} className="home-header-top">
                  <div className="fs-3">Loading...</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </HomeWrap>
    );
  }

  return (
    <HomeWrap isLogged={isLogged} viewType={viewType}>
      <div className="home-header">
        <div className="home-header-content home-header-content--part1">
          <div className="home-header-content-mask"></div>
          <Container className="">
            <Row className="home-header-bottom-divider">
              <Col md={12} className="home-header-content-cell">
                <div className="home-header-top d-flex align-items-end">
                  <h1 className="d-flex">
                    <span className="home-header-title flex-grow-1 flex-shrink-1">
                      SMART MODEL PORTFOLIOS
                      <br />
                      {viewType === "longShort" && "LONG AND SHORT"}
                      {viewType === "longOnly" && "LONG ONLY"}
                    </span>
                    <span></span>
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <HomePortfoliosTicker />

        {viewType === "longOnly" && <PortfolioHomeLongOnly />}
        {viewType === "portfolios" && <PortfolioHomeClassic />}
        {viewType === "longShort" && <PortfolioHomeLongShort />}
      </div>
    </HomeWrap>
  );
};
