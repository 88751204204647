/**
 * @author Sandro Bottarelli <s.bottarelli@trendrating.net>
 * @author Stefano Mondini <s.mondini@trendrating.net>
 * @author Elia Contini <e.contini@trendrating.net>
 *
 * @module trendrating-chart/PriceChartSystematicProduct
 *
 * @summary Trendrating Price Chart - reviewed code
 */

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
    createRef,
    forwardRef,
    RefObject,
    useEffect,
    useImperativeHandle,
} from "react";

type ChartProps = {
    className?: string;
    constructorType?: string;
    options: any;
    useAutomaticRatio?: boolean;
};

type HighchartsRef = {
    chart: Highcharts.Chart;
    container: RefObject<HTMLDivElement>;
};

/**
 * TrendratingPriceChart
 * @param {object}   displayRatio - chart ratio
 * @param {boolean}  ratings - enable rating view
 * @param {number}   firstDate - Trendrating date
 * @param {object}   functions - event listeners
 * @param {function} functions.measure - listener for measure
 * @param {function} functions.tooltip - listener for tooltip
 * @param {boolean}  historicalRating -
 * @param {string}   scale - "linear" or "logarithmic". Default "logarithmic"
 * @param {boolean}  scaleButton -
 */
export const Chart = forwardRef(
    (
        {
            className,
            constructorType = "stockChart",
            options,
            useAutomaticRatio = true,
        }: ChartProps,
        ref
    ) => {
        const chartRef = createRef<HighchartsRef>();

        useImperativeHandle(ref, () => chartRef.current!.chart);

        useEffect(() => {
            if (chartRef.current != null && chartRef.current.chart != null) {
                chartRef.current.chart.reflow();
            }
        }, [chartRef, options]);

        return (
            <div
                className={`${className ?? ""}${
                    useAutomaticRatio ? " ratio ratio-16x9" : ""
                }`}
            >
                <HighchartsReact
                    constructorType={constructorType}
                    ref={chartRef}
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        );
    }
);
