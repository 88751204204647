import { useCallback, useContext } from "react";
import { KeenContext } from "../KeenContextProvider";
import { User } from "../types/Defaults";

export const useKeen = () => {
    const context = useContext(KeenContext);
    // context must be null or KeenTracking, not undefined
    if (context === undefined) {
        throw new Error(`useKeen must be called within KeenContextProvider`);
    }

    const recordEvent = useCallback(
        (action: string, actionParam: any, user: User | null) => {
            let isProduction =
                process.env.NODE_ENV === "production" &&
                !document.location.host.startsWith("develop.");

            if (context === null) {
                console.log("Keen not ready");
            } else {
                context.recordEvent(
                    isProduction ? "usage-prod" : "usage-test",
                    {
                        action: action,
                        action_param: actionParam,
                        user: {
                            active: user?.active ?? null,
                            email: user?.email ?? null,
                            id: user?.id ?? null,
                        },
                    }
                );
            }
        },
        [context]
    );
    return { keen: context, recordEvent };
};
