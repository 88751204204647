import { ReactNode } from "react";
import { useAuth } from "../hooks/useAuth";
import { Col, Row } from "react-bootstrap";

type LockTableProps = {
  children?: ReactNode;
};

export const LockTable = ({ children }: LockTableProps) => {
  const { isLogged } = useAuth();

  if (isLogged) {
    return <>{children}</>;
  }

  return (
    <div
      className="app-lock-wrap app-lock-clickable"
      onClick={() => console.log("Login")}
    >
      <div className="app-lock">
        <div className="app-lock-table">{children}</div>
      </div>
      <div className="app-lock-text fs-5 text-center pt-2 pb-1">
        <Row className="d-flex justify-content-center">
          <Col md={8}>
            <p className="text-center">
              <i className="bi bi-lock-fill"></i> Would you like to access the
              detailed selection rules and the updated list of holdings? Are you
              interested in replicating this model portfolio? Act now and sign
              up!
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};
