/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/utils/Formatter
 * @summary Application data formatter: app specific formatter
 *          It uses trendrating/formatter/Formatter internally
 *
 */

import { Strategy as StrategyFormatter } from "../app/formatter/Strategy";
import { Formatter as FormatterBase } from "../formatter/Formatter";
import { Taxonomy } from "../formatter/Taxonomy";

export class Formatter {
    format = null;
    properties = null;
    taxonomy = null;
    taxonomies = null;

    // short peer names for pinned objects
    PEER_SHORT_NAMES = {
        "Developed Markets - Americas": "Developed Markets - Americas",
        "Developed Markets - Asia Pacific": "Developed Markets - Asia",
        "Developed Markets - Europe & Middle East":
            "Developed Markets - Europe",

        "Emerging Markets - Asia": "Emerging Markets - Asia",
        "Emerging Markets - Americas": "Emerging Markets - Americas",
        "Emerging Markets - Europe, Middle East & Africa":
            "Emerging Markets - Europe and Africa",

        "Frontier Markets - Africa": "Frontier Markets - Africa",
        "Frontier Markets - Americas": "Frontier Markets - Americas",
        "Frontier Markets - Asia": "Frontier Markets - Asia",
        "Frontier Markets - Europe & CIS": "Frontier Markets - Europe",
        "Frontier Markets - Middle East": "Frontier Markets - Middle East",
    };

    SERVER_TYPE_DEFAULT = "instrument";
    SERVER_TYPES = ["Commodity", "Currency", "ETF", "Index", "Sector", "Stock"];

    constructor(environment) {
        this.format = new FormatterBase();
        this.taxonomy = new Taxonomy();
        this.strategyFormatter = new StrategyFormatter(environment);

        this.setProperties(environment["properties"]);
        this.setTaxonomies(environment["taxonomies"]);
    }

    /**
     * Custom formatter for special cases
     *
     * @param {string} trendratingFormatterType
     * @param {object} params
     */
    custom(trendratingFormatterType, params) {
        return this.format[trendratingFormatterType](params);
    }

    /**
     * Format a value as HTML
     *
     * @param {string} property
     * @param {string} formatterName
     * @param {any} value
     * @param {object} valueHelper
     * @param {string} type
     *
     * @returns {string} a formatted string
     */
    html(property, formatterName, value, valueHelper, type) {
        return this._commonHtmlPdfText(
            property,
            formatterName,
            value,
            valueHelper,
            type,
            "HTML"
        );
    }
    /**
     * Format a value suitable for PDF generation
     *
     * @param {string} property
     * @param {string} formatterName
     * @param {any} value
     * @param {object} valueHelper
     * @param {string} type
     *
     * @returns {string} a formatted string
     */
    pdf(property, formatterName, value, valueHelper, type) {
        return this._commonHtmlPdfText(
            property,
            formatterName,
            value,
            valueHelper,
            type,
            "PDF"
        );
    }
    /**
     *
     * @param {object}  params
     *
     * @param {object}  params.item
     * @param {object}  params.item.data
     * @param {object}  params.item.idInfo
     *
     * @param {object}  params.options - formatting options
     * @param {boolean} params.options.isShortened - if true, "where" names
     *      are shortened. Default true
     * @param {object}  params.options.suffixWithName - add name after ticker (instrument type)
     *
     * @param {object}  params.taxonomies
     * @param {object}  params.taxonomies.size - SizeClassification
     * @param {object}  params.taxonomies.what - ICB or ETFclassification
     * @param {object}  params.taxonomies.where - MarketsFinancial
     *
     * @returns {string} the name
     */
    pinnedName(params) {
        var isShortened =
            params["options"] != null && "isShortened" in params["options"]
                ? params["options"]["isShortened"]
                : true;
        var suffixWithName =
            params["options"] != null && "suffixWithName" in params["options"]
                ? params["options"]["suffixWithName"]
                : false;
        var item = params["item"];
        var taxonomies = params["taxonomies"];

        var name = [];

        var where = null;
        switch (item["idInfo"]["type"]) {
            case "instrument": {
                name.push(item["data"]["ticker"]);

                where = item["data"]["country"];
                if (where != null && taxonomies["where"][where] != null) {
                    name.push(taxonomies["where"][where]["name"]);
                }

                if (suffixWithName && item["data"]["name"] != null) {
                    name.push(item["data"]["name"]);
                }

                break;
            }
            case "peer":
            default: {
                var what = item["idInfo"]["what"];
                where = item["idInfo"]["where"];
                var _name = this.taxonomy.getWhatAndWhereLabel({
                    what: what === "ICB" ? null : what,
                    whatTaxonomy: taxonomies["what"],
                    where: where === "WWW" ? null : where,
                    whereTaxonomy: taxonomies["where"],
                });

                var whereWhat = [];
                if (_name["what"] == null && _name["where"] == null) {
                    name.push("World");
                } else {
                    // where
                    where =
                        _name["where"] == null
                            ? null
                            : _name["where"].replace(":", " - ");
                    if (
                        isShortened === true &&
                        where != null &&
                        where in this.PEER_SHORT_NAMES
                    ) {
                        where = this.PEER_SHORT_NAMES[where];
                    }

                    if (where != null) {
                        whereWhat.push(where);
                    }

                    // what
                    if (_name["what"] != null) {
                        whereWhat.push(_name["what"]);
                    }

                    name.push(whereWhat.join(" - "));
                }
                // size
                if (item["idInfo"]["size"] != null) {
                    var size = taxonomies["size"].get(item["idInfo"]["size"]);
                    name.push(size["name"]);
                }
            }
        }

        return name.join(" - ");
    }

    rankingRule(syntheticRankProperty, rankingRule, instrument, output) {
        var value = instrument[syntheticRankProperty];
        var formatted = "";

        switch (output) {
            case "HTML":
            case "PDF":
            case "TEXT":
            default: {
                switch (syntheticRankProperty) {
                    case "rank":
                    case "rankFromDate": {
                        formatted = String(value + 1);

                        break;
                    }
                    case "rankDelta": {
                        formatted = this.custom("number", {
                            options: {
                                colored: "positive",
                                decimals: 0,
                                hasPositiveSign: true,
                                notAvailable: {
                                    input: null,
                                    output: "",
                                },
                            },
                            output: output,
                            value: value,
                            valueHelper: null,
                        });

                        break;
                    }
                    case "rankList": {
                        formatted = value != null ? String(value + 1) : "";

                        break;
                    }
                    default: {
                        switch (rankingRule["function"]) {
                            //case 'inRange': {
                            case "outlier": {
                                if (value === 1) {
                                    formatted = "";
                                } else {
                                    formatted = "outlier";
                                }

                                break;
                            }
                            case "quantile": {
                                formatted =
                                    value != null
                                        ? //? 'q:' + value
                                          String(value)
                                        : "";

                                break;
                            }
                            case "threshold": {
                                if (value === 1) {
                                    formatted = "over";
                                } else {
                                    formatted = "under";
                                }

                                break;
                            }
                            case "value": {
                                formatted = this._commonHtmlPdfText(
                                    rankingRule["property"],
                                    "table",
                                    instrument[syntheticRankProperty],
                                    instrument,
                                    instrument["type"],
                                    output
                                );

                                break;
                            }
                            default: {
                                formatted = value;
                            }
                        }
                    }
                }

                break;
            }
        }

        return formatted;
    }

    rationale(property, propertyAlias, rationaleObject) {
        var type = rationaleObject["type"].toLowerCase();
        var formatter = this._getFormatter(property, "table", type);

        var valueHelper = null;
        if (formatter["referenceProperties"] != null) {
            valueHelper = {};

            this._resolveReferenceProperties(
                formatter,
                rationaleObject,
                valueHelper
            );
        }

        if (property === "tradedvalue") {
            return [
                "USD",
                this.format[formatter["type"]]({
                    options: formatter["options"],
                    output: "HTML",
                    value: rationaleObject[propertyAlias],
                    valueHelper: valueHelper,
                }),
            ].join(" ");
        }

        return this.format[formatter["type"]]({
            options: formatter["options"],
            output: "HTML",
            value: rationaleObject[propertyAlias],
            valueHelper: valueHelper,
        });
    }

    get strategy() {
        return this.strategyFormatter;
    }

    /**
     * Format a value suitable for table widget
     *
     * @param {string} property
     * @param {string} formatterName
     * @param {object} instrument
     *
     * @returns {string} a formatted string
     */
    table(property, formatterName, instrument) {
        var type = instrument["type"].toLowerCase();
        var formatter = this._getFormatter(property, formatterName, type);

        var propertyServer = formatter["backendProperty"];

        var valueHelper = null;
        if (formatter["referenceProperties"] != null) {
            valueHelper = {};

            this._resolveReferenceProperties(
                formatter,
                instrument,
                valueHelper
            );
        }

        return this.format[formatter["type"]]({
            options: formatter["options"],
            output: "HTML",
            value: instrument[propertyServer],
            valueHelper: valueHelper,
        });
    }

    /**
     * Format TCR for all types of output
     *
     * @param {number} value
     * @param {string} output
     */
    tcr(value, output) {
        // TODO - peers still have -9999
        if (value === -9999 || value === "-9999" || value == null) {
            value = null;
        }

        return this.format["tcr"]({
            options: {
                notAvailable: {
                    input: null,
                    output: "-",
                },
            },
            output: output,
            value: value,
            valueHelper: null,
        });
    }

    /**
     * Format a value suitable for CSV generation
     *
     * @param {string} property
     * @param {string} formatterName
     * @param {any} value
     * @param {object} valueHelper
     * @param {string} type
     *
     * @returns {string} a formatted string
     */
    text(property, formatterName, value, valueHelper, type) {
        return this._commonHtmlPdfText(
            property,
            formatterName,
            value,
            valueHelper,
            type,
            "TEXT"
        );
    }

    // ----------------------------------------------------- private methods
    _commonHtmlPdfText(
        property,
        formatterName,
        value,
        valueHelper,
        type,
        output
    ) {
        var formatter = this._getFormatter(
            property,
            formatterName,
            type.toLowerCase()
        );

        if (valueHelper != null) {
            var propertyServer = formatter["backendProperty"];

            if (propertyServer in valueHelper) {
                value = valueHelper[propertyServer];
            }

            this._resolveReferenceProperties(
                formatter,
                valueHelper,
                valueHelper
            );
        }

        return this.format[formatter["type"]]({
            options: formatter["options"],
            output: output,
            value: value,
            valueHelper: valueHelper,
        });
    }

    _getFormatter(property, formatterName, type) {
        var sourceType = type;
        if (this.properties == null || this.taxonomies == null) {
            console.warn("[formatter] Properties or taxonomies not set", {
                formatterName: formatterName,
                property: property,
                type: sourceType,
            });

            return this._getFormatterFallback(property);
        }

        type =
            type == null || !(type in this.properties)
                ? this.SERVER_TYPE_DEFAULT
                : type;

        if (!(property in this.properties[type])) {
            type = this.SERVER_TYPE_DEFAULT;
        }
        // if property is not even defined in SERVER_TYPE_DEFAULT
        if (!(property in this.properties[type])) {
            console.warn(
                [
                    "[formatter]",
                    property,
                    "for type",
                    sourceType,
                    "not found",
                ].join(" "),
                {
                    formatterName: formatterName,
                    property: property,
                    type: sourceType,
                }
            );

            return this._getFormatterFallback(property);
        }

        var formatter = this.properties[type][property]["formatter"];

        if (formatter[formatterName] == null) {
            throw new Error(
                `Formatter ${formatterName} for ${property} is missing`
            );
        }

        // taxon support
        if (formatter[formatterName]["type"] === "taxon") {
            formatter[formatterName]["options"]["taxonomy"] =
                this.taxonomies[formatter[formatterName]["taxonomyName"]];
        }

        return formatter[formatterName];
    }

    _getFormatterFallback(property) {
        return {
            backendProperty: property,
            options: {
                notAvailable: {
                    input: null,
                    output: "",
                },
            },
            type: "string",
        };
    }
    /**
     * Parses a property configuration and prepare options to be
     * suitable by formatting methods
     *
     * @param {string} property
     * @param {object} sourcePropertyConfiguration - raw data from
     *      fieldsConfiguration.json
     */
    _preparePropertyConfiguration(property, sourcePropertyConfiguration) {
        var propertyConfiguration = { ...sourcePropertyConfiguration };

        if (!propertyConfiguration.hasOwnProperty("notAvailable")) {
            console.warn(
                "[Misconfigured Property] notAvailable undefined ",
                property,
                propertyConfiguration
            );

            return propertyConfiguration;
        }

        var formatter = null;
        var formatters = propertyConfiguration["formatter"];
        if (formatters == null) {
            return propertyConfiguration;
        }

        var notAvailable = propertyConfiguration["notAvailable"];
        var options = null;
        var outputNotAvailable = null;

        for (var formatterName in formatters) {
            formatter = formatters[formatterName];
            options = formatter["options"];

            formatter["backendProperty"] =
                propertyConfiguration["backendProperty"];

            outputNotAvailable = options["notAvailable"]["output"];
            formatter["options"]["notAvailable"] = {
                input: notAvailable,
                output: outputNotAvailable,
            };

            formatter["referenceProperties"] =
                formatter["referenceProperties"] == null
                    ? null
                    : formatter["referenceProperties"];
        }

        return propertyConfiguration;
    }

    _resolveReferenceProperties(formatter, source, target) {
        if (target != null) {
            if (formatter["referenceProperties"] != null) {
                for (var key in formatter["referenceProperties"]) {
                    target[key] = source[formatter["referenceProperties"][key]];
                }
            }
        }
    }
    // --------------------------------------------------- getters / setters

    setProperties(properties) {
        var serverTypeDefault = this.SERVER_TYPE_DEFAULT;
        var serverTypes = this.SERVER_TYPES;

        var _properties = {};
        // specific properties for instruments (ETF, Index, Stock)
        var serverType = null;
        for (let i = 0, length = serverTypes.length; i < length; i++) {
            serverType = serverTypes[i];
            if (serverType in properties) {
                _properties[serverType.toLowerCase()] = properties[serverType];
            }
        }
        // default properties for instruments
        _properties[serverTypeDefault] = {};
        for (const property in properties) {
            if (!serverTypes.includes(property)) {
                _properties[serverTypeDefault][property] = properties[property];
            }
        }

        this.properties = { ..._properties };

        // setting up input for notAvailable
        for (const clientType in this.properties) {
            for (const property in this.properties[clientType]) {
                this.properties[clientType][property] =
                    this._preparePropertyConfiguration(
                        property,
                        this.properties[clientType][property]
                    );
            }
        }
    }

    setTaxonomies(taxonomies) {
        this.taxonomies = taxonomies;
    }
}
