import { useEffect, useState } from "react";
import { Product } from "../../types/Product";
import { useAuth } from "../useAuth";
import { useEnvironment } from "../useEnvironment";

type useProductProps = {
  data: any;
  productId: string | undefined;
  isTcrMode?: boolean;
};

// compute normalizationThreshold to be used in bar formatter
export const normalizeAndCutOutliers = (
  arrayOfObject: any[],
  property: string
) => {
  if (arrayOfObject.length) {
    var rawData = [...arrayOfObject]; // Copy
    var LENGTH = rawData.length;
    rawData = rawData.sort(function (a: any, b: any) {
      var aAbs = a ? Math.log(Math.abs(a[property])) : a;
      var bAbs = b ? Math.log(Math.abs(b[property])) : b;

      if (aAbs > bAbs) {
        return -1;
      }
      if (aAbs < bAbs) {
        return 1;
      }

      return 0;
    });

    var maxIndex = Math.min(LENGTH - 1, Math.floor((3 / 4) * (LENGTH + 1)));
    var minIndex = Math.floor((1 / 4) * (LENGTH + 1));
    var max = rawData[maxIndex][property];
    var min = rawData[minIndex][property];
    var IQR = max - min;
    var threshold = Math.abs(IQR * 1.5);

    for (var i = 0, length = arrayOfObject.length; i < length; i++) {
      arrayOfObject[i]["_s_normalizationThreshold"] = threshold;
    }
  }

  return arrayOfObject;
};

const peerWeightSort = (data: any, isTcrMode: boolean = false) => {
  if (isTcrMode) {
    // sort by rate (D->A), weight (10 -> 0)
    data.sort(function (a: any, b: any) {
      var sortByL1 = "rate";
      var sortByL2 = "weight";

      if (a[sortByL1] > b[sortByL1]) {
        return 1;
      }

      if (a[sortByL1] < b[sortByL1]) {
        return -1;
      }

      if (a[sortByL2] > b[sortByL2]) {
        return -1;
      }

      if (a[sortByL2] < b[sortByL2]) {
        return 1;
      }

      return 0;
    });
  } else {
    data.sort(function (a: any, b: any) {
      // sort by weight weight (10 -> 0)
      var sortByL1 = "weight";

      if (a[sortByL1] > b[sortByL1]) {
        return -1;
      }

      if (a[sortByL1] < b[sortByL1]) {
        return 1;
      }

      return 0;
    });
  }
};

const peerWeightData = (
  rawData: any,
  dataType: any,
  isTcrMode: boolean = false
) => {
  var data = [];
  var item;

  for (let id in rawData) {
    item = rawData[id];
    data.push({
      cardinality: item["card"],
      constituents: item["constituents"],
      id: id,
      rate: item["rate"],
      type: dataType,
      weight: item["weight"],
    });
  }

  peerWeightSort(data, isTcrMode);

  return data;
};

export const useProduct = ({
  data,
  productId,
  isTcrMode = false,
}: useProductProps): Product | null => {
  const { isLogged } = useAuth();
  const { environment } = useEnvironment();
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    if (data != null) {
      let analytics: any = null;

      let benchmark: any = null;
      let calendarRebalance: any = null;
      let holdings: any = [];
      let info: any = null;
      let name: string = "";
      let peerWeights: any = {
        Country: [],
        CountryLong: [],
        CountryShort: [],
        Industry: [],
        IndustryShort: [],
        IndustryLong: [],
        Sector: [],
        SectorShort: [],
        SectorLong: [],
      };
      let priceList: any = [];
      let priceListLastDate: any;
      let priceBenchmark: any = [];
      let securities: any = {};
      let strategy: any = null;
      let type: string = "";

      let strategyAnalytics = {
        priceAnalytics: {},
        hPosAnalytics: {},
      };

      info = { ...data.info };

      name = info.name;

      type = data.type;

      securities = data.fetch.reduce((accumulator: any, item: any) => {
        accumulator[String(item.symbol)] = item;
        return accumulator;
      }, {});

      benchmark = data.benchmark;

      peerWeights.Country = peerWeightData(
        data.peerWeightsIndustry.l2,
        "where",
        isTcrMode
      );

      if (data.peerWeightsIndustryShort) {
        peerWeights.CountryShort = peerWeightData(
          data.peerWeightsIndustryShort.l2,
          "where",
          isTcrMode
        );
      }

      if (data.peerWeightsIndustryLong) {
        peerWeights.CountryLong = peerWeightData(
          data.peerWeightsIndustryLong.l2,
          "where",
          isTcrMode
        );
      }

      peerWeights.Industry = peerWeightData(
        data.peerWeightsIndustry.l1,
        "what",
        isTcrMode
      );

      if (data.peerWeightsIndustryShort) {
        peerWeights.IndustryShort = peerWeightData(
          data.peerWeightsIndustryShort.l1,
          "what",
          isTcrMode
        );
      }

      if (data.peerWeightsIndustryLong) {
        peerWeights.IndustryLong = peerWeightData(
          data.peerWeightsIndustryLong.l1,
          "what",
          isTcrMode
        );
      }

      peerWeights.Sector = peerWeightData(
        data.peerWeightsSector.l1,
        "what",
        isTcrMode
      );

      if (data.peerWeightsSectorShort) {
        peerWeights.SectorShort = peerWeightData(
          data.peerWeightsSectorShort.l1,
          "what",
          isTcrMode
        );
      }

      if (data.peerWeightsSectorLong) {
        peerWeights.SectorLong = peerWeightData(
          data.peerWeightsSectorLong.l1,
          "what",
          isTcrMode
        );
      }

      if (isLogged) {
        holdings = data.v.map((item: any) => ({
          ...securities[item.symbol],
          weight: item.weight,
        }));
      } else {
        let blockedHoldings = [];
        for (let i = 0; i < data?.v.length; i++) {
          if (i > 4) {
            break;
          }
          blockedHoldings.push(data.v[i]);
        }

        holdings = [];

        for (const item of blockedHoldings) {
          if (item.symbol in securities && securities[item.symbol] != null) {
            holdings.push({
              ...securities[item.symbol],
              weight: item.weight,
            });
          }
        }
      }

      calendarRebalance = data.calendarRebalance;

      priceList = data.H;

      priceListLastDate = data?.H?.[data?.H?.length - 1]?.d;

      priceBenchmark = data.B;

      strategyAnalytics.priceAnalytics = data?.priceAnalytics?.stats;
      strategyAnalytics.hPosAnalytics = data?.hPosAnalytics?.stats;

      // const strategies = new Strategies(environment);
      // strategy = strategies.decode(data.strategy);

      setProduct({
        analytics,
        benchmark,
        calendarRebalance,
        holdings,
        info,
        isTcrMode,
        name,
        peerWeights,
        priceList,
        priceListLastDate,
        priceBenchmark,
        productId: productId ?? "",
        securities,
        strategy,
        type,
        strategyAnalytics,
      } as Product);
    }
  }, [environment, data, productId, isLogged, isTcrMode]);

  return product;
};
