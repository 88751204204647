import { Col, Container, Row } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppAuthenticated } from "./AppAuthenticated";
import { ScrollToTop } from "./components/ScrollToTop";
import { useAppContext } from "./hooks/useEnvironment";
import { Capture } from "./pages/Capture";

export const App = () => {
    const isReady = useAppContext();

    if (!isReady) {
        // TODO put loading banner
        return (
            <Container className="mb-2">
                <Row className="mb-2">
                    <Col>
                        <div className="d-flex justify-content-center mt-5">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route path="/capture">
                    <Capture />
                </Route>
                <Route path="*">
                    <AppAuthenticated />
                </Route>
            </Switch>
        </Router>
    );
};
