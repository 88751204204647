import { ReactNode, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Login } from "../components/AuthenticationWizard/Login";
import { Registration } from "../components/AuthenticationWizard/Registration";
import { SuccessStep } from "../components/AuthenticationWizard/SuccessStep";
import { ChangePassword } from "../components/ChangePassword";
import { ProfileEditForm } from "../components/ProfileEditForm";
import { eventBus } from "../eventBus";
import { useAuth } from "../hooks/useAuth";
import { useRouter } from "../hooks/useRouter";
import { paths } from "../types/Defaults";

type HeaderProps = {
  children?: ReactNode;
  className?: string;
  viewType: "longOnly" | "longShort" | "portfolios";
};

type LoginData = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  firm: string;
  country: string;
  role: string;
};

type HeaderUserBoxProps = {
  setShowUserBox: Function;
  initialTab?: WizardStateType;
};

type EditProfileInfo = {
  id: number;
  email: string;
  profile: string;
  firstName: string;
  lastName: string;
  country: string;
  firm: string;
};

type WizardStateType = "Login" | "Registration" | "Success";

const HeaderUserBox = ({ setShowUserBox, initialTab }: HeaderUserBoxProps) => {
  const { isReady, isLogged, register, signin, signout, user, editProfile } =
    useAuth();
  const router = useRouter();
  const [isLogging, setIsLogging] = useState(false);
  const [loginFailedMessage, setLoginFailedMessage] = useState<string>("");
  const [hasLoginFailed, setLoginFailed] = useState<boolean>(false);
  const [showRegisterMessage, setShowRegisterMessage] =
    useState<boolean>(false);
  const [wizardState, setWizardState] = useState<WizardStateType>(
    initialTab ?? "Login"
  );

  const wizardSteps = useMemo(
    () => ({
      login: "Login",
      registration: "Registration",
      success: "Success",
      goToLogin: () => setWizardState("Login"),
      goToRegistration: () => setWizardState("Registration"),
      goToSuccess: () => setWizardState("Success"),
    }),
    []
  );

  const handleRegister = ({
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    firm,
    country,
    role,
  }: LoginData) => {
    setShowRegisterMessage(true);
    const registrationData = {
      email,
      firstName,
      lastName,
      profile: role,
      userName: email, // TODO check if it is ok, same as email for now
      country,
      firm,
    };
    register(registrationData).then(() =>
      setTimeout(() => {
        setShowRegisterMessage(false);
      }, 1000)
    );
  };

  const handleLogout = () => {
    if (isReady) {
      signout().then(() => {
        setShowUserBox(false);
      });
    }
  };

  const handlePasswordChange = ({
    id,
    password,
  }: {
    id: number;
    password: string;
  }) => {
    if (isReady) {
      return editProfile({
        id,
        password,
      });
    }
  };

  const handleEditProfile = ({
    id,
    email,
    profile,
    firstName,
    lastName,
    country,
    firm,
  }: EditProfileInfo) => {
    if (isReady) {
      return editProfile({
        id,
        email,
        profile,
        firstName,
        lastName,
        country,
        firm,
      });
    }
  };

  const handleLogin = ({ email, password }: LoginData) => {
    if (isReady) {
      setIsLogging(true);
      signin(email, password).then(
        (response: any) => {
          setIsLogging(false);
          setLoginFailed(false);
          if (router.pathname === "/") {
            // If home, redirect to product page
            setTimeout(() => router.push("/product"), 300);
          }
          setShowUserBox(false);
        },
        (error: any) => {
          setIsLogging(false);
          setLoginFailed(true);
          if (error.type === "USER_NOT_FOUND") {
            setLoginFailedMessage("Your email and/or password is not correct.");
          } else {
            if (error.message != null) {
              setLoginFailedMessage(error.message);
            } else {
              // TODO what is that?
              setLoginFailedMessage(
                "An unknown error occurred. Please contact Trendrating."
              );
            }
          }
        }
      );
    }
  };

  const AuthWizardComponent = () => {
    switch (wizardState) {
      case wizardSteps.registration:
        return (
          <Registration
            showRegisterMessage={showRegisterMessage}
            handleRegister={handleRegister}
            hasLoginFailed={hasLoginFailed}
            loginFailedMessage={loginFailedMessage}
            navigateToLogin={wizardSteps.goToLogin}
            navigateToSuccess={wizardSteps.goToSuccess}
          />
        );

      case wizardSteps.success:
        return <SuccessStep />;

      case wizardSteps.login:
      default:
        return (
          <Login
            handleLogin={handleLogin}
            hasLoginFailed={hasLoginFailed}
            loginFailedMessage={loginFailedMessage}
            showRegisterMessage={showRegisterMessage}
            navigateToRegistration={wizardSteps.goToRegistration}
          />
        );
    }
  };

  return (
    <div className="header-user-box">
      <Row>
        <Col sm={12}>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              className="text-light text-decoration-none"
              onClick={() => setShowUserBox(false)}
              variant="link"
            >
              <i className="bi bi-x-lg smaller-icon"></i>
              &nbsp;&nbsp;Close
            </Button>
          </div>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col sm={12}>
            <div className="d-flex justify-content-center align-items-center pt-4 pt-sm-0 flex-column">
              {isLogged ? (
                <div className="text-center">
                  <ProfileEditForm
                    userData={user}
                    editHandler={handleEditProfile}
                  />
                  <ChangePassword
                    userData={user}
                    changeUserPassword={handlePasswordChange}
                  />
                  <div className="mt-5">
                    <Button
                      className="btn btn-outline-light"
                      onClick={handleLogout}
                    >
                      Logout {user.firstName} {user.lastName}
                    </Button>
                  </div>
                </div>
              ) : isLogging ? (
                <div className="fs-4 text-light">Logging user...</div>
              ) : (
                <AuthWizardComponent />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const Header = ({ children, className, viewType }: HeaderProps) => {
  const { isReady, isLogged, user } = useAuth();
  const [showUserBox, setShowUserBox] = useState<boolean>(false);

  const [predefinedTab, setPredefinedTab] = useState<
    WizardStateType | undefined
  >();

  const busShowUserBox: any = (showBox: boolean) => {
    setShowUserBox((prevShowBox) => {
      if (prevShowBox === showBox) {
        // If the box is already open, still move the page to the top
        window.scrollTo(0, 0);
      }
      return showBox;
    });
  };

  useEffect(() => {
    eventBus.on("show-user-login-box", busShowUserBox);
    return () => {
      eventBus.remove("show-user-login-box", busShowUserBox);
    };
  }, []);

  useEffect(() => {
    eventBus.on("show-user-registration-box", () => {
      setPredefinedTab("Registration");
      busShowUserBox(true);
    });
    return () => {
      eventBus.remove("show-user-registration-box", () => {
        setPredefinedTab("Registration");
        busShowUserBox(true);
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (showUserBox) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showUserBox]);

  if (!isReady) {
    return (
      <header>
        <Container fluid className="h-100">
          <Row className="h-100 d-flex align-items-center">
            <Col className="col-auto">
              <Link to={paths.home} className="text-decoration-none">
                <img
                  className="img-fluid"
                  src="/images/logo.png"
                  alt="Trendrating"
                />
              </Link>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }

  return (
    <header className="position-relative">
      <Container fluid className="h-100 pb-2 pb-md-0">
        <Row className="h-100 d-flex align-items-center">
          <Col className="col-5 pb-2 pb-md-0 col-md-auto order-1">
            <Link to={paths.home} className="text-decoration-none">
              <img
                className="img-fluid"
                src="/images/logo.png"
                alt="Trendrating"
              />
            </Link>
          </Col>
          {children ? (
            <Col className="col-12 col-md-auto ms-auto order-3 order-md-2 pt-2 pt-md-0">
              {children}
            </Col>
          ) : null}
          <Col
            className={`col-7 col-md-auto ${
              children ? "" : "ms-auto"
            } order-2 order-md-3`}
          >
            <Button
              className={`header-user text-end text-truncate w-100 shadow-none text-decoration-none text-light ${
                showUserBox ? "active" : ""
              }`}
              onClick={() => setShowUserBox((showUserBox) => !showUserBox)}
              variant={showUserBox ? "primary" : "link"}
            >
              <i className="bi bi-person-circle"></i>{" "}
              {isLogged
                ? `${user.firstName} ${user.lastName}`
                : "Log In / Sign Up"}
            </Button>
            {showUserBox ? (
              <HeaderUserBox
                initialTab={predefinedTab}
                setShowUserBox={setShowUserBox}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </header>
  );
};
