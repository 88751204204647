import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ColumnDefinition,
  ColumnDefinitionAlign,
  Formatter,
  Options,
} from "tabulator-tables";
import { Grid } from "../../components/Grid";
import { useFormatter } from "../../hooks/useFormatter";

type ProductDetailAnalyticsGridYearlyProps = {
  analyticsDetail: any;
  callback?: Function;
  info: any;
};

export const ProductDetailAnalyticsGridYearly = ({
  analyticsDetail,
  callback,
  info,
}: ProductDetailAnalyticsGridYearlyProps) => {
  const formatter = useFormatter();
  const { t /*, i18n */ } = useTranslation();

  const columns: ColumnDefinition[] = useMemo(() => {
    const percentageFormatter: Formatter = (
      cell,
      formatterParams,
      onRendered
    ) => {
      return formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: cell.getValue(),
        valueHelper: {
          normalizationThreshold: 1,
        },
      });
    };

    const percentageColoredFormatter: Formatter = (
      cell,
      formatterParams,
      onRendered
    ) => {
      return formatter.custom("number", {
        options: {
          colored: "positive",
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: cell.getValue(),
        valueHelper: {
          normalizationThreshold: 1,
        },
      });
    };

    const percentageColoredFormatterWithBar: Formatter = (
      cell,
      formatterParams,
      onRendered
    ) => {
      const data: any = cell.getData();
      return formatter.custom("bar", {
        options: {
          colored: "positive",
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: cell.getValue(),
        valueHelper: {
          normalizationThreshold: data._s_normalizationThreshold,
        },
      });
    };

    return [
      {
        hozAlign: "right",
        vertAlign: "bottom",
        field: "timeFrame",
        title: t("Year"),
        formatter: (cell, formatterParams, onRendered) => {
          const value = cell.getValue();
          if (value === "Annualized") {
            return value;
          } else if (value === "average") {
            return "Average";
          }
          return formatter.custom("date", {
            options: {
              format: ["Y"],
              isMillisecond: false,
              notAvailable: {
                input: null,
                output: "",
              },
              separator: " ",
            },
            output: "HTML",
            value: parseInt(value),
            valueHelper: null,
          });
        },
      },
      {
        //create column group
        title: "Yearly performance",
        columns: [
          {
            hozAlign: "right" as ColumnDefinitionAlign,
            field: "strategyPerformance",
            title: t("Portfolio"),
            formatter: percentageFormatter,
          },
          {
            hozAlign: "right" as ColumnDefinitionAlign,
            field: "benchmarkPerformance",
            title: t("Benchmark"),
            formatter: percentageFormatter,
          },
          {
            hozAlign: "right" as ColumnDefinitionAlign,
            field: "deltaPerformance",
            title: t("Differences"),
            formatter: percentageColoredFormatterWithBar,
          },
        ],
      },
      {
        //create column group
        title: "Max drawdown",
        columns: [
          {
            hozAlign: "right" as ColumnDefinitionAlign,
            field: "strategyMaxDrawdown",
            title: t("Portfolio"),
            formatter: percentageFormatter,
          },
          {
            hozAlign: "right" as ColumnDefinitionAlign,
            field: "benchmarkMaxDrawdown",
            title: t("Benchmark"),
            formatter: percentageFormatter,
          },
          {
            hozAlign: "right" as ColumnDefinitionAlign,
            field: "deltaMaxDrawdown",
            title: t("Differences"),
            formatter: percentageColoredFormatter,
          },
        ],
      },
      {
        //create column group
        title: "Volatility",
        columns: [
          {
            hozAlign: "right" as ColumnDefinitionAlign,
            field: "strategyVolatility",
            title: t("Portfolio"),
            formatter: percentageFormatter,
          },
          {
            hozAlign: "right" as ColumnDefinitionAlign,
            field: "benchmarkVolatility",
            title: t("Benchmark"),
            formatter: percentageFormatter,
          },
          {
            hozAlign: "right" as ColumnDefinitionAlign,
            field: "deltaVolatility",
            title: t("Differences"),
            formatter: percentageColoredFormatter,
          },
        ],
      },
    ];
  }, [formatter, t]);

  const options: Options = useMemo(() => {
    return {
      layout: "fitColumns",
    };
  }, []);

  if (analyticsDetail == null) {
    return <>"No data"</>;
  }

  return (
    <div className="mt-3">
      <Grid
        className="tabulator-sm"
        columns={columns}
        data={analyticsDetail}
        callback={callback}
        options={options}
      />
    </div>
  );
};
