import { FormEvent, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useInput } from "../hooks/useInput";
import { useSignUpValidator } from "../hooks/useValidator";
import { CountrySelect, Select } from "./Select";

type ProfileEditProps = {
    editHandler: Function;
    userData: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        userName: string;
        role: string;
        profile: string;
        type: string;
        firm: string;
        creationTime: Date;
        active: boolean;
        country: string;
        products: string;
        contractType: string;
        productMax: number;
        billingStart: string;
        conversionDate: string;
        owner: string;
        stripeId: string;
    };
};

export function ProfileEditForm({ userData, editHandler }: ProfileEditProps) {
    const [checkValidation, startingCheckValidation] = useState(false);
    const [success, setSuccess] = useState<undefined | boolean>();
    const [isLoading, setIsLoading] = useState(false);

    const {
        id: userId,
        email: userEmail,
        profile: userInvestorProfile,
        firstName: userFirstName,
        lastName: userLastName,
        country: userCountry,
        firm: userFirm,
    } = userData;
    const email = useInput(userEmail);
    const firstName = useInput(userFirstName);
    const lastName = useInput(userLastName);
    const firm = useInput(userFirm);
    const country = useInput(userCountry);
    const role = useInput(userInvestorProfile);

    const paramsEdit = {
        email: userData.email,
        firstName: firstName.value,
        lastName: lastName.value,
        firm: firm.value,
        country: country.value,
        role: role.value,
    };

    const { state, isFormValid } = useSignUpValidator(paramsEdit);

    const validInputs = state;

    const addErrorClass = (isInputValid: boolean) => {
        let className = "";
        if (checkValidation) {
            if (!isInputValid) {
                className = "input-error-state";
            }
        }
        return className;
    };

    const firstNameErrorClass = addErrorClass(validInputs.firstName.isValid);
    const lastNameErrorClass = addErrorClass(validInputs.lastName.isValid);
    const firmErrorClass = addErrorClass(validInputs.firm.isValid);
    const countryErrorClass = addErrorClass(validInputs.country.isValid);
    const roleErrorClass = addErrorClass(validInputs.role.isValid);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        startingCheckValidation(true);
        if (isFormValid) {
            setIsLoading(true);
            editHandler({
                id: userId,
                email: email.value,
                firstName: firstName.value,
                lastName: lastName.value,
                country: country.value,
                firm: firm.value,
                profile: role.value,
            })
                .then(() => {
                    setIsLoading(false);
                    setSuccess(true);
                })
                .catch((err: any) => {
                    err && setSuccess(false);
                });
        }
    };

    const MessageHandler = () => {
        if (isLoading) {
            return (
                <div className="alert alert-primary fade show" role="alert">
                    Loading ...
                </div>
            );
        } else if (checkValidation) {
            if (success) {
                return (
                    <div className="alert alert-success" role="alert">
                        Your profile has been correctly updated
                    </div>
                );
            } else {
                return (
                    <div className="alert alert-danger" role="alert">
                        something went wrong the profile was not updated
                    </div>
                );
            }
        }
    };

    const showMessage = MessageHandler();

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Row className="mt-2">
                        <Col>
                            <div className="form-signin">
                                <h1 className="h3 mb-3 fw-normal text-center">
                                    Change your profile info
                                </h1>
                                {showMessage}
                                <form
                                    onSubmit={handleSubmit}
                                    className="mt-3 mb-3"
                                    id="edit-form"
                                >
                                    <Row className="g-3">
                                        <Col sm={6}>
                                            <label
                                                className="text-light input-label"
                                                htmlFor="floatingInputEmail"
                                            >
                                                Email address
                                            </label>
                                            <input
                                                disabled={true}
                                                value={userData.email}
                                                type="email"
                                                className="form-control"
                                                id="floatingInputEmail"
                                                name="email"
                                                autoComplete="off"
                                                placeholder=""
                                                data-bs-toggle="tooltip"
                                                title="Email field is not editable"
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Select
                                                className={`form-control ${roleErrorClass} ${
                                                    validInputs.role.isValid
                                                        ? "valid-state"
                                                        : ""
                                                }`}
                                                selectedValue={role.value}
                                                onSelect={role.onchange}
                                                placeholder="Choose an option"
                                                options={[
                                                    "Portfolio_Manager",
                                                    "Fund_Manager",
                                                    "Wealth_Manager",
                                                    "Financial_Advisor",
                                                    "Hedge_Fund_Manager",
                                                    "Family_office",
                                                    "Broker",
                                                    "Others",
                                                ]}
                                                selectTitle="Investor profile"
                                            >
                                                {checkValidation ? (
                                                    validInputs.role
                                                        .isValid ? undefined : (
                                                        <div className="validation-error-message">
                                                            <p>
                                                                {
                                                                    validInputs
                                                                        .role
                                                                        .message
                                                                }
                                                            </p>
                                                        </div>
                                                    )
                                                ) : undefined}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="g-3 mt-1">
                                        <Col sm={6}>
                                            <label
                                                className="text-light input-label"
                                                htmlFor="floatingInputFirstName"
                                            >
                                                First Name
                                            </label>
                                            <input
                                                value={firstName.value}
                                                onChange={firstName.onchange}
                                                type="text"
                                                className={`form-control ${firstNameErrorClass} ${
                                                    validInputs.firstName
                                                        .isValid
                                                        ? "valid-state"
                                                        : ""
                                                }`}
                                                id="floatingInputFirstName"
                                                name="firstName"
                                                autoComplete="off"
                                                placeholder=""
                                            />
                                            {checkValidation ? (
                                                validInputs.firstName
                                                    .isValid ? null : (
                                                    <div className="validation-error-message">
                                                        <p>
                                                            {
                                                                validInputs
                                                                    .firstName
                                                                    .message
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            ) : null}
                                        </Col>
                                        <Col sm={6}>
                                            <label
                                                className="text-light input-label"
                                                htmlFor="floatingLastName"
                                            >
                                                Last Name
                                            </label>
                                            <input
                                                value={lastName.value}
                                                onChange={lastName.onchange}
                                                type="text"
                                                className={`form-control ${lastNameErrorClass} ${
                                                    validInputs.lastName.isValid
                                                        ? "valid-state"
                                                        : ""
                                                }`}
                                                id="floatingLastName"
                                                name="lastName"
                                                autoComplete="off"
                                                placeholder=""
                                            />
                                            {checkValidation ? (
                                                validInputs.lastName
                                                    .isValid ? null : (
                                                    <div className="validation-error-message">
                                                        <p>
                                                            {
                                                                validInputs
                                                                    .lastName
                                                                    .message
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row className="g-3 mt-1">
                                        <Col sm={6}>
                                            <CountrySelect
                                                className={`form-control ${countryErrorClass} ${
                                                    validInputs.country.isValid
                                                        ? "valid-state"
                                                        : ""
                                                }`}
                                                selectedValue={country.value}
                                                onSelect={country.onchange}
                                            >
                                                {checkValidation ? (
                                                    validInputs.country
                                                        .isValid ? undefined : (
                                                        <div className="validation-error-message">
                                                            <p>
                                                                {
                                                                    validInputs
                                                                        .country
                                                                        .message
                                                                }
                                                            </p>
                                                        </div>
                                                    )
                                                ) : undefined}
                                            </CountrySelect>
                                        </Col>
                                        <Col sm={6}>
                                            <label
                                                className="text-light input-label"
                                                htmlFor="floatingInputFirm"
                                            >
                                                Firm
                                            </label>
                                            <input
                                                value={firm.value}
                                                onChange={firm.onchange}
                                                type="text"
                                                className={`form-control ${firmErrorClass} ${
                                                    validInputs.firm.isValid
                                                        ? "valid-state"
                                                        : ""
                                                }`}
                                                id="floatingInputFirm"
                                                name="firm"
                                                autoComplete="off"
                                                placeholder=""
                                            />
                                            {checkValidation ? (
                                                validInputs.firm
                                                    .isValid ? null : (
                                                    <div className="validation-error-message">
                                                        <p>
                                                            {
                                                                validInputs.firm
                                                                    .message
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            ) : null}
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        * Email is not editable if you need to change it, please
                        contact our customer service
                    </p>
                    <Button
                        className="w-50 me-2 btn btn-outline-light mt-2"
                        form="edit-form"
                        type="submit"
                        disabled={isLoading ? true : false}
                    >
                        Update Profile
                    </Button>
                </Col>
            </Row>
        </>
    );
}
