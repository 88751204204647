import { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { paths } from "../types/Defaults";

type FooterProps = {
    children?: ReactNode;
    className?: string;
};

const FooterLinks = () => {
    return (
        <div className="text-center d-md-flex justify-content-end">
            <Link
                className="px-1 px-sm-2 px-md-4 py-2 py-md-0 d-block d-sm-inline text-light text-decoration-none"
                to={paths.disclaimer}
            >
                Disclaimer
            </Link>
            <Link
                className="px-1 px-sm-2 px-md-4 py-2 py-md-0 d-block d-sm-inline text-light text-decoration-none"
                to={paths.privacy}
            >
                Privacy policy
            </Link>
            <Link
                className="px-1 px-sm-2 px-md-4 py-2 py-md-0 d-block d-sm-inline text-light text-decoration-none"
                to={paths.termsOfService}
            >
                Terms of Service
            </Link>
        </div>
    );
};

export const Footer = ({ children, className }: FooterProps) => {
    return (
        <footer
            className={`footer-page d-flex align-items-center ${
                className ?? ""
            }`}
        >
            <Container fluid>
                <Row>
                    <Col
                        sm={4}
                        className="py-2 py-sm-0 text-center text-md-start"
                    >
                        &copy; {new Date().getFullYear()} Trendrating
                    </Col>
                    {children ? (
                        <>
                            <Col sm={4}>{children}</Col>
                            <Col sm={4}>
                                <FooterLinks />
                            </Col>
                        </>
                    ) : (
                        <Col sm={8}>
                            <FooterLinks />
                        </Col>
                    )}
                </Row>
            </Container>
        </footer>
    );
};
